import React, { useEffect, useState } from 'react';
import {
  Divider,
  Grid,
  NotFound,
  Paper,
  Tab,
  Tabs,
  Typography,
  TrendBox,
  FormControl,
  Select,
  MenuItem,
  Stack,
  Box
} from '@esgian/esgianui';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  getOperatorPercentageCo2Emission,
  getOperatorEmissionHistoryDetails
} from '@api/Emissions';
import { OperatorEmissionTable, OperatorEmissionChart } from './index';
import {
  EmissionsDisclaimer,
  TextWithTooltipIcon,
  PublicBetaEmissionsDisclaimer
} from '@components';
import AccreditationStatementModal from '../../../../../Modals/AccreditationStatementModal';
import { useSegment } from '@hooks';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';

function OperatorEmissionsSection({ operatorShortName }) {
  const [percentageChangeData, setPercentageChangeData] = useState(null);
  const [emissionHistoryData, setEmissionHistoryData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showStatement, setShowStatement] = useState(false);
  const [noData, setNoData] = useState(true);
  const [periodSelect, setPeriodSelect] = useState(1);
  const params = useParams();
  const operatorType = useSelector(getOperatorType);
  const { isRoRo, segment } = useSegment();
  const [selectedUnitTab, setSelectedUnitTab] = useState(isRoRo ? 1 : 3);
  const commercialCategoryType = useSelector(getCommercialCategoryType);

  useEffect(() => {
    window.scrollTo(0, 0);
    const controller = new AbortController();
    const { signal } = controller;

    const getPercentageChangeData = async (operatorId) => {
      try {
        setLoading(true);
        await getOperatorPercentageCo2Emission(
          {
            operatorId: operatorId,
            SegmentTypeId: segment.id,
            OperatorTypeId: operatorType,
            CommercialCategoryId: commercialCategoryType
          },
          signal
        ).then((result) => {
          setPercentageChangeData(result);
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };
    getPercentageChangeData(params.id);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [commercialCategoryType]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    let periodValue = 'monthly';
    if (periodSelect === 3) {
      periodValue = 'yearly';
    } else if (periodSelect === 2) {
      periodValue = 'quarterly';
    }

    const getEmissionHistoryData = async () => {
      try {
        setLoading(true);
        setNoData(false);

        await getOperatorEmissionHistoryDetails(
          {
            OperatorId: params.id,
            PeriodType: periodValue,
            SegmentTypeId: segment.id,
            OperatorTypeId: operatorType,
            CommercialCategoryId: commercialCategoryType
          },
          signal
        ).then((result) => {
          setEmissionHistoryData(result);
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };
    getEmissionHistoryData(params.id);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [periodSelect, commercialCategoryType]);

  const { percentChangeTotalCo2, percentChangeCo2ByCeu, last12to1MonthsOperatorEmissionDetails } =
    percentageChangeData || {};

  const last12to1Months = last12to1MonthsOperatorEmissionDetails || {};
  const { totalCo2: last12to1MonthsTotalCo2, co2ByCeu: last12to1MonthsCo2ByCeu } = last12to1Months;

  const handlePeriodSelect = ({ target }) => {
    setPeriodSelect(target.value);
  };

  return (
    <Grid item xs={12}>
      <Paper sx={{ display: 'flex' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack spacing={2}>
              <Stack sx={{ p: 2 }} direction={'row'} justifyContent={'space-between'}>
                <Stack>
                  <Typography variant={'h6'}>
                    CO<sub>2</sub> emission History
                  </Typography>
                  <FormControl variant="standard" sx={{ mt: 1, minWidth: 100 }}>
                    <Select
                      variant={'standard'}
                      value={periodSelect}
                      labelId="operator-emission-history-select-label"
                      onChange={handlePeriodSelect}
                      id="operator-emission-history-select">
                      <MenuItem value={1}>Monthly</MenuItem>
                      <MenuItem value={2}>Quarterly</MenuItem>
                      <MenuItem value={3}>Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                <Stack direction={'row'} spacing={2}>
                  <TrendBox
                    loading={loading}
                    mainValue={last12to1MonthsTotalCo2?.toFixed(0)}
                    paperVariant={'outlined'}
                    header={
                      <TextWithTooltipIcon
                        label={
                          <Typography variant={'subtitle1'} bold>
                            Total tCO<sub>2</sub>
                          </Typography>
                        }
                        tooltipText={
                          'Total tCO2 for the last 12 months compared to the previous set of 12 months'
                        }
                      />
                    }
                    percent={percentChangeTotalCo2 ? Number(percentChangeTotalCo2?.toFixed(1)) : 0}
                  />
                  {isRoRo && (
                    <TrendBox
                      loading={loading}
                      mainValue={last12to1MonthsCo2ByCeu ? last12to1MonthsCo2ByCeu?.toFixed(2) : ''}
                      paperVariant={'outlined'}
                      header={
                        <TextWithTooltipIcon
                          label={
                            <Typography variant={'subtitle1'} bold>
                              tCO<sub>2</sub>/CEU
                            </Typography>
                          }
                          tooltipText={
                            'tCO2/CEU for the last 12 months compared to the previous set of 12 months'
                          }
                        />
                      }
                      percent={
                        percentChangeCo2ByCeu ? Number(percentChangeCo2ByCeu?.toFixed(2)) : 0
                      }
                    />
                  )}
                </Stack>
              </Stack>
              <Stack>
                <Tabs
                  value={selectedUnitTab}
                  onChange={(_, value) => {
                    setSelectedUnitTab(value);
                  }}>
                  {isRoRo && (
                    <Tab
                      value={1}
                      id="co2-ceu-tab"
                      label={
                        <TextWithTooltipIcon
                          label={
                            <Typography variant={'subtitle2'}>
                              CO<sub>2</sub>/CEU
                            </Typography>
                          }
                          tooltipText={
                            'Tonnes of CO₂ emission per CEU(Car equivalent Unit) for the operator based on the fleet owned by the operator in the selected time period.'
                          }
                        />
                      }
                    />
                  )}
                  {isRoRo && (
                    <Tab
                      id="gt-nm-tab"
                      value={2}
                      label={
                        <TextWithTooltipIcon
                          label={
                            <Typography variant={'subtitle2'}>
                              CO<sub>2</sub>/(GT&#8226;NM)
                            </Typography>
                          }
                          tooltipText={
                            'Grams of CO₂ emission per GT(Gross tonnage) capacity and distance travelled(NM- nautical miles) for the operator based on the fleet owned in the selected time period.'
                          }
                        />
                      }
                    />
                  )}
                  <Tab
                    id="dwt-nm-tab"
                    value={3}
                    label={
                      <TextWithTooltipIcon
                        label={
                          <Typography variant={'subtitle2'}>
                            CO<sub>2</sub>/(DWT&#8226;NM)
                          </Typography>
                        }
                        tooltipText={
                          'Grams of CO₂ emission per DWT(Deadweight) capacity and distance travelled(NM- nautical miles) for the operator based on the fleet owned in the selected time period.'
                        }
                      />
                    }
                  />
                  <Tab
                    id="total-co2-tab"
                    value={4}
                    label={
                      <TextWithTooltipIcon
                        label={
                          <Typography variant={'subtitle2'}>
                            Total CO<sub>2</sub>
                          </Typography>
                        }
                        tooltipText={
                          'Tonnes of CO₂ emission of the operator for the fleet owned in the selected time period.'
                        }
                      />
                    }
                  />
                </Tabs>
                <Divider />
              </Stack>
              {!noData && (
                <Box sx={{ p: 2 }}>
                  <Stack spacing={2}>
                    {isRoRo ? (
                      <EmissionsDisclaimer setShowStatement={setShowStatement} />
                    ) : (
                      <PublicBetaEmissionsDisclaimer />
                    )}
                    <OperatorEmissionChart
                      selectedUnitTab={selectedUnitTab}
                      periodSelect={periodSelect}
                      loading={loading}
                      emissionHistoryData={emissionHistoryData}
                      fileName={`${operatorShortName}-co2-emission-history`}
                    />
                  </Stack>
                </Box>
              )}
              {!loading && noData && (
                <Box sx={{ p: 2 }}>
                  <NotFound
                    header={'No Matching Result'}
                    text={'Please change your search parameters'}
                  />
                </Box>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {!noData && (
              <OperatorEmissionTable
                loading={loading}
                periodSelect={periodSelect}
                emissionHistoryData={emissionHistoryData}
                operatorShortName={operatorShortName}
                fileName={`${operatorShortName}-co2-emission-history`}
              />
            )}
          </Grid>
        </Grid>
      </Paper>
      <AccreditationStatementModal
        handleClose={() => setShowStatement(false)}
        open={showStatement}
      />
    </Grid>
  );
}

OperatorEmissionsSection.propTypes = {
  operatorShortName: PropTypes.string
};

OperatorEmissionsSection.defaultProps = {
  operatorShortName: ''
};

export default OperatorEmissionsSection;
