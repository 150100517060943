import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Stack,
  Table,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableCell,
  TableRow
} from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess, formatNumberReadable } from '@helpers';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

function MonthlyPortWaitingTable({
  loading,
  selectedMeasure,
  portCongestionData,
  yearOptions,
  setSelectedYear,
  selectedYear
}) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const tableData = useMemo(() => {
    if (!portCongestionData) return [];
    const { operatorCongestions } = portCongestionData;
    let tempData = [];
    operatorCongestions?.map((operator) => {
      const { years } = operator;
      let tempOpr = { ...operator };
      years?.forEach(
        ({
          year,
          months,
          averageWaitingTimeDays,
          totalWaitingTimeDays,
          ceuCapacityInWaiting,
          numWaitingEvents
        }) => {
          months?.forEach(
            ({
              start,
              totalWaitingTimeDays,
              averageWaitingTimeDays,
              ceuCapacityInWaiting,
              numWaitingEvents
            }) => {
              tempOpr[`${moment(start).format('MMM_YYYY')}_days`] = totalWaitingTimeDays;
              tempOpr[`${moment(start).format('MMM_YYYY')}_hours`] = averageWaitingTimeDays;
              tempOpr[`${moment(start).format('MMM_YYYY')}_ceu`] = ceuCapacityInWaiting;
              tempOpr[`${moment(start).format('MMM_YYYY')}_anchor_events`] = numWaitingEvents;
            }
          );
          tempOpr[`${year}hours`] = averageWaitingTimeDays;
          tempOpr[`${year}days`] = totalWaitingTimeDays;
          tempOpr[`${year}ceu`] = ceuCapacityInWaiting;
          tempOpr[`${year}anchor_events`] = numWaitingEvents;
        }
      );
      tempData.push(tempOpr);
    });

    return tempData;
  }, [portCongestionData]);

  const tableColumns = useMemo(() => {
    if (!tableData?.length) return [];

    let months = [];
    for (let i = 0; i <= 11; i++) {
      months.push(moment(`01/01/${selectedYear}`).add(i, 'months').format('MMM YYYY'));
    }

    let tempColumns = [
      {
        name: 'operator',
        label: 'Operator',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'multiselect',
          setCellProps: () => {
            return {
              id: 'operator-table'
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'operator'} id={rowData[1]} name={dataIndex} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: {
          display: false,
          viewColumns: false,
          filter: false,
          download: false
        }
      }
    ];

    months.forEach((date) => {
      tempColumns.push({
        name: `${date.split(' ').join('_')}_${selectedMeasure?.unit
          .toLowerCase()
          .split(' ')
          .join('_')}`,
        label: date,
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            if (!dataIndex) return '-';
            let numDecimals = 0;
            let val = dataIndex;
            if (![3, 4].includes(selectedMeasure?.id)) {
              numDecimals = 2;
              if (selectedMeasure?.id === 1) {
                val = val !== null ? val * 24 : null;
              }
            }
            return parseFloat(val) ? formatNumberReadable(val?.toFixed(numDecimals)) : '-';
          }
        }
      });
    });
    const isAverage = selectedMeasure?.id === 1;
    tempColumns.push({
      name: `${selectedYear}${selectedMeasure?.unit.toLowerCase().split(' ').join('_')}`,
      label: isAverage ? 'Average' : 'Total',
      options: {
        sort: true,
        sortThirdClickReset: true,

        customBodyRender: (dataIndex) => {
          let numDecimals = 0;
          let val = dataIndex;
          if (![3, 4].includes(selectedMeasure?.id)) {
            numDecimals = 2;
            if (selectedMeasure?.id === 1) {
              val = val !== null ? val * 24 : null;
            }
          }
          return parseFloat(val) ? formatNumberReadable(val?.toFixed(numDecimals)) : '-';
        }
      }
    });
    return [...tempColumns];
  }, [selectedYear, selectedMeasure]);

  const options = {
    customTableBodyFooterRender: () => {
      if (!portCongestionData) {
        return;
      }
      const { years } = portCongestionData;
      const year = years.find(({ year }) => year === selectedYear);
      let isAverage = selectedMeasure?.unit === 'Hours';
      let isCEU = selectedMeasure?.unit === 'CEU';
      let isEvent = selectedMeasure?.unit === 'Anchor events';

      return (
        <tbody>
          <TableRow key={`total-row-footer`}>
            <TableCell>
              <Typography variant={'caption'} bold>
                {isAverage ? 'Average' : 'Total'}
              </Typography>
            </TableCell>
            {year?.months?.map(
              (
                {
                  averageWaitingTimeDays,
                  totalWaitingTimeDays,
                  ceuCapacityInWaiting,
                  numWaitingEvents
                },
                i
              ) => {
                let value = averageWaitingTimeDays * 24;
                if (selectedMeasure?.id === 2) {
                  value = totalWaitingTimeDays;
                }
                if (selectedMeasure?.id === 3) {
                  value = ceuCapacityInWaiting;
                }
                if (selectedMeasure?.id === 4) {
                  value = numWaitingEvents;
                }

                return (
                  <TableCell
                    key={`total-${i}`}
                    sx={{
                      textWrap: 'nowrap'
                    }}>
                    <Typography variant={'caption'} bold>
                      {selectedMeasure.id === 3 || selectedMeasure.id === 4
                        ? formatNumberReadable(parseFloat(value).toFixed(0)) || '0'
                        : formatNumberReadable(parseFloat(value).toFixed(2)) || '0'}
                    </Typography>
                  </TableCell>
                );
              }
            )}
            <TableCell>
              <Typography variant={'caption'} bold sx={{ p: 0, m: '2px' }}></Typography>
            </TableCell>
          </TableRow>
        </tbody>
      );
    },
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: true,
    onDownload: (buildHead, buildBody, columns, data) => {
      return (
        '\uFEFF' +
        buildHead(columns) +
        buildBody(
          data.map((item) => {
            item.data = item.data?.map((val) => {
              if (val === '-') {
                return null;
              }
              return val;
            });
            return item;
          })
        )
      );
    },
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: `Monthly ${selectedMeasure?.type} per operator.csv`
    },
    setTableProps: () => {
      return {
        id: 'Port-waiting-time-table'
      };
    },
    tableBodyMaxHeight: '80vh',
    elevation: 0,
    print: false,
    viewColumns: false,
    pagination: true,
    toolbar: true,
    rowsPerPageOptions: [10, 25, 50, 100],
    sortOrder: {
      name: 'operator',
      direction: 'asc'
    }
  };
  return (
    <Table
      loading={loading}
      skeletonRows={6}
      mode={theme.mode}
      title={
        <Stack sx={{ pt: 2 }}>
          <Typography id={'port-waiting-table-title'} variant={'h6'}>
            Monthly {selectedMeasure?.type} per operator
            {selectedMeasure?.type !== 'Anchor events'
              ? ` (${selectedMeasure?.unit.toLowerCase()})`
              : ''}
          </Typography>
          <Stack sx={{ pt: 2 }}>
            <FormControl variant="outlined" sx={{ maxWidth: 100 }} size="small">
              <InputLabel id="port-waiting-table-year-label">Year</InputLabel>
              <Select
                id="port-waiting-table-year-select"
                value={selectedYear + ''}
                variant="outlined"
                label="Year"
                labelId={'Port-waiting-table-year-label'}
                sx={{ minWidth: 20, maxWidth: '120px' }}
                onChange={({ target }) => setSelectedYear(parseInt(target.value))}>
                {yearOptions?.map((year) => (
                  <MenuItem key={`select-${year}`} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      }
      data={tableData}
      columns={tableColumns}
      options={options}
    />
  );
}
MonthlyPortWaitingTable.propTypes = {
  portCongestionData: PropTypes.object,
  loading: PropTypes.bool,
  selectedMeasure: PropTypes.object,
  selectedYear: PropTypes.number,
  yearOptions: PropTypes.arrayOf(PropTypes.number),
  setSelectedYear: PropTypes.func
};

MonthlyPortWaitingTable.defaultProps = {
  portCongestionData: {},
  yearOptions: [],
  loading: false,
  selectedMeasure: {},
  selectedYear: moment().year(),
  setSelectedYear: undefined
};

export default MonthlyPortWaitingTable;
