import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess, formatNumberReadable, formatAxisValue } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useSegment, useTheme } from '@hooks';

const getUnitName = (unit) => {
  let unitName = `tCO2/CEU`;
  if (unit === 2) {
    unitName = 'gCO2/(GT•NM)';
  }
  if (unit === 3) {
    unitName = 'gCO2/(DWT•NM)';
  }
  if (unit === 4) {
    unitName = 'Total tCO2';
  }
  return unitName;
};

const getOptions = (categories, themeMode, user, selectedUnitTab, periodSelect, fileName) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, twoColorsContrast, threeColors }
    }
  } = themeMode;
  return {
    chart: {
      type: 'line',
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      height: 350,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    colors: [twoColorsContrast[1], twoColorsContrast[0], threeColors[0]],
    theme: {
      mode: mode
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: periodSelect === 3 ? '40%' : '80%'
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      markers: { radius: [0, 12, 12] }
    },
    xaxis: {
      categories: categories,
      tickPlacement: 'between'
    },
    yaxis: [
      {
        seriesName: getUnitName(selectedUnitTab),
        forceNiceScale: true,
        title: {
          text: getUnitName(selectedUnitTab),
          style: {
            color: twoColorsContrast[1]
          }
        },
        decimalsInFloat: 2,
        labels: {
          formatter: formatAxisValue
        }
      },
      ...(periodSelect !== 3
        ? [
            {
              seriesName: getUnitName(selectedUnitTab),
              show: false
            }
          ]
        : []),
      {
        show: selectedUnitTab === 4,
        opposite: true,
        decimalsInFloat: 2,
        forceNiceScale: true,
        title: {
          text: 'Distance [NM]',
          style: {
            color: threeColors[0]
          }
        },
        labels: {
          formatter: formatAxisValue
        }
      }
    ],
    stroke: {
      width: [0, 2, 2],
      curve: 'straight',
      dashArray: [0, 5, 0]
    },
    markers: {
      size: [4, 0],
      strokeWidth: 0,
      hover: {
        size: 5
      }
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return formatNumberReadable(value?.toFixed(1));
        }
      }
    }
  };
};

function OperatorEmissionChart({
  loading,
  periodSelect,
  emissionHistoryData,
  selectedUnitTab,
  fileName
}) {
  const [categories, setCategories] = useState('monthly');
  const [options, setOptions] = useState({});
  const [newData, setNewData] = useState(null);
  const [series, setSeries] = useState([]);
  const user = useSelector(getUser);
  const { theme } = useTheme();
  const { isRoRo } = useSegment();

  useEffect(() => {
    if (!categories?.length) return;
    setOptions(getOptions(categories, theme, user, selectedUnitTab, periodSelect, fileName));
  }, [categories, theme, user, selectedUnitTab, periodSelect, fileName]);

  useEffect(() => {
    if (!emissionHistoryData) return;
    const {
      operatorPeriodEmissionDetailsList: periodData,
      polyFitTotalCo2,
      polyFitCo2ByCeu,
      polyFitCo2ByDeadweightDistance,
      polyFitCo2ByGrossTonnageDistance
    } = emissionHistoryData;

    let filteredPeriodData;

    if (!isRoRo && periodSelect === 3) {
      filteredPeriodData = periodData.slice(1);
    } else {
      filteredPeriodData = periodData;
    }
    setCategories(filteredPeriodData?.map(({ periodStr }) => periodStr) || []);

    let tempData = {
      co2Ceu: [],
      gtnm: [],
      dwtnm: [],
      totalCo2: [],
      totDist: [],
      polyFitTotalCo2: polyFitTotalCo2,
      polyFitCo2ByCeu: polyFitCo2ByCeu,
      polyFitCo2ByDeadweightDistance: polyFitCo2ByDeadweightDistance,
      polyFitCo2ByGrossTonnageDistance: polyFitCo2ByGrossTonnageDistance
    };

    // call function to get data in default state
    for (let item of filteredPeriodData) {
      tempData.totalCo2.push(item.totalCo2 || 0);
      tempData.co2Ceu.push(item.co2ByCeu || 0);
      tempData.totDist.push(item.totalDistanceNm || 0);
      tempData.gtnm.push(item.co2ByGrossTonnageDistance || 0);
      tempData.dwtnm.push(item.co2ByDeadweightDistance || 0);
    }
    setNewData(tempData);
  }, [emissionHistoryData]);

  useEffect(() => {
    if (!newData) return;
    const {
      palette: {
        charts: { twoColorsContrast, threeColors }
      }
    } = theme;

    let tempSeries = [];
    let trendLine = {
      name: 'Trendline',
      type: 'line',
      color: twoColorsContrast[0],
      data: []
    };

    if (selectedUnitTab === 1) {
      tempSeries.push({
        name: 'tCO2/CEU',
        type: 'column',
        data: newData.co2Ceu.map((value) => parseFloat(value).toFixed(2))
      });
      if (periodSelect !== 3) {
        tempSeries.push({
          ...trendLine,
          ...{ data: newData.polyFitCo2ByCeu.map((value) => parseFloat(value).toFixed(2)) }
        });
      }
    }
    if (selectedUnitTab === 2) {
      tempSeries.push({
        name: 'gCO2/(GT•NM)',
        type: 'column',
        data: newData.gtnm.map((value) => parseFloat(value).toFixed(2))
      });
      if (periodSelect !== 3) {
        tempSeries.push({
          ...trendLine,
          ...{
            data: newData.polyFitCo2ByGrossTonnageDistance.map((value) =>
              parseFloat(value).toFixed(2)
            )
          }
        });
      }
    }
    if (selectedUnitTab === 3) {
      tempSeries.push({
        name: 'gCO2/(DWT•NM)',
        type: 'column',
        data: newData.dwtnm.map((value) => parseFloat(value).toFixed(2))
      });
      if (periodSelect !== 3) {
        tempSeries.push({
          ...trendLine,
          ...{
            data: newData.polyFitCo2ByDeadweightDistance.map((value) =>
              parseFloat(value).toFixed(2)
            )
          }
        });
      }
    }
    if (selectedUnitTab === 4) {
      tempSeries.push({
        name: 'Total tCO2',
        type: 'column',
        data: newData.totalCo2.map((value) => parseFloat(value).toFixed(2))
      });
      if (periodSelect !== 3) {
        tempSeries.push({
          ...trendLine,
          ...{ data: newData.polyFitTotalCo2.map((value) => parseFloat(value).toFixed(2)) }
        });
      }
      tempSeries.push({
        name: 'Distance [NM]',
        type: 'line',
        color: threeColors[0],
        data: newData.totDist.map((value) => parseFloat(value).toFixed(2))
      });
    }
    setSeries(tempSeries);
  }, [newData, selectedUnitTab]);

  return (
    <ApexChart
      loading={loading || !series?.length}
      selectedUnitTab={selectedUnitTab}
      height={480}
      options={options || {}}
      type={'line'}
      data={series || []}
    />
  );
}
OperatorEmissionChart.propTypes = {
  loading: PropTypes.bool,
  emissionHistoryData: PropTypes.object,
  selectedUnitTab: PropTypes.number,
  periodSelect: PropTypes.number,
  fileName: PropTypes.string.isRequired
};
OperatorEmissionChart.defaultProps = {
  loading: false,
  emissionHistoryData: null,
  selectedUnitTab: 1,
  periodSelect: 1
};
export default OperatorEmissionChart;
