import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, TextField } from '@esgian/esgianui';

const CreateVoyagePopup = ({
  open,
  setModalOpen,
  voyageGroupToEdit,
  setVoyageGroupToEdit,
  value,
  onChange,
  existingGroups,
  isSubmitDisabled,
  onSubmit
}) => {
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Modal
      title={voyageGroupToEdit ? 'Edit Voyage Group' : 'Create a new group'}
      open={open}
      onClose={() => {
        if (voyageGroupToEdit) {
          setVoyageGroupToEdit(undefined);
        }
        setModalOpen(false);
      }}>
      <Box display="flex" gap={2} flexDirection="column">
        <TextField
          label={voyageGroupToEdit ? 'Edit group name' : 'Create a new group'}
          autoComplete={'off'}
          type={'search'}
          placeholder={''}
          value={value}
          error={!!errorMessage}
          helperText={errorMessage}
          fullWidth
          onChange={(e) => {
            const groupName = e.target.value;
            onChange(groupName);
            let errorMessage = '';

            const isGroupAlreadyExist = existingGroups.some(
              (singleGroup) =>
                singleGroup.name.trim().toLowerCase() === groupName.trim().toLowerCase()
            );
            if (isGroupAlreadyExist) {
              errorMessage = `A Group with the name "${groupName}" already exists`;
            }
            if (groupName.trim().length >= 20) {
              errorMessage = 'Group name should be less than 20 characters. ';
            }
            setErrorMessage(errorMessage);
          }}
        />
        <Box display="flex" gap={2} justifyContent="center">
          <Button
            variant="text"
            onClick={() => {
              if (voyageGroupToEdit) {
                setVoyageGroupToEdit(undefined);
              }
              setModalOpen(false);
            }}>
            Close
          </Button>
          <Button disabled={isSubmitDisabled} onClick={onSubmit}>
            {voyageGroupToEdit ? 'Save' : '+ CREATE A NEW GROUP'}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

CreateVoyagePopup.propTypes = {
  open: PropTypes.bool,
  setModalOpen: PropTypes.func,
  voyageGroupToEdit: PropTypes.object,
  setVoyageGroupToEdit: PropTypes.func,
  value: PropTypes.string,
  onChange: PropTypes.func,
  existingGroups: PropTypes.array,
  isSubmitDisabled: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default CreateVoyagePopup;
