import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Typography } from '@esgian/esgianui';
import { useSegment } from '@hooks/useSegment';

function EmissionsDisclaimer({ setShowStatement }) {
  const { isRoRo } = useSegment();
  if (isRoRo) {
    return (
      <Alert severity={'info'}>
        <Typography variant={'body2'}>
          The Esgian Emissions module is accredited by Smart Freight Center as the first{' '}
          <em>modelled</em> CO<sub>2</sub> emission calculation adhering to the GLEC framework. This
          is thus an accurate <em>estimation</em> of CO<sub>2</sub> emissions per vessel per hour
          aggregated to various levels of analysis. For Accreditation statement – please click{' '}
          <Typography
            variant={'body2'}
            color={'primary'}
            component={'span'}
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              setShowStatement(true);
            }}>
            here
          </Typography>
          .
        </Typography>
      </Alert>
    );
  }
  return (
    <Alert severity={'info'}>
      Public Beta– Please note that we are refining the emission estimation model for MPP vessels
      and the values will be recalculated based on the refined model. Users will be notified after
      recalculation.
    </Alert>
  );
}

EmissionsDisclaimer.propTypes = { setShowStatement: PropTypes.func.isRequired };

EmissionsDisclaimer.defaultProps = {};

export default EmissionsDisclaimer;
