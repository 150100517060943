import React from 'react';
import { Stack, Divider, Button, Paper, Typography } from '@esgian/esgianui';
import { OPERATOR_TYPE } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { getOperatorType, setOperatorType } from '@store/features';
import { TextWithTooltipIcon } from '@components';
import {
  OPR_TYPE_BOTH_TEXT,
  OPR_TYPE_DEEP_SEA_TEXT,
  OPR_TYPE_SHORT_SEA_TEXT
} from '@constants/tooltips';

function OperatorTypeSelect() {
  const dispatch = useDispatch();
  const operatorType = useSelector(getOperatorType);

  return (
    <Paper>
      <Stack
        direction={'row'}
        divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}>
        <Button
          fullWidth
          onClick={() => dispatch(setOperatorType(OPERATOR_TYPE.ALL))}
          sx={{ borderRadius: '4px 0px 0px 4px' }}
          variant={operatorType === OPERATOR_TYPE.ALL ? 'contained' : 'text'}
          id={'btn-operator-type-select-both'}>
          Both
          <TextWithTooltipIcon
            label={
              <Typography variant={'subtitle2'} style={{ visibility: 'hidden' }}>
                .
              </Typography>
            }
            tooltipText={OPR_TYPE_BOTH_TEXT}
          />
        </Button>
        <Button
          fullWidth
          onClick={() => dispatch(setOperatorType(OPERATOR_TYPE.SHORT_SEA))}
          sx={{ borderRadius: '0px 0px 0px 0px' }}
          variant={operatorType === OPERATOR_TYPE.SHORT_SEA ? 'contained' : 'text'}
          id={'btn-operator-type-select-short-sea'}>
          Short sea
          <TextWithTooltipIcon
            label={
              <Typography variant={'contained'} style={{ visibility: 'hidden' }}>
                .
              </Typography>
            }
            tooltipText={OPR_TYPE_SHORT_SEA_TEXT}
          />
        </Button>
        <Button
          fullWidth
          onClick={() => dispatch(setOperatorType(OPERATOR_TYPE.DEEP_SEA))}
          sx={{ borderRadius: '0px 4px 4px 0px' }}
          variant={operatorType === OPERATOR_TYPE.DEEP_SEA ? 'contained' : 'text'}
          id={'btn-operator-type-select-deep-sea'}>
          deep sea
          <TextWithTooltipIcon
            label={
              <Typography variant={'contained'} style={{ visibility: 'hidden' }}>
                .
              </Typography>
            }
            tooltipText={OPR_TYPE_DEEP_SEA_TEXT}
          />
        </Button>
      </Stack>
    </Paper>
  );
}

OperatorTypeSelect.propTypes = {};

OperatorTypeSelect.defaultProps = {};

export default OperatorTypeSelect;
