import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getLookupVesselHierarchy } from '@store/features';

export const useVesselLookup = () => {
  const { operatorHierarchy } = useSelector(getLookupVesselHierarchy);

  const getVesselListFromOperators = useCallback(
    (operators) => {
      let vesselIds = [];

      for (const operator of operators) {
        const foundOperator = operatorHierarchy?.find(({ id }) => {
          return parseInt(id) === parseInt(operator.id);
        });

        if (foundOperator) {
          vesselIds = vesselIds.concat(foundOperator.children.map(({ id }) => id));
        }
      }

      return vesselIds;
    },
    [operatorHierarchy]
  );

  return { getVesselListFromOperators };
};
