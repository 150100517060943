import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useTheme } from '@hooks/useTheme';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

const getHorizontalBarOptions = (user, theme, categories, fileName) => {
  const {
    palette: {
      mode,
      charts: { background },
      typographyText: { primary }
    }
  } = theme;
  return {
    chart: {
      type: 'bar',
      background: background,
      height: 350,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    colors: ['#00BDD6'],
    tooltip: {
      y: {
        title: {
          formatter: () => 'Consistency Score: '
        }
      }
    },
    theme: {
      mode: mode
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    dataLabels: {
      enabled: true,
      offsetX: -10,
      style: {
        colors: undefined
      },
      background: {
        enabled: true,
        padding: 4,
        foreColor: primary,
        borderRadius: 2,
        borderWidth: 0,
        opacity: 0.0
      }
    },
    xaxis: {
      labels: {
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: false
      },
      categories: categories
    }
  };
};

function ConsistencyChart({ loading, series, categories, costumeOptions, fileName }) {
  const [options, setOptions] = useState({});
  const user = useSelector(getUser);

  const { theme } = useTheme();

  useEffect(() => {
    setOptions({
      ...getHorizontalBarOptions(user, theme, categories, fileName),
      ...costumeOptions
    });
  }, [theme, categories]);

  return (
    <>
      {options && (
        <ApexChart
          loading={loading}
          height={480}
          options={options}
          type={'bar'}
          data={series || []}
        />
      )}
    </>
  );
}

ConsistencyChart.propTypes = {
  loading: PropTypes.bool,
  series: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.string),
  costumeOptions: PropTypes.object,
  fileName: PropTypes.string.isRequired
};

ConsistencyChart.defaultProps = {
  series: [],
  categories: [],
  loading: false,
  costumeOptions: {}
};

export default ConsistencyChart;
