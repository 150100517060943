import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Stack, Typography } from '@esgian/esgianui';
import sfcAccreditation from '@assets/images/sfc-accreditation.png';

function AccreditationStatementModal({ open, handleClose }) {
  return (
    <Modal title={'Accreditation statement'} open={open} size={'md'} handleClose={handleClose}>
      <Stack>
        <Typography> Accreditation by Smart Freight Centre (SFC) in process.</Typography>
        <img
        //    alt={'sf-accreditation'} srcSet={sfcAccreditation} src={sfcAccreditation}
        />
      </Stack>
    </Modal>
  );
}

AccreditationStatementModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

AccreditationStatementModal.defaultProps = {
  open: false
};

export default AccreditationStatementModal;
