import { ApexChart } from '@esgian/esgianui';
import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@hooks/useTheme';
import moment from 'moment';

function EmissionChart({ categories, series }) {
  const { theme } = useTheme();

  const options = {
    stroke: {
      width: series.map(() => 2),
      show: true
    },
    xaxis: {
      categories,
      type: 'datetime',
      tickAmount: categories.length,
      labels: {
        formatter: function (val) {
          return moment(val).format('YYYY-MM-DD');
        },
        rotate: -45,
        rotateAlways: true
      }
    },
    yaxis: {
      title: {
        text: 'Tonnes',
        style: {
          fontWeight: 900,
          fontSize: '11px',
          fontFamily: '"Noto Sans", Roboto, helvetica, Arial, sans-serif'
        }
      },
      labels: {
        formatter: function (val) {
          return val === null ? null : val.toFixed(2);
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    theme: {
      mode: theme.mode
    },
    chart: {
      background: theme.palette.charts.background
    }
  };
  return <ApexChart options={options} type="line" data={series} />;
}

EmissionChart.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object),
  categories: PropTypes.arrayOf(PropTypes.string)
};

export default EmissionChart;
