import { BaseApiRequest } from '@api/BaseApi';
import { objectToQuery } from '@api/helpers';

export const getVesselOperatorHistory = (signal, body, version = 1.0) => {
  return BaseApiRequest(
    signal,
    `/Operator/OperatorHistory?api-version=${version}`,
    'ships',
    'POST',
    body
  );
};

export const getOperatorIncomingPortCalls = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/IncomingVessels/Operator?${query}`);
};

export const getOperatorProfile = (data, signal) => {
  const query = objectToQuery(data);

  return BaseApiRequest(signal, `/Operator?${query}`);
};

export const getOperatorStats = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/OperatorStats?${query}`);
};

export const getOperatorTradeRoute = (operatorId, signal, body, version = 1.0) => {
  return BaseApiRequest(signal, `/TradeRoute?api-version=${version}`, 'ais', 'POST', body);
};

export const getTradeRoutePorts = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Operator/TradeRoute?${query}`);
};

export const getOperatorInformation = (signal) => {
  return BaseApiRequest(signal, `/OperatorInformation`);
};
