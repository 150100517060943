import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { getValueUptoTwoDecimalPlaces } from '../VoyageSummaryRouteMap/utils';

function PortWaitingTimeTable({ loading, voyagePortCalls }) {
  const { theme } = useTheme();
  const user = useSelector(getUser);

  const columns = [
    {
      name: 'portName',
      label: 'Port',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect'
      }
    },
    {
      name: 'portTimeDays',
      label: 'Port time (days)',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect',
        customBodyRenderLite: (dataIndex) => {
          const value = voyagePortCalls[dataIndex]?.portTimeDays;
          return getValueUptoTwoDecimalPlaces(value);
        }
      }
    },
    {
      name: 'portWaitingTimeDays',
      label: 'Port waiting time (days)',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'multiselect',
        customBodyRenderLite: (dataIndex) => {
          const value = voyagePortCalls[dataIndex]?.portWaitingTimeDays;
          return getValueUptoTwoDecimalPlaces(value);
        }
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: true,
    search: true,
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: 'ports-overview.csv'
    },
    setTableProps: () => {
      return {
        id: 'ports-table'
      };
    },
    enableNestedDataAccess: '.',
    print: false,
    viewColumns: false,
    pagination: false,
    toolbar: false,
    tableBodyMaxHeight: '230px',
    rowsPerPageOptions: [10, 25, 50, 100],
    elevation: 0
  };
  return (
    <Table
      style={{ p: 2, height: '400px' }}
      skeletonRows={6}
      loading={loading}
      mode={theme.mode}
      title=""
      data={voyagePortCalls ?? []}
      columns={columns}
      options={options}
    />
  );
}

PortWaitingTimeTable.propTypes = {
  loading: PropTypes.bool,
  voyagePortCalls: PropTypes.array
};

PortWaitingTimeTable.defaultProps = {
  loading: false,
  voyagePortCalls: []
};

export default PortWaitingTimeTable;
