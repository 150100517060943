import { BaseApiRequest } from '@api/BaseApi';
import moment from 'moment';
import { GEOGRAPHICAL_TYPES, OPERATOR_SELECT_TYPES, SEGMENT, getLocationTypeId } from '@constants';
import { objectToQuery } from '@api/helpers';
export const getLatestPortCalls = (body, signal) => {
  return BaseApiRequest(signal, `/LastPortCalls`, 'ships', 'POST', body);
};

export const getOperatorPerformance = (
  {
    selectedOperator,
    selectedPortLoad,
    selectedPortDischarge,
    startDate,
    endDate,
    maxTransitTime,
    minServices,
    operatorType
  },
  segmentTypeId,
  signal
) => {
  if (maxTransitTime === '' || maxTransitTime === null || maxTransitTime === 'null') {
    maxTransitTime = 0;
  }
  if (minServices === '' || minServices === null || minServices === 'null') {
    minServices = 0;
  }

  return BaseApiRequest(signal, `/PortCalls/OperatorPerformance?api-version=1.0`, 'ships', 'POST', {
    serviceRequest: {
      operatorPerformanceId: selectedOperator.id,
      toType: GEOGRAPHICAL_TYPES.PORT,
      fromType: GEOGRAPHICAL_TYPES.PORT,
      toId: selectedPortDischarge.id,
      fromId: selectedPortLoad.id,
      operatorIds: null,
      startDate: startDate,
      endDate: endDate,
      maxTransitTime: maxTransitTime,
      minServices: minServices,
      operatorTypeId: operatorType,
      segmentTypeId: segmentTypeId
    }
  });
};

export const getPortFrequency = (
  {
    operatorTypeId,
    segmentTypeId,
    commercialCategoryId,
    startDate,
    endDate,
    selectedLocationLoad,
    selectedLocationDischarge,
    selectedOperators,
    selectType,
    maxTransitTime,
    minServices
  },
  signal
) => {
  let operatorIds = null;
  if (selectType === OPERATOR_SELECT_TYPES.DYNAMIC) {
    operatorIds = [];
  } else {
    operatorIds = selectedOperators?.map(({ id }) => id);
  }
  const extraFilters = {};
  if (segmentTypeId === SEGMENT.RoRo.id) {
    extraFilters.OperatorTypeId = operatorTypeId;
  } else {
    extraFilters.CommercialCategoryId = commercialCategoryId;
  }

  return BaseApiRequest(signal, `/PortCalls/PortFrequency`, 'ships', 'POST', {
    serviceRequest: {
      operatorPerformanceId: null,
      toType: getLocationTypeId(selectedLocationDischarge.type),
      toId: selectedLocationDischarge.id,
      fromType: getLocationTypeId(selectedLocationLoad.type),
      fromId: selectedLocationLoad.id,
      topFive: selectType === OPERATOR_SELECT_TYPES.AUTO,
      operatorIds: operatorIds?.length ? operatorIds : null,
      startDate: moment(startDate).format('MM/DD/yyyy'),
      endDate: moment(endDate).format('MM/DD/yyyy'),
      maxTransitTime: parseInt(maxTransitTime) || 200,
      minServices: parseInt(minServices) || 0,
      segmentTypeId: segmentTypeId,
      ...extraFilters
    }
  });
};

export const getPortActivity = (
  fromType,
  fromId,
  startDate,
  endDate,
  operatorIds,
  topFive,
  operatorTypeId,
  segmentTypeId,
  commercialCategoryId,
  signal = null
) => {
  let body = {
    serviceRequest: {
      operatorPerformanceId: null,
      toType: 0,
      fromType: getLocationTypeId(fromType),
      toId: 0,
      topFive: topFive,
      fromId: fromId,
      operatorIds: operatorIds?.length ? operatorIds : null,
      startDate: startDate,
      endDate: endDate,
      maxTransitTime: 0,
      minServices: 0,
      operatorTypeId: operatorTypeId,
      segmentTypeId: segmentTypeId,
      commercialCategoryId: commercialCategoryId
    }
  };
  return BaseApiRequest(signal, `/PortCalls/PortActivity`, 'ships', 'POST', body);
};

export const getPortStats = (data, signal = null) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/PortCalls/PortStats?${query}`);
};

export const getGlobalPortsStats = (data, signal = null) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/PortCalls/GlobalPortsStats?${query}`);
};

export const getTopPorts = (data, signal = null) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/PortCalls/GetTopPorts?${query}`);
};

export const getUnderlyingPortCalls = (data, signal = null) => {
  let tempData = { ...data };
  tempData.FromType = getLocationTypeId(tempData.FromType);
  const query = objectToQuery(tempData);
  return BaseApiRequest(signal, `/Operator/PortCalls?${query}`);
};

export const getRegionTopPorts = (data, signal = null) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Region/GetTopRegionPorts?${query}`);
};

export const getOperatorYTDStats = (data, signal = null) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Operator/OperatorStats?${query}`);
};

export const getPortCongestion = (data, signal = null) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/PortCongestion?${query}`);
};

export const getMaxPortDraught = (body, signal) => {
  return BaseApiRequest(signal, `/PortCalls/MaxDraught`, 'ships', 'POST', body);
};
