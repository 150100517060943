import React, { useState } from 'react';
import { Grid, Badge, Button, FilterIcon, Typography, DateRangeMobi } from '@esgian/esgianui';
import moment from 'moment';
import { LocationSelect } from '@components/Inputs';
import AdvancedPortActivityFilters from './AdvancedPortActivityFilters';
import {
  getPortActivityFilterCount,
  getPortActivityFilters,
  updatePortActivityFilters
} from '@store/features/filters/CommercialAnalyticsPage/PortActivitySlice/PortActivitySlice';
import { useDispatch, useSelector } from 'react-redux';
import { getDateFormat, getLookupLocations } from '@store/features';
import { getMonthDateRangePickerSx, getStartEndMonth } from '@helpers/DatePickerHelpers';
import { useSegment } from '@hooks';

function PortActivityFilters() {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const lookupLocations = useSelector(getLookupLocations);
  const dispatch = useDispatch();
  const portActivityFilters = useSelector(getPortActivityFilters);
  const filterCount = useSelector(getPortActivityFilterCount);
  const dateFormat = useSelector(getDateFormat);
  const { segmentMinDate } = useSegment();
  const { startDate, endDate, selectedLocation } = portActivityFilters;

  const updateFilterValue = (payload) => {
    dispatch(updatePortActivityFilters(payload));
  };

  return (
    <Grid item container spacing={2}>
      <Grid item lg={3} md={4} className={'section-filter'}>
        <Typography
          variant={'subtitle2'}
          bold
          gutterBottom
          color={'text.secondary'}
          id={'port-name-label'}>
          Location*
        </Typography>

        <LocationSelect
          placeholder={'Search by port, city or region'}
          handleChange={(val) => {
            updateFilterValue({ selectedLocation: val });
          }}
          selectedLocation={selectedLocation}
          locationList={lookupLocations}
        />
      </Grid>
      <Grid item className={'section-filter'}>
        <Typography
          variant={'subtitle2'}
          bold
          gutterBottom
          color={'text.secondary'}
          id={'period-label'}>
          Period*
        </Typography>
        <DateRangeMobi
          sx={getMonthDateRangePickerSx(startDate, endDate)}
          onClose={({ value }) => {
            const { start, end } = getStartEndMonth(value, dateFormat);
            updateFilterValue({
              startDate: start,
              endDate: end
            });
          }}
          dateFormat={dateFormat}
          minStartDate={segmentMinDate}
          disableFuture
          startDate={moment(startDate, dateFormat)}
          endDate={moment(endDate, dateFormat)}
          placeholder={'Period'}
          dateWheels={'MMM YYYY'}
        />
      </Grid>
      <Grid item alignSelf={'end'} sx={{ pb: '0.7em' }}>
        <Badge badgeContent={filterCount} color="error">
          <Button startIcon={<FilterIcon />} variant={'text'} onClick={() => setAdvancedOpen(true)}>
            Advanced Filter
          </Button>
        </Badge>
      </Grid>
      <Grid item xs={12}></Grid>
      <AdvancedPortActivityFilters
        updateFilterValue={updateFilterValue}
        advancedOpen={advancedOpen}
        setAdvancedOpen={setAdvancedOpen}
      />
    </Grid>
  );
}

PortActivityFilters.propTypes = {};

PortActivityFilters.defaultProps = {};

export default PortActivityFilters;
