import React from 'react';
import PropTypes from 'prop-types';
import { ArrowSwapButton, Box, Stack, Typography } from '@esgian/esgianui';
import { LocationSelect } from '@components/Inputs';
import { useSelector } from 'react-redux';
import { getLookupLocations } from '@store/features';

function LocationLoadAndDischargeSelect({
  handleSwap,
  setSelectedLocationOfLoad,
  setSelectedLocationOfDischarge,
  selectedLocationOfDischarge,
  selectedLocationOfLoad
}) {
  const lookupLocations = useSelector(getLookupLocations);

  const handlePortSwap = () => {
    let tempLoad = selectedLocationOfLoad ? { ...selectedLocationOfLoad } : null;
    let tempDist = selectedLocationOfDischarge ? { ...selectedLocationOfDischarge } : null;
    if (handleSwap) {
      handleSwap(tempDist, tempLoad);
    } else {
      setSelectedLocationOfLoad(tempDist);
      setSelectedLocationOfDischarge(tempLoad);
    }
  };
  return (
    <Stack direction={'row'}>
      <Stack className={'section-filter'}>
        <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
          Location of Load*
        </Typography>
        <LocationSelect
          keyPreFix={'load'}
          placeholder={'Search by port, city or region'}
          handleChange={setSelectedLocationOfLoad}
          selectedLocation={selectedLocationOfLoad}
          locationList={lookupLocations}
        />
      </Stack>
      <Box alignSelf={'flex-end'} sx={{ pb: 1 }}>
        <ArrowSwapButton handleClick={handlePortSwap} />
      </Box>
      <Stack className={'section-filter'}>
        <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
          Location of Discharge*
        </Typography>
        <LocationSelect
          keyPreFix={'dist'}
          placeholder={'Search by port, city or region'}
          selectedLocation={selectedLocationOfDischarge}
          handleChange={setSelectedLocationOfDischarge}
          locationList={lookupLocations}
        />
      </Stack>
    </Stack>
  );
}

LocationLoadAndDischargeSelect.propTypes = {
  selectedLocationOfDischarge: PropTypes.object,
  selectedLocationOfLoad: PropTypes.object,
  setSelectedLocationOfLoad: PropTypes.func.isRequired,
  setSelectedLocationOfDischarge: PropTypes.func.isRequired,
  handleSwap: PropTypes.func
};

LocationLoadAndDischargeSelect.defaultProps = {
  selectedLocationOfDischarge: null,
  selectedLocationOfLoad: null,
  handleSwap: null
};

export default LocationLoadAndDischargeSelect;
