import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@esgian/esgianui';
import { VoyagesTable } from '@components/Tables';

function CompletedVoyageSection({ loading, completedVoyagesData }) {
  const numVoyages = completedVoyagesData?.length ?? 0;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant={'body2'} sx={{ mb: 2 }}>
          {`Display voyage: ${numVoyages}`}
        </Typography>
        <VoyagesTable
          voyageSearchResults={completedVoyagesData}
          fileName={'completed-voyages'}
          loading={loading}
          title="Voyages"
        />
      </Grid>
    </Grid>
  );
}

CompletedVoyageSection.propTypes = {
  loading: PropTypes.bool,
  completedVoyagesData: PropTypes.arrayOf(PropTypes.object)
};

CompletedVoyageSection.defaultProps = {
  loading: false,
  completedVoyagesData: []
};

export default CompletedVoyageSection;
