import { BaseApiRequest } from '@api/BaseApi';
import { VESSEL_MAX_CAPACITY, VESSEL_MAX_CAPACITY_DWT, VESSEL_MIN_BUILD_YEAR } from '@constants';
import moment from 'moment';
import { objectToQuery } from '@api/helpers';

export const getVesselsPositions = (imos, signal) => {
  return BaseApiRequest(signal, '/Vessel/LatestPositions?api-version=1.0', 'ais', 'POST', {
    imos: imos
  });
};

export const getVesselLatestPosition = (imo, signal) => {
  return BaseApiRequest(signal, `/Vessel/LatestPosition/${imo}`, 'ais');
};

export const getVesselProfile = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Vessels/VesselDetails?${query}`);
};
export const getVesselLastPortCalls = (imo, num, signal, version = 2.0) => {
  return BaseApiRequest(signal, `/Vessel/LastPortCalls/${imo}/${num}?api-version=${version}`);
};
export const getVesselNextPortCall = (signal, imo, version = 1.0) => {
  return BaseApiRequest(signal, `/Vessel/NextPortCall/${imo}?api-version=${version}`, 'ais');
};

export const getVesselDetails = (signal, segmentTypeId) => {
  return BaseApiRequest(signal, `/VesselDetails?segmentTypeId=${segmentTypeId}`);
};
export const fetchOwnerOperatorVesselHierarchy = (
  signal,
  minCapacity = 0,
  maxCapacity = VESSEL_MAX_CAPACITY_DWT,
  minBuildYear = VESSEL_MIN_BUILD_YEAR,
  maxBuildYear = moment().year,
  ownerIds = null
) => {
  const body = {
    ownerOperatorVesselHierarchyRequestObj: {
      minCapacity: minCapacity,
      maxCapacity: maxCapacity,
      minBuildYear: minBuildYear,
      maxBuildYear: maxBuildYear,
      ownerIds: ownerIds
    }
  };
  return BaseApiRequest(signal, `/Hierarchy/OwnerOperatorVessel`, 'ships', 'POST', body);
};
export const fetchOperatorVesselHierarchy = (
  signal,
  segmentId,
  minCapacity = 0,
  maxCapacity = VESSEL_MAX_CAPACITY,
  minBuildYear = VESSEL_MIN_BUILD_YEAR,
  maxBuildYear = moment().year,
  ownerIds = null
) => {
  const body = {
    operatorVesselHierarchyRequestObj: {
      minCapacity: minCapacity,
      maxCapacity: maxCapacity,
      minBuildYear: minBuildYear,
      maxBuildYear: maxBuildYear,
      ownerIds: ownerIds,
      segmentTypeId: segmentId
    }
  };
  return BaseApiRequest(signal, `/Hierarchy/OperatorVessel?api-version=1.0`, 'ships', 'POST', body);
};

export const fetchVessels = (
  signal,
  segmentId,
  minCapacity = 0,
  maxCapacity = VESSEL_MAX_CAPACITY,
  minBuildYear = VESSEL_MIN_BUILD_YEAR,
  maxBuildYear = moment().year,
  ownerIds = null
) => {
  const body = {
    vesselDetailsRequestObj: {
      minCapacity: minCapacity,
      maxCapacity: maxCapacity,
      minBuildYear: minBuildYear,
      maxBuildYear: maxBuildYear,
      ownerIds: ownerIds,
      segmentTypeId: segmentId
    }
  };
  return BaseApiRequest(signal, `/Hierarchy/Vessel?api-version=1.0`, 'ships', 'POST', body);
};
