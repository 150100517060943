import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  InfoBox,
  Paper,
  ShipsIcon,
  StorageIcon,
  Tab,
  Tabs,
  TrendingUpIcon
} from '@esgian/esgianui';
import { useSearchParams } from 'react-router-dom';
import {
  OperatorEmissionsSection,
  OperatorCiiRatingSection,
  OperatorActivitySection
} from '@components/Sections/ShipAnalytics/OperatorProfileSection/SubSections';
import OperatorOverviewSection from './SubSections/OperatorOverviewSection';
import TradeRouteSection from '@components/Sections/ShipAnalytics/OperatorProfileSection/SubSections/TradeRouteSection';
import { TextWithTooltipIcon } from '@components';
import { useSegment } from '@hooks';

function OperatorProfileSection({
  fleetAgeData,
  lastPortCalls,
  vesselsData,
  setLastPortCalls,
  operatorData,
  loading,
  hasCiiData,
  hasEmissionsData,
  oprUpcomingPortCallsData
}) {
  const [tabSection, setTabSection] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isRoRo } = useSegment();

  const handleSectionChange = (_, val) => {
    if (!hasEmissionsData && val === 3) {
      return;
    }
    if (!hasCiiData && val === 4) {
      return;
    }
    setTabSection(val);
    searchParams.set('section', val);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setTabSection(parseInt(searchParams.get('section') || 1));
  }, []);

  const {
    averageAge,
    averageCapacity,
    fleetSize,
    totalCapacity,
    operatorShortName,
    operatorLongName
  } = operatorData || {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <InfoBox
          loading={loading.overview}
          mainValue={fleetSize || '-'}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          subHeader={'Vessels'}
          header={'Fleet Size'}
          colorSecondary={'#63d5fa'}
          colorMain={'#61efbf'}
          headerColor={'#63d5fa'}
        />
      </Grid>
      <Grid item xs={3}>
        <InfoBox
          loading={loading.overview}
          mainValue={averageAge ? averageAge.toFixed(1) : '-'}
          icon={<TrendingUpIcon fontSize={'large'} color={'inherit'} />}
          subHeader={'Years'}
          header={'Average Age'}
          colorSecondary={'#57ed80'}
          colorMain={'#57edd1'}
          headerColor={'#57ed80'}
        />
      </Grid>
      <Grid item xs={3}>
        <InfoBox
          loading={loading.overview}
          mainValue={averageCapacity ? parseFloat(averageCapacity.toFixed(0)) : '-'}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={isRoRo ? 'CEU' : 'DWT'}
          header={'Average Capacity'}
          colorSecondary={'#66a6ff'}
          colorMain={'#89f7fe'}
          headerColor={'#66a6ff'}
        />
      </Grid>
      <Grid item xs={3}>
        <InfoBox
          loading={loading.overview}
          mainValue={totalCapacity || '-'}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={isRoRo ? 'CEU' : 'DWT'}
          header={'Total Capacity'}
          colorSecondary={'#4faefd'}
          colorMain={'#5d3afc'}
          headerColor={'#4faefd'}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Tabs value={tabSection} onChange={handleSectionChange} variant="scrollable">
            <Tab value={1} label="Overview" />
            <Tab value={2} label="Activity" />
            <Tab
              sx={{ cursor: hasEmissionsData ? 'pointer' : 'default' }}
              disableRipple={!hasEmissionsData}
              value={3}
              id="vessel-cii-rating-tab"
              label={
                !hasEmissionsData ? (
                  <TextWithTooltipIcon
                    color={'text.secondary'}
                    label={'CO₂ Emission'}
                    tooltipText={'Insufficient data to compute CO₂ emissions'}
                  />
                ) : (
                  'CO₂ Emission'
                )
              }
            />
            <Tab
              sx={{ cursor: hasCiiData ? 'pointer' : 'default' }}
              disableRipple={!hasCiiData}
              value={4}
              label={
                !hasCiiData ? (
                  <TextWithTooltipIcon
                    color={'text.secondary'}
                    label={'CII RATING'}
                    tooltipText={'Insufficient data to compute CII'}
                  />
                ) : (
                  'Cii rating'
                )
              }
            />
            <Tab value={5} label="COMMERCIAL FOOTPRINT" />
          </Tabs>
        </Paper>
      </Grid>
      {tabSection === 1 && (
        <OperatorOverviewSection
          fleetAgeData={fleetAgeData}
          loading={loading}
          operatorData={operatorData}
          vesselsData={vesselsData}
        />
      )}
      {tabSection === 2 && (
        <OperatorActivitySection
          assetLevel={'operator'}
          setPortCallData={setLastPortCalls}
          operatorData={operatorData}
          fleetAgeData={fleetAgeData}
          loading={loading}
          lastPortCalls={lastPortCalls}
          oprUpcomingPortCallsData={oprUpcomingPortCallsData}
        />
      )}
      {tabSection === 3 && (
        <OperatorEmissionsSection
          operatorLongName={operatorLongName}
          operatorShortName={operatorShortName}
        />
      )}
      {tabSection === 4 && (
        <OperatorCiiRatingSection
          operatorLongName={operatorLongName}
          operatorShortName={operatorShortName}
        />
      )}
      {tabSection === 5 && <TradeRouteSection />}
    </Grid>
  );
}

OperatorProfileSection.propTypes = {
  operatorData: PropTypes.object,
  vesselsData: PropTypes.arrayOf(PropTypes.object),
  lastPortCalls: PropTypes.arrayOf(PropTypes.object),
  fleetAgeData: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.object.isRequired,
  setLastPortCalls: PropTypes.func.isRequired,
  hasCiiData: PropTypes.bool,
  hasEmissionsData: PropTypes.bool,
  oprUpcomingPortCallsData: PropTypes.arrayOf(PropTypes.object)
};

OperatorProfileSection.defaultProps = {
  vesselsData: null,
  lastPortCalls: null,
  fleetAgeData: null,
  hasCiiData: false,
  hasEmissionsData: false,
  oprUpcomingPortCallsData: null
};

export default OperatorProfileSection;
