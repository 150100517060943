import React from 'react';
import { Divider, Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import PortWaitingTimeTable from './VoyagePortWaitingTimeTable';
import PropTypes from 'prop-types';
function VoyagePortWaitingTime({ averageData, voyagePortCalls }) {
  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack spacing={2}>
        <Stack>
          <Typography variant={'h6'}>Port time and port waiting time</Typography>
        </Stack>
        <Stack spacing={2}>
          <Grid container sx={{ textAlign: 'center' }}>
            <Grid item xs>
              <Typography variant={'body2'} bold>
                {averageData.averagePortTime ?? 0}
              </Typography>
              <Typography variant={'caption'} color={'text.secondary'}>
                Avg. Port time (days)
              </Typography>
            </Grid>
            <div>
              <Divider orientation={'vertical'} />
            </div>
            <Grid item xs>
              <Typography variant={'body2'} bold>
                {averageData.averagePortWaitingTime ?? 0}
              </Typography>
              <Typography variant={'caption'} color={'text.secondary'}>
                Avg. Port waiting time (days)
              </Typography>
            </Grid>
          </Grid>
        </Stack>
        <Stack>
          <PortWaitingTimeTable voyagePortCalls={voyagePortCalls} />
        </Stack>
      </Stack>
    </Paper>
  );
}

VoyagePortWaitingTime.propTypes = {
  averageData: PropTypes.shape({
    averagePortTime: PropTypes.string,
    averagePortWaitingTime: PropTypes.string
  }),
  voyagePortCalls: PropTypes.array
};

VoyagePortWaitingTime.defaultProps = {
  averageData: {
    averagePortTime: '0',
    averagePortWaitingTime: '0'
  },
  voyagePortCalls: []
};

export default VoyagePortWaitingTime;
