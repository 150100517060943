import React, { useState, useEffect } from 'react';
import { Grid, PageHeader, Typography, CircularProgress } from '@esgian/esgianui';
import AssuredWorkloadOutlinedIcon from '@mui/icons-material/AssuredWorkloadOutlined';
import VoyageAnalyticsFilters from '@components/Sections/ShipAnalytics/VoyageAnalyticsSection/VoyageAnalyticsFilters';
import CompletedVoyageSection from '@components/Sections/VoyageAnalytics/CompletedVoyageSection';
import { useSelector } from 'react-redux';
import { getCompletedVoyagesFilters } from '@store/features/filters/VoyageAnalyticsPage/CompletedVoyage/CompletedVoyagesSlice';
import { getVoyageSearchDetails } from '@api';
import AccreditationStatementModal from '../../../components/Modals/AccreditationStatementModal';
import VoyageGroupsPopup from '../../../components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupsPopup';
import { useSegment } from '../../../hooks/useSegment';
import moment from 'moment';

function CompletedVoyagePage() {
  const [showStatement, setShowStatement] = useState(false);
  const [completedVoyagesData, setCompletedVoyagesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(true);
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState(null); // Applied filters state
  const initialFilters = useSelector(getCompletedVoyagesFilters);
  const { segment } = useSegment();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchData = async () => {
      if (!appliedFilters) return; // Fetch only if there are applied filters

      const validFilters =
        appliedFilters &&
        appliedFilters.toId &&
        appliedFilters.fromId &&
        moment(appliedFilters.startDate).isValid() &&
        moment(appliedFilters.endDate).isValid();

      if (!validFilters) {
        console.log('Invalid filters, skipping fetch');
        setCompletedVoyagesData([]);
        setNoData(true);
        setLoading(false);
        return;
      }

      setLoading(true);
      setNoData(false);

      try {
        let body = {
          ...appliedFilters,
          toId: appliedFilters.toId.id ?? null,
          fromId: appliedFilters.fromId.id ?? null,
          segmentTypeId: segment.id
        };

        const response = await getVoyageSearchDetails(body, signal);
        const voyageSearchResults = response.voyageSearchResults || [];
        setCompletedVoyagesData(voyageSearchResults);
        setNoData(voyageSearchResults.length === 0);
      } catch (error) {
        console.error('Error fetching voyage search results:', error);
        setCompletedVoyagesData([]);
        setNoData(true);
      } finally {
        setLoading(false);
      }
    };

    if (shouldFetchData) {
      fetchData();
    }

    return () => {
      controller.abort();
    };
  }, [shouldFetchData, appliedFilters, segment.id]);

  const handleApplyFilters = () => {
    setAppliedFilters(initialFilters); // Apply current filters
    setShouldFetchData(true); // Trigger data fetching
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={6}>
        <PageHeader
          id={'lbl-voyage-analytics'}
          title={'Voyage analytics'}
          icon={
            <AssuredWorkloadOutlinedIcon
              fontSize={'small'}
              onClick={() => {
                setShowStatement(true);
              }}
              style={{ cursor: 'pointer' }}
            />
          }
        />
      </Grid>
      <Grid item container xs={6} spacing={1} justifyContent="flex-end" alignItems="center">
        <VoyageGroupsPopup mode="search" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant={'subtitle2'}>Completed voyage</Typography>
      </Grid>
      <VoyageAnalyticsFilters handleApplyFilters={handleApplyFilters} />

      {loading && (
        <Grid
          item
          xs={12}
          style={{
            minHeight: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative'
          }}>
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 'calc(50% - 50px)',
              left: 'calc(50% - 50px)'
            }}
            size={100}
          />
        </Grid>
      )}

      {!loading && appliedFilters && (
        <Grid
          item
          xs={12}
          style={{
            minHeight: '400px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <CompletedVoyageSection loading={loading} completedVoyagesData={completedVoyagesData} />
        </Grid>
      )}

      <AccreditationStatementModal
        handleClose={() => setShowStatement(false)}
        open={showStatement}
      />
    </Grid>
  );
}

export default CompletedVoyagePage;
