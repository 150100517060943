import { createListenerMiddleware } from '@reduxjs/toolkit';
import { SEGMENT } from '@constants';
import { fetchGeneralLookups, setSegment } from '@store/features';

export const getUserMiddleware = () => {
  const userMiddleware = createListenerMiddleware();
  userMiddleware.startListening({
    predicate: (action, currentState, previousState) => {
      return (
        currentState.auth.user !== previousState.auth.user &&
        currentState.auth.hasAccess &&
        currentState.auth.browserId
      );
    },
    effect: (action, listenerApi) => {
      listenerApi.cancelActiveListeners();
      const controller = new AbortController();
      const { signal } = controller;
      const state = listenerApi.getState();
      if (!state.auth.hasAccess) {
        return;
      }

      let userPermissions = state.auth.user.profile.permissionList;
      const hasRoRoAccess = userPermissions.includes(SEGMENT.RoRo.value);
      const hasMPPAccess = userPermissions.includes(SEGMENT.MPP.value);
      let uriSegment = window.location.pathname?.split('/')[1];

      if (['roro', 'mpp'].includes(uriSegment)) {
        if (uriSegment === 'roro' && hasRoRoAccess) {
          listenerApi.dispatch(setSegment(SEGMENT.RoRo));
        }
        if (uriSegment === 'mpp' && hasMPPAccess) {
          listenerApi.dispatch(setSegment(SEGMENT.MPP));
        }
      } else {
        if (hasRoRoAccess) {
          listenerApi.dispatch(setSegment(SEGMENT.RoRo));
        } else if (hasMPPAccess) {
          listenerApi.dispatch(setSegment(SEGMENT.MPP));
        }
      }

      listenerApi.dispatch(
        fetchGeneralLookups({
          signal: signal
        })
      );

      return () => {
        controller.abort();
      };
    }
  });

  return userMiddleware;
};
