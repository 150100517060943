import React, { useEffect, useState } from 'react';
import PropTypes, { number } from 'prop-types';
import TableWithSummary from './TableWithSummary/TableWithSummary';
import { useSegment } from '../../../hooks/useSegment';
import { formatNumberReadable } from '@helpers';

export const getDataShape = (type, wtt, ttw, wtw, tooltip) => ({
  type: { value: type, tooltip },
  wtt: { value: wtt },
  ttw: { value: ttw },
  wtw: { value: wtw }
});

export const emissionTableColumns = [
  { name: 'type', label: 'Type' },
  {
    name: 'wtt',
    label: 'WTT',
    tooltip: 'WTT: Well to Tank Emissions. Sometimes also referred as  energy production emission.'
  },
  {
    name: 'ttw',
    label: 'TTW',
    tooltip:
      'TTW: Tank to Wake emission. This is the emission due to fuel combustion in the vessel sometimes referred as operational emission.'
  },
  {
    name: 'wtw',
    label: 'WTW',
    tooltip:
      'WTW: Well to wake emission. This is the total emissions from energy production and operation (WTT +TTW) .'
  }
];

const Co2eEmissionTable = ({ myCargoHoc, myCargoToc, emissionSummary, totalEts, isLoading }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData([
      {
        items: { value: 'My cargo (T)' },
        amount: {
          value: formatNumberReadable(emissionSummary.myCargoTn?.toFixed(1)),
          isEditable: false
        }
      },
      {
        items: { value: 'TOC GHG Emission for my cargo (kg)' },
        amount: { value: formatNumberReadable(emissionSummary.tocGhgEmissionKg?.toFixed(0)) }
      },
      {
        items: { value: 'HOC GHG Emission for my cargo (kg)' },
        amount: { value: formatNumberReadable(emissionSummary.hocGhgEmissionKg?.toFixed(0)) }
      },
      {
        items: { value: 'Total GHG Emission for my cargo (kg)' },
        amount: { value: formatNumberReadable(emissionSummary.totalGhgEmission?.toFixed(0)) }
      }
    ]);
  }, [emissionSummary]);

  const myCargoTableColumns = [
    {
      name: 'items',
      label: 'Items'
    },
    {
      name: 'amount',
      label: 'Amount'
    }
  ];

  const summary = [
    {
      label: 'Total GHG Emission for My Cargo (kg)',
      value: formatNumberReadable(totalEts?.toFixed(0))
    }
  ];

  const myCargoTocEmissionTableColumns = [
    {
      name: 'voyageLeg',
      label: 'Voyage Leg'
    },
    {
      name: 'origin',
      label: 'Origin'
    },
    {
      name: 'destination',
      label: 'Destination'
    },
    {
      name: 'ghcEmissionKgCo2e',
      label: 'Distance (km)'
    },
    {
      name: 'myTocActivity',
      label: 'My TOC activity (T-km)'
    },
    {
      name: 'energyProduction',
      label: 'Energy Production'
    },
    {
      name: 'operational',
      label: 'Operational'
    },
    {
      name: 'overall',
      label: 'Overall'
    }
  ];

  const myCargoTocEmissionTableData =
    myCargoToc.voyageLegMyCargoEmissionIntensities?.map((d) => ({
      voyageLeg: { value: `#${d.voyageLeg}` },
      origin: { value: d.fromPortName },
      destination: { value: d.toPortName },
      ghcEmissionKgCo2e: { value: formatNumberReadable(d.ghgEmissionOverallKg?.toFixed(0)) },
      myTocActivity: { value: formatNumberReadable(d.myTocActivity?.toFixed(0)) },
      energyProduction: { value: formatNumberReadable(d.ghgEmissionEneryProductionKg?.toFixed(0)) },
      operational: { value: formatNumberReadable(d.ghgEmissionOperationalKg?.toFixed(0)) },
      overall: { value: formatNumberReadable(d.ghgEmissionOverallKg?.toFixed(0)) }
    })) ?? [];

  const myCargoTocEmissionTableTotalRow = {
    voyageLeg: { value: 'Total' },
    origin: { value: '-' },
    destination: { value: '-' },
    ghcEmissionKgCo2e: { value: '-' },
    myTocActivity: { value: formatNumberReadable(myCargoToc.totalMyTocActivity?.toFixed(0)) },
    energyProduction: {
      value: formatNumberReadable(myCargoToc.totalGhgEmissionEneryProductionKg?.toFixed(0))
    },
    operational: {
      value: formatNumberReadable(myCargoToc.totalGhgEmissionOperationalKg?.toFixed(0))
    },
    overall: { value: formatNumberReadable(myCargoToc.totalGhgEmissionOverallKg?.toFixed(0)) }
  };

  const { isMPP } = useSegment();

  const myCargoTocEmissionTableBallastRow = {
    voyageLeg: { value: 'Ballast' },
    origin: { value: '-' },
    destination: { value: '-' },
    ghcEmissionKgCo2e: { value: '-' },
    myTocActivity: { value: '-' },
    energyProduction: { value: '-' },
    operational: { value: '-' },
    overall: { value: Number(myCargoToc.ballast).toFixed(2) }
  };

  const myCargoTocEmissionTableBallastPlusRow = {
    voyageLeg: { value: 'Total + Ballast' },
    origin: { value: '-' },
    destination: { value: '-' },
    ghcEmissionKgCo2e: { value: '-' },
    myTocActivity: { value: '-' },
    energyProduction: { value: '-' },
    operational: { value: '-' },
    overall: { value: Number(myCargoToc.totalGhgEmissionOverallPlusBallastKg).toFixed(2) }
  };

  const finalDataRows = [...myCargoTocEmissionTableData, myCargoTocEmissionTableTotalRow];
  if (isMPP) {
    finalDataRows.push(myCargoTocEmissionTableBallastRow);
    finalDataRows.push(myCargoTocEmissionTableBallastPlusRow);
  }

  const myCargoHocEmissionTableColumns = [
    {
      name: 'portName',
      label: 'Port Name'
    },
    {
      name: 'operationalHocEmissionIntensityKgCo2eT',
      label: 'Operational HOC Emission intensity (kg CO₂e/T)'
    },
    {
      name: 'hocActivityT',
      label: 'HOC activity (T)'
    },
    {
      name: 'ghcEmissionKgCo2e',
      label: 'GHG Emission (kg CO₂e)'
    }
  ];

  const myCargoHocEmissionTableData =
    myCargoHoc.voyageLegMyCargoHocEmissions?.map((d) => ({
      portName: { value: d.portName },
      operationalHocEmissionIntensityKgCo2eT: {
        value: d.operationalHocEmissionIntensity?.toFixed(1)
      },
      hocActivityT: { value: formatNumberReadable(d.hocActivity?.toFixed(1)) },
      ghcEmissionKgCo2e: { value: formatNumberReadable(d.hocGhgEmission?.toFixed(0)) }
    })) ?? [];

  return (
    <TableWithSummary
      title="My Cargo"
      columns={myCargoTableColumns}
      data={data}
      onUpdate={(rowIndex, columnName, newValue) => {
        const newData = [...data];
        newData[rowIndex][columnName].value = +newValue;
        setData(newData);
      }}
      summary={summary}
      detailsTableTitle="My Cargo TOC (Transport Operation Category) Emission"
      detailsTableColumns={myCargoTocEmissionTableColumns}
      detailsTableData={finalDataRows}
      secondaryDetailsTableTitle="My Cargo HOC (Hub Operation Category) Emission"
      secondaryDetailsTableColumns={myCargoHocEmissionTableColumns}
      secondaryDetailsTableData={myCargoHocEmissionTableData}
      isLoading={isLoading}
    />
  );
};

export const emissionValuesPropTypes = {
  wtt: PropTypes.number,
  ttw: PropTypes.number,
  wtw: PropTypes.number
};

Co2eEmissionTable.propTypes = {
  myCargoToc: PropTypes.object,
  myCargoHoc: PropTypes.object,
  emissionSummary: PropTypes.object,
  totalEts: PropTypes.number,
  isLoading: PropTypes.bool
};

export default Co2eEmissionTable;
