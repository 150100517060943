import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const categories = ['At port', 'Standby', 'Transit'];

function VoyageActivityProfileChart({ loading, activityData }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const { atPortDays, standbyDays, transitDays } = activityData;

  const options = useMemo(() => {
    const { palette: { mode, charts: { background } } = {} } = theme || {};
    const seriesColors = ['#80DFEB', '#00D684', '#0052D6'];
    return {
      chart: {
        type: 'donut',
        fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
        background: background
      },
      colors: seriesColors,
      legend: {
        show: true,
        markers: {
          fillColors: seriesColors
        },
        labels: {
          colors: mode ? 'dark' : 'light'
        },
        position: 'bottom'
      },
      plotOptions: {
        pie: {
          donut: {
            size: '50%'
          }
        }
      },
      dataLabels: {
        dropShadow: {
          enabled: false
        },
        style: {
          colors: ['#000']
        },
        formatter: function (val) {
          return val.toFixed(1) + '%';
        }
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val.toFixed(1) + '%';
          }
        }
      },
      title: {
        offsetY: 195,
        offsetX: -45,
        style: {
          fontWeight: 100,
          fontSize: '1.25rem'
        },
        align: 'center'
      },
      labels: categories,
      stroke: {
        show: false,
        width: 0
      }
    };
  }, [theme, categories, user]);

  const data = [atPortDays, standbyDays, transitDays];
  return (
    <ApexChart loading={loading} data={data} height={320} options={options || {}} type={'donut'} />
  );
}

VoyageActivityProfileChart.propTypes = {
  loading: PropTypes.bool,
  activityData: PropTypes.object.isRequired
};

VoyageActivityProfileChart.defaultProps = {
  loading: false
};

export default VoyageActivityProfileChart;
