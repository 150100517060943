import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography
} from '@esgian/esgianui';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { scrollbarStyling } from '../../../../utils/constants';
const AddToVoyageGroupPopup = ({
  savedGroups,
  onCreateANewGroup,
  onAddVoyageToGroup,
  isSavingVoyageToGroup,
  isLoadingVoyageGroups
}) => {
  const themeMode = useSelector(getThemeMode);

  const hasAnyGroup = savedGroups.length > 0;

  return (
    <Box p={1} width="300px" sx={{ backgroundColor: themeMode ? 'black' : undefined }}>
      <Typography variant={'h6'} p={1}>
        Add the voyage to...
      </Typography>
      {!hasAnyGroup && !isLoadingVoyageGroups && (
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical'
          }}
          variant="body1"
          color="gray"
          px={1}>
          You have no group yet, create one to start
        </Typography>
      )}
      {isLoadingVoyageGroups && (
        <Typography variant="body1" p={1}>
          Loading...
        </Typography>
      )}
      {hasAnyGroup && !isLoadingVoyageGroups && (
        <List sx={{ maxHeight: '200px', overflow: 'auto', ...scrollbarStyling }}>
          {savedGroups.map((group) => {
            return (
              <ListItem key={group.id} disablePadding>
                <ListItemButton
                  sx={{ py: 0.5, px: 1 }}
                  onClick={() => onAddVoyageToGroup(group.id)}
                  disabled={isSavingVoyageToGroup}>
                  <ListItemText primary={group.name} />
                  <Typography variant="body2" color="gray">
                    ({group.voyages.length} voyages)
                  </Typography>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}

      <Button variant="text" p={2} sx={{ marginTop: '8px' }} onClick={() => onCreateANewGroup()}>
        + CREATE A NEW GROUP
      </Button>
    </Box>
  );
};

AddToVoyageGroupPopup.propTypes = {
  onCreateANewGroup: PropTypes.func.isRequired,
  savedGroups: PropTypes.object.isRequired,
  isSavingVoyageToGroup: PropTypes.bool,
  isLoadingVoyageGroups: PropTypes.bool,
  onAddVoyageToGroup: PropTypes.func
};

export default AddToVoyageGroupPopup;
