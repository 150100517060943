import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from './TableWithSummary/TableWithSummary';
import { fuelColumns, getFuelData } from './MainEngineFuelTable';

export const fuelTableHeight = '300px';

const MainGeneratorFuelTable = ({ mainGeneratorFuel, fuelTypes, isLoading }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const mappedData = mainGeneratorFuel.map((fuel) => {
      return getFuelData(fuelTypes[fuel.fuelTypeId], fuel);
    });

    setData(mappedData);
  }, [mainGeneratorFuel]);

  return (
    <TableWithSummary
      title="Main generator fuel"
      columns={fuelColumns}
      data={data}
      onUpdate={(rowIndex, columnName, newValue) => {
        const newData = [...data];
        newData[rowIndex][columnName].value = +newValue;
        setData(newData);
      }}
      minTableHeight={fuelTableHeight}
      maxTableHeight={fuelTableHeight}
      isLoading={isLoading}
    />
  );
};

MainGeneratorFuelTable.propTypes = {
  mainGeneratorFuel: PropTypes.array,
  fuelTypes: PropTypes.object,
  isLoading: PropTypes.bool
};

export default MainGeneratorFuelTable;
