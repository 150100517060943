import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const getYaxis = (max, avgColor) => {
  let axis = [];
  let first = true;
  for (let i = 0; i < 6; i++) {
    axis.push({
      show: first,
      max: max,
      forceNiceScale: true,
      tickAmount: 0,
      decimalsInFloat: 0,
      title: {
        text: 'Number of vessels'
      }
    });
    first = false;
  }
  axis.push({
    opposite: true,
    tickAmount: 10,
    seriesName: 'Avg',
    max: (max) => max * 1.25,
    forceNiceScale: true,
    decimalsInFloat: 0,
    labels: {
      style: {
        colors: avgColor
      }
    },
    title: {
      style: {
        color: avgColor
      },
      text: 'Average'
    }
  });
  return axis;
};

const getOptions = (categories, theme, user, max, fileName, totals) => {
  const {
    palette: {
      mode,
      text: { primary },
      charts: { background, rowColors, twoColorsContrast, tenColorsSequential }
    }
  } = theme;
  return {
    chart: {
      type: 'line',
      height: 350,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: true
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [0, 1, 2, 3, 4, 5]
    },
    stroke: {
      width: [0, 0, 0, 0, 0, 0, 2],
      dashArray: [0, 0, 0, 0, 0, 0, 5]
    },
    colors: [
      tenColorsSequential[8],
      tenColorsSequential[6],
      tenColorsSequential[4],
      tenColorsSequential[3],
      tenColorsSequential[2],
      tenColorsSequential[1],
      twoColorsContrast[0]
    ],
    theme: {
      mode: mode
    },
    annotations: {
      points: totals?.map((item, i) => {
        return {
          x: categories[i],
          y: item,
          marker: {
            size: 0,
            radius: 0
          },
          label: {
            borderColor: 'transparent',
            offsetY: 0,
            style: {
              color: primary,
              background: 'transparent'
            },

            text: `Total: ${item}`
          }
        };
      })
    },
    xaxis: {
      categories: categories,
      forceNiceScale: true,
      title: {
        text: 'Quarter'
      }
    },
    yaxis: getYaxis(max, twoColorsContrast[0]),
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          position: 'center'
        },
        background: {
          enabled: false
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true
    },
    legend: {
      position: 'top',
      showForSingleSeries: true,
      horizontalAlign: 'left'
    },
    noData: {
      text: 'There is no data to display'
    }
  };
};

function OperatorFleetAgeChart({ loading, fleetAgeData, fileName }) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [totals, setTotals] = useState([]);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!fleetAgeData?.length) return;
    let totals = [0, 0, 0, 0, 0, 0, 0, 0];
    setSeries([
      {
        name: '0-5',
        type: 'bar',
        data:
          fleetAgeData?.map(({ countVesselInAgeRange0To5 }, i) => {
            totals[i] += countVesselInAgeRange0To5 || 0;
            return countVesselInAgeRange0To5;
          }) || []
      },
      {
        name: '6-10',
        type: 'bar',
        data:
          fleetAgeData?.map(({ countVesselInAgeRange6To10 }, i) => {
            totals[i] += countVesselInAgeRange6To10 || 0;
            return countVesselInAgeRange6To10;
          }) || []
      },
      {
        name: '11-15',
        type: 'bar',
        data:
          fleetAgeData?.map(({ countVesselInAgeRange11To15 }, i) => {
            totals[i] += countVesselInAgeRange11To15 || 0;
            return countVesselInAgeRange11To15;
          }) || []
      },
      {
        name: '16-20',
        type: 'bar',
        data:
          fleetAgeData?.map(({ countVesselInAgeRange16To20 }, i) => {
            totals[i] += countVesselInAgeRange16To20 || 0;
            return countVesselInAgeRange16To20;
          }) || []
      },
      {
        name: '21-25',
        type: 'bar',
        data:
          fleetAgeData?.map(({ countVesselInAgeRange21To25 }, i) => {
            totals[i] += countVesselInAgeRange21To25 || 0;
            return countVesselInAgeRange21To25;
          }) || []
      },
      {
        name: '25+',
        type: 'bar',
        data:
          fleetAgeData?.map(({ countVesselAgeGreaterThan25 }, i) => {
            totals[i] += countVesselAgeGreaterThan25 || 0;
            return countVesselAgeGreaterThan25;
          }) || []
      },
      { name: 'Avg', type: 'line', data: fleetAgeData?.map(({ averageAge }) => averageAge) || [] }
    ]);
    setTotals(totals);
  }, [fleetAgeData]);
  const maxVesselCount = fleetAgeData.reduce((max, obj) => Math.max(max, obj.totalCountVessel), 0);
  useEffect(() => {
    if (!fleetAgeData?.length) return;
    const max = maxVesselCount * 1.4;
    setOptions(
      getOptions(
        fleetAgeData?.map(({ quarter }) => quarter),
        theme,
        user,
        max,
        fileName,
        totals
      )
    );
  }, [theme, series]);
  return (
    <ApexChart
      loading={loading}
      height={480}
      options={options || {}}
      type={'line'}
      data={series || []}
    />
  );
}
OperatorFleetAgeChart.propTypes = {
  loading: PropTypes.bool,
  fleetAgeData: PropTypes.arrayOf(PropTypes.object),
  fileName: PropTypes.string.isRequired
};
OperatorFleetAgeChart.defaultProps = {
  fleetAgeData: [],
  loading: false
};
export default OperatorFleetAgeChart;
