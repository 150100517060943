import React from 'react';
import PropTypes from 'prop-types';
import { Grid, InfoBox, ShipsIcon, StorageIcon, TrendingUpIcon } from '@esgian/esgianui';
import { OperatorsTable } from '@components/Tables';
import { useSegment } from '@hooks';

function OperatorsOverviewSection({ loading, operatorDetails }) {
  const { isRoRo } = useSegment();
  const { operators, averageAge, averageCapacity, totalCapacity, fleetSize } =
    operatorDetails || {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={3} id={'operator-overview-fleet-size'}>
        <InfoBox
          loading={loading}
          mainValue={fleetSize ? fleetSize : ''}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          subHeader={'Vessels'}
          header={'Fleet Size'}
          colorSecondary={'#63d5fa'}
          colorMain={'#61efbf'}
          headerColor={'#63d5fa'}
          headerId={'operator-overview-fleet-size-label'}
          mainValueId={'operator-overview-fleet-size-value'}
        />
      </Grid>
      <Grid item xs={3} id={'operator-overview-avg-age'}>
        <InfoBox
          loading={loading}
          mainValue={averageAge ? averageAge.toFixed(1) : ''}
          icon={<TrendingUpIcon fontSize={'large'} color={'inherit'} />}
          subHeader={'Years'}
          header={'Average Age'}
          colorSecondary={'#57ed80'}
          colorMain={'#57edd1'}
          headerColor={'#57ed80'}
          headerId={'operator-overview-avg-age-label'}
          mainValueId={'operator-overview-avg-age-value'}
        />
      </Grid>
      <Grid item xs={3} id={'operator-overview-avg-capacity'}>
        <InfoBox
          loading={loading}
          mainValue={averageCapacity ? parseFloat(averageCapacity.toFixed(0)) : '-'}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={isRoRo ? 'CEU' : 'DWT'}
          header={'Average Capacity'}
          colorSecondary={'#66a6ff'}
          colorMain={'#89f7fe'}
          headerColor={'#66a6ff'}
          headerId={'operator-overview-avg-capacity-label'}
          mainValueId={'operator-overview-avg-capacity-value'}
        />
      </Grid>
      <Grid item xs={3} id={'operator-overview-total-capacity'}>
        <InfoBox
          loading={loading}
          mainValue={totalCapacity ? totalCapacity : ''}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={isRoRo ? 'CEU' : 'DWT'}
          header={'Total Capacity'}
          colorSecondary={'#4faefd'}
          colorMain={'#5d3afc'}
          headerColor={'#4faefd'}
          headerId={'operator-overview-total-capacity-label'}
          mainValueId={'operator-overview-total-capacity-value'}
        />
      </Grid>
      <Grid item xs={12}>
        <OperatorsTable operators={operators} fileName={'operators-overview'} loading={loading} />
      </Grid>
    </Grid>
  );
}

OperatorsOverviewSection.propTypes = {
  loading: PropTypes.bool,
  operatorDetails: PropTypes.object
};

OperatorsOverviewSection.defaultProps = {};

export default OperatorsOverviewSection;
