import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, NoResult } from '@esgian/esgianui';
import { PortTimeVsPortCallsChart, PortTimeYtdComparisonChart } from '@components/Charts';

function RegionPortStatisticsSection({ loading, regionPortStatsData, regionName }) {
  return (
    <>
      {!loading && !regionPortStatsData ? (
        <Grid item lg={6} sm={12}>
          <NoResult
            header={'No Matching Result'}
            text={'Please change your search parameters'}
            show={true}
          />
        </Grid>
      ) : (
        <Grid item lg={6} sm={12}>
          <PortTimeVsPortCallsChart
            loading={loading}
            portName={regionName}
            portStats={regionPortStatsData}
            fileName={`port-time-vs-port-calls-${regionName?.toLowerCase().split(' ').join('-')}`}
          />
        </Grid>
      )}
      {!loading && !regionPortStatsData ? (
        <Grid item lg={6} sm={12}>
          <NoResult
            header={'No Matching Result'}
            text={'Please change your search parameters'}
            show={true}
          />
        </Grid>
      ) : (
        <Grid item lg={6} sm={12}>
          <PortTimeYtdComparisonChart
            portStats={regionPortStatsData}
            loading={loading}
            portName={regionName}
            fileName={`port-time-change-${regionName?.toLowerCase().split(' ').join('-')}`}
          />
        </Grid>
      )}
    </>
  );
}

RegionPortStatisticsSection.propTypes = {
  loading: PropTypes.bool,
  regionPortStatsData: PropTypes.object,
  regionName: PropTypes.string
};

RegionPortStatisticsSection.defaultProps = {
  loading: false,
  regionPortStatsData: null,
  regionName: ''
};

export default RegionPortStatisticsSection;
