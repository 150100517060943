import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Button, ButtonGroup, Grid, Stack, Typography } from '@esgian/esgianui';
import { checkUserHasAccess, formatAxisValue } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';
import ApexCharts from 'apexcharts';

const getMainChartDefOptions = (
  seriesMain,
  user,
  dateList,
  theme,
  idMain,
  groupName,
  fileName,
  type,
  yAxisTitle
) => {
  const {
    mode,
    palette: {
      charts: { background, rowColors }
    },
    typography: { fontFamily }
  } = theme;
  return {
    series: seriesMain,
    chart: {
      id: idMain,
      type: type,
      group: groupName,
      background: background,
      fontFamily: fontFamily,
      height: 350,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true),
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    colors: ['#7ddbbd', '#b3dffd', '#a1e1e2', '#82e2cb', '#6bc2c3', '#a0d6fd', '#5db1f0'],
    tooltip: {
      shared: true,
      intersect: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      opacity: 1
    },
    markers: {
      size: 3
    },
    theme: {
      mode: mode
    },
    grid: {
      row: {
        colors: rowColors, // takes an array which will be repeated on columns
        opacity: 0.5
      }
    },
    stroke: {
      colors:
        type === 'bar'
          ? ['transparent']
          : ['#94e59f', '#b3dffd', '#a1e1e2', '#82e2cb', '#6bc2c3', '#a0d6fd', '#5db1f0'],
      width: 2
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: '90%',
        barHeight: '100%'
      }
    },
    xaxis: {
      labels: { show: true, rotate: -45, rotateAlways: false, hideOverlappingLabels: false },
      categories: dateList
    },
    yaxis: {
      title: {
        text: yAxisTitle || ''
      },
      tickAmount: 5,
      max: (max) => max * 1.2,
      labels: {
        minWidth: 50,
        maxWidth: 50,
        formatter: (value) => {
          if (value <= 5 && value !== 0) {
            return value?.toFixed(1);
          }
          return formatAxisValue(value);
        }
      }
    },
    legend: {
      showForSingleSeries: true,
      position: 'top',
      horizontalAlign: 'left'
    }
  };
};

const getBrushChartOptions = (
  series,
  dateList,
  theme,
  idBrush,
  groupName,
  type,
  user,
  fileName
) => {
  const {
    mode,
    typography: { fontFamily },
    palette: {
      charts: { background, rowColors }
    }
  } = theme;
  return {
    series: series,
    chart: {
      id: idBrush,
      type: type === 'bar' ? 'bar' : 'area',
      group: groupName,
      fontFamily: fontFamily,
      background: background,
      height: 130,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    colors: ['#94e59f', '#b3dffd', '#a1e1e2', '#82e2cb', '#6bc2c3', '#a0d6fd', '#5db1f0'],
    stroke: {
      colors: ['#94e59f', '#b3dffd', '#a1e1e2', '#82e2cb', '#6bc2c3', '#a0d6fd', '#5db1f0'],
      width: 2
    },
    tooltip: { shared: true, intersect: false },

    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 0,
        columnWidth: '75%',
        barHeight: '100%'
      }
    },
    fill: {
      opacity: type === 'bar' ? 1 : '0.4'
    },
    markers: {
      size: 3
    },
    theme: {
      mode: mode
    },
    xaxis: {
      labels: {
        rotate: -15,
        rotateAlways: false,
        hideOverlappingLabels: false
      },
      categories: dateList
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    legend: {
      showForSingleSeries: false,
      position: 'top',
      horizontalAlign: 'left'
    },
    yaxis: {
      tickAmount: 3,
      title: {
        text: 'Total'
      },
      labels: {
        minWidth: 50,
        maxWidth: 50,
        formatter: (value) => {
          if (value <= 3 && value !== 0) {
            return value?.toFixed(1);
          }
          return formatAxisValue(value);
        }
      }
    }
  };
};

function TimeSeriesBrushChart({
  idMain,
  idBrush,
  groupName,
  fileName,
  dateList,
  seriesMain,
  seriesBrush,
  yAxisTitle,
  loading
}) {
  const [type, setType] = useState('bar');
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const mainChartRef = useRef(null);
  const brushChartRef = useRef(null);

  useEffect(() => {}, [seriesMain, seriesBrush]);

  /**
   * Workaround due to issues with group not working on option change.
   * See: https://github.com/apexcharts/apexcharts.js/issues/623
   */
  useEffect(() => {
    if (mainChartRef.current) {
      mainChartRef.current.destroy();
    }
    if (brushChartRef.current) {
      brushChartRef.current.destroy();
    }
    if (!seriesBrush.length || !seriesMain.length || loading) {
      return;
    }

    const mainOptions = getMainChartDefOptions(
      seriesMain,
      user,
      dateList,
      theme,
      idMain,
      groupName,
      fileName,
      type,
      yAxisTitle
    );
    const secondaryOptions = getBrushChartOptions(
      seriesBrush,
      dateList,
      theme,
      idBrush,
      groupName,
      type,
      user,
      fileName
    );

    mainChartRef.current = new ApexCharts(document.querySelector('#main'), mainOptions);
    brushChartRef.current = new ApexCharts(document.querySelector('#brush'), secondaryOptions);

    mainChartRef.current.render();
    brushChartRef.current.render();

    return () => {
      if (mainChartRef.current) {
        mainChartRef.current.destroy();
      }
      if (brushChartRef.current) {
        brushChartRef.current.destroy();
      }
    };
  }, [dateList, seriesMain, seriesBrush, theme, type, loading]);

  return (
    <Grid container spacing={2}>
      {!loading && (
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
            <Typography variant={'body2'}>VIEW BY:</Typography>
            <ButtonGroup disableElevation>
              <Button
                onClick={() => setType('bar')}
                variant={type === 'bar' ? 'contained' : 'outlined'}
                id={'bar-chart-option'}>
                Bar Chart
              </Button>
              <Button
                onClick={() => setType('line')}
                variant={type === 'line' ? 'contained' : 'outlined'}
                id={'line-chart-option'}>
                Line Chart
              </Button>
            </ButtonGroup>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12}>
        {!loading && (
          <>
            <div id={'main'} />
            <div id={'brush'} />
          </>
        )}
        {loading && <ApexChart height={500} options={{}} type={'bar'} loading />}
      </Grid>
    </Grid>
  );
}

TimeSeriesBrushChart.propTypes = {
  idMain: PropTypes.string.isRequired,
  idBrush: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  dateList: PropTypes.arrayOf(PropTypes.string),
  seriesMain: PropTypes.arrayOf(PropTypes.object),
  seriesBrush: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  yAxisTitle: PropTypes.string
};

TimeSeriesBrushChart.defaultProps = {
  seriesMain: [],
  seriesBrush: [],
  dateList: [],
  loading: false,
  yAxisTitle: null
};

export default TimeSeriesBrushChart;
