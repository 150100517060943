import { Paper, Stack, Typography } from '@esgian/esgianui';
import PropTypes from 'prop-types';
import React from 'react';
import { getGeneralSlice } from '@store/features';
import { useSelector } from 'react-redux';

const vesselColors = [
  '#80DFEB',
  '#DD53FF',
  '#FFD465',
  '#0E9EFF',
  '#FFDAF9',
  '#4BAC3C',
  '#A47AFF',
  '#E3FFA7',
  '#F8A457',
  '#3E46FF'
];

const StatusIndicatorDot = ({ color }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
    <circle cx="4" cy="4" r="4" fill={color} />
  </svg>
);

StatusIndicatorDot.propTypes = {
  color: PropTypes.string
};

const VoyageGroupVesselsFloatingWindow = ({ vessels }) => {
  const { themeMode } = useSelector(getGeneralSlice);

  return (
    <Paper
      style={{
        zIndex: 1,
        width: '12.5rem',
        background: themeMode ? 'rgba(255, 255, 255, 0.80)' : 'rgba(0, 0, 0, 0.80)',
        padding: '0.5rem 0'
      }}>
      <Stack pl={2} pr={2} spacing={1}>
        {vessels.map((voyage, index) => {
          return (
            <Stack key={index} direction={'row'} justifyContent={'start'} sx={{ gap: '8px' }}>
              <Typography variant={'body2'} color="primary.contrastText">
                <StatusIndicatorDot color={vesselColors[index]} />
              </Typography>
              <Typography variant={'body2'} color="primary.contrastText">
                {voyage.name}
              </Typography>
            </Stack>
          );
        })}
      </Stack>
    </Paper>
  );
};

export default VoyageGroupVesselsFloatingWindow;

VoyageGroupVesselsFloatingWindow.propTypes = {
  vessels: PropTypes.array
};
