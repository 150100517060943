import React, { useEffect, useState } from 'react';
import { Breadcrumbs, Grid, Link, PageHeader, Typography } from '@esgian/esgianui';
import { useNavigate } from 'react-router-dom';
import { getOperatorStats } from '@api';
import OperatorsOverviewSection from '@components/Sections/ShipAnalytics/OperatorsOverviewSection';
import { SEGMENT } from '@constants';
import { useSegment } from '@hooks';
import { CommercialCategoryTypeSelect, OperatorTypeSelect } from '@components/Inputs';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';

function OperatorsOverviewPage() {
  const [loading, setLoading] = useState(false);
  const [operatorsData, setOperatorsData] = useState(null);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);
  const { segment, isRoRo } = useSegment();
  const navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const controller = new AbortController();
    const { signal } = controller;

    const getOperatorData = async () => {
      try {
        setLoading(true);
        let body = { SegmentTypeId: segment.id, minFleetSize: 1 };
        if (isRoRo) {
          body.OperatorTypeId = operatorType;
        } else {
          body.CommercialCategoryId = commercialCategoryType;
        }
        const responses = await getOperatorStats(body, signal);
        setOperatorsData(responses);
        setLoading(false);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    getOperatorData();
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [segment, operatorType, commercialCategoryType]);

  let uriExt = segment?.id === SEGMENT.RoRo.id ? 'roro' : 'mpp';
  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <PageHeader
          id={'lbl-operator-overview'}
          loading={loading}
          title={'Operators Overview'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                id="home-breadcrumb"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange(`/${uriExt}/commercial-analytics`)}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                id="ship-analysis-breadcrumb"
                onClick={() => routeChange(`/${uriExt}/commercial-analytics`)}>
                Commercial Analytics
              </Link>
              <Typography color="primary" id="operator-overview">
                Operators Overview
              </Typography>
            </Breadcrumbs>
          }
        />
      </Grid>

      <Grid item xs={12}>
        {isRoRo ? <OperatorTypeSelect /> : <CommercialCategoryTypeSelect />}
      </Grid>
      <Grid item xs={12}>
        <OperatorsOverviewSection loading={loading} operatorDetails={operatorsData} />
      </Grid>
    </Grid>
  );
}

OperatorsOverviewPage.propTypes = {};

OperatorsOverviewPage.defaultProps = {};

export default OperatorsOverviewPage;
