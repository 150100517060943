import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ApexChart, Divider, Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import { checkUserHasAccess, getDateList } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const getOptions = (theme, dateList, daysMax, portCallsMax, user, fileName) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, threeColors, twoColorsContrast }
    }
  } = theme;

  return {
    chart: {
      height: 350,
      type: 'mixed',
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      stacked: false,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        columnWidth: '50%'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [0, 3, 2],
      curve: 'straight',
      dashArray: [0, 0, 6]
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    colors: [twoColorsContrast[1], threeColors[1], twoColorsContrast[0]],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (value, { seriesIndex }) {
          if (seriesIndex === 1) {
            return value;
          }
          return value?.toFixed(2);
        }
      },
      x: {
        show: true
      }
    },
    markers: {
      size: [0, 3],
      strokeColors: '#ffffff',
      strokeWidth: 1,
      hover: {
        size: 5
      }
    },
    fill: {
      type: 'solid'
    },
    theme: {
      mode: mode
    },
    labels: dateList,
    yaxis: [
      {
        decimalsInFloat: 1,
        min: 0,
        tickAmount: 7,
        max: daysMax * 1.2,
        labels: { style: { colors: twoColorsContrast[1] } },
        title: {
          text: 'Days'
        }
      },
      {
        opposite: true,
        decimalsInFloat: 0,
        seriesName: 'Number of Port Calls',
        tickAmount: 7,
        min: 0,
        max: portCallsMax * 1.2,
        labels: { style: { colors: threeColors[1] } },
        title: {
          text: 'No. of port calls'
        }
      },
      {
        seriesName: 'Average Port Time Trendline [Days]',
        show: false,
        tickAmount: 7,
        min: 0,
        max: daysMax * 1.2,
        decimalsInFloat: 1
      }
    ],
    xaxis: {
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    }
  };
};

function PortTimeVsPortCallsChart({ portStats, loading, portName, fileName }) {
  const [series, setSeries] = useState([]);
  const [average, setAverage] = useState({ time: 0, number: 0 });
  const [options, setOptions] = useState(null);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (portStats) {
      const { monthlyPortCallDto, polyFit } = portStats;
      const { monthlyTotals } = monthlyPortCallDto;
      const startDate = moment(monthlyTotals[0]?.currentYear?.date);
      const endDate = moment(monthlyTotals[monthlyTotals.length - 1]?.currentYear?.date);

      let daysMax = 0;
      let portCallsMax = 0;
      let sumTime = 0;
      let sumNum = 0;
      const timeSeries = monthlyTotals.map(({ currentYear }) => {
        let val = currentYear.portTimeAverage || 0;
        if (Math.abs(val) > daysMax) {
          daysMax = Math.abs(val);
        }
        sumTime += parseFloat(currentYear.portTimeAverage || 0);
        return parseFloat(currentYear.portTimeAverage || 0);
      });

      const totalSeries = monthlyTotals.map(({ currentYear }) => {
        let val = currentYear.total || 0;
        if (Math.abs(val) > portCallsMax) {
          portCallsMax = Math.abs(val);
        }
        sumNum += parseFloat(currentYear.total || 0);
        return parseFloat(currentYear.total || 0);
      });
      setAverage({ time: sumTime / monthlyTotals?.length, number: sumNum / monthlyTotals?.length });

      const tempData = [
        {
          name: 'Monthly Average Port Time [Days]',
          type: 'column',
          data: timeSeries
        },
        {
          name: 'Number of Port Calls',
          type: 'line',
          data: totalSeries
        },
        { name: 'Average Port Time Trendline [Days]', data: polyFit || [], type: 'line' }
      ];

      setOptions(
        getOptions(theme, getDateList(startDate, endDate), daysMax, portCallsMax, user, fileName)
      );
      setSeries(tempData);
    }
  }, [portStats, theme]);
  const {
    palette: {
      charts: { twoColorsContrast, threeColors }
    }
  } = theme;
  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack spacing={2} divider={<Divider />}>
        <Stack>
          <Typography variant={'h6'}>Port Time vs Port Calls</Typography>
          <Typography variant={'body2'} color={'text.secondary'}>
            {portName}
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Grid container sx={{ textAlign: 'center' }}>
            <Grid item xs>
              <Typography sx={{ color: twoColorsContrast[1] }} variant={'body2'} bold>
                {average.time?.toFixed(1)} days
              </Typography>
              <Typography variant={'caption'} color={'text.secondary'}>
                average time
              </Typography>
            </Grid>
            <div>
              <Divider orientation={'vertical'} />
            </div>
            <Grid item xs>
              <Typography sx={{ color: threeColors[1] }} variant={'body2'} bold>
                {average.number?.toFixed(1)}
              </Typography>
              <Typography variant={'caption'} color={'text.secondary'}>
                average # port calls
              </Typography>
            </Grid>
          </Grid>

          <ApexChart
            loading={loading || !options}
            data={series}
            height={480}
            options={options || {}}
            type={'bar'}
          />
        </Stack>
      </Stack>
    </Paper>
  );
}

PortTimeVsPortCallsChart.propTypes = {
  loading: PropTypes.bool,
  portStats: PropTypes.object,
  portName: PropTypes.string,
  fileName: PropTypes.string.isRequired
};

PortTimeVsPortCallsChart.defaultProps = {
  loading: false,
  portStats: null,
  portName: null
};

export default PortTimeVsPortCallsChart;
