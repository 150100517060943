import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Stack,
  ArrowDropUpIcon,
  Typography,
  ArrowDropDownIcon,
  Grid,
  Paper
} from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { ProfileLink } from '@components/Links';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const getCustomTableStyle = () => {
  return {
    MUIDataTableToolbar: {
      styleOverrides: {
        root: {
          display: 'flex',
          padding: '0px',
          minHeight: 'auto'
        },
        actions: { width: '100%' }
      }
    },
    MUIDataTableFilterList: { display: 'none' },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          borderRight: '1px solid #e4e4e8'
        }
      }
    }
  };
};

function PortOperatorStatsTable({ loading, operatorStats, selectedYear, fileName }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const columns = useMemo(
    () => [
      {
        name: 'operatorShortName',
        label: 'Operator',
        options: {
          sort: true,
          filterType: 'textField',
          sortThirdClickReset: true,
          setCellHeaderProps: () => ({
            style: { minWidth: '200px', maxWidth: '200px', width: '200px' }
          }),
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink name={dataIndex} profile={'operator'} id={rowData[1]} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: { display: false, viewColumns: false, filter: false, download: false }
      },
      {
        name: 'prevYearYTDPortCalls',
        label: `YTD ${selectedYear - 1}`,
        options: {
          setCellHeaderProps: () => ({
            style: { minWidth: '250px', maxWidth: '250px', width: '250px' }
          }),
          filter: true,
          sort: true
        }
      },
      {
        name: 'ytdPortCalls',
        label: `YTD ${selectedYear}`,
        options: {
          setCellHeaderProps: () => ({
            style: { minWidth: '250px', maxWidth: '250px', width: '250px' }
          }),
          filter: true,
          sort: true
        }
      },
      {
        name: 'increase',
        label: 'Increase %',
        options: {
          setCellHeaderProps: () => ({
            style: { minWidth: '250px', maxWidth: '250px', width: '250px' }
          }),
          customBodyRender: (dataIndex) => {
            if (parseFloat(dataIndex)) {
              if (dataIndex >= 0) {
                return (
                  <Stack direction={'row'}>
                    <ArrowDropUpIcon color={'success'} />
                    <Typography variant={'caption'} color={'text.success'}>
                      {parseFloat(dataIndex?.toFixed(1))}%
                    </Typography>
                  </Stack>
                );
              } else {
                return (
                  <Stack direction={'row'}>
                    <ArrowDropDownIcon color={'error'} />
                    <Typography variant={'caption'} color={'text.error'}>
                      {parseFloat(dataIndex?.toFixed(1))}%
                    </Typography>
                  </Stack>
                );
              }
            }
            return '-';
          },
          filter: true,
          sort: true
        }
      },
      {
        name: 'prevYearYTDPortCallsShare',
        label: `YTD ${selectedYear - 1}`,
        options: {
          setCellHeaderProps: () => ({ style: { minWidth: '8vw', maxWidth: '8vw' } }),
          customBodyRender: (dataIndex) => {
            return parseFloat(dataIndex) ? `${parseFloat(dataIndex?.toFixed(1))}%` : '0%';
          },
          filter: true,
          sort: true
        }
      },
      {
        name: 'ytdPortCallsShare',
        label: `YTD ${selectedYear}`,
        options: {
          setCellHeaderProps: () => ({ style: { minWidth: '8vw', maxWidth: '8vw' } }),
          customBodyRender: (dataIndex) => {
            return parseFloat(dataIndex) ? `${parseFloat(dataIndex?.toFixed(1))}%` : '0%';
          },
          filter: true,
          sort: true
        }
      },
      {
        name: 'increaseShare',
        label: 'Increase %',
        options: {
          setCellHeaderProps: () => ({ style: { minWidth: '8vw', maxWidth: '8vw' } }),
          customBodyRender: (dataIndex) => {
            if (parseFloat(dataIndex)) {
              if (dataIndex >= 0) {
                return (
                  <Stack direction={'row'}>
                    <ArrowDropUpIcon color={'success'} />
                    <Typography variant={'caption'} color={'text.success'}>
                      {parseFloat(dataIndex?.toFixed(1))}%
                    </Typography>
                  </Stack>
                );
              } else {
                return (
                  <Stack direction={'row'}>
                    <ArrowDropDownIcon color={'error'} />
                    <Typography variant={'caption'} color={'text.error'}>
                      {parseFloat(dataIndex?.toFixed(1))}%
                    </Typography>
                  </Stack>
                );
              }
            }
            return '-';
          },
          filter: true,
          sort: true
        }
      }
    ],
    [selectedYear]
  );

  const options = useMemo(() => {
    return {
      selectableRows: 'none',
      responsive: 'standard',
      filter: false,
      search: false,
      print: false,
      tableBodyMaxHeight: '90vh',
      download: checkUserHasAccess(user, true),
      downloadOptions: {
        filename: fileName
      },
      customToolbar: () => {
        return (
          <Grid
            container
            sx={{ backgroundColor: () => (theme.mode === 'dark' ? '#171717' : '#fffff') }}>
            <Grid
              item
              sx={{
                padding: 2,
                borderBottom: '1px solid #e4e4e8',
                borderTop: '1px solid #e4e4e8',
                minWidth: '200px',
                maxWidth: '200px'
              }}
            />
            <Grid
              item
              sx={{
                padding: 2,
                borderLeft: '1px solid #e4e4e8',
                borderTop: '1px solid #e4e4e8',
                borderRight: '1px solid #e4e4e8',
                minWidth: '751px',
                maxWidth: '751px',
                borderBottom: '1px solid #e4e4e8'
              }}
              textAlign={'center'}>
              <Typography bold variant={'body2'}>
                No. of Port Calls
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                borderTop: '1px solid #e4e4e8',
                padding: 2,
                borderBottom: '1px solid #e4e4e8'
              }}
              xs
              textAlign={'center'}>
              <Typography bold variant={'body2'}>
                Share of Port Calls
              </Typography>
            </Grid>
          </Grid>
        );
      },
      viewColumns: false,
      pagination: false,
      elevation: 0,
      toolbar: true,
      sortOrder: {
        name: 'ytdPortCalls',
        direction: 'desc'
      }
    };
  }, [user, theme, fileName]);

  return (
    <Paper>
      <Table
        customStyle={getCustomTableStyle()}
        loading={loading}
        mode={theme.mode}
        title={''}
        data={operatorStats?.length ? operatorStats : []}
        columns={columns}
        options={options}
      />
    </Paper>
  );
}

PortOperatorStatsTable.propTypes = {
  loading: PropTypes.bool,
  operatorStats: PropTypes.arrayOf(PropTypes.object),
  selectedYear: PropTypes.number,
  fileName: PropTypes.string.isRequired
};

PortOperatorStatsTable.defaultProps = {
  loading: false,
  operatorStats: [],
  selectedYear: moment().year()
};

export default PortOperatorStatsTable;
