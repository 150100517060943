import React, { useEffect, useMemo, useState } from 'react';
import {
  ArrowBackIcon,
  ColorBox,
  Grid,
  Typography,
  Paper,
  Stack,
  Box,
  Skeleton
} from '@esgian/esgianui';
import { useNavigate, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import VoyageGroupFilter from '../../../components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupFilter';
import { getVoyageDetails } from '../../../api/Voyage';
import { useVoyageGroups } from '../hooks/useVoyageGroups';
import SavedVoyageCard from '../../../components/Sections/VoyageAnalytics/VoyageGroupSection/SavedVoyageCard';
import VoyageGroupRouteMap from '../../../components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupRouteMap';
import VoyageGroupVesselsFloatingWindow from '../../../components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupVesselsFloatingWindow';
import { useSegment } from '@hooks';
import { scrollbarStyling } from '../../../utils/constants';

export const generateVoyageIdFromVoyageOverview = (voyageOverview, voyageTransitDetails) =>
  `${voyageOverview.imo}-${voyageTransitDetails.fromPortId}-${voyageTransitDetails.toPortId}-${voyageOverview.departureTime}-${voyageOverview.arrivalTime}`;

export const generateVoyageIdFromSavedVoyage = (voyage) =>
  `${voyage.imo}-${voyage.fromPortId}-${voyage.toPortId}-${voyage.departureDate}-${voyage.arrivalDate}`;

function VoyageGroupPage() {
  const params = useParams();

  const voyageGroupId = params.voyageGroupId;

  const { isLoadingVoyageGroups, savedVoyageGroups } = useVoyageGroups();
  const { uriExt } = useSegment();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [hoveredVoyageId, setHoveredVoyageId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [savedVoyages, setSavedVoyages] = useState([]);
  const [selectedVesselsIdList, setSelectedVesselsIdList] = useState([]);

  const isFullScreen = searchParams.get('view') === 'map';
  const routeChange = (path) => {
    navigate(path);
  };

  const [savedVoyageGroup, setSavedVoyageGroup] = useState();

  const reset = () => {
    // setSavedVoyageGroup(undefined);
    // setSavedVoyages([]);
    // setSelectedVesselsIdList([]);
    // refetch();
    window.location.reload();
  };

  useEffect(() => {
    const targetVoyageGroup = savedVoyageGroups.find(
      (group) => group.id.toString() === voyageGroupId.toString()
    );
    if (!targetVoyageGroup) return;
    setSavedVoyageGroup(targetVoyageGroup);
  }, [savedVoyageGroups]);

  useEffect(() => {
    if (!savedVoyageGroup || savedVoyageGroup?.voyages?.length === 0) return;

    if (!savedVoyageGroup || savedVoyageGroup?.voyages?.length === 0) return;

    setIsLoading(true);
    const controller = new AbortController();
    const { signal } = controller;
    const getSavedVoyagesDetails = async () => {
      const promisesForVoyageDetails = savedVoyageGroup.voyages.map((v) => {
        const filterParams = {
          ...v,
          fromPortId: parseInt(v.fromPortId),
          toPortId: parseInt(v.toPortId),
          startDate: v.departureDate,
          endDate: v.arrivalDate
        };
        return getVoyageDetails(filterParams, signal);
      });
      const responses = await Promise.all(promisesForVoyageDetails);

      const savedVoyagesDetails = responses.map((r) => r[0]);

      setSavedVoyages(savedVoyagesDetails);
      setIsLoading(false);

      // select all vessels on first load
      setSelectedVesselsIdList(
        savedVoyagesDetails.map((v) =>
          generateVoyageIdFromVoyageOverview(v.voyageOverview, v.voyageTransitDetails)
        )
      );
      setSelectedVesselsIdList(
        savedVoyagesDetails.map((v) =>
          generateVoyageIdFromVoyageOverview(v.voyageOverview, v.voyageTransitDetails)
        )
      );
    };

    getSavedVoyagesDetails();
  }, [savedVoyageGroup]);

  const routes = useMemo(
    () =>
      savedVoyages.map((v) => ({
        routes: v.voyageTransitDetails.transitDetails,
        id: generateVoyageIdFromVoyageOverview(v.voyageOverview, v.voyageTransitDetails),
        ports: v.voyagePortCalls.portCalls
      })),
    [savedVoyages]
  );
  const savedVessels = useMemo(
    () =>
      savedVoyages.map((v) => ({
        name: v.voyageOverview.vesselName,
        imo: generateVoyageIdFromVoyageOverview(v.voyageOverview, v.voyageTransitDetails)
      })),
    [savedVoyages]
  );

  if (isFullScreen) {
    return (
      <Grid container sx={{ position: 'relative' }} spacing={1}>
        <VoyageGroupFilter
          multiSelectOptions={savedVessels}
          selectedVesselsId={selectedVesselsIdList}
          onSelectVessels={setSelectedVesselsIdList}
        />
        <Stack position="absolute" left="1rem" top="7rem" spacing={1}>
          <VoyageGroupVesselsFloatingWindow vessels={savedVessels} />
        </Stack>
        <VoyageGroupRouteMap routes={routes} selectedVesselsIdList={selectedVesselsIdList} />
      </Grid>
    );
  }

  const getVoyageLink = (voyageData) => {
    return `${uriExt}/completed-voyages/${voyageData.voyageOverview.imo}?departureDate=${voyageData.voyageOverview.departureTime}&arrivalDate=${voyageData.voyageOverview.arrivalTime}&fromPortId=${voyageData.voyageTransitDetails.fromPortId}&toPortId=${voyageData.voyageTransitDetails.toPortId}`;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center">
          <ColorBox onClick={() => routeChange(-1)}>
            <ArrowBackIcon fontSize={'small'} />
          </ColorBox>
          <Stack direction="column" sx={{ marginLeft: 1 }}>
            <Typography variant={'h6'}>{savedVoyageGroup?.name ?? ''}</Typography>
            <Typography variant={'body2'}>
              Completed Voyages: {savedVoyageGroup?.voyages?.length ?? 0}
            </Typography>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={6} sx={{ height: '100%' }}>
        <Stack direction="column" height="90vh" overflow="scroll">
          <Paper sx={{ p: 2 }}>
            <Typography variant={'h6'}>Voyage List</Typography>
          </Paper>
          {(isLoading || isLoadingVoyageGroups) && (
            <Stack gap={2} pt={2}>
              {Array.from({ length: 4 }).map((item, index) => (
                <Box key={index}>
                  <Skeleton variant={'rectangular'} height={210} />
                </Box>
              ))}
            </Stack>
          )}

          <Box overflow="auto" sx={scrollbarStyling}>
            {savedVoyages.map((voyageData) => (
              <Box
                key={voyageData.voyageOverview.imo}
                onMouseEnter={() => {
                  setHoveredVoyageId(
                    generateVoyageIdFromVoyageOverview(
                      voyageData.voyageOverview,
                      voyageData.voyageTransitDetails
                    )
                  );
                }}
                onMouseLeave={() => {
                  setHoveredVoyageId(undefined);
                }}
                sx={{ cursor: 'pointer' }}>
                <SavedVoyageCard
                  groupId={voyageGroupId}
                  voyageId={generateVoyageIdFromVoyageOverview(
                    voyageData.voyageOverview,
                    voyageData.voyageTransitDetails
                  )}
                  voyageLink={getVoyageLink(voyageData)}
                  vesselName={voyageData.voyageOverview.vesselName}
                  route={`${voyageData.voyageOverview.departurePort} - ${voyageData.voyageOverview.arrivalPort}`}
                  startDate={moment(voyageData.voyageOverview.departureTime).format('YYYY-MM-DD')}
                  endDate={moment(voyageData.voyageOverview.arrivalTime).format('YYYY-MM-DD')}
                  fuel="5000"
                  transitTime={voyageData.voyageTransitDetails.transitDays}
                  distance={voyageData.voyageTransitDetails.totalDistance}
                  operator="WW Co2e Emission"
                  emissionIntensity="xx"
                  isHovered={
                    hoveredVoyageId ===
                    generateVoyageIdFromVoyageOverview(
                      voyageData.voyageOverview,
                      voyageData.voyageTransitDetails
                    )
                  }
                  refetch={reset}
                />
              </Box>
            ))}
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={6} sx={{ height: '100%' }}>
        <Stack direction="column" spacing={1}>
          {isLoading || isLoadingVoyageGroups ? (
            <Box>
              <Skeleton variant={'rectangular'} height={'90vh'} />
            </Box>
          ) : (
            <VoyageGroupRouteMap
              routes={routes}
              selectedVesselsIdList={selectedVesselsIdList}
              hoveredVoyageId={hoveredVoyageId}
            />
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

VoyageGroupPage.defaultProps = {};

export default VoyageGroupPage;
