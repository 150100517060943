import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AdvancedFilterDrawer,
  Divider,
  FilterSlider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@esgian/esgianui';
import { OperatorMultiSelect } from '@components/Inputs';
import {
  OPERATOR_MAX_NUM_SERVICES,
  OPERATOR_SELECT_TYPES,
  VESSEL_MAX_TRANSIT_TIME
} from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getServiceLevelFilters,
  resetServiceLevelAdvFilters
} from '@store/features/filters/CommercialAnalyticsPage/ServiceLevelSlice/ServiceLevelSlice';
import { getLookupOperators } from '@store/features';

function AdvancedFilters({ advancedOpen, setAdvancedOpen, updateFilterValue }) {
  const [filters, setFilters] = useState({});
  const serviceLevelFilters = useSelector(getServiceLevelFilters);
  const lookupOperators = useSelector(getLookupOperators);
  const dispatch = useDispatch();

  const { selectedOperators, selectType, maxTransitTime, minServices } = filters || {};

  const handleClearClick = () => {
    dispatch(resetServiceLevelAdvFilters());
    setAdvancedOpen(false);
  };

  const handleApplyClick = () => {
    updateFilterValue(filters);
    setAdvancedOpen(false);
  };

  const updateTempFilters = (payload) => {
    setFilters({ ...filters, ...payload });
  };

  useEffect(() => {
    setFilters({ ...serviceLevelFilters });
  }, [serviceLevelFilters]);

  return (
    <AdvancedFilterDrawer
      anchor={'right'}
      open={advancedOpen}
      handleReset={handleClearClick}
      onClose={() => setAdvancedOpen(false)}
      handleApply={handleApplyClick}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={'section-filter'}>
          <Stack spacing={2}>
            <Stack>
              <Typography variant={'subtitle1'} gutterBottom color={'text.primary'}>
                Display Operators
              </Typography>
              <RadioGroup
                value={selectType}
                onChange={({ target }) => {
                  updateTempFilters({ selectType: target.value });
                }}>
                <FormControlLabel
                  value={OPERATOR_SELECT_TYPES.AUTO}
                  label={<Typography variant={'subtitle2'}>Top 5</Typography>}
                  control={<Radio />}
                />
                <FormControlLabel
                  value={OPERATOR_SELECT_TYPES.DYNAMIC}
                  label={<Typography variant={'subtitle2'}>All dynamic operators</Typography>}
                  control={<Radio />}
                />
                <FormControlLabel
                  value={OPERATOR_SELECT_TYPES.MANUAL}
                  label={<Typography variant={'subtitle2'}>Manually select operators</Typography>}
                  control={<Radio />}
                />
              </RadioGroup>
              {selectType === OPERATOR_SELECT_TYPES.MANUAL && (
                <OperatorMultiSelect
                  placeholder={'Select Operators...'}
                  selectedOperators={selectedOperators}
                  operatorsList={lookupOperators || []}
                  handleChange={(_, values) => updateTempFilters({ selectedOperators: values })}
                />
              )}
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} className={'section-filter'}>
          <FilterSlider
            step={10}
            value={maxTransitTime}
            marks={[
              { value: 0, label: 0 },
              { value: VESSEL_MAX_TRANSIT_TIME, label: VESSEL_MAX_TRANSIT_TIME }
            ]}
            max={VESSEL_MAX_TRANSIT_TIME}
            min={0}
            title={'Max transit time (Days)'}
            onChange={(val) => updateTempFilters({ maxTransitTime: val })}
          />
        </Grid>
        <Grid item xs={12} className={'section-filter'}>
          <FilterSlider
            marks={[
              { value: 0, label: 0 },
              { value: OPERATOR_MAX_NUM_SERVICES, label: OPERATOR_MAX_NUM_SERVICES }
            ]}
            value={minServices}
            max={OPERATOR_MAX_NUM_SERVICES}
            min={0}
            title={'Min No. of total services for the period'}
            onChange={(val) => updateTempFilters({ minServices: val })}
          />
        </Grid>
      </Grid>
    </AdvancedFilterDrawer>
  );
}

AdvancedFilters.propTypes = {
  advancedOpen: PropTypes.bool,
  setAdvancedOpen: PropTypes.func.isRequired,
  updateFilterValue: PropTypes.func.isRequired
};

AdvancedFilters.defaultProps = {};

export default AdvancedFilters;
