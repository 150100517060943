import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Grid, Typography } from '@esgian/esgianui';
import moment from 'moment';
import { ProfileLink } from '@components/Links';
import { SEGMENT } from '@constants';
import { useTheme } from '@hooks/useTheme';
import { useSelector } from 'react-redux';
import { getSegment } from '@store/features';

function VesselDetails({ selectedVessel, routeChange, mapHeight }) {
  const segment = useSelector(getSegment);

  const { customScrollbar } = useTheme();
  const {
    operator_id,
    operatorId,
    operator_short_name,
    operatorShortName,
    heading,
    imo,
    mmsi,
    speed,
    timestamp,
    eta,
    destination
  } = selectedVessel || {};
  let uriExt = useMemo(() => (segment?.id === SEGMENT.RoRo.id ? 'roro' : 'mpp'), [segment]);
  return (
    <Grid
      container
      sx={{
        ...customScrollbar,
        width: '100%',
        bgcolor: 'background.paper',
        position: 'relative',
        overflow: 'auto',
        p: 2,
        maxHeight: `calc(${mapHeight} - 60px - 32px)`
      }}>
      <Grid item container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <Typography variant={'body2'} bold>
              Operator:
            </Typography>
          </Grid>
          <Grid item>
            <ProfileLink
              linkVariant={'body2'}
              profile={'operator'}
              name={operator_short_name || operatorShortName}
              id={operator_id || operatorId}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <Typography variant={'body2'} bold>
              IMO:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>{imo}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <Typography variant={'body2'} bold>
              MMSI:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>{mmsi}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <Typography variant={'body2'} bold>
              Heading:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>{heading}&deg;</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <Typography variant={'body2'} bold>
              Speed:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>{speed} Knots</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <Typography variant={'body2'} bold>
              Position Received:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>
              {moment.utc(timestamp)?.format('MMMM Do YYYY, HH:mm:ss a')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <Typography variant={'body2'} bold>
              ETA:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>
              {moment.utc(eta)?.format('MMMM Do YYYY, HH:mm:ss a')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <Typography variant={'body2'} bold>
              Destination:
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>{destination}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      {selectedVessel && (
        <Grid item xs={12} sx={{ pt: 2 }}>
          <Button
            size={'large'}
            fullWidth
            variant={'contained'}
            onClick={() => routeChange(`/${uriExt}/commercial-analytics/vessel/${imo}`)}>
            Vessel Profile
          </Button>
        </Grid>
      )}
    </Grid>
  );
}

VesselDetails.propTypes = {
  selectedVessel: PropTypes.object,
  routeChange: PropTypes.func.isRequired,
  mapHeight: PropTypes.string.isRequired
};

VesselDetails.defaultProps = {
  selectedVessel: null
};

export default VesselDetails;
