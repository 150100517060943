import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Paper, Typography, Button, Box, ArrowForwardIcon, Link } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { tableTitlePaddingLeft } from '@helpers';
import { getFilterInputValidationOption, getFilterInputValidationList } from '../helpers';
import { useSegment, useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import moment from 'moment/moment';
import { DATE_TIME_FORMAT } from '@constants';
import { useNavigate } from 'react-router-dom';
import AddToVoyageGroupPopup from '../../Sections/VoyageAnalytics/VoyageGroupSection/AddToVoyageGroupPopup';
import { useVoyageGroups } from '../../../pages/VoyageAnalytics/hooks/useVoyageGroups';
import { updateVoyageGroup } from '../../../api/Voyage';
import { toast } from 'react-toastify';
import CreateVoyagePopup from '../../Sections/VoyageAnalytics/VoyageGroupSection/CreateVoyagePopup';
import { usePopupState, bindPopover, bindHover } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
function VoyagesTable({ title, loading, hideColumns, fileName, voyageSearchResults }) {
  const { theme } = useTheme();
  const [showCreateVoyageGroupPopup, setShowCreateVoyageGroupPopup] = useState(false);
  const [newVoyageGroupName, setNewVoyageGroupName] = useState('');
  const user = useSelector(getUser);
  const navigate = useNavigate();
  const { uriExt } = useSegment();
  const [selectedVessels, setSelectedVessels] = useState([]);
  const email = user?.email;
  const [isSavingVoyagesToGroup, setIsSavingVoyagesToGroup] = useState(false);
  const { savedVoyageGroups, isLoadingVoyageGroups, createNewGroup, isGroupBeingCreated, refetch } =
    useVoyageGroups();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover'
  });

  const onAddToVoyageGroup = async (groupId) => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const updatedVoyages = [...savedVoyageGroups];

    const targetGroup = updatedVoyages.find((group) => group.id === groupId);

    if (!targetGroup) {
      toast.error('Requested group not found.');
      return;
    }

    const voyagesOnTargetGroup = targetGroup.voyages;

    const duplicateVoyages = [];

    const voyagesAfterEliminatingDuplicates = selectedVessels.filter((voyage) => {
      return !voyagesOnTargetGroup.some((voyageOnTargetGroup) => {
        const isMatch =
          voyage.imo === voyageOnTargetGroup.imo &&
          voyage.fromPortId === voyageOnTargetGroup.fromPortId &&
          voyage.toPortId === voyageOnTargetGroup.toPortId &&
          voyage.departureDate === voyageOnTargetGroup.departureDate &&
          voyage.arrivalDate === voyageOnTargetGroup.arrivalDate;
        if (isMatch) {
          duplicateVoyages.push(voyageOnTargetGroup.imo);
        }
        return isMatch;
      });
    });

    const voyagesToSave = [...voyagesOnTargetGroup, ...voyagesAfterEliminatingDuplicates];

    if (duplicateVoyages.length === selectedVessels.length) {
      toast.info(`Voyage${duplicateVoyages.length > 1 ? 's' : ''} already exists in the group.`);
      return;
    }

    if (voyagesToSave.length > 10) {
      toast.error(
        duplicateVoyages.length > 0
          ? `Voyage${duplicateVoyages.length > 1 ? 's' : ''} ${duplicateVoyages.join(
              ', '
            )} already exist in the group. Including ${
              duplicateVoyages.length > 1 ? 'them' : 'it'
            }, a group can have a maximum of 10 voyages.`
          : 'A Group can have maximum 10 voyages.'
      );
      return;
    }

    updatedVoyages.forEach((group) => {
      if (group.id === groupId) {
        return group.voyages.push(...voyagesAfterEliminatingDuplicates);
      }
      return group;
    });

    try {
      setIsSavingVoyagesToGroup(true);
      await updateVoyageGroup(email, updatedVoyages, signal);
      toast.success(
        `Voyage${voyagesToSave.length > 1 ? 's' : ''} saved to group successfully.${
          duplicateVoyages.length > 0
            ? `Voyage${duplicateVoyages.length > 1 ? 's' : ''} ${duplicateVoyages.join(', ')} ${
                duplicateVoyages.length > 1 ? 'have' : 'has'
              } been excluded since ${
                duplicateVoyages.length > 1 ? 'they' : 'it'
              } already exist in the group.`
            : ''
        }`
      );
    } catch (err) {
      toast.error('Unable to save  voyages to group the group.');
      console.log('error', err);
    }
    setIsSavingVoyagesToGroup(false);
  };

  const columns = useMemo(() => {
    return [
      {
        name: 'vesselName',
        label: 'Vessel Name',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'multiselect',
          setCellProps: () => {
            return {
              style: {
                boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'vessel'} id={rowData[1]} name={dataIndex} />
            ) : (
              '-'
            );
          }
        }
      },
      {
        name: 'imo',
        label: 'IMO',
        options: {
          display: !hideColumns.includes('imo'),
          download: !hideColumns.includes('imo'),
          filter: !hideColumns.includes('imo'),
          filterType: 'custom',
          sort: true,
          sortThirdClickReset: true,
          customFilterListOptions: getFilterInputValidationList(),
          filterOptions: getFilterInputValidationOption('IMO Number')
        }
      },

      {
        name: 'operatorShortName',
        label: 'Operator name',
        options: {
          sort: true,
          filterType: 'textField',
          sortThirdClickReset: true,
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'operator'} id={rowData[3]} name={dataIndex} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: {
          display: false,
          sort: false,
          filter: false,
          download: false
        }
      },
      {
        name: 'fromPortName',
        label: 'Departure Port',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'port'} id={rowData[8]} name={dataIndex} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'toPortId',
        options: {
          display: false,
          sort: false,
          filter: false,
          download: false
        }
      },
      {
        name: 'startDate',
        label: 'Departure Time',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          customBodyRender: (dataIndex) => {
            if (moment(dataIndex).isValid()) {
              return moment(dataIndex).format(DATE_TIME_FORMAT);
            }
            return '-';
          }
        }
      },
      {
        name: 'toPortName',
        label: 'Arrival Port',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null ? (
              <ProfileLink profile={'port'} id={rowData[5]} name={dataIndex} />
            ) : (
              '--'
            );
          }
        }
      },
      {
        name: 'fromPortId',
        options: {
          display: false,
          sort: false,
          filter: false,
          download: false
        }
      },
      {
        name: 'endDate',
        label: 'Arrival Time',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          customBodyRender: (dataIndex) => {
            if (moment(dataIndex).isValid()) {
              return moment(dataIndex).format(DATE_TIME_FORMAT);
            }
            return '-';
          }
        }
      },
      {
        name: 'transitTime',
        label: 'Transit Time',
        options: {
          sort: true,
          sortThirdClickReset: true,
          filterType: 'textField',
          customBodyRender: (value) => {
            const formattedValue = parseFloat(value).toFixed(2);
            return `${formattedValue} days`;
          }
        }
      },
      {
        name: 'voyageProfile',
        options: {
          display: false,
          sort: false,
          filter: false,
          download: false
        }
      },
      {
        name: 'voyageProfileId',
        label: 'Voyage Profile',
        options: {
          sort: false,
          filter: false,
          filterType: 'textField',
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            let imo = rowData[1];
            let params = {
              departureDate: rowData[6],
              arrivalDate: rowData[9],
              fromPortId: rowData[8],
              toPortId: rowData[5]
            };
            let paramsEncoded = '';
            Object.keys(params).forEach((key) => {
              if (paramsEncoded !== '') {
                paramsEncoded += '&';
              }
              paramsEncoded += key + '=' + encodeURIComponent(params[key]);
            });

            return (
              <Link
                underline="hover"
                variant={'body2'}
                href={`${uriExt}/completed-voyages/${imo}?${paramsEncoded}`}
                onClick={(e) => {
                  navigate(`${uriExt}/completed-voyages/${imo}?${paramsEncoded}`);
                  e.preventDefault();
                }}
                sx={{ textAlign: 'left', cursor: 'pointer' }}>
                View
                <ArrowForwardIcon fontSize="small" sx={{ ml: 1 }} />
              </Link>
            );
          }
        }
      }
    ];
  }, [hideColumns]);

  const options = useMemo(() => {
    return {
      onRowSelectionChange: (_currentRowsSelected, _allRowsSelected, rowsSelected) => {
        setSelectedVessels(
          rowsSelected.map((dataIndex) => {
            const voyage = voyageSearchResults[dataIndex];
            return {
              imo: voyage.imo.toString(),
              fromPortId: voyage.fromPortId,
              toPortId: voyage.toPortId,
              departureDate: voyage.startDate,
              arrivalDate: voyage.endDate
            };
          })
        );
      },
      customToolbarSelect: () => {},
      responsive: 'standard',
      filter: false,
      search: false,
      download: false,
      setTableProps: () => {
        return {
          id: 'vessel-table'
        };
      },
      elevation: 0,
      print: false,
      viewColumns: false,
      pagination: true,
      toolbar: false,
      tableBodyMaxHeight: '80vh',
      rowsPerPageOptions: [10, 25, 50, 100],
      sortOrder: {
        name: 'vesselName',
        direction: 'asc'
      },
      textLabels: {
        body: {
          noMatch: 'Sorry, no voyages found'
        }
      },
      selectToolbarPlacement: 'none'
    };
  }, [user, fileName, voyageSearchResults]);

  useEffect(() => {
    popupState.isOpen && refetch();
  }, [popupState.isOpen]);

  return (
    <Paper>
      <Box display="flex" justifyContent="space-between" p={2}>
        <Typography variant="h6">{title}</Typography>
        {selectedVessels.length > 0 && (
          <Box>
            <Button
              variant="outlined"
              disabled={isSavingVoyagesToGroup}
              {...bindHover(popupState)}
              size="small">
              + add to my voyage group
            </Button>
            <HoverPopover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <AddToVoyageGroupPopup
                savedGroups={savedVoyageGroups}
                isSavingVoyageToGroup={isSavingVoyagesToGroup}
                isLoadingVoyageGroups={isLoadingVoyageGroups}
                onAddVoyageToGroup={onAddToVoyageGroup}
                onCreateANewGroup={() => {
                  setShowCreateVoyageGroupPopup(true);
                }}
              />
            </HoverPopover>
          </Box>
        )}
      </Box>
      <Table
        customStyle={tableTitlePaddingLeft}
        loading={loading}
        mode={theme.mode}
        data={voyageSearchResults?.length ? voyageSearchResults : []}
        columns={columns}
        options={options}
      />
      <CreateVoyagePopup
        open={showCreateVoyageGroupPopup}
        setModalOpen={setShowCreateVoyageGroupPopup}
        value={newVoyageGroupName}
        onChange={setNewVoyageGroupName}
        existingGroups={savedVoyageGroups}
        isSubmitDisabled={isGroupBeingCreated}
        onSubmit={() => {
          createNewGroup(newVoyageGroupName).then(() => {
            setShowCreateVoyageGroupPopup(false);
            setNewVoyageGroupName('');
          });
        }}
      />
    </Paper>
  );
}

VoyagesTable.propTypes = {
  title: PropTypes.string,
  vessels: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.string.isRequired,
  voyageSearchResults: PropTypes.arrayOf(PropTypes.object)
};

VoyagesTable.defaultProps = {
  title: '',
  vessel: [],
  loading: true,
  hideColumns: [],
  voyageSearchResults: []
};

export default VoyagesTable;
