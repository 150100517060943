import { createSlice } from '@reduxjs/toolkit';

export const VOYAGE_PROFILE_PARAMS = {
  FROM_PORT_ID: 'fromPortId',
  TO_PORT_ID: 'toPortId',
  DEPARTURE_DATE: 'departureDate',
  ARRIVAL_DATE: 'arrivalDate'
};

export const mapFilterUriKeys = ['shortestPath', 'port', 'speed', 'draught', 'voyageInfo'];

const initialState = {
  mapOptions: {
    shortestPath: false,
    fullscreen: false,
    port: false,
    speed: false,
    draught: false,
    voyageInfo: true
  },
  section: 1
};

const VoyageProfileSlice = createSlice({
  name: 'VoyageProfile',
  initialState: initialState,
  reducers: {
    updateVoyageProfileFilters: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        if (key === 'mapOptions') {
          state.mapOptions = { ...state.mapOptions, ...payload.mapOptions };
        } else {
          state[key] = payload[key];
        }
      });
    }
  }
});

export const getVoyageProfileFilters = (state) => {
  return state.filters.voyageAnalytics.voyageProfile;
};

export const { updateVoyageProfileFilters } = VoyageProfileSlice.actions;

export default VoyageProfileSlice.reducer;
