import React from 'react';
import PropTypes from 'prop-types';
import {
  CountryFlag,
  Divider,
  Grid,
  Link,
  Paper,
  Skeleton,
  Stack,
  Typography
} from '@esgian/esgianui';
import { VesselsMap } from '@components/Maps';
import { VesselsTable } from '@components/Tables';
import { OperatorFleetAgeChart } from './index';
import { MAP_STYLE_DARK_MODE, MAP_STYLE_LIGHT_MODE } from '@constants';
import { useSegment, useTheme } from '@hooks';

function OperatorOverviewSection({ loading, operatorData, vesselsData, fleetAgeData }) {
  const { isRoRo } = useSegment();
  const { theme } = useTheme();

  const ensureHttpsAndWww = (website) => {
    let site = website;
    if (!website.startsWith('www.') && !website.startsWith('https://')) {
      site = `https://www.${site}`;
    } else if (website.startsWith('www.')) {
      site = `https://${site}`;
    }
    return site;
  };

  const {
    country,
    address,
    website,
    operatorLongName,
    operatorShortName,
    operatorVessels,
    isoAlpha2
  } = operatorData || {};

  let hideColumns = isRoRo
    ? ['operatorShortName', 'maxLiftingCapacity', 'numCranes']
    : ['operatorShortName', 'loadCapacity', 'callSign', 'ownerLongName', 'class', 'grossTonnage'];

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Paper sx={{ p: 2, height: '100%' }}>
              <Grid container spacing={2}>
                <Grid item container spacing={2} xs={12}>
                  <Grid item alignSelf={'center'}>
                    <Typography variant={'h6'}>Overview</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {!loading.overview ? (
                  <Grid item container spacing={2}>
                    <Grid item xs={2}>
                      <Typography variant={'body2'} bold>
                        Name:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>{`${operatorLongName}`}</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={8}>
                    <Skeleton />
                  </Grid>
                )}
                {!loading.overview ? (
                  <Grid item container spacing={2}>
                    <Grid item xs={2}>
                      <Typography variant={'body2'} bold>
                        Short Name:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>{`${operatorShortName}`}</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={8}>
                    <Skeleton />
                  </Grid>
                )}
                {!loading.overview ? (
                  <Grid item container spacing={2}>
                    <Grid item xs={2}>
                      <Typography variant={'body2'} bold>
                        Country:{' '}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Stack direction={'row'} spacing={1} alignItems={'center'}>
                        <CountryFlag size={15} countryCode={isoAlpha2} />
                        <Typography variant={'body2'}>{country}</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={8}>
                    <Skeleton />
                  </Grid>
                )}
                {!loading.overview ? (
                  <Grid item container spacing={2}>
                    <Grid item xs={2}>
                      <Typography variant={'body2'} bold>
                        Address:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant={'body2'}>{address}</Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={8}>
                    <Skeleton />
                  </Grid>
                )}
                {!loading.overview ? (
                  <Grid item container spacing={2}>
                    <Grid item xs={2}>
                      <Typography variant={'body2'} bold>
                        Website:{' '}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {website ? (
                        <Link
                          variant={'body2'}
                          target={'__blank'}
                          href={ensureHttpsAndWww(website)}>
                          {website}
                        </Link>
                      ) : (
                        <Typography variant={'body2'}>{website}</Typography>
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={8}>
                    <Skeleton />
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            {loading.vesselData ? (
              <Skeleton variant={'rectangular'} height={'40vh'} />
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  <VesselsMap
                    showSpeedLegend={true}
                    height={'50vh'}
                    zoom={0}
                    projection={'mercator'}
                    mapStyle={theme.mode === 'dark' ? MAP_STYLE_DARK_MODE : MAP_STYLE_LIGHT_MODE}
                    vessels={vesselsData}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {isRoRo && (
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Stack spacing={2}>
              <Stack>
                <Typography variant={'h6'}>Fleet Development and Age Profile</Typography>
                <Typography variant={'body2'} color={'text.secondary'}>
                  Historical
                </Typography>
              </Stack>
              <Divider />
              <OperatorFleetAgeChart
                fleetAgeData={fleetAgeData}
                loading={loading?.vesselAge}
                fileName={`${operatorShortName}-fleet-age`}
              />
            </Stack>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <VesselsTable
          loadCapacityUnit={isRoRo ? 'CEU' : 'Deadweight'}
          fileName={`${operatorShortName}-vessel-list`}
          title={'Operator Vessels'}
          loading={loading.vesselData}
          hideColumns={hideColumns}
          vessels={operatorVessels}
        />
      </Grid>
    </>
  );
}

OperatorOverviewSection.propTypes = {
  loading: PropTypes.object.isRequired,
  operatorData: PropTypes.object,
  vesselsData: PropTypes.arrayOf(PropTypes.object),
  fleetAgeData: PropTypes.arrayOf(PropTypes.object)
};

OperatorOverviewSection.defaultProps = {
  operatorData: null,
  vesselsData: [],
  fleetAgeData: []
};

export default OperatorOverviewSection;
