import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const buildColorPalette = (grades, gradeColors) => {
  let colors = [];
  for (let grade of grades) {
    switch (grade) {
      case 'A':
        colors.push(gradeColors[0]);
        break;
      case 'B':
        colors.push(gradeColors[1]);
        break;
      case 'C':
        colors.push(gradeColors[2]);
        break;
      case 'D':
        colors.push(gradeColors[3]);
        break;
      case 'E':
        colors.push(gradeColors[4]);
        break;
    }
  }
  return colors;
};

const getOptions = (categories, theme, user, ratings, averageCII) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, fiveColorsIndication, twoColorsContrast }
    }
  } = theme;
  const colors = buildColorPalette(ratings, fiveColorsIndication);

  return {
    annotations: {
      position: 'front',
      yaxis: [
        {
          y: averageCII,
          yAxisIndex: 0,
          opacity: 1,
          strokeDashArray: 5,
          borderColor: twoColorsContrast[1],
          label: {
            position: 'right',
            textAnchor: 'end',
            borderColor: twoColorsContrast[1],
            style: {
              background: twoColorsContrast[1]
            },
            text: `Average CII (${averageCII?.toFixed(1)})`
          }
        }
      ]
    },
    chart: {
      type: 'bar',
      height: 350,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: 'vessels-cii-rating'
          },
          svg: {
            filename: 'vessels-cii-rating'
          },
          png: {
            filename: 'vessels-cii-rating'
          }
        }
      }
    },
    colors: colors,
    theme: {
      mode: mode
    },
    xaxis: {
      categories: categories
    },

    yaxis: {
      title: {
        text: 'CII'
      },
      decimalsInFloat: 0
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        distributed: true, // this line is mandatory
        borderRadius: 4,
        dataLabels: {
          position: 'center'
        }
      }
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value;
        },
        title: {
          formatter: () => 'CII: '
        }
      }
    },
    legend: {
      customLegendItems: ['A', 'B', 'C', 'D', 'E'],
      labels: {
        colors: fiveColorsIndication
      },
      fontSize: '12px',
      markers: {
        width: 10,
        height: 10,
        strokeWidth: 0,
        strokeColor: '#fff',
        fillColors: fiveColorsIndication,
        radius: 2,
        customHTML: undefined,
        onClick: undefined,
        offsetX: 0,
        offsetY: 0
      },
      position: 'top',
      horizontalAlign: 'left'
    }
  };
};

function VesselCiiRatingChart({ loading, ciiRatingData }) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState(null);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!ciiRatingData) return;
    const { vesselEmissionDetailsList: vessels, avgCII } = ciiRatingData;
    let tempSeries = [
      {
        data: vessels.map(({ cii }) => parseFloat(cii?.toFixed(1)))
      }
    ];
    setOptions(
      getOptions(
        vessels.map(({ vesselName }) => vesselName),
        theme,
        user,
        vessels.map(({ ciiRating }) => ciiRating),
        avgCII
      )
    );

    setSeries(tempSeries);
  }, [ciiRatingData, theme]);

  return (
    <>
      <ApexChart
        loading={loading}
        height={480}
        options={options || {}}
        type={'bar'}
        data={series}
      />
    </>
  );
}
VesselCiiRatingChart.propTypes = {
  loading: PropTypes.bool,
  ciiRatingData: PropTypes.object
};

VesselCiiRatingChart.defaultProps = {
  ciiRatingData: null,
  loading: false
};

export default VesselCiiRatingChart;
