import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import mapboxgl, { Map, NavigationControl } from 'mapbox-gl';
import { MAP_STYLE_DEEP_DARK_MODE, MAP_STYLE_LIGHT_MODE, MAPBOX_API_TOKEN } from '@constants';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { Button, Paper, Typography } from '@esgian/esgianui';
import { useSearchParams } from 'react-router-dom';
import {
  attachMarkers,
  createPulsingMarker,
  getLayerConfig,
  getMarkers,
  getRouteSource,
  putMarkerOnMap,
  removeMarkerFromMap,
  removeMarkers
} from '../VoyageSummarySection/VoyageSummaryRouteMap/utils';

const routeColors = [
  '#80DFEB',
  '#DD53FF',
  '#FFD465',
  '#0E9EFF',
  '#FFDAF9',
  '#4BAC3C',
  '#A47AFF',
  '#E3FFA7',
  '#F8A457',
  '#3E46FF'
];

mapboxgl.accessToken = MAPBOX_API_TOKEN;

function VoyageGroupRouteMap({ routes, selectedVesselsIdList, hoveredVoyageId }) {
  const themeMode = useSelector(getThemeMode);

  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);
  const portsMarkerRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const isFullScreen = searchParams.get('view') === 'map';
  const showColorCode = searchParams.get('showColorCode') === 'true';
  const isShowPorts = searchParams.get('showPorts') === 'true';

  const [isStyleLoaded, setIsStyleLoaded] = useState(false);

  const goFullScreen = () => {
    setSearchParams((prevParams) => {
      prevParams.set('view', 'map');
      prevParams.set('showPorts', 'true');
      prevParams.set('showColorCode', 'true');
      return prevParams;
    });
  };

  useEffect(() => {
    if (mapRef.current || routes.length === 0) {
      setIsStyleLoaded(false);
      setIsStyleLoaded(false);
      return;
    }
    const map = new Map({
      container: mapContainerRef.current,
      style: themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      center: [113.55, 22.1667],
      zoom: 1
    });
    mapRef.current = map;
    map.addControl(new NavigationControl({}));

    portsMarkerRef.current = getMarkers(
      routes.reduce(
        (prevPortsMarker, currentRoute) => [...prevPortsMarker, ...currentRoute.ports],
        []
      ),
      map,
      {
        portsColor: '#fff'
      }
    );
    map.on('load', () => {
      routes.forEach((r, index) => {
        mapRef.current.addSource(
          r.id,
          getRouteSource([
            {
              route: r.routes,
              color: showColorCode ? routeColors[index] : routeColors[0]
            }
          ])
        );
        mapRef.current.addLayer(getLayerConfig(r.id));
      });
      if (isShowPorts) {
        attachMarkers(portsMarkerRef.current, map);
      }
    });

    map.on('styledata', () => {
      setIsStyleLoaded(true);
    });

    return () => map.remove();
  }, [routes]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    mapRef.current.setStyle(themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE);
  }, [themeMode]);

  useEffect(() => {
    if (!mapRef.current || !portsMarkerRef.current) {
      return;
    }
    removeMarkers(portsMarkerRef.current);
    updateVisiblePortMarkers();
    if (isShowPorts) {
      attachMarkers(portsMarkerRef.current, mapRef.current);
    } else {
      removeMarkers(portsMarkerRef.current);
    }
  }, [isShowPorts]);

  const updateVisiblePortMarkers = () => {
    portsMarkerRef.current = getMarkers(
      routes
        .filter((r) => selectedVesselsIdList.includes(r.id))
        .reduce((prevPortsMarker, currentRoute) => [...prevPortsMarker, ...currentRoute.ports], []),
      mapRef.current,
      {
        portsColor: '#fff'
      }
    );
  };

  useEffect(() => {
    if (!mapRef.current || !isStyleLoaded || !routes?.length) {
      return;
    }

    if (isShowPorts) {
      removeMarkers(portsMarkerRef.current);
      updateVisiblePortMarkers();
      attachMarkers(portsMarkerRef.current, mapRef.current);
    }

    routes.forEach((r) => {
      const isVisible = selectedVesselsIdList.includes(r.id);
      mapRef?.current?.setLayoutProperty(r.id, 'visibility', isVisible ? 'visible' : 'none');

      if (!isFullScreen && hoveredVoyageId && hoveredVoyageId === r.id) {
        mapRef?.current?.setPaintProperty(r.id, 'line-width', 5);
        mapRef?.current?.setPaintProperty(r.id, 'line-color', routeColors[1]);
      } else if (!isFullScreen) {
        mapRef?.current?.setPaintProperty(r.id, 'line-width', 2);
        mapRef?.current?.setPaintProperty(r.id, 'line-color', routeColors[0]);
      }

      // Remove start and end markers if the route is not visible
      if (!isVisible) {
        removeMarkerFromMap(mapRef.current, `start-${r.id}`);
        removeMarkerFromMap(mapRef.current, `end-${r.id}`);
      } else {
        const pulsingMarkerStart = createPulsingMarker(mapRef.current, 'rgba(14,81,255,1)');
        const pulsingMarkerEnd = createPulsingMarker(mapRef.current, 'rgba(255,152,0)');

        putMarkerOnMap(
          mapRef.current,
          pulsingMarkerStart,
          r.routes[0].lon,
          r.routes[0].lat,
          `start-${r.id}`
        );
        putMarkerOnMap(
          mapRef.current,
          pulsingMarkerEnd,
          r.routes[r.routes.length - 1].lon,
          r.routes[r.routes.length - 1].lat,
          `end-${r.id}`
        );
      }
    });
  }, [selectedVesselsIdList, isStyleLoaded, hoveredVoyageId]);
  useEffect(() => {
    if (!mapRef.current || !isStyleLoaded) {
      return;
    }
    routes.forEach((r, index) => {
      const geojsonSource = mapRef.current.getSource(r.id);
      if (!geojsonSource) return;
      // Update the data after the GeoJSON source was created
      geojsonSource.setData(
        getRouteSource([
          {
            route: r.routes,
            color: showColorCode ? routeColors[index] : routeColors[0]
          }
        ]).data
      );
    });
  }, [showColorCode, isStyleLoaded]);

  return (
    <Paper sx={{ position: 'relative' }}>
      {!isFullScreen && (
        <Typography variant={'h6'} sx={{ position: 'absolute', zIndex: 20, p: 2 }}>
          Voyage routes on Map
        </Typography>
      )}

      <div
        style={{
          position: 'absolute',
          zIndex: 20,
          right: '10px',
          bottom: '20px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          gap: 2
        }}>
        <Button
          onClick={() => goFullScreen()}
          startIcon={<img src="/assets/images/full-screen-icon.png" alt="Full screen" />}
          sx={{ background: 'white', color: 'black' }}>
          <span>Full screen</span>
        </Button>
      </div>

      <div
        className="voyage-summary-map-container"
        ref={mapContainerRef}
        style={{
          height: isFullScreen ? '100vh' : '90vh',
          width: isFullScreen ? '100vw' : '100%',
          borderRadius: 4
        }}
      />
    </Paper>
  );
}

VoyageGroupRouteMap.propTypes = {
  routes: PropTypes.array,
  selectedVesselsIdList: PropTypes.array,
  selectedRouteId: PropTypes.string,
  hoveredVoyageId: PropTypes.string
};

export default VoyageGroupRouteMap;
