import { useMemo } from 'react';
import { APP_NAME } from '@constants';
import { getEsgianTheme } from '@esgian/esgianui';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';

export const useTheme = () => {
  const themeMode = useSelector(getThemeMode);

  const theme = useMemo(() => {
    let tempTheme = getEsgianTheme(themeMode ? 'dark' : 'light', APP_NAME);
    return { ...tempTheme, ...{ mode: tempTheme.palette.mode } };
  }, [themeMode]);

  const customScrollbar = useMemo(() => {
    return {
      '::-webkit-scrollbar': {
        width: '7px'
      },
      /* Track */
      '::-webkit-scrollbar-track': {
        borderRadius: '10px',
        background: ({ palette }) => palette.neutral.neutral01
      },
      /* Handle */
      ' ::-webkit-scrollbar-thumb': {
        background: ({ palette }) => palette.primary.main,
        borderRadius: '10px'
      }
    };
  }, []);

  const charts = useMemo(() => {
    const {
      palette: { charts }
    } = theme;
    return charts;
  }, [theme]);

  return { theme, charts, themeMode, customScrollbar };
};
