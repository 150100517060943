import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

function PortCallShareChart({ selectedYear, loading, seriesData, categories, fileName }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const options = useMemo(() => {
    const { palette: { mode, charts: { background, tenPieColors, otherColor } } = {} } =
      theme || {};

    const top10Colors = tenPieColors.slice(0, categories.length);

    const seriesColors = [...top10Colors, otherColor];
    return {
      chart: {
        height: 350,
        type: 'donut',
        fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
        background: background,
        toolbar: {
          tools: {
            download: checkUserHasAccess(user, true)
          },
          export: {
            csv: {
              filename: fileName
            },
            svg: {
              filename: fileName
            },
            png: {
              filename: fileName
            }
          }
        }
      },
      colors: seriesColors,
      legend: {
        show: true,
        markers: {
          fillColors: seriesColors
        },
        labels: {
          colors: mode ? 'dark' : 'light'
        }
      },
      plotOptions: {
        pie: {
          donut: {
            size: '50%'
          }
        }
      },
      dataLabels: {
        dropShadow: {
          enabled: false
        }
      },
      title: {
        text: selectedYear,
        offsetY: 195,
        offsetX: -45,
        style: {
          fontWeight: 100,
          fontSize: '1.25rem',
          color: background
        },
        align: 'center'
      },
      labels: categories
    };
  }, [theme, categories, user, fileName, selectedYear]);

  return (
    <ApexChart
      loading={loading || !options}
      data={seriesData}
      height={350}
      options={options || {}}
      type={'donut'}
    />
  );
}

PortCallShareChart.propTypes = {
  loading: PropTypes.bool,
  seriesData: PropTypes.arrayOf(PropTypes.number),
  categories: PropTypes.arrayOf(PropTypes.string),
  selectedYear: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired
};

PortCallShareChart.defaultProps = {
  loading: false,
  seriesData: [],
  categories: []
};

export default PortCallShareChart;
