import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AdvancedFilterDrawer, Divider, FilterSlider, Grid } from '@esgian/esgianui';
import moment from 'moment/moment';
import {
  OPERATOR_MAX_FLEET_SIZE,
  VESSEL_MIN_BUILD_YEAR,
  VESSEL_MAX_CAPACITY,
  VESSEL_MAX_CAPACITY_DWT
} from '@constants';
import { useSegment } from '@hooks/useSegment';
import { useDispatch, useSelector } from 'react-redux';
import { getOprCo2EmissionsFilters } from '@store/features/filters/EmissionAnalyticsPage/Co2EmissionsSlice/Co2EmissionsSlice';
import { resetOprCo2EmissionsAdvFilters } from '@store/features/filters/EmissionAnalyticsPage/OperatorComparisonReducer/OperatorComparisonReducer';

function OperatorCo2EmissionsAdvancedFilters({
  advancedOpen,
  setAdvancedOpen,
  setFetchDataSwitch,
  updateFilterValue,
  fetchDataSwitch
}) {
  const [filters, setFilters] = useState({});
  const { isRoRo, segment } = useSegment();
  const dispatch = useDispatch();
  const co2EmissionsFilters = useSelector(getOprCo2EmissionsFilters);

  const handleClearClick = () => {
    dispatch(resetOprCo2EmissionsAdvFilters(segment));
    setFilters(co2EmissionsFilters);
    setAdvancedOpen(false);
  };

  const handleApplyClick = () => {
    updateFilterValue(filters);
    setFetchDataSwitch(!fetchDataSwitch);
    setAdvancedOpen(false);
  };

  const updateTempFilters = (payload) => {
    setFilters({ ...filters, ...payload });
  };

  useEffect(() => {
    setFilters({ ...co2EmissionsFilters });
  }, [co2EmissionsFilters]);

  const { minCapacity, maxCapacity, minFleetSize, maxFleetSize, minBuildYear, maxBuildYear } =
    filters;
  return (
    <AdvancedFilterDrawer
      anchor={'right'}
      open={advancedOpen}
      handleReset={handleClearClick}
      onClose={() => setAdvancedOpen(false)}
      handleApply={handleApplyClick}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} className={'section-filter'}>
          <FilterSlider
            disableMarkRotation
            step={10}
            value={[minFleetSize, maxFleetSize]}
            marks={[
              { value: 0, label: 0 },
              { value: OPERATOR_MAX_FLEET_SIZE, label: OPERATOR_MAX_FLEET_SIZE }
            ]}
            max={OPERATOR_MAX_FLEET_SIZE}
            min={0}
            title={'Fleet size'}
            onChange={(val) => updateTempFilters({ minFleetSize: val[0], maxFleetSize: val[1] })}
          />
        </Grid>
        <Grid item xs={12} className={'section-filter'}>
          <FilterSlider
            disableMarkRotation
            step={100}
            marks={[
              { value: 0, label: 0 },
              {
                value: isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_CAPACITY_DWT,
                label: isRoRo
                  ? `${VESSEL_MAX_CAPACITY / 1000}k`
                  : `${VESSEL_MAX_CAPACITY_DWT / 1000}k`
              }
            ]}
            value={[minCapacity, maxCapacity]}
            max={isRoRo ? VESSEL_MAX_CAPACITY : VESSEL_MAX_CAPACITY_DWT}
            min={0}
            title={isRoRo ? 'Vessel capacity' : 'Deadweight'}
            onChange={(val) => updateTempFilters({ minCapacity: val[0], maxCapacity: val[1] })}
          />
        </Grid>
        <Grid item xs={12} className={'section-filter'}>
          <FilterSlider
            disableMarkRotation
            marks={[
              { value: VESSEL_MIN_BUILD_YEAR, label: VESSEL_MIN_BUILD_YEAR },
              { value: moment().year(), label: moment().year() }
            ]}
            value={[minBuildYear, maxBuildYear]}
            max={moment().year()}
            min={VESSEL_MIN_BUILD_YEAR}
            title={'Build year'}
            onChange={(val) => updateTempFilters({ minBuildYear: val[0], maxBuildYear: val[1] })}
          />
        </Grid>
      </Grid>
    </AdvancedFilterDrawer>
  );
}

OperatorCo2EmissionsAdvancedFilters.propTypes = {
  advancedOpen: PropTypes.bool,
  setAdvancedOpen: PropTypes.func.isRequired,
  setFetchDataSwitch: PropTypes.func.isRequired,
  updateFilterValue: PropTypes.func.isRequired,
  fetchDataSwitch: PropTypes.bool.isRequired
};

OperatorCo2EmissionsAdvancedFilters.defaultProps = {
  advancedOpen: false
};

export default OperatorCo2EmissionsAdvancedFilters;
