import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Paper, Table, Typography } from '@esgian/esgianui';
import moment from 'moment';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess, formatNumberReadable } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useSegment, useTheme } from '@hooks';
import { DRAUGHT_ON_ARRIVAL_DEPARTURE_POD_TEXT } from '@constants/tooltips';

function VesselTransitTimeAndSpread({
  vesselsEmissions,
  performanceData,
  dateRangeList,
  includeSpread
}) {
  const [operatorVesselData, setOperatorVesselData] = useState([]);
  const [emissionIncluded, setEmissionIncluded] = useState(false);
  const { isRoRo } = useSegment();
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!performanceData || !dateRangeList.length) {
      return;
    }

    const { operatorPerformance } = performanceData;
    let { transitResult } = operatorPerformance;
    transitResult = transitResult[0];
    const { operatorMonthlyTransitResults } = transitResult;
    let tempData = [];
    dateRangeList.forEach((month, index) => {
      let transitResults = operatorMonthlyTransitResults[index];
      const { voyageInformation } = transitResults || {};
      if (voyageInformation?.length) {
        let vesselData = voyageInformation?.map(
          ({
            vesselName,
            transitTime,
            imo,
            startDate,
            vesselSpread,
            endDate,
            arrivalDraught,
            departureDraught,
            imoDraught,
            percentArrivalDraught,
            percentDepartureDraught
          }) => {
            let emissions = {};
            if (vesselsEmissions?.length) {
              emissions = vesselsEmissions.find((data) => {
                const { startDate: sd, imo: id, emissions } = data || {};
                if (startDate === sd && imo === id) {
                  return emissions;
                }
              });
            }
            return {
              period: month,
              vesselName: vesselName,
              transitTime: transitTime,
              imo: imo,
              spread: vesselSpread,
              arrivalDraught: arrivalDraught,
              departureDraught: departureDraught,
              imoDraught: imoDraught,
              percentArrivalDraught: percentArrivalDraught,
              percentDepartureDraught: percentDepartureDraught,
              ...emissions?.emissions,
              pol: startDate ? moment(startDate).format('DD.MM.yyyy') : null,
              pod: endDate ? moment(endDate).format('DD.MM.yyyy') : null
            };
          }
        );
        tempData = [...tempData, ...vesselData];
      }
    });
    if (vesselsEmissions?.length) {
      setEmissionIncluded(true);
    } else {
      setEmissionIncluded(false);
    }
    setOperatorVesselData(tempData);
  }, [performanceData, vesselsEmissions]);

  const columns = [
    {
      name: '#',
      label: '#',
      options: {
        sort: false,
        customBodyRender: (dataIndex, rowIndex) => {
          return rowIndex.rowIndex + 1;
        }
      }
    },
    {
      name: 'imo',
      options: { display: false, viewColumns: false, filter: false }
    },
    {
      name: 'vesselName',
      label: 'Vessel Name',
      options: {
        sort: false,
        customBodyRender: (dataIndex, rowIndex) => {
          const { rowData } = rowIndex;
          return dataIndex !== null ? (
            <ProfileLink name={dataIndex} profile={'vessel'} id={rowData[1]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'period',
      label: 'Period',
      options: { sort: false }
    },
    {
      name: 'transitTime',
      label: 'Transit time [days]',
      options: {
        sort: false,
        customBodyRender: (dataIndex) => {
          return dataIndex?.toFixed(1) || '-';
        }
      }
    },
    {
      name: 'pol',
      label: 'ATA POL',
      options: {
        display: includeSpread,
        sort: false,
        customBodyRender: (dataIndex) => {
          return dataIndex !== null ? dataIndex : '-';
        }
      }
    },
    {
      name: 'percentArrivalDraught',
      label: 'Arrival Percentage Draught',
      options: {
        display: false,
        download: false,
        filter: false
      }
    },
    {
      name: 'imoDraught',
      label: 'IMO Draught',
      options: {
        display: false,
        download: false,
        filter: false
      }
    },

    {
      name: 'pod',
      label: 'ATA POD',
      options: {
        display: includeSpread,
        sort: false,
        customBodyRender: (dataIndex) => {
          return dataIndex !== null ? dataIndex : '-';
        }
      }
    },
    {
      name: 'percentDepartureDraught',
      label: 'Departure Percentage Draught',
      options: {
        display: false,
        download: false,
        filter: false
      }
    },
    {
      name: 'arrivalDraught',
      label: 'Draught on arrival',
      options: {
        hint: DRAUGHT_ON_ARRIVAL_DEPARTURE_POD_TEXT,
        sort: false,
        display: isRoRo,
        sortThirdClickReset: false,
        customBodyRender: (dataIndex, rowIndex) => {
          if (dataIndex !== undefined && dataIndex !== null) {
            const percentArrivalDraught = parseFloat(dataIndex) / parseFloat(rowIndex.rowData[7]);
            return `${dataIndex}/${
              rowIndex.rowData[7] !== null ? rowIndex.rowData[7]?.toFixed(1) : 'N/A'
            } (${(percentArrivalDraught * 100).toFixed(1)}%)`;
          } else {
            return 'ais draught / - [-%]';
          }
        }
      }
    },
    {
      name: 'departureDraught',
      label: 'Draught on departure',
      options: {
        hint: DRAUGHT_ON_ARRIVAL_DEPARTURE_POD_TEXT,
        sort: false,
        display: isRoRo,
        sortThirdClickReset: false,
        customBodyRender: (dataIndex, rowIndex) => {
          if (dataIndex !== undefined && dataIndex !== null) {
            const percentDepartureDraught = parseFloat(dataIndex) / parseFloat(rowIndex.rowData[7]);
            return `${dataIndex}/${
              rowIndex.rowData[7] !== null ? rowIndex.rowData[7]?.toFixed(1) : 'N/A'
            } (${(percentDepartureDraught * 100).toFixed(1)}%)`;
          } else {
            return 'ais draught / - [-%]';
          }
        }
      }
    },
    {
      name: 'spread',
      label: 'Vessel Spread',
      options: {
        display: includeSpread,
        sort: false
      }
    },
    {
      name: 'cO2',
      options: {
        customHeadLabelRender: () => (
          <div style={{ width: '100px' }}>
            CO<sub>2</sub>[mt]
          </div>
        ),
        display: includeSpread,
        sort: false,
        customBodyRender: (dataIndex) => {
          if (emissionIncluded) {
            const formattedValue = dataIndex?.toFixed(2) || '-';
            return formatNumberReadable(formattedValue).toString();
          } else {
            return <CircularProgress size={15} />;
          }
        }
      }
    },
    {
      name: 'cO2PerCEU',
      options: {
        customHeadLabelRender: () => (
          <div>
            CO<sub>2</sub>/CEU [mt/CEU]
          </div>
        ),
        display: includeSpread,
        sort: false,
        customBodyRender: (dataIndex) => {
          return emissionIncluded ? dataIndex?.toFixed(2) || '-' : <CircularProgress size={15} />;
        }
      }
    },
    {
      name: 'cii',
      options: {
        customHeadLabelRender: () => (
          <div>
            CII [CO<sub>2</sub>/(GT•NM)]
          </div>
        ),
        display: includeSpread,
        sort: false,
        customBodyRender: (dataIndex) => {
          return emissionIncluded ? dataIndex?.toFixed(2) || '-' : <CircularProgress size={15} />;
        }
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
    download: checkUserHasAccess(user, true),
    tableBodyMaxHeight: '70vh',
    tableBodyHeight: '70vh',
    elevation: 0,
    print: false,
    viewColumns: false,
    pagination: false,
    toolbar: checkUserHasAccess(user, true),
    sortOrder: {
      name: 'startDate',
      direction: 'desc'
    },
    downloadOptions: {
      filename: 'Service Level-Transit Time & Spread'
    }
  };
  return (
    <Paper variant={'outlined'}>
      <Table
        customStyle={{
          MUIDataTableFilterList: { display: 'none' },
          MUIDataTableToolbar: {
            styleOverrides: {
              root: {
                minHeight: 0
              }
            }
          }
        }}
        loading={false}
        mode={theme.mode}
        data={operatorVesselData?.length ? operatorVesselData : []}
        columns={columns}
        options={options}
      />
    </Paper>
  );
}

VesselTransitTimeAndSpread.propTypes = {
  performanceData: PropTypes.object,
  dateRangeList: PropTypes.arrayOf(PropTypes.string),
  vesselsEmissions: PropTypes.arrayOf(PropTypes.object),
  includeSpread: PropTypes.bool
};

VesselTransitTimeAndSpread.defaultProps = {
  performanceData: null,
  dateRangeList: null,
  vesselsEmissions: null,
  includeSpread: false
};

export default VesselTransitTimeAndSpread;
