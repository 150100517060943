import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import mapboxgl, { Map, NavigationControl } from 'mapbox-gl';
import { MAP_STYLE_DEEP_DARK_MODE, MAP_STYLE_LIGHT_MODE, MAPBOX_API_TOKEN } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import { Button, Paper, Typography, CircularProgress } from '@esgian/esgianui';
import './route-map.css';

import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { VoyageSummaryFilterKeys } from '../VoyageSummaryConstants';
import {
  createShipIcon,
  getLayerConfig,
  getRouteLayer,
  getRouteSource,
  putMarkerOnMap,
  createPulsingMarker,
  getMarkers,
  attachMarkers,
  removeMarkers
} from './utils';
import {
  getVoyageProfileFilters,
  updateVoyageProfileFilters
} from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';

const VOYAGE_ORIGINAL_ROUTE_KEY = 'originalRoute';
const MAP_SHORTEST_ROUTE_KEY = 'shortestRoute';

mapboxgl.accessToken = MAPBOX_API_TOKEN;

function VoyageSummaryRouteMap({
  voyageTransitDetails,
  voyagePortCalls,
  shortestPath,
  shouldShowFullscreenMap
}) {
  const themeMode = useSelector(getThemeMode);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    // section,
    mapOptions: {
      shortestPath: showShortestPath,
      fullscreen,
      // speed: showSpeed,
      port: showPort,
      voyageInfo: showVoyageInfo
      // draught: showDraught
    }
  } = useSelector(getVoyageProfileFilters);
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [searchParams] = useSearchParams();

  const selectedDate = +searchParams.get(VoyageSummaryFilterKeys.SELECTED_DATE) || 0;
  const routeMarkersRef = useRef([]);
  const shortestRouteMarkersRef = useRef([]);

  const [shipMarker, setShipMarker] = useState(null);

  const transitDetailsCollection = voyageTransitDetails?.transitDetails ?? [];

  useEffect(() => {
    if (mapRef.current) {
      return;
    }
    const map = new Map({
      renderWorldCopies: true,
      container: mapContainerRef.current,
      style: themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE,
      center: [113.55, 22.1667],
      zoom: 1
    });
    mapRef.current = map;

    map.addControl(new NavigationControl({}));

    routeMarkersRef.current = getMarkers(voyagePortCalls?.portCalls ?? [], mapRef.current);
    shortestRouteMarkersRef.current = getMarkers([], mapRef.current);

    map.on('load', () => {
      setIsMapLoaded(true);

      const routes = [
        { route: transitDetailsCollection, color: themeMode ? '#80DFEB' : '#1557FF' }
      ];
      map.addSource(VOYAGE_ORIGINAL_ROUTE_KEY, getRouteSource(routes));
      map.addLayer(
        getLayerConfig(VOYAGE_ORIGINAL_ROUTE_KEY, {
          visibility: showVoyageInfo ? 'visible' : 'none'
        })
      );
      map.addLayer(
        getLayerConfig(VOYAGE_ORIGINAL_ROUTE_KEY, {
          visibility: showShortestPath ? 'visible' : 'none'
        })
      );

      const shortestRoute = [{ route: shortestPath.shortestPathCoords, color: '#E989D8' }];
      map.addLayer(
        getRouteLayer(shortestRoute, {
          id: MAP_SHORTEST_ROUTE_KEY,
          visibility: showShortestPath ? 'visible' : 'none'
        })
      );

      const pulsingMarkerStart = createPulsingMarker(map, 'rgba(14,81,255,1)');
      const pulsingMarkerEnd = createPulsingMarker(map, 'rgba(255,152,0)');
      if (transitDetailsCollection.length > 0) {
        putMarkerOnMap(
          map,
          pulsingMarkerStart,
          transitDetailsCollection[0].lon,
          transitDetailsCollection[0].lat,
          'start'
        );
        putMarkerOnMap(
          map,
          pulsingMarkerEnd,
          transitDetailsCollection[transitDetailsCollection.length - 1].lon,
          transitDetailsCollection[transitDetailsCollection.length - 1].lat,
          'end'
        );
      }

      if (showPort) {
        attachMarkers(routeMarkersRef.current, map);
      }
      if (showShortestPath && showPort) {
        attachMarkers(shortestRouteMarkersRef.current, map);
      }
    });
    return () => map.remove();
  }, []);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }

    const geojsonSource = mapRef.current.getSource(VOYAGE_ORIGINAL_ROUTE_KEY);
    geojsonSource &&
      geojsonSource.setData(
        getRouteSource([{ route: transitDetailsCollection, color: '#80DFEB' }]).data
      );
  }, [transitDetailsCollection]);

  useEffect(() => {
    if (!mapRef.current || !isMapLoaded) {
      return;
    }
    mapRef.current.setStyle(themeMode ? MAP_STYLE_DEEP_DARK_MODE : MAP_STYLE_LIGHT_MODE);

    mapRef.current.once('styledata', () => {
      const routes = [
        { route: transitDetailsCollection, color: themeMode ? '#80DFEB' : '#1557FF' }
      ];
      mapRef.current.addSource(VOYAGE_ORIGINAL_ROUTE_KEY, getRouteSource(routes));
      mapRef.current.addLayer(
        getLayerConfig(VOYAGE_ORIGINAL_ROUTE_KEY, {
          visibility: showVoyageInfo ? 'visible' : 'none'
        })
      );

      const shortestRoute = [{ route: shortestPath.shortestPathCoords, color: '#E989D8' }];
      mapRef.current.addLayer(
        getRouteLayer(shortestRoute, {
          id: MAP_SHORTEST_ROUTE_KEY,
          visibility: showShortestPath ? 'visible' : 'none'
        })
      );

      // Re-attach markers after style change
      if (showPort) {
        attachMarkers(routeMarkersRef.current, mapRef.current);
      }
      if (showShortestPath && showPort) {
        attachMarkers(shortestRouteMarkersRef.current, mapRef.current);
      }

      // Re-apply pulsing markers
      const pulsingMarkerStart = createPulsingMarker(mapRef.current, 'rgba(14,81,255,1)');
      const pulsingMarkerEnd = createPulsingMarker(mapRef.current, 'rgba(255,152,0)');
      if (transitDetailsCollection.length > 0) {
        putMarkerOnMap(
          mapRef.current,
          pulsingMarkerStart,
          transitDetailsCollection[0].lon,
          transitDetailsCollection[0].lat,
          'start'
        );
        putMarkerOnMap(
          mapRef.current,
          pulsingMarkerEnd,
          transitDetailsCollection[transitDetailsCollection.length - 1].lon,
          transitDetailsCollection[transitDetailsCollection.length - 1].lat,
          'end'
        );
      }
    });
  }, [themeMode]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    const hasRouteLayer = mapRef.current.getLayer(MAP_SHORTEST_ROUTE_KEY);
    if (!hasRouteLayer) {
      return;
    }

    mapRef.current.setLayoutProperty(
      MAP_SHORTEST_ROUTE_KEY,
      'visibility',
      showShortestPath ? 'visible' : 'none'
    );
  }, [showShortestPath]);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    const hasRouteLayer = mapRef.current.getLayer(VOYAGE_ORIGINAL_ROUTE_KEY);
    if (!hasRouteLayer) {
      return;
    }

    mapRef.current.setLayoutProperty(
      VOYAGE_ORIGINAL_ROUTE_KEY,
      'visibility',
      showVoyageInfo ? 'visible' : 'none'
    );
  }, [showVoyageInfo]);

  useEffect(() => {
    if (!mapRef.current || !routeMarkersRef.current || !shortestRouteMarkersRef.current) {
      return;
    }

    if (showPort) {
      attachMarkers(routeMarkersRef.current, mapRef.current);
    } else {
      removeMarkers(routeMarkersRef.current, mapRef.current);
    }
    if (showShortestPath && showPort) {
      attachMarkers(shortestRouteMarkersRef.current, mapRef.current);
    } else {
      removeMarkers(shortestRouteMarkersRef.current, mapRef.current);
    }
  }, [showShortestPath, showPort]);

  useEffect(() => {
    if (!mapRef.current || !fullscreen) return;
    // Remove previous ship marker
    if (shipMarker) {
      shipMarker.remove();
    }

    // Add new ship marker
    const currentPosition = transitDetailsCollection.find(
      (position) => moment(position.timeStamp).toDate().getTime() === selectedDate
    );

    if (currentPosition) {
      const newShipMarker = new mapboxgl.Marker({
        element: document.createElement('img')
      })
        .setLngLat([currentPosition.lon, currentPosition.lat])
        .setLngLat([currentPosition.lon, currentPosition.lat])
        .addTo(mapRef.current);

      newShipMarker.getElement().src = createShipIcon();
      setShipMarker(newShipMarker);
    } else {
      const prevPositionFromSelectedDateIndex = transitDetailsCollection.findLastIndex(
        (position) => moment(position.timeStamp).toDate().getTime() < selectedDate
      );
      const prevPositionFromSelectedDate =
        transitDetailsCollection[prevPositionFromSelectedDateIndex];
      const nextPositionFromSelectedDate = transitDetailsCollection.find(
        (position) => moment(position.timeStamp).toDate().getTime() > selectedDate
      );

      if (prevPositionFromSelectedDate && nextPositionFromSelectedDate) {
        const prevPosition = [prevPositionFromSelectedDate.lon, prevPositionFromSelectedDate.lat];
        const nextPosition = [nextPositionFromSelectedDate.lon, nextPositionFromSelectedDate.lat];

        const progress =
          (selectedDate - moment(prevPositionFromSelectedDate.timeStamp).toDate().getTime()) /
          (moment(nextPositionFromSelectedDate.timeStamp).toDate().getTime() -
            moment(prevPositionFromSelectedDate.timeStamp).toDate().getTime());
        const currentPosition = [
          prevPosition[0] + (nextPosition[0] - prevPosition[0]) * progress,
          prevPosition[1] + (nextPosition[1] - prevPosition[1]) * progress
        ];
        const newShipMarker = new mapboxgl.Marker({
          element: document.createElement('img')
        })
          .setLngLat(currentPosition)
          .addTo(mapRef.current);
        newShipMarker.getElement().src = createShipIcon();
        setShipMarker(newShipMarker);
      }
    }
  }, [fullscreen, selectedDate]);

  return (
    <Paper sx={{ position: 'relative', height: '100%' }}>
      {!fullscreen && (
        <Typography variant={'h6'} sx={{ position: 'absolute', zIndex: 20, p: 2 }}>
          Voyage route
        </Typography>
      )}

      {!fullscreen && (
        <div
          style={{
            position: 'absolute',
            zIndex: 20,
            right: '10px',
            bottom: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            gap: 2
          }}>
          <Button
            onClick={() => {
              searchParams.set('fullscreen', true);
              navigate({ search: searchParams.toString() }, { replace: true });
              dispatch(updateVoyageProfileFilters({ mapOptions: { fullscreen: true } }));
            }}
            startIcon={<img src="/assets/images/full-screen-icon.png" alt="Full screen" />}
            sx={{ background: 'white', color: 'black' }}>
            <span>Full screen</span>
          </Button>
        </div>
      )}
      <div
        className="voyage-summary-map-container"
        ref={mapContainerRef}
        style={{
          height: fullscreen ? (shouldShowFullscreenMap ? '100vh' : '70vh') : '400px',
          width: fullscreen ? '100vw' : '100%',
          borderRadius: fullscreen ? 0 : 4
        }}
      />
      {!isMapLoaded && (
        <CircularProgress
          sx={{
            position: 'absolute',
            top: 'calc(50% - 50px)',
            left: 'calc(50% - 50px)'
          }}
          size={50}
        />
      )}
    </Paper>
  );
}

VoyageSummaryRouteMap.propTypes = {
  voyageTransitDetails: PropTypes.object.isRequired,
  voyagePortCalls: PropTypes.object.isRequired,
  shortestPath: PropTypes.object.isRequired,
  shouldShowFullscreenMap: PropTypes.bool
};
VoyageSummaryRouteMap.defaultProps = {
  voyageTransitDetails: {},
  voyagePortCalls: {},
  shortestPath: {},
  shouldShowFullscreenMap: false
};

export default VoyageSummaryRouteMap;
