import { combineReducers } from '@reduxjs/toolkit';
import CompletedVoyageSlice from '@store/features/filters/VoyageAnalyticsPage/CompletedVoyage/CompletedVoyagesSlice';
import VoyageProfilePage from '@pages/VoyageAnalytics/VoyageProfilePage';
import VoyageProfileSlice from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';

const VoyageAnalyticsReducer = combineReducers({
  completedVoyages: CompletedVoyageSlice,
  voyageProfile: VoyageProfileSlice
});

export default VoyageAnalyticsReducer;

export const getRegionProfileSection = (state) => {
  return state.filters.regionProfile.section;
};
