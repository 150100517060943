import { BaseApiRequest } from '@api/BaseApi';
import { objectToQuery } from '@api/helpers';

export const getOwnerStats = (data, signal) => {
  const query = objectToQuery(data);

  return BaseApiRequest(signal, `/OwnerStats?${query}`);
};

export const getVesselOwnerHistory = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Owner/OwnershipHistory?${query}`);
};

export const getOwnershipInformation = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Owner/OwnershipInformation?${query}`);
};
