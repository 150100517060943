export const getHorizontalBarOptions = (theme, categories) => {
  return {
    chart: {
      type: 'bar',
      background: 'transparent'
    },
    colors: theme.palette.charts.monoColor,
    tooltip: {
      y: {
        title: {
          formatter: () => 'Consistency Score: '
        }
      }
    },
    theme: {
      mode: `${theme.mode}`
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left'
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: true,
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetX: -10
    },
    xaxis: {
      labels: {
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: false
      },
      categories: categories
    }
  };
};
