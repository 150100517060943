import { logout } from '@store/helpers';
import { APP_NAME } from '@constants';

export const BaseApiRequest = async (
  signal,
  path,
  service = 'ships',
  method = 'GET',
  body = undefined,
  headers = { 'Content-type': 'application/json' }
) => {
  const sessionState = JSON.parse(sessionStorage.getItem(APP_NAME));
  let uri = process.env.SHIPS_API;
  if (service === 'auth') {
    uri = process.env.AUTH_API;
  } else if (service === 'ais') {
    uri = process.env.AIS_API;
  } else if (service === 'geo') {
    uri = process.env.GEO_API;
  } else if (service === 'emissions') {
    uri = process.env.EMISSIONS_API;
  } else if (service === 'voyage') {
    uri = process.env.VOYAGE_ANALYTICS_API;
  } else if (service === 'voyages') {
    uri = process.env.VOYAGES_API;
  } else if (service === 'users') {
    uri = process.env.USER_API;
  }
  let reqSignal = {};
  if (service !== 'auth') {
    reqSignal = { signal: signal };
  }
  uri = `${uri}${path}`;
  return await fetch(uri, {
    ...reqSignal,
    mode: 'cors',
    credentials: 'include',
    method: method,
    body: JSON.stringify(body),
    headers: {
      userId: sessionState?.userId || null,
      browserId: sessionState?.browserId || null,
      sameSite: 'none',
      withCredentials: true,
      EsgianApiKey: process.env.API_KEY,
      SupportsCredentials: true,
      'access-control-allow-origin': '*',
      'Access-Control-Allow-Headers':
        'X-Requested-With, X-HTTP-Method-Override, Content-Type, Accept',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,UPDATE,OPTIONS',
      'Access-Control-Allow-Credentials': true,
      'sec-fetch-site': 'same-origin',
      'Content-type': 'application/json',
      'Keep-Alive': 'timeout=100, max=100',
      ...headers
    }
  })
    .then(async (response) => {
      const reject = (errors) => {
        return Promise.reject({
          status: response.status,
          errors
        });
      };
      if (response.status === 401) {
        logout();
        return reject(['Unauthorized']);
      }
      if (response.status === 403) {
        return reject(['Forbidden']);
      } else if (response.status === 404) {
        return reject(['Requested URL does not exist']);
      } else if (response.status === 500) {
        return reject(['Internal server error']);
      } else if (response.status === 504) {
        return reject(['Request timed out']);
      } else if (response.status === 204) {
        return reject(['No content']);
      }

      try {
        let data;

        data = await response;
        if (method === 'DELETE') {
          return response;
        }
        data = response?.json();

        if (response.ok) {
          return data;
        }

        return reject(data[Object.keys(data)[0]]);
      } catch (e) {
        return reject(['Unknown error.']);
      }
    })
    .catch((err) => {
      if (err?.errors?.length && typeof err?.errors[0] === 'string') {
        return Promise.reject({
          status: err.status ? err.status : undefined,
          errors: typeof err.errors === 'string' ? [err.errors] : err.errors
        });
      }

      return Promise.reject({ status: 500, errors: ['Network error'] });
    });
};
