import React, { useEffect, useState } from 'react';
import { Dashboard, EsgianTheme } from '@esgian/esgianui';
import { Route, useNavigate, Routes, useSearchParams } from 'react-router-dom';
import { getSideMenuList } from './SideMenuList';
import { getToken, logout } from '@store/helpers';
import PrivateRoute from '@components/PrivateRoute';
import Swal from 'sweetalert2';
import './mobiscrollTheme.sass';
import { LoginPage } from '@pages';
import { logoutUser } from '@api';
import { getRoutes } from './Routes';
import usePageTracking from './usePageTracking';
import { APP_NAME, SEGMENT } from '@constants';
import {
  fetchUserFromToken,
  getGeneralSlice,
  getUser,
  getUserAccess,
  getUserStatus,
  isUserLoading,
  resetUser,
  setSegment,
  setSideMenuOpen,
  setThemeMode
} from '@store/features';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '@hooks';
import { getVoyageProfileFilters } from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';

function App() {
  const dispatch = useDispatch();
  const [loginRedirect, setLoginRedirect] = useState(null);
  const [init, setInit] = useState(false);
  const { themeMode, sideMenuOpen, dropDownMenuOpen, segment } = useSelector(getGeneralSlice);
  const userStatus = useSelector(getUserStatus);
  const loadingUser = useSelector(isUserLoading);
  const user = useSelector(getUser);
  const userHasAccess = useSelector(getUserAccess);
  const { theme } = useTheme();
  const {
    mapOptions: { fullscreen }
  } = useSelector(getVoyageProfileFilters);
  if (process.env.CURRENT_ENV === 'production') {
    usePageTracking();
  }
  let navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
  };
  const handleLogout = async () => {
    Swal.fire({
      title: 'Signing out...'
    });
    Swal.showLoading();
    await logoutUser(user.token).then(() => {
      logout();
      dispatch(resetUser());
    });
    Swal.close();

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Logout successful',
      showConfirmButton: false,
      timer: 1500
    });
    routeChange('/login');
  };

  useEffect(() => {
    if (loadingUser) return;
    if (userHasAccess && !segment) return;
    if (!userHasAccess) {
      routeChange('./login');
      return;
    }
    if (loginRedirect) {
      routeChange(loginRedirect);
      setLoginRedirect(null);
    } else if (segment?.id === SEGMENT.RoRo.id) {
      routeChange('/roro/dashboard');
    } else if (segment?.id === SEGMENT.MPP.id) {
      routeChange('/mpp/commercial-analytics');
    }
    setInit(true);
  }, [segment, loadingUser, userHasAccess]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    if (userStatus === 'idle') {
      let path = window.location?.pathname;
      if (path !== '/' && path !== '/login') {
        let tempPath = window.location?.pathname + window.location?.search;
        setLoginRedirect(tempPath);
      }
      const token = getToken();
      if (token) {
        dispatch(fetchUserFromToken(signal));
      } else {
        routeChange('/login');
      }
    }
    return () => {
      controller.abort();
    };
  }, [userStatus]);
  return (
    <EsgianTheme mode={themeMode ? 'dark' : 'light'}>
      <Dashboard
        setSelectedSegment={(segment) => dispatch(setSegment(segment))}
        selectedSegment={segment}
        app={APP_NAME}
        handleLogoutClick={handleLogout}
        hideMenu={!user || !userHasAccess}
        handleFaqClick={() => {
          routeChange('/roro/commercial-analytics/faq');
        }}
        user={user}
        handleThemeChange={() => {
          dispatch(setThemeMode(!themeMode));
        }}
        themeMode={theme.mode}
        sideMenuOpen={sideMenuOpen}
        setSideMenuOpen={(val) => {
          dispatch(setSideMenuOpen(val));
        }}
        manualContainerStyles={fullscreen}
        sideMenuContent={getSideMenuList(
          segment,
          routeChange,
          sideMenuOpen,
          dropDownMenuOpen,
          dispatch
        )}>
        <ToastContainer
          toastStyle={{ background: theme.palette.background.paper }}
          position="top-right"
          autoClose={3000}
          theme={theme.mode}
        />
        <Routes>
          <Route
            path="/login"
            exact
            element={<LoginPage segment={segment} loginRedirect={loginRedirect} />}
          />
          {init &&
            getRoutes(user, segment).map(({ path, element, isRedirect }) => {
              if (isRedirect && !loadingUser) {
                return <Route key={path} path={path} exact element={element} />;
              }
              return (
                <Route
                  key={path}
                  path={path}
                  exact
                  element={<PrivateRoute>{element}</PrivateRoute>}
                />
              );
            })}
        </Routes>
      </Dashboard>
    </EsgianTheme>
  );
}

export default App;
