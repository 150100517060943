import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Typography, Stack } from '@esgian/esgianui';
import { checkUserHasAccess, formatAxisValue } from '@helpers';
import { useTheme } from '@hooks/useTheme';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
const moment = require('moment');
const formatDate = (rawDate) => {
  if (rawDate) {
    const startDate = moment(rawDate);
    const endDate = moment(startDate).add(6, 'days');

    const startMonth = startDate.format('MMM'); // Short month name
    const endMonth = endDate.format('MMM'); // Short month name

    const startDay = startDate.format('D');
    const endDay = endDate.format('D');

    const year = endDate.year();

    return `${startMonth} ${startDay} - ${endMonth} ${endDay}, ${year}`;
  }

  return '';
};

const getWeekData = (portCongestionData, yearIndex, weekIndex) => {
  const { years } = portCongestionData;
  const { weeks = [] } = years[years.length - 1 - yearIndex] || {};
  return weeks[weekIndex] ?? {};
};

const getOptions = (weeks, theme, user, fileName, portCongestionData, selectedMeasure) => {
  const {
    palette: {
      mode,
      charts: { background, twoColorsContrast, twoColorsNeutral, threeColors }
    }
  } = theme;
  let colors = [...threeColors, twoColorsNeutral[0], twoColorsContrast[0]];
  return {
    chart: {
      type: 'area',
      height: 350,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName,
            headerCategory: 'Week no.'
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [2, 2, 2]
    },
    colors: colors,
    theme: {
      mode: mode
    },
    xaxis: {
      categories: weeks,
      title: {
        text: 'Week no.'
      }
    },
    yaxis: {
      title: {
        text: `${selectedMeasure.unit}`
      },
      decimalsInFloat: 0,
      labels: {
        formatter: function (value) {
          if (selectedMeasure.unit === 'CEU') {
            return formatAxisValue(parseInt(value));
          } else {
            return value;
          }
        }
      }
    },

    fill: {
      type: 'solid'
    },

    tooltip: {
      onDatasetHover: {
        highlightDataSeries: true
      },
      custom: function ({ seriesIndex, dataPointIndex, w }) {
        const weekData = getWeekData(portCongestionData, seriesIndex, dataPointIndex);
        const measureUnit = selectedMeasure.unit;
        const rawDate = weekData?.weekStart;
        if (rawDate) {
          let unitvalue = (weekData.averageWaitingTimeDays * 24).toFixed(2);
          if (selectedMeasure.id === 2) {
            unitvalue = weekData?.totalWaitingTimeDays.toFixed(2);
          }
          if (selectedMeasure.id === 3) {
            unitvalue = formatAxisValue(weekData?.ceuCapacityInWaiting || 0);
          }
          if (selectedMeasure.id === 4) {
            unitvalue = weekData?.numWaitingEvents;
          }

          const formattedDate = formatDate(rawDate);
          const measureUnitLabel =
            measureUnit === 'CEU'
              ? 'CEU cap:'
              : measureUnit === 'Anchor events'
              ? 'Anchor events:'
              : `Waiting time (${measureUnit}):`;
          const vesselsSpan =
            measureUnit !== 'Anchor events'
              ? `<span style="margin-bottom: 8px; margin-left:10px">
           Anchor events: ${weekData?.numWaitingEvents}
          </span>`
              : '';
          return `
        <div style="display: flex; padding: 16px; flex-direction: column; align-items: flex-start; align-self: stretch; border-radius: 4px; box-shadow: 0 4px 4px 0; rgba(255, 255, 255, 0.8);">
          <span style="margin-bottom: 8px; margin-left:10px; font-weight: bold; ">
            Week: ${weekData?.week}
          </span>
          <span style="margin-bottom: 8px; margin-left:10px">
             ${measureUnitLabel} ${unitvalue}
          </span>
          ${vesselsSpan}
          <span style="margin-bottom: 8px; margin-left:10px">
            Date: ${formattedDate}
          </span>
          <div style="position: absolute; left: 0; top: 50px; height: 50%; width: 2px; padding: 2px 2px 0 2px; background-color: ${w?.config?.colors[seriesIndex]};"></div>
        </div>
      `;
        }
        return '';
      },
      shared: true,
      intersect: false,
      enabled: true,
      y: {
        formatter: function (value) {
          return `${value?.toFixed(0)} ${selectedMeasure.unit}`;
        }
      },
      x: {
        show: true,
        formatter: function (value, { dataPointIndex }) {
          return `Week: ${weeks[dataPointIndex]}`;
        }
      }
    },

    legend: {
      position: 'top',
      horizontalAlign: 'left'
    }
  };
};

function WeeklyPortWaitingChart({ portCongestionData, selectedMeasure, loading, fileName }) {
  const { theme } = useTheme();
  const user = useSelector(getUser);

  const series = useMemo(() => {
    if (!portCongestionData) return [];
    let tempSeries = [];
    const { years } = portCongestionData;
    const maxDataLength = Math.max(...years.map(({ weeks }) => weeks?.length || 0));

    years.forEach(({ year, weeks }) => {
      let tempData = weeks?.map(
        ({
          averageWaitingTimeDays,
          totalWaitingTimeDays,
          ceuCapacityInWaiting,
          numWaitingEvents
        }) => {
          if (selectedMeasure.id === 1) {
            return averageWaitingTimeDays * 24;
          }
          if (selectedMeasure.id === 2) {
            return totalWaitingTimeDays;
          }
          if (selectedMeasure.id === 3) {
            return ceuCapacityInWaiting;
          }
          if (selectedMeasure.id === 4) {
            return numWaitingEvents;
          }
        }
      );
      tempData = [...tempData, ...Array(maxDataLength - tempData.length).fill(null)];
      tempSeries.push({ name: year, data: tempData });
    });
    return tempSeries;
  }, [portCongestionData, theme, selectedMeasure]);

  const options = useMemo(() => {
    let weeks = [];
    for (let i = 1; i <= 52; i++) {
      weeks.push(i);
    }

    return getOptions(weeks, theme, user, fileName, portCongestionData, selectedMeasure);
  }, [selectedMeasure, portCongestionData, theme]);

  return (
    <Stack spacing={2}>
      <Stack>
        <Typography variant={'h6'}>Weekly {selectedMeasure?.type}</Typography>
      </Stack>
      <ApexChart
        loading={loading}
        height={280}
        options={options || {}}
        type={'line'}
        data={series.reverse()}
      />
    </Stack>
  );
}
WeeklyPortWaitingChart.propTypes = {
  loading: PropTypes.bool,
  fileName: PropTypes.string.isRequired,
  portCongestionData: PropTypes.object,
  selectedMeasure: PropTypes.object
};
WeeklyPortWaitingChart.defaultProps = {
  portCongestionData: {},
  loading: false,
  selectedMeasure: {}
};
export default WeeklyPortWaitingChart;
