import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Button, ButtonGroup, Grid, Stack, Typography } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

export const getChartOptions = (user, theme, categories, average, fileName) => {
  return {
    chart: {
      background: 'transparent',
      height: 350,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    colors: theme.palette.charts.monoColor,
    theme: {
      mode: theme.mode
    },
    tooltip: {
      y: {
        title: {
          formatter: () => '# of sailings: '
        }
      }
    },
    stroke: {
      width: 2
    },
    markers: {
      size: 3
    },
    dataLabels: { enabled: false },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          position: 'top'
        }
      }
    },
    yaxis: { min: 0, max: (max) => max * 1.2 },
    xaxis: {
      labels: {
        rotate: -45,
        rotateAlways: false,
        hideOverlappingLabels: false
      },
      categories: categories
    },
    annotations: {
      position: 'front',
      yaxis: [
        {
          y: average,
          strokeDashArray: 5,
          borderColor: '#5db1f0',
          label: {
            position: 'right',
            textAnchor: 'end',
            borderColor: '#5db1f0',
            style: {
              color: '#fff',
              background: '#5db1f0'
            },
            text: `Average(${average?.toFixed(2)})`
          }
        }
      ]
    }
  };
};

function ServiceLevelChart({ loading, performanceData, dateRangeList, fileName }) {
  const [type, setType] = useState('bar');
  const [options, setOptions] = useState(null);
  const [series, setSeries] = useState([]);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!performanceData || !dateRangeList.length) {
      return;
    }
    const { operatorPerformance } = performanceData;
    let { operatorFrequencyResult } = operatorPerformance;
    operatorFrequencyResult = operatorFrequencyResult[0];
    const { frequencyStats, operatorMonthlyFrequencyResults } = operatorFrequencyResult;
    const { average } = frequencyStats;
    setSeries([{ data: operatorMonthlyFrequencyResults.map(({ portCalls }) => portCalls) }]);
    setOptions(getChartOptions(user, theme, dateRangeList, average, fileName));
  }, [performanceData, dateRangeList, theme]);

  return (
    <Grid container spacing={2}>
      {!loading && (
        <Grid item xs={12}>
          <Stack direction={'row'} spacing={2} sx={{ alignItems: 'center' }}>
            <Typography>VIEW BY:</Typography>
            <ButtonGroup disableElevation>
              <Button
                onClick={() => setType('bar')}
                variant={type === 'bar' ? 'contained' : 'outlined'}>
                Bar Chart
              </Button>
              <Button
                onClick={() => setType('area')}
                variant={type === 'area' ? 'contained' : 'outlined'}>
                Area Chart
              </Button>
            </ButtonGroup>
          </Stack>
        </Grid>
      )}
      <Grid item xs={12}>
        {type === 'bar' && (
          <ApexChart
            options={options || {}}
            type={'bar'}
            loading={loading}
            data={series}
            height={480}
          />
        )}
        {type === 'area' && (
          <ApexChart
            options={options || {}}
            type={'area'}
            loading={loading}
            data={series}
            height={480}
          />
        )}
      </Grid>
    </Grid>
  );
}

ServiceLevelChart.propTypes = {
  performanceData: PropTypes.object,
  loading: PropTypes.bool,
  dateRangeList: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.string.isRequired
};

ServiceLevelChart.defaultProps = {
  loading: false,
  dateRangeList: [],
  performanceData: null
};

export default ServiceLevelChart;
