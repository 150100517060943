import { DashboardInfoBox, Grid } from '@esgian/esgianui';
import React from 'react';
import PropTypes from 'prop-types';
import { TextWithTooltipIcon } from '@components';
import { formatNumberReadable } from '@helpers';

const Summary = ({ summary, isLoading }) => {
  const infoBoxes = [
    {
      label: 'ETS cost(€)',
      subheader: 'For the voyage',
      value: formatNumberReadable(Number(summary.etsCost).toFixed(0)),
      tooltip: 'Total EU ETS cost corresponding to the CO₂ emission for the voyage.'
    },
    {
      label: 'CO₂e',
      subheader: 'For the voyage',
      value: formatNumberReadable(Number(summary.co2e).toFixed(0)),
      tooltip:
        'Tonnes of CO₂e emission for the voyage. CO₂ equivalents (CO₂e) means the number of metric tons of CO₂ emissions with the same global warming potential as one metric ton of another greenhouse gas. As per ISO 14083 CO₂e Includes CO₂, CH4, CFCs,HFCs,NF3,N2O,PFCs,SF6,SO2F2'
    },
    {
      label: 'CO₂e intensity',
      subheader: 'For the voyage (kgCO2e/t.km)',
      value: summary.con2eIntensity,
      tooltip: 'CO₂e (kg) emitted per ton cargo weight carried per kilometer traveled.'
    },
    {
      label: 'CO₂(t)',
      subheader: 'For the voyage',
      value: formatNumberReadable(Number(summary.co2).toFixed(0)),
      tooltip: 'Tonnes of Carbon Dioxide emitted for the voyage'
    }
  ];

  return (
    <Grid container spacing={2}>
      {infoBoxes.map((infoBox) => (
        <Grid key={infoBox.label} item xs={3}>
          <DashboardInfoBox
            value={infoBox.value}
            header={
              <TextWithTooltipIcon
                labelBold
                labelVariant="subtitle1"
                label={infoBox.label}
                tooltipText={infoBox.tooltip}
              />
            }
            subheader={infoBox.subheader}
          />
        </Grid>
      ))}
    </Grid>
  );
};

Summary.propTypes = {
  summary: PropTypes.shape({
    etsCost: PropTypes.number,
    co2e: PropTypes.number,
    con2eIntensity: PropTypes.number,
    co2: PropTypes.number
  }),
  isLoading: PropTypes.bool
};

export default Summary;
