import React from 'react';
import { DateRangeMobi, Grid, TextField, Typography } from '@esgian/esgianui';
import { OperatorSelect, SelectPortLoadAndDischarge } from '@components/Inputs';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { getDateFormat, getLookupOperators } from '@store/features';
import {
  getOperatorPerformanceFilters,
  updateOperatorPerformanceFilters
} from '@store/features/filters/CommercialAnalyticsPage/OperatorPerformanceSlice/OperatorPerformanceSlice';
import { getMonthDateRangePickerSx, getStartEndMonth } from '@helpers/DatePickerHelpers';
import { useSegment } from '@hooks';

function OperatorPerformanceFilters() {
  const lookupOperators = useSelector(getLookupOperators);
  const dispatch = useDispatch();
  const { segmentMinDate } = useSegment();
  const operatorPerformanceFilters = useSelector(getOperatorPerformanceFilters);
  const dateFormat = useSelector(getDateFormat);

  const {
    selectedPortLoad,
    selectedPortDischarge,
    startDate,
    endDate,
    selectedOperator,
    maxTransitTime,
    minServices
  } = operatorPerformanceFilters;

  const updateFilterValue = (payload) => {
    dispatch(updateOperatorPerformanceFilters(payload));
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item>
          <SelectPortLoadAndDischarge
            handleSwap={(val1, val2) =>
              updateFilterValue({ selectedPortLoad: val1, selectedPortDischarge: val2 })
            }
            setSelectedPortDischarge={(val) => updateFilterValue({ selectedPortDischarge: val })}
            setSelectedPortLoad={(val) => updateFilterValue({ selectedPortLoad: val })}
            selectedPortDischarge={selectedPortDischarge}
            selectedPortLoad={selectedPortLoad}
          />
        </Grid>
        <Grid item className={'section-filter'}>
          <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
            Period*
          </Typography>
          <DateRangeMobi
            sx={getMonthDateRangePickerSx(startDate, endDate)}
            onClose={({ value }) => {
              const { start, end } = getStartEndMonth(value, dateFormat);
              updateFilterValue({
                startDate: start,
                endDate: end
              });
            }}
            dateFormat={dateFormat}
            minStartDate={segmentMinDate}
            disableFuture
            startDate={moment(startDate, dateFormat)}
            endDate={moment(endDate, dateFormat)}
            placeholder={'Period'}
            dateWheels={'MMM YYYY'}
          />
        </Grid>
        <Grid item lg={2.5} md={6} sm={10} className={'section-filter'}>
          <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
            Operator*
          </Typography>
          <OperatorSelect
            placeholder={'Select Operator...'}
            selectedOperator={selectedOperator}
            handleChange={(val) => updateFilterValue({ selectedOperator: val })}
            operatorList={lookupOperators}
          />
        </Grid>
        <Grid item lg={1.5} md={6} sm={10} className={'section-filter'}>
          <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
            Max transit time
          </Typography>
          <TextField
            fullWidth
            placeholder={'Max transit time...'}
            type={'number'}
            value={maxTransitTime || ''}
            onChange={({ target }) => updateFilterValue({ maxTransitTime: target.value })}
          />
        </Grid>
        <Grid item lg={1.5} md={6} sm={10} className={'section-filter'}>
          <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
            Min no. of services
          </Typography>
          <TextField
            fullWidth
            placeholder={'Min no. of services...'}
            type={'number'}
            value={minServices || ''}
            onChange={({ target }) => updateFilterValue({ minServices: target.value })}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

OperatorPerformanceFilters.propTypes = {};

OperatorPerformanceFilters.defaultProps = {};

export default OperatorPerformanceFilters;
