import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useTheme } from '@hooks/useTheme';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

const getPoints = (data, theme) => {
  let points = [];
  const {
    palette: {
      charts: { background },
      text
    }
  } = theme;
  data.forEach(({ averageCIIRating, operatorShortName }) => {
    points.push({
      x: operatorShortName,
      y: 100,
      marker: {
        size: 0
      },
      label: {
        borderWidth: 0,
        text: averageCIIRating,
        style: {
          background: background,
          color: text.primary,
          fontSize: '16px'
        }
      }
    });
  });
  return points;
};

const getOptions = (categories, theme, user, points) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, fiveColorsIndication }
    }
  } = theme;
  return {
    annotations: {
      position: 'front',
      points: points
    },
    chart: {
      type: 'bar',
      height: 350,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      dataLabels: {
        enabled: false,
        formatter: function (val) {
          return `${val}%`;
        }
      },
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: 'operators-cii-chart'
          },
          svg: {
            filename: 'operators-cii-chart'
          },
          png: {
            filename: 'operators-cii-chart'
          }
        }
      },
      stacked: true,
      stackType: '100%'
    },
    colors: fiveColorsIndication,
    theme: {
      mode: mode
    },
    xaxis: {
      categories: categories
    },

    yaxis: {
      title: {
        text: '% of vessels with CII rating'
      },
      decimalsInFloat: 0
    },
    fill: {
      opacity: 1
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        dataLabels: {
          position: 'center'
        }
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      y: {
        formatter: function (value) {
          return `${value}%`;
        }
      }
    },
    legend: {
      position: 'top',
      showForSingleSeries: true,
      horizontalAlign: 'left'
    }
  };
};

function OperatorCiiRatingChart({ loading, ciiRatingData }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const { series = [], options = {} } = useMemo(() => {
    if (!ciiRatingData?.length) return {};
    return {
      series: [
        {
          name: 'A',
          data: ciiRatingData.map(({ percentARating }) => parseInt(percentARating) || 0)
        },
        {
          name: 'B',
          data: ciiRatingData.map(({ percentBRating }) => parseInt(percentBRating) || 0)
        },
        {
          name: 'C',
          data: ciiRatingData.map(({ percentCRating }) => parseInt(percentCRating) || 0)
        },
        {
          name: 'D',
          data: ciiRatingData.map(({ percentDRating }) => parseInt(percentDRating) || 0)
        },
        {
          name: 'E',
          data: ciiRatingData.map(({ percentERating }) => parseInt(percentERating) || 0)
        }
      ],
      options: getOptions(
        ciiRatingData.map(({ operatorShortName }) => operatorShortName),
        theme,
        user,
        getPoints(ciiRatingData, theme)
      )
    };
  }, [ciiRatingData, theme]);

  return (
    <ApexChart loading={loading} height={480} options={options || {}} type={'bar'} data={series} />
  );
}

OperatorCiiRatingChart.propTypes = {
  loading: PropTypes.bool,
  ciiRatingData: PropTypes.arrayOf(PropTypes.object)
};

OperatorCiiRatingChart.defaultProps = {
  ciiRatingData: [],
  loading: false
};

export default OperatorCiiRatingChart;
