import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getVesselLatestPosition,
  getVesselOperatorHistory,
  getVesselOwnerHistory,
  getVesselProfile,
  getVesselNextPortCall,
  getVesselYearlySpeedHistogram,
  getVesselPercentageCo2Emission,
  getVesselCiiRatingData,
  getLatestPortCalls
} from '@api';
import {
  ArrowBackIcon,
  Breadcrumbs,
  ColorBox,
  Grid,
  Link,
  PageHeader,
  Typography
} from '@esgian/esgianui';
import VesselProfileSection from '@components/Sections/ShipAnalytics/VesselProfile';
import { DEFAULT_NUM_PORT_CALLS, GEOGRAPHICAL_TYPES } from '@constants';

function VesselProfilePage() {
  const [vesselsData, setVesselsData] = useState(null);
  const [latestPositionData, setLatestPositionData] = useState(null);
  const [ownerHistoryData, setOwnerHistoryData] = useState(null);
  const [operatorHistoryData, setOperatorHistoryData] = useState(null);
  const [speedHistogramData, setSpeedHistogramData] = useState(null);
  const [portCallData, setPortCallData] = useState(null);
  const [nextPortCallData, setNextPortCallData] = useState(null);
  const [hasEmissionsData, setHasEmissionsData] = useState(false);
  const [hasCiiData, setHasCiiData] = useState(false);
  const [pageCount, setPageCount] = useState(-1);
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const navigate = useNavigate();

  const routeChange = (path) => {
    navigate(path);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    const controller = new AbortController();
    const { signal } = controller;

    const getVesselData = async (imo) => {
      try {
        setLoading(true);
        await Promise.all([
          getVesselProfile({ imo: imo }, signal).then((result) => {
            setVesselsData(result);
          }),
          getVesselYearlySpeedHistogram(imo, signal).then((result) => {
            setSpeedHistogramData(result);
          }),
          getVesselOwnerHistory({ imo: imo }, signal).then((result) => {
            setOwnerHistoryData(result);
          }),
          getVesselLatestPosition(imo, signal).then((result) => {
            setLatestPositionData(result);
          }),
          getVesselOperatorHistory(signal, { imos: [imo] }).then((result) => {
            setOperatorHistoryData(result);
          }),
          getLatestPortCalls(
            {
              geographicalType: GEOGRAPHICAL_TYPES.ALL,
              numberOfPortCalls: DEFAULT_NUM_PORT_CALLS,
              imos: [imo]
            },
            signal
          ).then((result) => {
            setPortCallData(result);
          }),
          getVesselNextPortCall(signal, imo, 1.0).then((result) => {
            setNextPortCallData(result);
          }),
          getVesselPercentageCo2Emission(imo, signal)
            .then(() => {
              setHasEmissionsData(true);
            })
            .catch(() => {
              setHasEmissionsData(false);
            }),
          getVesselCiiRatingData(imo, 'monthly', signal)
            .then((result) => {
              const { emissionExistsFlag } = result;
              setHasCiiData(emissionExistsFlag);
            })
            .catch(() => {
              setHasCiiData(false);
            })
        ]);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };
    getVesselData(params.imo);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const getAllLatestPortCallsDataForUnknownOperators = async () => {
      await getLatestPortCalls({
        geographicalType: GEOGRAPHICAL_TYPES.ALL,
        numberOfPortCalls: DEFAULT_NUM_PORT_CALLS,
        imos: [vesselsData.imo],
        AllPortCalls: true
      })
        .then((result) => {
          setPortCallData(result);
        })
        .catch((error) => {
          console.log('Error in getting all latest port calls', error);
        });
    };
    if (vesselsData && !vesselsData.operatorId) {
      getAllLatestPortCallsDataForUnknownOperators();
    }
  }, [vesselsData]);

  const { vesselName } = vesselsData || {};
  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item>
        <ColorBox onClick={() => routeChange(pageCount)}>
          <ArrowBackIcon fontSize={'small'} />
        </ColorBox>
      </Grid>
      <Grid item>
        <PageHeader
          id={'lbl-vessel-profile'}
          loading={loading || !vesselName}
          title={`${vesselName}`}
          className={'vessel-profile-page-header'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                id={'home-breadcrumb'}
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange('/${uriExt}/commercial-analytics')}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                id={'commercial-analytics-breadcrumb'}
                onClick={() => routeChange('/${uriExt}/commercial-analytics')}>
                Commercial Analytics
              </Link>
              <Typography color="primary" id={'vessel-profile'}>
                Vessel Profile
              </Typography>
            </Breadcrumbs>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <VesselProfileSection
          hasCiiData={hasCiiData}
          hasEmissionsData={hasEmissionsData}
          loading={loading}
          pageCount={pageCount}
          vesselsData={vesselsData}
          setPageCount={setPageCount}
          portCallData={portCallData}
          setPortCallData={setPortCallData}
          nextPortCallData={nextPortCallData}
          ownerHistoryData={ownerHistoryData}
          latestPositionData={latestPositionData}
          operatorHistoryData={operatorHistoryData}
          speedHistogramData={speedHistogramData}
        />
      </Grid>
    </Grid>
  );
}

VesselProfilePage.propTypes = {};

VesselProfilePage.defaultProps = {};

export default VesselProfilePage;
