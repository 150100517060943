import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  InfoBox,
  Link,
  Paper,
  ShipsIcon,
  Skeleton,
  StorageIcon,
  Tab,
  Tabs,
  TrendingUpIcon,
  Typography,
  CountryFlag
} from '@esgian/esgianui';
import { VesselsTable, VesselPortCallsTable } from '@components/Tables';
import { VesselsMap } from '@components/Maps';
import { useSearchParams } from 'react-router-dom';

import ValueDisplay from '@components/ValueDisplay';
import { MAP_STYLE_DARK_MODE, MAP_STYLE_LIGHT_MODE } from '@constants';
import { useTheme } from '@hooks';

function OwnerProfileSection({ setLastPortCalls, vessels, ownerData, loading, lastPortCalls }) {
  const [tabSection, setTabSection] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const { theme } = useTheme();

  const handleSectionChange = (_, val) => {
    setTabSection(val);
    searchParams.set('section', val);
    setSearchParams(searchParams);
  };

  useEffect(() => {
    setTabSection(parseInt(searchParams.get('section') || 1));
  }, []);

  const {
    country,
    address,
    website,
    averageAge,
    averageCapacity,
    fleetSize,
    totalCapacity,
    ownerLongName,
    ownerShortName,
    ownerVessels,
    isoAlpha2
  } = ownerData || {};
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <InfoBox
          colorSecondary={'#63d5fa'}
          colorMain={'#61efbf'}
          headerColor={'#63d5fa'}
          loading={loading}
          mainValue={fleetSize || '0'}
          icon={<ShipsIcon sx={{ fill: '#fff', mb: 1 }} fontSize={'large'} color={'inherit'} />}
          subHeader={'Vessels'}
          header={'Fleet Size'}
        />
      </Grid>
      <Grid item xs={3}>
        <InfoBox
          colorSecondary={'#57ed80'}
          colorMain={'#57edd1'}
          headerColor={'#57ed80'}
          loading={loading}
          mainValue={averageAge ? averageAge.toFixed(1) : '-'}
          icon={<TrendingUpIcon fontSize={'large'} color={'inherit'} />}
          subHeader={'Years'}
          header={'Average Age'}
        />
      </Grid>
      <Grid item xs={3}>
        <InfoBox
          colorSecondary={'#66a6ff'}
          colorMain={'#89f7fe'}
          headerColor={'#66a6ff'}
          loading={loading}
          mainValue={averageCapacity ? parseFloat(averageCapacity.toFixed(0)) : '-'}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={'CEU'}
          header={'Average Capacity'}
        />
      </Grid>
      <Grid item xs={3}>
        <InfoBox
          loading={loading}
          mainValue={totalCapacity || '-'}
          icon={<StorageIcon fontSize={'large'} color={'inherit'} />}
          subHeader={'CEU'}
          header={'Total Capacity'}
          colorSecondary={'#4faefd'}
          colorMain={'#5d3afc'}
          headerColor={'#4faefd'}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Tabs value={tabSection} onChange={handleSectionChange} variant="scrollable">
            <Tab value={1} label="Overview" />
            <Tab value={2} label="Activity" />
          </Tabs>
        </Paper>
      </Grid>
      {tabSection === 1 && (
        <>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Paper sx={{ p: 2, height: '100%' }}>
                  <Grid container spacing={2}>
                    <Grid item container spacing={2} xs={12}>
                      <Grid item alignSelf={'center'}>
                        <Typography variant={'h6'}>Overview</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid container item xs={6}>
                      <Grid container direction={'column'} spacing={1}>
                        <ValueDisplay title={'Name'} value={ownerLongName} loading={loading} />
                        <ValueDisplay
                          title={'Short Name'}
                          value={ownerShortName}
                          loading={loading}
                        />
                        <ValueDisplay title={'Country'} value={ownerShortName} loading={loading}>
                          <Grid item container spacing={1}>
                            <Grid item sx={{ placeSelf: 'flex-end' }}>
                              <CountryFlag size={20} countryCode={isoAlpha2} />
                            </Grid>
                            <Grid item xs={6}>
                              <Typography variant={'body2'}>{`${country}`}</Typography>
                            </Grid>
                          </Grid>
                        </ValueDisplay>
                        <ValueDisplay title={'Address'} value={address} loading={loading} />
                        <ValueDisplay title={'Website'} value={address} loading={loading}>
                          <Link target={'__blank'} href={website}>
                            {website || ''}
                          </Link>
                        </ValueDisplay>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={4}>
                {loading ? (
                  <Skeleton variant={'rectangular'} height={'40vh'} />
                ) : (
                  <Grid container>
                    <Grid item xs={12}>
                      <VesselsMap
                        showSpeedLegend={true}
                        zoom={0}
                        projection={'mercator'}
                        mapStyle={
                          theme.mode === 'dark' ? MAP_STYLE_DARK_MODE : MAP_STYLE_LIGHT_MODE
                        }
                        vessels={vessels}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <VesselsTable
                hideColumns={[
                  'grossTonnage',
                  'deadweight',
                  'ownerLongName',
                  'maxLiftingCapacity',
                  'numCranes'
                ]}
                title={'Owner Vessels'}
                loading={loading}
                vessels={ownerVessels}
                fileName={`fleet-list-${ownerShortName}`}
              />
            </Paper>
          </Grid>
        </>
      )}
      {tabSection === 2 && (
        <Grid item xs={12}>
          <VesselPortCallsTable
            assetLevel={'owner'}
            setPortCallData={setLastPortCalls}
            name={ownerShortName}
            hideColumns={['operatorName', 'countryName']}
            portCalls={lastPortCalls}
            loading={loading}
            title={'Last Port Calls'}
          />
        </Grid>
      )}
    </Grid>
  );
}

OwnerProfileSection.propTypes = {
  ownerData: PropTypes.object,
  vessels: PropTypes.arrayOf(PropTypes.object),
  lastPortCalls: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  setLastPortCalls: PropTypes.func.isRequired
};

OwnerProfileSection.defaultProps = {
  ownerData: null,
  lastPortCalls: null,
  loading: false,
  vessels: []
};

export default OwnerProfileSection;
