import { useEffect, useState } from 'react';
import { createNewVoyageGroup, getSavedVoyageGroups, updateVoyageGroup } from '../../../api/Voyage';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features/auth/AuthSlice.js';
import { toast } from 'react-toastify';
import { generateVoyageIdFromSavedVoyage } from '../VoyageGroupPage/VoyageGroupPage';

export const useVoyageGroups = () => {
  const user = useSelector(getUser);
  const email = user?.email;
  const [isLoadingVoyageGroups, setIsLoadingVoyageGroups] = useState(false);
  const [savedVoyageGroups, setSavedVoyageGroups] = useState([]);
  const [isDeletingVoyageGroup, setIsDeletingVoyageGroup] = useState(false);
  const [isDeletingVoyageFromGroup, setIsDeletingVoyageFromGroup] = useState(false);
  const [isRenamingVoyage, setIsRenamingVoyage] = useState(false);
  const [isGroupBeingCreated, setIsGroupBeingCreated] = useState(false);
  const controller = new AbortController();
  const { signal } = controller;

  const fetchSavedVoyageGroups = async () => {
    setIsLoadingVoyageGroups(true);
    try {
      const res = await getSavedVoyageGroups(email, signal);
      setSavedVoyageGroups(JSON.parse(res.userInformation));
    } catch (error) {
      console.log('error', error);
      setSavedVoyageGroups([]);
    } finally {
      setIsLoadingVoyageGroups(false);
    }
  };

  useEffect(() => {
    fetchSavedVoyageGroups();
  }, []);

  const deleteVoyageGroup = async (voyageToDelete) => {
    try {
      setIsDeletingVoyageGroup(true);
      const updated = savedVoyageGroups.filter((v) => v.id !== voyageToDelete.id);
      await updateVoyageGroup(email, updated, signal);
      toast.success(`Voyage group "${voyageToDelete.name}" deleted successfully.`);
      await fetchSavedVoyageGroups();
    } catch (error) {
      toast.error(`Could not delete the voyage: $ `);
    }
    setIsDeletingVoyageGroup(false);
  };

  const renameVoyageGroup = async (voyageToRename, newName) => {
    setIsRenamingVoyage(true);
    try {
      const updated = savedVoyageGroups.map((v) =>
        v.id === voyageToRename.id ? { ...v, name: newName } : v
      );
      await updateVoyageGroup(email, updated, signal);
      toast.success(`Voyage group "${voyageToRename.name}" renamed to "${newName}" successfully.`);
      await fetchSavedVoyageGroups();
    } catch (error) {
      toast.error(`Could not rename the voyage: ${voyageToRename.name}`);
    }
    setIsRenamingVoyage(false);
  };

  const createNewGroup = async (newGroupName) => {
    setIsGroupBeingCreated(true);
    try {
      await createNewVoyageGroup(email, newGroupName, savedVoyageGroups, signal);
      await fetchSavedVoyageGroups();
      toast.success(`Voyage group "${newGroupName}" created successfully.`);
    } catch (error) {
      toast.error(`Could not create the new voyage group: ${newGroupName}`);
      console.error(error);
    }
    setIsGroupBeingCreated(false);
  };

  const deleteVoyageFromGroup = async (groupId, voyageId, refetch) => {
    const updatedVoyageGroups = savedVoyageGroups.map((group) => {
      if (group.id == groupId) {
        return {
          ...group,
          voyages: group.voyages.filter(
            (voyage) => generateVoyageIdFromSavedVoyage(voyage) !== voyageId
          )
        };
      }
      return group;
    });

    try {
      setIsDeletingVoyageFromGroup(true);
      await updateVoyageGroup(email, updatedVoyageGroups, signal);
      toast.success(`Voyage "${voyageId}" deleted from group "${groupId}" successfully.`);
      refetch();
      await fetchSavedVoyageGroups();
    } catch (error) {
      toast.error(`Could not delete the voyage: ${voyageId} from group: ${groupId}`);
    } finally {
      setIsDeletingVoyageFromGroup(false);
    }
  };

  return {
    isLoadingVoyageGroups,
    savedVoyageGroups,
    deleteVoyageGroup,
    isDeletingVoyageGroup,
    isRenamingVoyage,
    renameVoyageGroup,
    createNewGroup,
    isGroupBeingCreated,
    refetch: fetchSavedVoyageGroups,
    deleteVoyageFromGroup,
    isDeletingVoyageFromGroup
  };
};
