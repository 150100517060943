import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete,
  CircularProgress,
  TextField,
  Chip,
  Divider,
  Grid,
  ListItem,
  Button,
  Checkbox
} from '@esgian/esgianui';
import { useTheme } from '@hooks';

function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function VesselSelect({
  handleChange,
  placeholder,
  selectedVessels,
  vesselList,
  disabled,
  error,
  helperText,
  limitTags,
  id,
  width
}) {
  const [vessels, setVessels] = useState([]);
  const { customScrollbar } = useTheme();

  useEffect(() => {
    if (vesselList) {
      const sortedVessels = [...vesselList].sort(compare);
      sortedVessels[0] = { ...sortedVessels[0], first: true }; // Set the first option to have first: true
      setVessels(sortedVessels);
    }
  }, [vesselList]);

  const handleClearAll = () => {
    handleChange(null, []);
  };

  return (
    <Autocomplete
      ListboxProps={{
        sx: { ...customScrollbar }
      }}
      componentsProps={{
        paper: {
          sx: {
            width: width || '350px'
          }
        }
      }}
      id={'vessels-select'}
      disabled={disabled}
      isOptionEqualToValue={(option, value) => {
        if (vessels.length) {
          return option.id === value.id;
        }
        return false;
      }}
      value={vessels?.length ? selectedVessels : []}
      disableCloseOnSelect
      onChange={handleChange}
      renderOption={(props, option, { selected }) => {
        return (
          <React.Fragment key={`vessel-${option.id}`}>
            {option?.first && (
              <Grid container spacing={2} sx={{ p: 2 }}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    id={'vessel-check-all-btn'}
                    onClick={() => handleChange(null, vessels)}
                    variant={'contained'}
                    color={'primary'}>
                    Check all
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    id={'clear-vessels-btn'}
                    onClick={handleClearAll}
                    variant={'outlined'}
                    color={'secondary'}>
                    Clear
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            )}
            {!option?.all && (
              <ListItem {...props}>
                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                {option.name}
              </ListItem>
            )}
          </React.Fragment>
        );
      }}
      sx={{ width: width || '250px' }}
      multiple
      options={vessels}
      limitTags={limitTags}
      getOptionLabel={(option) => option.name}
      renderTags={(value) => {
        if (value.length === vessels.length) {
          return 'All';
        }
        let i = 0;
        return value.map(({ name }) => {
          if (!name) {
            return;
          }
          if (i === limitTags) {
            i++;
            return `+${value.length - limitTags}`;
          } else if (i < limitTags) {
            i++;
            return <Chip size={'small'} key={`chip-${name}`} label={name} />;
          }
        });
      }}
      filterOptions={(options, state) =>
        options.filter(
          ({ name, id }) =>
            name?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            id?.toString() === state.inputValue
        )
      }
      renderInput={(params) => (
        <TextField
          sx={{ minWidth: width || '250px' }}
          id={id}
          error={error}
          helperText={helperText}
          placeholder={selectedVessels?.length >= 1 ? '' : placeholder}
          {...params}
          label="Select Vessels"
        />
      )}
    />
  );
}

VesselSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  limitTags: PropTypes.number,
  placeholder: PropTypes.string,
  selectedVessels: PropTypes.arrayOf(PropTypes.object),
  vesselList: PropTypes.arrayOf(PropTypes.object),
  id: PropTypes.string,
  width: PropTypes.string
};

VesselSelect.defaultProps = {
  disabled: false,
  error: false,
  helperText: null,
  limitTags: 1,
  placeholder: '',
  selectedVessels: [],
  vesselList: [],
  id: 'vessel-select'
};

export default VesselSelect;
