import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchLookupLocations,
  fetchLookupOperators,
  fetchLookupPorts,
  fetchLookupRegions,
  fetchOperatorVesselHierarchy,
  fetchOwnerOperatorVesselHierarchy,
  fetchVessels
} from '@api';
import { REGION_TYPES } from '@constants';
const importOrder = ['Region', 'Country', 'Port'];

const sortByLocation = importOrder.reduce((obj, item, index) => {
  return {
    ...obj,
    [item]: index
  };
}, {});

function sortRegions(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

function locationSort(a, b) {
  return a?.name?.localeCompare(b?.name);
}

function compareOperator(a, b) {
  if (a.operatorLongName < b.operatorLongName) {
    return -1;
  }
  if (a.operatorLongName > b.operatorLongName) {
    return 1;
  }
  return 0;
}
function comparePort(a, b) {
  if (a.portName < b.portName) {
    return -1;
  }
  if (a.portName > b.portName) {
    return 1;
  }
  return 0;
}

const STATUSES = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
};

const initialState = {
  generalStatus: STATUSES.IDLE,
  segmentStatus: STATUSES.IDLE,
  lookupOperators: [],
  lookupVesselHierarchy: {
    ownerHierarchy: [],
    vessels: [],
    operatorHierarchy: []
  },
  lookupPorts: [],
  lookupRegions: [],
  lookupLocations: []
};

export const fetchGeneralLookups = createAsyncThunk(
  'data/fetchGeneralLookups',
  async ({ signal }) => {
    let responses = [];
    try {
      responses = await Promise.all([
        fetchLookupRegions({ regionTypes: [REGION_TYPES.COMMERCIAL_REGIONS_SHIPS] }, signal),
        fetchLookupLocations(signal),
        fetchOwnerOperatorVesselHierarchy(signal)
      ]);
    } catch (error) {
      console.log(error);
    }
    const regionData = responses[0];
    const locations = responses[1];
    const { ownerOperatorAssetDetailsList = [] } = responses[2];
    return {
      lookupRegions: regionData,
      lookupLocations: locations,
      ownerHierarchy: ownerOperatorAssetDetailsList
    };
  }
);

export const fetchSegmentLookups = createAsyncThunk(
  'data/fetchSegmentLookups',
  async ({ signal, segment }) => {
    let responses = [];
    try {
      responses = await Promise.all([
        fetchLookupOperators(signal, segment.id),
        fetchLookupPorts({ SegmentTypeId: segment.id }, signal),
        fetchOperatorVesselHierarchy(signal, segment.id),
        fetchVessels(signal, segment.id)
      ]);
    } catch (error) {
      console.log(error);
    }
    const { operatorAssetDetailsList = [] } = responses[2];
    const { assetDetailsList = [] } = responses[3];
    return {
      vessels: assetDetailsList,
      operatorHierarchy: operatorAssetDetailsList,
      lookupOperators: responses[0],
      lookupPorts: responses[1]
    };
  }
);

const DataSlice = createSlice({
  name: 'lookupData',
  initialState: initialState,
  reducers: {
    resetData: () => initialState,
    resetSegmentData: (state) => {
      state.segmentStatus = STATUSES.IDLE;
      state.lookupPorts = [];
      state.lookupOperators = [];
      state.lookupVesselHierarchy.vessels = [];
      state.lookupVesselHierarchy.operatorHierarchy = [];
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGeneralLookups.pending, (state) => {
        state.generalStatus = STATUSES.LOADING;
      })
      .addCase(fetchGeneralLookups.fulfilled, (state, action) => {
        state.generalStatus = STATUSES.SUCCEEDED;
        state.lookupRegions = action.payload.lookupRegions?.sort(sortRegions);
        state.lookupLocations = action.payload.lookupLocations
          ?.sort(locationSort)
          ?.sort((a, b) => sortByLocation[a.type] - sortByLocation[b.type]);
        state.lookupVesselHierarchy.ownerHierarchy = action.payload.ownerHierarchy;
      })
      .addCase(fetchGeneralLookups.rejected, (state) => {
        state.generalStatus = STATUSES.FAILED;
      })
      .addCase(fetchSegmentLookups.pending, (state) => {
        state.segmentStatus = STATUSES.LOADING;
      })
      .addCase(fetchSegmentLookups.fulfilled, (state, action) => {
        state.segmentStatus = STATUSES.SUCCEEDED;
        state.lookupPorts = action.payload.lookupPorts?.sort(comparePort);
        state.lookupOperators = action.payload.lookupOperators?.sort(compareOperator);
        state.lookupVesselHierarchy.vessels = action.payload.vessels;
        state.lookupVesselHierarchy.operatorHierarchy = action.payload.operatorHierarchy;
      })
      .addCase(fetchSegmentLookups.rejected, (state) => {
        state.segmentStatus = STATUSES.FAILED;
      });
  }
});

export const getLookupData = (state) => state.lookupData;
export const getLookupOperators = (state) => {
  return state.lookupData.lookupOperators;
};
export const getLookupVesselHierarchy = (state) => state.lookupData.lookupVesselHierarchy;
export const getLookupPorts = (state) => state.lookupData.lookupPorts;
export const getLookupRegions = (state) => state.lookupData.lookupRegions;
export const getLookupLocations = (state) => state.lookupData.lookupLocations;

export const getGeneralLookupLoading = (state) =>
  [STATUSES.LOADING, STATUSES.IDLE].includes(state.lookupData.generalStatus);

export const getSegmentLookupLoading = (state) =>
  [STATUSES.LOADING, STATUSES.IDLE].includes(state.lookupData.segmentStatus);

export const { resetData, resetSegmentData } = DataSlice.actions;

export default DataSlice.reducer;
