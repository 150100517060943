import React, { useState } from 'react';
import {
  Badge,
  Button,
  DateRangeMobi,
  Divider,
  FilterIcon,
  Grid,
  Typography
} from '@esgian/esgianui';
import moment from 'moment';
import LocationLoadAndDischargeSelect from '@components/Inputs/LocationLoadAndDischargeSelect/LocationLoadAndDischargeSelect';
import AdvancedFilters from '@components/Sections/ShipAnalytics/PortServiceLevelSection/PortServiceLevelFilters/AdvancedFilters';
import { useDispatch, useSelector } from 'react-redux';
import {
  getServiceLevelFilterCount,
  getServiceLevelFilters,
  updateServiceLevelFilters
} from '@store/features/filters/CommercialAnalyticsPage/ServiceLevelSlice/ServiceLevelSlice';
import { getDateFormat } from '@store/features';
import { getMonthDateRangePickerSx, getStartEndMonth } from '@helpers/DatePickerHelpers';
import { useSegment } from '@hooks';

function PortServiceLevelFilters() {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const dispatch = useDispatch();
  const serviceLevelFilters = useSelector(getServiceLevelFilters);
  const dateFormat = useSelector(getDateFormat);
  const filterCount = useSelector(getServiceLevelFilterCount);
  const { segmentMinDate } = useSegment();
  const updateFilterValue = (payload) => {
    dispatch(updateServiceLevelFilters(payload));
  };

  const { startDate, endDate, selectedLocationLoad, selectedLocationDischarge } =
    serviceLevelFilters;

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item>
          <LocationLoadAndDischargeSelect
            selectedLocationOfDischarge={selectedLocationDischarge}
            setSelectedLocationOfDischarge={(val) =>
              updateFilterValue({ selectedLocationDischarge: val })
            }
            selectedLocationOfLoad={selectedLocationLoad}
            setSelectedLocationOfLoad={(val) => updateFilterValue({ selectedLocationLoad: val })}
            handleSwap={(val1, val2) =>
              updateFilterValue({ selectedLocationLoad: val1, selectedLocationDischarge: val2 })
            }
          />
        </Grid>
        <Grid item className={'section-filter'}>
          <Grid container direction={'column'}>
            <Grid item>
              <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
                Period*
              </Typography>
            </Grid>
            <Grid item>
              <DateRangeMobi
                sx={getMonthDateRangePickerSx(startDate, endDate)}
                onClose={({ value }) => {
                  const { start, end } = getStartEndMonth(value, dateFormat);
                  updateFilterValue({
                    startDate: start,
                    endDate: end
                  });
                }}
                dateFormat={dateFormat}
                minStartDate={segmentMinDate}
                disableFuture
                startDate={moment(startDate, dateFormat)}
                endDate={moment(endDate, dateFormat)}
                placeholder={'Period'}
                dateWheels={'MMM YYYY'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item alignSelf={'end'} sx={{ pb: '0.7em' }}>
          <Badge badgeContent={filterCount} color="error">
            <Button
              startIcon={<FilterIcon />}
              variant={'text'}
              onClick={() => setAdvancedOpen(true)}>
              Advanced Filter
            </Button>
          </Badge>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <AdvancedFilters
          updateFilterValue={updateFilterValue}
          advancedOpen={advancedOpen}
          setAdvancedOpen={setAdvancedOpen}
        />
      </Grid>
    </Grid>
  );
}

PortServiceLevelFilters.propTypes = {};

PortServiceLevelFilters.defaultProps = {};

export default PortServiceLevelFilters;
