import { combineReducers } from '@reduxjs/toolkit';
import createFleetCiiFilterSlice from '@store/features/filters/EmissionAnalyticsPage/FleetCiiRatingSlice/FleetCiiRatingSlice';
import createCo2EmissionsSlice from '@store/features/filters/EmissionAnalyticsPage/Co2EmissionsSlice/Co2EmissionsSlice';
import createSectionSlice from '@store/features/filters/SectionSlice/SectionSlice';

const FleetCiiSlice = createFleetCiiFilterSlice('vesselFleetCii');
const Co2EmissionsSlice = createCo2EmissionsSlice('vesselCo2Emissions');
const VesselSectionSlice = createSectionSlice('vesselSectionSlice');

const VesselComparisonReducer = combineReducers({
  section: VesselSectionSlice.reducer,
  fleetCiiRating: FleetCiiSlice.reducer,
  co2Emissions: Co2EmissionsSlice.reducer
});

export const { setSection: setVesselCompSection } = VesselSectionSlice.actions;
export const {
  updateFilters: updateVslFleetCiiFilters,
  resetAdvancedFilters: resetVslFleetCiiAdvFilters,
  resetFilters: resetVslFleetCiiFilters
} = FleetCiiSlice.actions;
export const {
  setDisplayUnit: setVslEmissionsDisplayUnit,
  updateFilters: updateVslCo2EmissionsFilters,
  resetAdvancedFilters: resetVslCo2EmissionsAdvFilters,
  resetFilters: resetVslCo2EmissionsFilters
} = Co2EmissionsSlice.actions;
export default VesselComparisonReducer;
