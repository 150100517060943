import React, { useMemo, useState } from 'react';

import PropTypes from 'prop-types';
import { Grid, FormControl, Select, MenuItem, InputLabel, Paper, Divider } from '@esgian/esgianui';
import MonthlyPortWaitingTable from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortWaitingTimeSection/MonthlyPortWaitingTable';
import WeeklyPortWaitingChart from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortWaitingTimeSection/WeeklyPortWaitingChart';
import MonthlyPortWaitingChart from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortWaitingTimeSection/MonthlyPortWaitingChart';
import moment from 'moment';

function PortWaitingTimeSection({ portCongestionData, loading, portName }) {
  const [measureSelect, setMeasureSelect] = useState(1);
  const [selectedYear, setSelectedYear] = useState(moment().year());

  function getMeasureInfo(measureSelect) {
    switch (measureSelect) {
      case 1:
        return { id: 1, type: 'average port waiting time', unit: 'Hours' };
      case 2:
        return { id: 2, type: 'total port waiting time', unit: 'Days' };
      case 3:
        return { id: 3, type: 'CEU capacity', unit: 'CEU' };
      case 4:
        return { id: 4, type: 'number of anchor events', unit: 'Anchor events' };
      default:
        return { type: 'waiting-time', unit: '' };
    }
  }

  const yearOptions = useMemo(() => {
    if (!portCongestionData) return [];
    const { operatorCongestions } = portCongestionData;
    let years = operatorCongestions.length ? operatorCongestions[0].years : [];
    return years.map(({ year }) => year);
  }, [portCongestionData]);

  return (
    <Paper variant={'outlined'} sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid item lg={5.5} className={'section-filter'}>
            <Grid item xs={8}>
              <FormControl variant="outlined" sx={{ minWidth: 120 }} size="small">
                <InputLabel id="measure-select-label">Measure</InputLabel>
                <Select
                  value={measureSelect}
                  labelId="port-waiting-tab-measure-select-label"
                  variant="outlined"
                  label={'Measure'}
                  onChange={(event) => setMeasureSelect(parseInt(event.target.value))}
                  id="port-waiting-tab-measure-select"
                  inputProps={{ 'aria-label': 'Without label' }}>
                  <MenuItem value={1}>Avg. waiting time</MenuItem>
                  <MenuItem value={2}>Total waiting time</MenuItem>
                  <MenuItem value={3}>CEU Capacity</MenuItem>
                  <MenuItem value={4}>No. of anchor events</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2, pb: 2 }}>
            <Divider />
          </Grid>
          <WeeklyPortWaitingChart
            portCongestionData={portCongestionData}
            loading={loading}
            selectedMeasure={getMeasureInfo(measureSelect)}
            fileName={`${portName?.split(' ').join('-')}-weekly-${
              getMeasureInfo(measureSelect).type
            }`}
          />
          <MonthlyPortWaitingChart
            portCongestionData={portCongestionData}
            loading={loading}
            selectedMeasure={getMeasureInfo(measureSelect)}
            fileName={`${portName?.split(' ').join('-')}-monthly-${
              getMeasureInfo(measureSelect).type
            }`}
          />
          <MonthlyPortWaitingTable
            portCongestionData={portCongestionData}
            loading={loading}
            yearOptions={yearOptions}
            setSelectedYear={setSelectedYear}
            selectedYear={selectedYear}
            selectedMeasure={getMeasureInfo(measureSelect)}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

PortWaitingTimeSection.propTypes = {
  portCongestionData: PropTypes.object,
  loading: PropTypes.bool,
  portName: PropTypes.string
};

PortWaitingTimeSection.defaultProps = {
  portCongestionData: null,
  loading: false,
  portName: ''
};

export default PortWaitingTimeSection;
