import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, CircularProgress, TextField } from '@esgian/esgianui';

function OperatorSelect({ placeholder, operatorList, handleChange, selectedOperator }) {
  return (
    <Autocomplete
      fullWidth
      onChange={(_, operator) => handleChange(operator)}
      disableClearable
      disablePortal
      disabled={!operatorList.length}
      getOptionLabel={(option) => option.operatorLongName}
      id="company-select"
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      filterOptions={(operators, state) => {
        return operators.filter(
          ({ operatorLongName, operatorShortName, id }) =>
            operatorLongName?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            operatorShortName?.toLowerCase().includes(state.inputValue.toLowerCase()) ||
            id?.toString() === state.inputValue
        );
      }}
      value={selectedOperator}
      options={operatorList || []}
      renderInput={(params) => (
        <TextField
          placeholder={placeholder}
          fullWidth
          {...params}
          label={operatorList.length ? '' : <CircularProgress size={25} />}
        />
      )}
    />
  );
}

OperatorSelect.propTypes = {
  placeholder: PropTypes.string,
  operatorList: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func.isRequired,
  selectedOperator: PropTypes.object
};

OperatorSelect.defaultProps = {
  placeholder: '',
  selectedCompany: {},
  companiesList: []
};

export default OperatorSelect;
