import React, { useEffect, useState } from 'react';
import { Grid, NotFound, Paper, Typography, Stack } from '@esgian/esgianui';
import moment from 'moment/moment';
import { getVesselCiiRating } from '@api/Emissions';
import VesselComparisonSectionsFilter from '@components/Sections/EmissionsAnalytics/VesselComparisonSection/VesselComparisonSectionsFilter';
import { OPERATOR_SELECT_TYPES, OPERATOR_TYPE } from '@constants';
import {
  VesselCiiRatingChart,
  VesselCiiRatingTable
} from '@components/Sections/EmissionsAnalytics/VesselComparisonSection/VesselCiiRatingSection/index';
import { AccreditationStatementModal } from '@components/Modals';
import EmissionsDisclaimer from '../../../../Display/EmissionsDisclaimer';
import { useSegment } from '@hooks';
import { useSelector } from 'react-redux';
import { getVslFleetCiiFilters } from '@store/features/filters/EmissionAnalyticsPage/FleetCiiRatingSlice/FleetCiiRatingSlice';

function VesselCiiRatingSection() {
  const [ciiRatingData, setCiiRatingData] = useState(null);
  const [showStatement, setShowStatement] = useState(false);
  const [fetchDataSwitch, setFetchDataSwitch] = useState(false);
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(true);
  const { segment } = useSegment();
  const fleetCiiFilters = useSelector(getVslFleetCiiFilters);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getCiiRatingData = async () => {
      const {
        startDate,
        endDate,
        selectType,
        maxCapacity,
        minCapacity,
        minBuildYear,
        maxBuildYear,
        selectedAssets
      } = fleetCiiFilters;
      try {
        if (!init || !moment(startDate).isValid() || !moment(endDate).isValid()) {
          return;
        }

        let vesselIds = null;

        if (selectType === OPERATOR_SELECT_TYPES.MANUAL && !selectedAssets.length) {
          setNoData(true);
          setCiiRatingData(null);
          return;
        }
        if (selectType === OPERATOR_SELECT_TYPES.MANUAL && selectedAssets.length) {
          vesselIds = selectedAssets
            .filter(({ id, type }) => {
              if (type === 'vessel') {
                return id;
              }
            })
            .map(({ id }) => id);
        }

        setLoading(true);
        setNoData(false);
        const response = await getVesselCiiRating(
          startDate,
          endDate,
          vesselIds,
          minCapacity,
          maxCapacity,
          minBuildYear,
          maxBuildYear,
          segment.id,
          OPERATOR_TYPE.ALL,
          signal
        );
        if (!response) {
          setNoData(true);
          setCiiRatingData(null);
        } else {
          setCiiRatingData(response);
          setNoData(false);
        }
        setLoading(false);
      } catch (error) {
        setNoData(true);
        setLoading(false);
        setCiiRatingData(null);
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    getCiiRatingData();
    return () => {
      controller.abort();
    };
  }, [fleetCiiFilters, fetchDataSwitch, init, segment]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Stack>
            <Typography sx={{ pl: 2, pt: 2 }} variant={'h6'}>
              Vessel CII rating comparison
            </Typography>
            <VesselComparisonSectionsFilter
              filters={fleetCiiFilters}
              init={init}
              setInit={setInit}
              setFetchDataSwitch={setFetchDataSwitch}
              fetchDataSwitch={fetchDataSwitch}
            />
            <Stack sx={{ pl: 2, pr: 2 }} spacing={2}>
              {!loading && noData && (
                <Grid item xs={12}>
                  <NotFound
                    header={'No Matching Result'}
                    text={'Please change your search parameters'}
                  />
                </Grid>
              )}

              {!noData && (
                <>
                  <EmissionsDisclaimer setShowStatement={setShowStatement} />
                  <VesselCiiRatingChart ciiRatingData={ciiRatingData} loading={loading} />
                </>
              )}
            </Stack>
            {!noData && <VesselCiiRatingTable ciiRatingData={ciiRatingData} loading={loading} />}
          </Stack>
        </Paper>
      </Grid>
      <AccreditationStatementModal
        handleClose={() => setShowStatement(false)}
        open={showStatement}
      />
    </Grid>
  );
}

VesselCiiRatingSection.propTypes = {};

VesselCiiRatingSection.defaultProps = {};

export default VesselCiiRatingSection;
