import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Grid, Typography } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const getOptions = (categories, theme, user, fileName) => {
  const {
    palette: {
      mode,
      charts: { background, sixColorsStacked }
    }
  } = theme;

  return {
    chart: {
      type: 'bar',
      height: 350,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      background: background,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: true
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val?.toFixed(0);
      }
    },
    theme: {
      mode: mode
    },

    xaxis: {
      categories: categories,
      labels: {
        rotate: -45,
        rotateAlways: true
      }
    },
    fill: {
      opacity: 1
    },
    colors: sixColorsStacked,
    yaxis: {
      decimalsInFloat: 0,
      max: 100,
      title: {
        text: '% of time'
      }
    },
    labels: {
      formatter: function (val) {
        return `${val?.toFixed(0)}%`;
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      y: {
        formatter: function (value) {
          if (value !== null && value !== undefined) {
            return value.toFixed(0) + '%';
          }
          return ' - ';
        }
      }
    },
    plotOptions: {
      bar: {
        columnWidth: '75%',
        borderRadius: 0,
        horizontal: false
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      itemMargin: {
        horizontal: 10,
        vertical: 5
      }
    },
    noData: {
      text: 'There is no data to display'
    }
  };
};
function GlobalFleetActivityDevelopmentChart({ loading, globalFleetActivity, fileName }) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const { series = [], details = {} } = useMemo(() => {
    if (!globalFleetActivity) return [];

    const {
      segmentMonthlyActivityStatusChange = [],
      segmentMonthlyPercentActivityStatusCountDict = []
    } = globalFleetActivity;
    const previousYear = moment().subtract(1, 'year').year().toString();
    const currentYear = moment().year().toString();

    let tempDetails = {};

    Object.keys(segmentMonthlyActivityStatusChange)?.forEach((key) => {
      let value = segmentMonthlyActivityStatusChange[key];
      if (!isNaN(value)) {
        tempDetails[key] = parseFloat(value);
      } else {
        tempDetails[key] = null;
      }
    });
    const tempData = [
      {
        name: `${previousYear} Transit`,
        group: 'previousYear',
        data: []
      },
      {
        name: `${previousYear} Standby`,
        group: 'previousYear',
        data: []
      },
      {
        name: `${previousYear} At Port`,
        group: 'previousYear',
        data: []
      },
      {
        name: `${currentYear} Transit`,
        group: 'currentYear',
        data: []
      },
      {
        name: `${currentYear} Standby`,
        group: 'currentYear',
        data: []
      },
      {
        name: `${currentYear} At port`,
        group: 'currentYear',
        data: []
      }
    ];

    const prevYearData = segmentMonthlyPercentActivityStatusCountDict[previousYear];
    const currYearData = segmentMonthlyPercentActivityStatusCountDict[currentYear];

    for (let i = 0; i < 12; i++) {
      tempData[0].data.push(parseFloat(prevYearData[i]?.percentMonthlyTransit).toFixed(2) || 0);
      tempData[1].data.push(parseFloat(prevYearData[i]?.percentMonthlyStandBy).toFixed(2) || 0);
      tempData[2].data.push(parseFloat(prevYearData[i]?.percentMonthlyAtPort).toFixed(2) || 0);

      if (currYearData && currYearData[i]) {
        tempData[3].data.push(parseFloat(currYearData[i]?.percentMonthlyTransit).toFixed(2) || 0);
        tempData[4].data.push(parseFloat(currYearData[i]?.percentMonthlyStandBy).toFixed(2) || 0);
        tempData[5].data.push(parseFloat(currYearData[i]?.percentMonthlyAtPort).toFixed(2) || 0);
      } else {
        tempData[3].data.push(0);
        tempData[4].data.push(0);
        tempData[5].data.push(0);
      }
    }

    return { series: tempData, details: tempDetails };
  }, [globalFleetActivity]);

  const categories = useMemo(() => {
    if (!globalFleetActivity) return [];

    const yearData =
      globalFleetActivity.segmentMonthlyPercentActivityStatusCountDict[
        moment().subtract(1, 'year').year()
      ];

    return yearData ? yearData.map(({ month }) => month) : [];
  }, [globalFleetActivity]);

  const options = useMemo(() => {
    return getOptions(categories, theme, user, fileName);
  }, [categories, theme]);

  const getDisplayValue = useCallback((value, numDec, middleText, percentValue) => {
    let mainValue = value !== null ? value?.toFixed(numDec) : '-';
    let percent = percentValue !== null ? percentValue?.toFixed(2) : 0;
    return `${mainValue} ${middleText} (${percent}%)`;
  }, []);

  return (
    <>
      <ApexChart
        loading={loading || !options || !series?.length}
        height={480}
        options={options}
        type={'bar'}
        data={series}
      />
      <Grid item xs={12}>
        <Typography variant={'body1'} align="left">
          Monthly Comparison: Latest completed month vs previous month
        </Typography>
        <ul>
          <li style={{ marginBottom: '4px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={8}>
                <Typography variant={'body1'}>At port:</Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography align={'right'} color={'primary'} variant={'body1'}>
                  {getDisplayValue(details?.diffAtPort, 0, 'hours', details?.percentDiffAtPort)}
                </Typography>
              </Grid>
            </Grid>
          </li>
          <li style={{ marginBottom: '4px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={8}>
                <Typography variant={'body1'}>Standby:</Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography align={'right'} color={'primary'} variant={'body1'}>
                  {getDisplayValue(details?.diffStandBy, 0, 'hours', details?.percentDiffStandby)}
                </Typography>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={2}>
              <Grid item xs={6} md={8}>
                <Typography variant={'body1'}>Transit:</Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography align={'right'} color={'primary'} variant={'body1'}>
                  {getDisplayValue(details?.diffTransit, 0, 'hours', details?.percentDiffTransit)}
                </Typography>
              </Grid>
            </Grid>
          </li>
        </ul>
      </Grid>
    </>
  );
}
GlobalFleetActivityDevelopmentChart.propTypes = {
  loading: PropTypes.bool,
  fileName: PropTypes.string,
  globalFleetActivity: PropTypes.object
};
GlobalFleetActivityDevelopmentChart.defaultProps = {
  globalFleetActivity: null,
  fileName: '',
  loading: false
};
export default GlobalFleetActivityDevelopmentChart;
