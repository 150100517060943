import React from 'react';
import {
  Grid,
  Paper,
  Typography,
  Stack,
  ShipsIcon,
  DeleteOutlineIcon,
  Box,
  CircularProgress
} from '@esgian/esgianui';
import PropTypes from 'prop-types';
import { useVoyageGroups } from '../../../../pages/VoyageAnalytics/hooks/useVoyageGroups';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getThemeMode } from '@store/features';
import {
  getValueUptoDecimalPlaces,
  getValueUptoTwoDecimalPlaces
} from '../VoyageSummarySection/VoyageSummaryRouteMap/utils';

function SavedVoyageCard({
  vesselName,
  route,
  startDate,
  endDate,
  fuel,
  transitTime,
  distance,
  operator,
  emissionIntensity,
  isHovered,
  groupId,
  voyageId,
  refetch,
  voyageLink
}) {
  const themeMode = useSelector(getThemeMode);

  const { deleteVoyageFromGroup, isDeletingVoyageFromGroup } = useVoyageGroups();
  const cardStyle = { p: 2, height: '100%', my: 2 };
  const navigate = useNavigate();
  if (isHovered) {
    cardStyle.background = themeMode ? '#333' : '#ddd';
  }

  const handleDeleteVoyage = (event) => {
    event.stopPropagation();
    deleteVoyageFromGroup(groupId, voyageId, refetch);
  };

  return (
    <Paper sx={cardStyle} onClick={() => navigate(voyageLink)}>
      <Grid container spacing={2} alignItems="center">
        <Grid item container xs={9} alignItems="center" spacing={1}>
          <Grid item>
            <ShipsIcon color={'primary'} />
          </Grid>
          <Grid item>
            <Typography variant={'body2'}>{vesselName}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          {isDeletingVoyageFromGroup ? (
            <CircularProgress size={20} />
          ) : (
            <DeleteOutlineIcon
              sx={{ fill: ({ palette }) => palette.text.primary }}
              color={'primary'}
              onClick={handleDeleteVoyage}
            />
          )}
        </Grid>
      </Grid>
      <Stack direction="column" xs={12} spacing={1} sx={{ ml: 2, mt: 2 }}>
        <Typography variant={'body1'}>{route}</Typography>
        <Typography variant={'caption'}>
          {startDate} - {endDate}
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <Box display="flex" flexDirection="column">
            <Typography variant={'caption'}>
              Transit Time (Days): {getValueUptoTwoDecimalPlaces(transitTime)}
            </Typography>
            <Typography variant={'caption'}>Distance (NM): {Math.floor(distance)}</Typography>
            {/* <Typography variant={'caption'}>Operator: {operator}</Typography> */}
          </Box>
          {/* <Box display="flex" flexDirection="column">
            <Typography variant={'caption'}>CO₂e (kg): {Math.floor(fuel)}</Typography>
            <Typography variant={'caption'}>
              CO₂e Emission Intensity (kg CO₂e/t.km):{' '}
              {getValueUptoDecimalPlaces(emissionIntensity, 3, 'xx')}
            </Typography>
          </Box> */}
        </Box>
      </Stack>
    </Paper>
  );
}

SavedVoyageCard.propTypes = {
  vesselName: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  fuel: PropTypes.string.isRequired,
  transitTime: PropTypes.number.isRequired,
  distance: PropTypes.string.isRequired,
  operator: PropTypes.string.isRequired,
  emissionIntensity: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isHovered: PropTypes.bool.isRequired,
  groupId: PropTypes.string.isRequired,
  voyageId: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  voyageLink: PropTypes.string.isRequired
};

export default SavedVoyageCard;
