import { Box, Grid, Typography, CircularProgress } from '@esgian/esgianui';
import React, { useEffect, useState, useRef } from 'react';
import Summary from './Summary';
import {
  CargoEstimatedEtsCostTable,
  VoyagesEstimatedEtSCostTable,
  Co2eEmissionTable
} from '../../../Tables/EmissionAndETSTables';
import Co2EmissionTable from '../../../Tables/EmissionAndETSTables/Co2EmissionTable';
import FuelConsumptionTable from '../../../Tables/EmissionAndETSTables/FuelConsumptionTable';
import MainEngineFuelTable from '../../../Tables/EmissionAndETSTables/MainEngineFuelTable';
import MainGeneratorFuelTable from '../../../Tables/EmissionAndETSTables/MainGeneratorFuelTable';
import FuelConsumptionOverTimeChart from './FuelConsumptionOverTimeChart';
import EmissionOverTimeChart from './EmissionOverTimeChart';
import OverallVoyage from '../../../Tables/EmissionAndETSTables/OverallVoyage';
import { useParams, useSearchParams } from 'react-router-dom';
import { VoyageSummaryFilterKeys } from '../VoyageSummarySection/VoyageSummaryConstants';
import { getVoyageEmissionDetails } from '../../../../api/Voyage';
import { TextWithTooltipIcon } from '@components';

function EmissionAndEtsSection() {
  const params = useParams();
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [loadedData, setLoadedData] = useState(null);
  const [searchParams] = useSearchParams();
  const [myCargoWeightForCustomizedCalculation, setMyCargoWeightForCustomizedCalculation] =
    useState(null);
  const [myEtsCostForCustomizedCalculation, setMyEtsCostForCustomizedCalculation] = useState(null);
  const isInitialLoad = useRef(true); // Ref to track initial load

  useEffect(() => {
    // Fetch initial data on mount
    fetchInitialEmissionAndEtsData();
  }, []);

  useEffect(() => {
    if (!isInitialLoad.current) {
      // Fetch updated data when customized calculation parameters change, avoiding the initial load
      fetchEmissionAndEtsData(
        myCargoWeightForCustomizedCalculation,
        myEtsCostForCustomizedCalculation
      );
    }
  }, [myCargoWeightForCustomizedCalculation, myEtsCostForCustomizedCalculation]);

  const fetchInitialEmissionAndEtsData = async () => {
    setIsLoadingData(true);
    const controller = new AbortController();
    const { signal } = controller;

    const fromPortId = +searchParams.get(VoyageSummaryFilterKeys.FROM_PORT_ID);
    const toPortId = +searchParams.get(VoyageSummaryFilterKeys.TO_PORT_ID);
    const departureDate = searchParams.get(VoyageSummaryFilterKeys.DEPARTURE_DATE);
    const arrivalDate = searchParams.get(VoyageSummaryFilterKeys.ARRIVAL_DATE);

    const filterParams = {
      imo: params.imo,
      fromPortId: fromPortId,
      toPortId: toPortId,
      startDate: departureDate,
      endDate: arrivalDate,
      myCargoWeight: null,
      myEtsCost: null
    };

    try {
      const [response] = await getVoyageEmissionDetails(filterParams, signal);
      setLoadedData(response);
      // Set initial values from the API response
      setMyCargoWeightForCustomizedCalculation(
        response.myCargoEuEtsAllowanceSummary.myCargoWeightTn
      );
      setMyEtsCostForCustomizedCalculation(response.myCargoEuEtsAllowanceSummary.etsCostPerCo2);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingData(false);
      isInitialLoad.current = false; // Mark the initial load as completed
    }
  };

  const fetchEmissionAndEtsData = async (myCargoWeight = null, myEtsCost = null) => {
    setIsLoadingData(true);
    const controller = new AbortController();
    const { signal } = controller;

    const fromPortId = +searchParams.get(VoyageSummaryFilterKeys.FROM_PORT_ID);
    const toPortId = +searchParams.get(VoyageSummaryFilterKeys.TO_PORT_ID);
    const departureDate = searchParams.get(VoyageSummaryFilterKeys.DEPARTURE_DATE);
    const arrivalDate = searchParams.get(VoyageSummaryFilterKeys.ARRIVAL_DATE);

    const filterParams = {
      imo: params.imo,
      fromPortId: fromPortId,
      toPortId: toPortId,
      startDate: departureDate,
      endDate: arrivalDate,
      myCargoWeight,
      myEtsCost
    };

    try {
      const [response] = await getVoyageEmissionDetails(filterParams, signal);
      setLoadedData(response);
      // No need to set initial values here to avoid triggering useEffect
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingData(false);
    }
  };

  const summary = {
    etsCost: loadedData?.emissionSummary?.etsCost?.toFixed(2) ?? '-',
    co2e: loadedData?.emissionSummary.co2eTn?.toFixed(2) ?? '-',
    con2eIntensity: loadedData?.emissionSummary?.co2eIntensity?.toFixed(3) ?? '-',
    co2: loadedData?.emissionSummary.co2Tn?.toFixed(2) ?? '-'
  };

  return (
    <Box display="flex" gap={2} flexDirection="column">
      {isLoadingData ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress
            sx={{
              position: 'absolute',
              top: 'calc(50% - 50px)',
              left: 'calc(50% - 50px)'
            }}
            size={100}
          />
        </Box>
      ) : (
        <>
          <Summary summary={summary} isLoading={isLoadingData} />

          <Box>
            <Typography variant="h6" my={2}>
              <TextWithTooltipIcon
                labelVariant={'h6'}
                label="EU ETS Cost"
                tooltipText="Estimated EU ETS Cost"
              />
            </Typography>
            <CargoEstimatedEtsCostTable
              myCargoEuEtsAllowanceSummary={loadedData?.myCargoEuEtsAllowanceSummary}
              myCargoEuEtsAllowanceCost={loadedData?.myCargoEuEtsAllowanceCost}
              isLoading={isLoadingData}
              myCargoWeightForCustomizedCalculation={myCargoWeightForCustomizedCalculation}
              myEtsCostForCustomizedCalculation={myEtsCostForCustomizedCalculation}
              setMyCargoWeightForCustomizedCalculation={setMyCargoWeightForCustomizedCalculation}
              setMyEtsCostForCustomizedCalculation={setMyEtsCostForCustomizedCalculation}
            />
            <VoyagesEstimatedEtSCostTable
              isLoading={isLoadingData}
              overallVoyageEuEtsAllowanceSummary={loadedData?.overallVoyageEuEtsAllowanceSummary}
              overallVoyageEuEtsAllowanceCost={loadedData?.overallVoyageEuEtsAllowanceCost}
            />
          </Box>

          <Box>
            <Typography variant="h6" my={2}>
              CO₂e Emission (GHG Emission)
            </Typography>
            <Co2eEmissionTable
              totalEts={loadedData?.myCargoEuEtsAllowanceSummary.totalMyCargoEstimatedEuEtsCost}
              myCargoToc={loadedData?.myCargoTocEmission ?? {}}
              myCargoHoc={loadedData?.myCargoHocEmission ?? {}}
              emissionSummary={loadedData?.myCargoGhgEmissionSummary ?? {}}
              isLoading={isLoadingData}
            />
            <OverallVoyage
              isLoading={isLoadingData}
              overallVoyage={loadedData?.overallVoyageGhgEmission}
            />
          </Box>

          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              CO<sub>2</sub> Emission
            </Typography>
            <Co2EmissionTable
              title={'Overall Voyage'}
              filename={'co2-emission'}
              ciiRating={loadedData?.ciiRating}
              isLoading={isLoadingData}
            />
          </Box>

          <Box>
            <EmissionOverTimeChart
              dailyEmissions={loadedData?.dailyEmissions}
              isLoading={isLoadingData}
            />
          </Box>

          <Box display="flex" gap={2}>
            <Box flex={1}>
              <FuelConsumptionTable
                fuelDetails={loadedData?.fuelDetails}
                isLoading={isLoadingData}
              />
            </Box>
            <Box flex={2}>
              <FuelConsumptionOverTimeChart
                fuelDetails={loadedData?.fuelDetails}
                isLoading={isLoadingData}
              />
            </Box>
          </Box>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MainEngineFuelTable
                isLoading={isLoadingData}
                fuelTypes={loadedData?.fuelDetails?.fuelTypeIdsFuelNames ?? {}}
                mainEngineFuel={loadedData?.fuelDetails?.mainEngineFuelDurations ?? []}
              />
            </Grid>
            <Grid item xs={6}>
              <MainGeneratorFuelTable
                isLoading={isLoadingData}
                fuelTypes={loadedData?.fuelDetails?.fuelTypeIdsFuelNames ?? {}}
                mainGeneratorFuel={loadedData?.fuelDetails?.mainGeneratorFuelDurations ?? []}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}

export default EmissionAndEtsSection;
