import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from './TableWithSummary/TableWithSummary';
import { emissionTableColumns, getDataShape } from './Co2eEmissionTable';
import { formatNumberReadable } from '@helpers';

const Co2EmissionTable = ({ ciiRating, isLoading, title }) => {
  const [data, setData] = useState([]);

  const summary = [
    {
      label: 'Total CO₂  (tn)',
      value: formatNumberReadable(ciiRating?.totalCo2Ttw?.toFixed(0)) ?? '--'
    },
    {
      label: `CII (${ciiRating?.ciiDisplayString})`,
      value: ciiRating?.ciiValueTtw?.toFixed(2) ?? '--'
    },
    {
      label: 'CII',
      value: ciiRating?.ciiRatingTtw ?? '--'
    }
  ];

  useEffect(() => {
    if (!ciiRating) {
      return;
    }

    setData([
      getDataShape(
        'CO₂',
        ciiRating?.totalCo2Wtt,
        formatNumberReadable(ciiRating?.totalCo2Ttw?.toFixed(0)),
        ciiRating?.totalCo2Wtw
      ),
      getDataShape(
        'CII',
        ciiRating?.ciiValueWtt,
        formatNumberReadable(ciiRating?.ciiValueTtw?.toFixed(2)),
        ciiRating?.ciiValueWtw
      )
    ]);
  }, [ciiRating]);

  return (
    <TableWithSummary
      title={title}
      columns={emissionTableColumns}
      data={data}
      summary={summary}
      isLoading={isLoading}
    />
  );
};

Co2EmissionTable.propTypes = {
  ciiRating: PropTypes.object,
  isLoading: PropTypes.bool,
  title: PropTypes.string
};

export default Co2EmissionTable;
