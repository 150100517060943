import { Box, Paper, Skeleton, Tab, Tabs, Typography } from '@esgian/esgianui';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EmissionChart from './EmissionChart';
import { emissionAndEtsChartColors, graphDataLimit } from './FuelConsumptionOverTimeChart';

const tabs = {
  co2ChartTab: 0,
  co2eChartTab: 1
};

const getLabelFromEmissionType = (type) => {
  if (type === 'co2WttTn') {
    return 'CO₂ wtt';
  }
  if (type === 'co2Tn') {
    return 'CO₂ ttw';
  }

  if (type === 'co2WtwTn') {
    return 'CO₂ wtw';
  }
  if (type === 'co2eWttTn') {
    return 'CO₂e wtt';
  }
  if (type === 'co2eTtwTn') {
    return 'CO₂e ttw';
  }
  if (type === 'co2eWtwTn') {
    return 'CO₂e wtw';
  }
};

function DailyEmissionsChart({ dailyEmissions, isLoading }) {
  const [selectedTab, setSelectedTab] = useState(tabs.co2ChartTab);

  const categories = [];
  const co2Series = [];
  const co2eSeries = [];

  if (dailyEmissions) {
    categories.push(...dailyEmissions.map((e) => e.dayDate));
    const co2EmissionByTypeByDaily = {
      co2WttTn: {},
      co2Tn: {},
      co2WtwTn: {}
    };
    const co2eEmissionByTypeByDaily = {
      co2eWttTn: {},
      co2eTtwTn: {},
      co2eWtwTn: {}
    };

    dailyEmissions.forEach(
      ({ dayDate, co2WttTn, co2Tn, co2WtwTn, co2eWttTn, co2eTtwTn, co2eWtwTn }) => {
        co2EmissionByTypeByDaily.co2WttTn[dayDate] = co2WttTn;
        co2EmissionByTypeByDaily.co2Tn[dayDate] = co2Tn;
        co2EmissionByTypeByDaily.co2WtwTn[dayDate] = co2WtwTn;
        co2eEmissionByTypeByDaily.co2eWttTn[dayDate] = co2eWttTn;
        co2eEmissionByTypeByDaily.co2eTtwTn[dayDate] = co2eTtwTn;
        co2eEmissionByTypeByDaily.co2eWtwTn[dayDate] = co2eWtwTn;
      }
    );

    Object.entries(co2EmissionByTypeByDaily).forEach(([emissionType, emissionValuePerDaily], i) => {
      co2Series.push({
        name: getLabelFromEmissionType(emissionType),
        data: categories.map((t) => emissionValuePerDaily[t] || null).slice(0, graphDataLimit),
        color: emissionAndEtsChartColors[i]
      });
    });

    Object.entries(co2eEmissionByTypeByDaily).forEach(
      ([emissionType, emissionValuePerDaily], i) => {
        co2eSeries.push({
          name: getLabelFromEmissionType(emissionType),
          data: categories.map((t) => emissionValuePerDaily[t] || null).slice(0, graphDataLimit),
          color: emissionAndEtsChartColors[i]
        });
      }
    );
  }

  if (isLoading) {
    return <Skeleton height="400px" variant="rectangular" />;
  }

  return (
    <Paper sx={{ padding: 2 }}>
      <Box display="flex" gap={2} alignItems="center">
        <Typography>Emissions over time</Typography>
        <Tabs
          value={selectedTab}
          onChange={(e, newSelectedTab) => {
            setSelectedTab(newSelectedTab);
          }}
          variant="scrollable"
          scrollButtons={false}>
          <Tab
            label={
              <span>
                CO<sub>2</sub>
              </span>
            }
          />
          <Tab
            label={
              <span style={{ textTransform: 'none' }}>
                CO<sub>2</sub>e
              </span>
            }
          />
        </Tabs>
      </Box>
      <Box>
        {selectedTab === tabs.co2ChartTab && (
          <EmissionChart categories={categories.slice(0, graphDataLimit)} series={co2Series} />
        )}
        {selectedTab === tabs.co2eChartTab && (
          <EmissionChart categories={categories.slice(0, graphDataLimit)} series={co2eSeries} />
        )}
      </Box>
    </Paper>
  );
}

DailyEmissionsChart.propTypes = {
  dailyEmissions: PropTypes.object,
  isLoading: PropTypes.bool
};

export default DailyEmissionsChart;
