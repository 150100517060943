import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const getOptions = (categories, theme, user, fileName) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, sixColorsStacked }
    }
  } = theme;
  return {
    chart: {
      type: 'bar',
      height: 350,
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: true
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val?.toFixed(0);
      }
    },
    theme: {
      mode: mode
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false
      }
    },
    xaxis: {
      categories: categories
    },
    fill: {
      opacity: 1
    },
    colors: sixColorsStacked,
    yaxis: {
      decimalsInFloat: 0,
      max: 100,
      title: {
        text: '% of time'
      }
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      y: {
        formatter: function (value) {
          if (value !== null && value !== undefined) {
            return value.toFixed(2) + '%';
          }
          return ' - ';
        }
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      offsetX: -35,
      itemMargin: {
        horizontal: 10,
        vertical: 5
      }
    },
    noData: {
      text: 'There is no data to display'
    }
  };
};
function VesselActivityProfileChart({ loading, vesselActivityData, fileName }) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!vesselActivityData) return;

    setSeries([
      {
        name: `Transit`,
        group: 'operator',
        data:
          vesselActivityData?.imoMonthlyPercentActivityStatus?.map(
            ({ percentMonthlyTransit }) => parseFloat(percentMonthlyTransit) || 0
          ) || []
      },
      {
        name: `Standby`,
        group: 'operator',
        data:
          vesselActivityData?.imoMonthlyPercentActivityStatus?.map(
            ({ percentMonthlyStandBy }) => percentMonthlyStandBy || 0
          ) || []
      },
      {
        name: `At Port`,
        group: 'operator',
        data:
          vesselActivityData?.imoMonthlyPercentActivityStatus?.map(
            ({ percentMonthlyAtPort }) => percentMonthlyAtPort || 0
          ) || []
      },
      {
        name: `Segment Transit`,
        group: 'segment',
        data:
          vesselActivityData?.segmentTypeIdMonthlyPercentActivityStatus?.map(
            ({ percentMonthlyTransit }) => parseFloat(percentMonthlyTransit) || 0
          ) || []
      },
      {
        name: `Segment Standby`,
        group: 'segment',
        data:
          vesselActivityData?.segmentTypeIdMonthlyPercentActivityStatus?.map(
            ({ percentMonthlyStandBy }) => percentMonthlyStandBy || 0
          ) || []
      },
      {
        name: `Segment At Port`,
        group: 'segment',
        data:
          vesselActivityData?.segmentTypeIdMonthlyPercentActivityStatus?.map(
            ({ percentMonthlyAtPort }) => percentMonthlyAtPort || 0
          ) || []
      }
    ]);
  }, [vesselActivityData]);

  useEffect(() => {
    if (!vesselActivityData) return;
    setOptions(
      getOptions(
        vesselActivityData?.imoMonthlyPercentActivityStatus?.map(({ monthStr }) => monthStr) || [],
        theme,
        user,
        fileName
      )
    );
  }, [theme, series]);

  return (
    <ApexChart
      loading={loading || !options || !series?.length}
      height={480}
      options={options || {}}
      type={'bar'}
      data={series}
    />
  );
}
VesselActivityProfileChart.propTypes = {
  loading: PropTypes.bool,
  fileName: PropTypes.string.isRequired,
  vesselActivityData: PropTypes.object
};
VesselActivityProfileChart.defaultProps = {
  vesselActivityData: null,
  loading: false
};
export default VesselActivityProfileChart;
