import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Grid, Typography } from '@esgian/esgianui';
import { checkUserHasAccess, formatAxisValue, getDateList } from '@helpers';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import { getDateFormat, getUser } from '@store/features';
import { useTheme } from '@hooks';

const getOptions = (theme, dateList, user, maxValue, fileName) => {
  const {
    palette: {
      mode,
      charts: { background, threeColors, twoColorsContrast }
    }
  } = theme;
  return {
    chart: {
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: { filename: fileName },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      height: 350,
      type: 'mixed',
      background: background,
      stacked: false,
      zoom: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        columnWidth: '50%'
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: [0, 3, 2],
      curve: 'straight',
      dashArray: [0, 0, 6]
    },
    colors: [twoColorsContrast[1], threeColors[1], twoColorsContrast[0]],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (value, { seriesIndex }) {
          if (seriesIndex === 1) {
            return formatAxisValue(value);
          }
          return value?.toFixed(4);
        }
      },
      x: {
        show: true
      }
    },
    markers: {
      size: [0, 3],
      strokeColors: '#ffffff',
      strokeWidth: 1,
      hover: {
        size: 5
      }
    },
    fill: {
      type: 'solid'
    },
    theme: {
      mode: mode
    },
    labels: dateList,
    yaxis: [
      {
        max: (max) => {
          if (maxValue) {
            return maxValue * 1.15;
          }
          return max * 1.15;
        },
        tickAmount: 5,
        decimalsInFloat: 3,
        title: {
          text: 'gCO2/(GT•NM)'
        }
      },
      {
        opposite: true,
        min: 500,
        labels: {
          formatter: formatAxisValue,
          style: {
            colors: [threeColors[1]]
          }
        },
        max: (max) => max * 1.15,
        decimalsInFloat: 0,
        tickAmount: 5,
        title: {
          text: 'DISTANCE [NM]',
          style: {
            color: threeColors[1]
          }
        }
      },
      { max: () => maxValue * 1.15, tickAmount: 5, decimalsInFloat: 5, show: false }
    ],
    xaxis: {
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true
      }
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      markers: { radius: [0, 12, 12] }
    }
  };
};

function GlobalEmissionIntensityChart({ endDate, globalEmissionIntensity, loading, fileName }) {
  const [maxValue, setMaxValue] = useState(null);
  const dateFormat = useSelector(getDateFormat);
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const { series = [], details = {} } = useMemo(() => {
    if (!globalEmissionIntensity) return {};
    const {
      segmentMonthlyEmissionDetailsChange = {},
      segmentMonthlyEmissionDetailsWithPolyFit = {}
    } = globalEmissionIntensity;

    const {
      segmentMonthlyEmissionDetailsList = [],
      polyFitSegmentMonthlyTotalCo2ByGrossTonnageDistance = []
    } = segmentMonthlyEmissionDetailsWithPolyFit;

    let monthlyEmissions = [];
    let distanceNm = [];

    segmentMonthlyEmissionDetailsList.forEach(({ monthTotalCo2ByGtNm, monthDistanceNm }) => {
      monthlyEmissions.push(monthTotalCo2ByGtNm.toFixed(4));
      distanceNm.push(monthDistanceNm.toFixed(0));
    });
    setMaxValue(Math.max(...monthlyEmissions));

    const co2ByGtDistance = polyFitSegmentMonthlyTotalCo2ByGrossTonnageDistance.map(
      (value) => value?.toFixed(4) ?? null
    );

    let tempDetails = {};

    Object.keys(segmentMonthlyEmissionDetailsChange)?.forEach((key) => {
      let value = segmentMonthlyEmissionDetailsChange[key];
      if (!isNaN(value)) {
        tempDetails[key] = parseFloat(value);
      } else {
        tempDetails[key] = null;
      }
    });

    const tempData = [
      {
        name: 'gCO2/GT.NM',
        data: monthlyEmissions,
        type: 'column'
      },
      {
        name: 'Distance [NM]',
        data: distanceNm,
        type: 'line'
      },
      {
        name: 'Trendline',
        data: co2ByGtDistance,
        type: 'line'
      }
    ];

    return { series: tempData, details: tempDetails };
  }, [globalEmissionIntensity]);

  const options = useMemo(() => {
    if (!endDate) return null;

    const last12MonthsStartDate = moment(endDate, dateFormat)
      .subtract(11, 'months')
      .startOf('month');
    return getOptions(
      theme,
      getDateList(last12MonthsStartDate, moment(endDate, dateFormat)),
      user,
      maxValue,
      fileName
    );
  }, [theme, endDate, maxValue]);

  const getDisplayValue = useCallback((value, numDec, middleText, percentValue) => {
    let mainValue = value !== null ? parseFloat(value).toFixed(numDec) : '-';
    let percent = percentValue !== null ? parseFloat(percentValue).toFixed(2) : 0;

    return `${mainValue} ${middleText ? middleText : ''} (${percent}%)`;
  }, []);

  return (
    <>
      <ApexChart
        loading={loading || !options}
        data={series}
        height={480}
        options={options || {}}
        type={'bar'}
      />
      <Grid item xs={12}>
        <Typography variant={'body1'} align="left">
          Monthly Comparison: Latest completed month vs previous month
        </Typography>
        <ul>
          <li style={{ marginBottom: '4px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={8}>
                <Typography variant={'body1'}>
                  Total CO<sub>2</sub>
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography align={'right'} color={'primary'} variant={'body1'}>
                  {getDisplayValue(details.diffTotalCo2, 2, 'Tonnes', details.percentDiffTotalCo2)}
                </Typography>
              </Grid>
            </Grid>
          </li>
          <li style={{ marginBottom: '4px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6} md={8}>
                <Typography variant={'body1'}>Distance:</Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography align={'right'} color={'primary'} variant={'body1'}>
                  {`${getDisplayValue(
                    details.diffDistanceNm,
                    2,
                    'NM',
                    details.percentDiffDistanceNm
                  )}`}
                </Typography>
              </Grid>
            </Grid>
          </li>
          <li>
            <Grid container spacing={2}>
              <Grid item xs={6} md={8}>
                <Typography variant={'body1'}>
                  gCO<sub>2</sub>/GT.NM:
                </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography align={'right'} color={'primary'} variant={'body1'} spacing={1}>
                  {`${getDisplayValue(
                    details.diffTotalCo2ByGtNm,
                    5,
                    '',
                    details.percentDiffTotalCo2ByGtNm
                  )}`}
                </Typography>
              </Grid>
            </Grid>
          </li>
        </ul>
      </Grid>
    </>
  );
}

GlobalEmissionIntensityChart.propTypes = {
  loading: PropTypes.bool,
  globalEmissionIntensity: PropTypes.object,
  endDate: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired
};

GlobalEmissionIntensityChart.defaultProps = {
  loading: false,
  globalEmissionIntensity: null
};

export default GlobalEmissionIntensityChart;
