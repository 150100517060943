import React, { useEffect, useMemo, useState } from 'react';
import {
  ArrowBackIcon,
  Button,
  Grid,
  IconButton,
  InfoOutlineIcon,
  Paper,
  Skeleton,
  Slide,
  Stack,
  Tab,
  Tabs,
  ToolTip,
  Typography
} from '@esgian/esgianui';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  getVoyageProfileFilters,
  VOYAGE_PROFILE_PARAMS
} from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';
import { getVoyageDetails } from '@api';

import { toast } from 'react-toastify';
import { EmissionAndEtsSection, VoyageSummarySection } from '@components/Sections';
import { VoyageSummaryFilterKeys } from '@components/Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryConstants';
import VoyageGroupsPopup from '../../components/Sections/VoyageAnalytics/VoyageGroupSection/VoyageGroupsPopup';

const sectionShortNames = {
  1: 'Voyage Summary',
  2: 'Emission and ETS',
  3: 'Vessel Specification'
};

const AisDataStatusColor = {
  'Very good': '#80DFEB',
  Good: '#4CAF50',
  Medium: 'white',
  Low: '#FF9800',
  'Very Low': '#EF5350'
};

const AisDataDescription = {
  'Very Good': 'AIS Data quality is excellent',
  Good: 'AIS Data quality is upto requirements',
  Medium:
    'Some AIS data points corresponding to this voyage have not been received from the vessel, therefore corrections have been done on modelled emissions accordingly.',
  Low: 'AIS data corresponding to this voyage received from the vessel seems to be lower than usual, therefore corrections have been done on modelled emissions',
  'Very Low':
    'We have received very less AIS data for this voyage from the vessel. Correction factors have been applied to emissions to account for missing data. The estimations are prone to have errors due to lack of data.'
};

const getAisStatus = (aisGrade) => {
  let status = 'Very Low';

  if (aisGrade > 95) {
    status = 'Very good';
  } else if (aisGrade > 85 && aisGrade <= 95) {
    status = 'Good';
  } else if (aisGrade > 80 && aisGrade <= 85) {
    status = 'Medium';
  } else if (aisGrade > 70 && aisGrade <= 80) {
    status = 'Low';
  }

  return status;
};

const VoyageProfilePage = () => {
  const [searchParams] = useSearchParams();
  const {
    mapOptions: { fullscreen }
  } = useSelector(getVoyageProfileFilters);

  const params = useParams();
  const section = parseInt(searchParams.get('section')) || 1;

  const [voyageData, setVoyageData] = useState(null);
  const navigate = useNavigate();

  const handleSectionChange = (_, val) => {
    searchParams.set('section', val);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  const {
    aisStatus = 0,
    aisColor,
    aisDataDescription
  } = useMemo(() => {
    if (!voyageData) return {};
    const {
      voyageTransitDetails: { aisDensity }
    } = voyageData;
    const aisStatus = getAisStatus(aisDensity ?? 0);
    return {
      aisStatus: aisStatus,
      aisColor: AisDataStatusColor[aisStatus],
      aisDataDescription: AisDataDescription[aisStatus]
    };
  }, [voyageData]);

  const hasEmission = voyageData?.voyageTransitDetails.activeEmission;

  useEffect(() => {
    if (searchParams.has('section')) {
      handleSectionChange(null, parseInt(searchParams.get('section')));
    } else {
      searchParams.set('section', section);
      navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const getVoyageProfileData = async () => {
      try {
        const filterParams = {
          imo: params.imo,
          fromPortId: parseInt(searchParams.get(VoyageSummaryFilterKeys.FROM_PORT_ID)),
          toPortId: parseInt(searchParams.get(VoyageSummaryFilterKeys.TO_PORT_ID)),
          startDate: searchParams.get(VoyageSummaryFilterKeys.DEPARTURE_DATE),
          endDate: searchParams.get(VoyageSummaryFilterKeys.ARRIVAL_DATE)
        };

        await getVoyageDetails(filterParams, signal)
          .then((resp) => {
            setVoyageData(resp[0]);
          })
          .catch(() => {
            toast.error('Unable to fetch voyage data.', {
              position: 'top-right'
            });
          })
          .finally(() => {});
      } catch (error) {
        if (error.name === 'AbortError') {
          // console.log('Found abortError');
        } else {
          toast.error('Unable to fetch voyage data.', {
            position: 'top-right'
          });
        }
      }
    };

    let imo = params.imo;
    if (imo && Object.values(VOYAGE_PROFILE_PARAMS).every((param) => searchParams.has(param))) {
      getVoyageProfileData(imo);
    }
    return () => {
      controller.abort();
    };
  }, []);
  const { voyageOverview } = voyageData ?? {};

  return (
    <Grid container spacing={fullscreen ? 0 : 2} justifyContent={'stretch'}>
      {!fullscreen && (
        <Grid item xs={12}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Stack direction={'row'} spacing={2}>
              <IconButton
                onClick={() => navigate(-1)}
                sx={{
                  background: '#80DFEB33',
                  borderRadius: '0.5rem',
                  height: 'fit-content'
                }}>
                <ArrowBackIcon fontSize={'small'} />
              </IconButton>
              <Stack spacing={1}>
                <Typography variant={'h5'}>
                  {!voyageData ? (
                    <Skeleton width={'20vw'} height={'1em'} variant={'rectangular'} />
                  ) : (
                    `Voyage from ${voyageOverview.departurePort} to ${voyageOverview.arrivalPort}`
                  )}
                </Typography>
                <Typography id={sectionShortNames?.[section]}>
                  {!voyageData ? (
                    <Skeleton width={'20vw'} />
                  ) : (
                    `From ${moment(voyageOverview.departureTime).format('YYYY-MM-DD')} to ${moment(
                      voyageOverview.arrivalTime
                    ).format('YYYY-MM-DD')}`
                  )}
                </Typography>
              </Stack>
            </Stack>
            <Stack direction={'row'} spacing={2}>
              <Button
                variant="text"
                sx={{
                  height: 'fit-content',
                  color: 'text.primary',
                  textTransform: 'none'
                }}
                startIcon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none">
                    <circle cx="4" cy="4" r="4" fill={aisColor} />
                  </svg>
                }
                endIcon={
                  <ToolTip title={aisDataDescription}>
                    <InfoOutlineIcon sx={{ fontSize: 16 }} />
                  </ToolTip>
                }>
                <Typography variant="body2">{`AIS: ${aisStatus}`}</Typography>
              </Button>
              <VoyageGroupsPopup mode="profile" />
            </Stack>
          </Stack>
        </Grid>
      )}
      {!fullscreen && (
        <Grid item xs={12}>
          <Paper>
            <Tabs onChange={handleSectionChange} value={section || 1}>
              <Tab
                value={1}
                id="voyage-summary-tab"
                label={'Voyage Summary'}
                sx={{ textTransform: 'none' }}
              />
              {hasEmission && (
                <Tab
                  value={2}
                  id="emission-and-ets-tab"
                  label={'Emission and ETS'}
                  sx={{ textTransform: 'none' }}
                />
              )}
            </Tabs>
          </Paper>
        </Grid>
      )}
      <Grid item xs={12}>
        <Slide direction="right" in={section === 1} mountOnEnter unmountOnExit>
          <div>
            <VoyageSummarySection voyageData={voyageData} />
          </div>
        </Slide>
        <Slide direction="right" in={section === 2} mountOnEnter unmountOnExit>
          <div>
            <EmissionAndEtsSection />
          </div>
        </Slide>
      </Grid>
    </Grid>
  );
};

export default VoyageProfilePage;
