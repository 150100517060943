import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  DeleteOutlineIcon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Modal,
  Typography
} from '@esgian/esgianui';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useParams, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { updateVoyageGroup } from '../../../../api/Voyage';
import { toast } from 'react-toastify';
import { getUser } from '@store/features/auth/AuthSlice.js';
import { useVoyageGroups } from '../../../../pages/VoyageAnalytics/hooks/useVoyageGroups';
import VoyageGroupListPopup from './VoyageGroupListPopup';
import PropTypes from 'prop-types';
import AddToVoyageGroupPopup from './AddToVoyageGroupPopup';
import { usePopupState, bindPopover, bindHover } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import CreateVoyagePopup from './CreateVoyagePopup';

const VoyageGroupsPopup = ({ mode }) => {
  const isSearchPage = mode === 'search';

  const [isOpenManageModal, setIsOpenManageModal] = useState(false);
  const [voyageGroupName, setVoyageGroupName] = useState('');
  const [modalOpen, setModalOpen] = React.useState(false);
  const user = useSelector(getUser);
  const email = user?.email;
  const [isSavingVoyageToGroup, setIsSavingVoyageToGroup] = React.useState(false);
  const [voyageGroupToEdit, setVoyageGroupToEdit] = useState();
  const [renamedVoyageGroupName, setRenamedVoyageGroupName] = useState('');
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'AddToGroupsPopup'
  });

  const params = useParams();

  const {
    isLoadingVoyageGroups,
    savedVoyageGroups,
    deleteVoyageGroup,
    isDeletingVoyageGroup,
    renameVoyageGroup,
    isRenamingVoyage,
    createNewGroup,
    isGroupBeingCreated,
    refetch
  } = useVoyageGroups();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    popupState.isOpen && refetch();
  }, [popupState.isOpen]);

  const voyageIdentity = {
    imo: params.imo,
    fromPortId: +searchParams.get('fromPortId'),
    toPortId: +searchParams.get('toPortId'),
    departureDate: searchParams.get('departureDate'),
    arrivalDate: searchParams.get('arrivalDate')
  };
  const addVoyageToGroup = async (groupId) => {
    console.log(savedVoyageGroups);
    console.log(savedVoyageGroups);
    const abortController = new AbortController();
    const signal = abortController.signal;

    const updatedVoyages = [...savedVoyageGroups];

    const targetGroup = updatedVoyages.find((group) => group.id === groupId);

    if (!targetGroup) {
      toast.error('Unable to save voyage to group', {
        position: 'top-right'
      });
      return;
    }

    if (targetGroup.voyages.length >= 10) {
      toast.error('Group can have maximum 10 voyages', {
        position: 'bottom-right'
      });
      return;
    }

    let isExisting = false;
    targetGroup.voyages.forEach((voyage) => {
      if (
        voyage.imo === voyageIdentity.imo &&
        voyage.fromPortId === voyageIdentity.fromPortId &&
        voyage.toPortId === voyageIdentity.toPortId &&
        voyage.departureDate === voyageIdentity.departureDate &&
        voyage.arrivalDate === voyageIdentity.arrivalDate
      ) {
        isExisting = true;
        return;
      }
    });

    if (isExisting) {
      setIsSavingVoyageToGroup(false);
      toast.error('Voyage already saved to group', {
        position: 'bottom-right'
      });
      return;
    }

    updatedVoyages.forEach((group) => {
      if (group.id === groupId) {
        return group.voyages.push(voyageIdentity);
      }
      return group;
    });
    setIsSavingVoyageToGroup(true);

    try {
      await updateVoyageGroup(email, updatedVoyages, signal);
      toast.success('Voyage saved to group successfully', {
        position: 'bottom-right'
      });
    } catch (err) {
      toast.error('Unable to save voyage to group', {
        position: 'bottom-right'
      });
      console.log('error', err);
    } finally {
      setIsSavingVoyageToGroup(false);
    }
  };

  const isDisabledManageModalActions = isDeletingVoyageGroup;

  return (
    <>
      <Box>
        <Button
          variant="text"
          sx={{
            height: 'fit-content',
            size: '0.875rem',
            textTransform: 'none'
          }}
          startIcon={<FavoriteBorderIcon sx={{ fontSize: 16 }} />}
          {...bindHover(popupState)}>
          <Typography variant="body2">
            {isSearchPage ? `MY VOYAGE GROUP` : 'SAVE TO GROUP'}
          </Typography>
        </Button>
        <HoverPopover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}>
          {isSearchPage ? (
            <VoyageGroupListPopup
              setModalOpen={setModalOpen}
              savedGroups={savedVoyageGroups}
              addVoyageToGroup={addVoyageToGroup}
              isSavingVoyageToGroup={isSavingVoyageToGroup}
              isLoadingVoyageGroups={isLoadingVoyageGroups}
              setIsOpenManageModal={setIsOpenManageModal}
            />
          ) : (
            <AddToVoyageGroupPopup
              onCreateANewGroup={() => setModalOpen(true)}
              savedGroups={savedVoyageGroups}
              onAddVoyageToGroup={addVoyageToGroup}
              isSavingVoyageToGroup={isSavingVoyageToGroup}
              isLoadingVoyageGroups={isLoadingVoyageGroups}
            />
          )}
        </HoverPopover>
      </Box>

      {/* <Modal
        title={voyageGroupToEdit ? 'Edit Voyage Group' : 'Create a new group'}
        open={modalOpen || voyageGroupToEdit}
        onClose={() => {
          if (voyageGroupToEdit) {
            setVoyageGroupToEdit(undefined);
          }
          setModalOpen(false);
        }}>
        <Stack spacing={2} sx={{ display: 'grid', justifyContent: 'center' }}>
          <TextField
            label={voyageGroupToEdit ? 'Edit group name' : 'Create a new group'}
            autoComplete={'off'}
            type={'search'}
            sx={{ minWidth: '20em', maxWidth: '20em' }}
            placeholder={''}
            value={voyageGroupToEdit ? renamedVoyageGroupName : voyageGroupName}
            error={!!duplicateGroupErrorMessage}
            helperText={duplicateGroupErrorMessage}
            fullWidth
            onChange={(e) => {
              const groupName = e.target.value;
              if (groupName.length > 20) {
                toast.error('Group name should be less than 20 characters', {
                  position: 'bottom-right'
                });
                return;
              }
              if (voyageGroupToEdit) {
                setRenamedVoyageGroupName(groupName);
              } else {
                setVoyageGroupName(groupName);
              }
              const isGroupAlreadyExist = savedVoyageGroups.some(
                (singleGroup) =>
                  singleGroup.name.trim().toLowerCase() === groupName.trim().toLowerCase()
              );
              if (isGroupAlreadyExist) {
                setDuplicateGroupErrorMessage(`Group with the name "${groupName}" already exists`);
              } else if (duplicateGroupErrorMessage) {
                setDuplicateGroupErrorMessage('');
              }
            }}
          />

          <Stack spacing={2} direction="row">
            <Button variant="text" onClick={() => setModalOpen(false)}>
              Close
            </Button>
            <Button
              disabled={
                isGroupBeingCreated ||
                !!duplicateGroupErrorMessage ||
                (!voyageGroupName && !renamedVoyageGroupName) ||
                isRenamingVoyage
              }
              onClick={() => {
                if (voyageGroupToEdit) {
                  renameVoyageGroup(voyageGroupToEdit, renamedVoyageGroupName).then(() => {
                    setVoyageGroupToEdit(undefined);
                    setRenamedVoyageGroupName('');
                  });
                } else {
                  createNewGroup(voyageGroupName).then(() => {
                    setVoyageGroupName('');
                    setModalOpen(false);
                  });
                }
              }}
              fullWidth>
              {voyageGroupToEdit ? 'Save' : '+ CREATE A NEW GROUP'}
            </Button>
          </Stack>
        </Stack>
      </Modal> */}
      <CreateVoyagePopup
        open={modalOpen || voyageGroupToEdit}
        setModalOpen={setModalOpen}
        value={voyageGroupToEdit ? renamedVoyageGroupName : voyageGroupName}
        onChange={voyageGroupToEdit ? setRenamedVoyageGroupName : setVoyageGroupName}
        existingGroups={savedVoyageGroups}
        isSubmitDisabled={isGroupBeingCreated || isRenamingVoyage}
        onSubmit={() => {
          if (voyageGroupToEdit) {
            renameVoyageGroup(voyageGroupToEdit, renamedVoyageGroupName).then(() => {
              setVoyageGroupToEdit(undefined);
              setRenamedVoyageGroupName('');
            });
          } else {
            createNewGroup(voyageGroupName).then(() => {
              setVoyageGroupName('');
              setModalOpen(false);
            });
          }
        }}
        voyageGroupToEdit={voyageGroupToEdit}
        setVoyageGroupToEdit={setVoyageGroupToEdit}
      />
      <Modal
        title="Manage my voyage group"
        open={isOpenManageModal && savedVoyageGroups.length > 0}
        onClose={() => setIsOpenManageModal(false)}>
        <List dense>
          {savedVoyageGroups.map((v) => {
            return (
              <ListItem
                disabled={isDisabledManageModalActions}
                key={v.id}
                secondaryAction={
                  <Box display="flex">
                    <Button
                      variant="text"
                      onClick={() => {
                        setRenamedVoyageGroupName(v.name);
                        setVoyageGroupToEdit(v);
                      }}>
                      Edit
                    </Button>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => {
                        deleteVoyageGroup(v);
                      }}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  </Box>
                }>
                <ListItemText primary={v.name} />
              </ListItem>
            );
          })}
        </List>
        <Box display="flex" gap={2} justifyContent="center" alignItems="center">
          <Button
            disabled={isDisabledManageModalActions}
            variant="text"
            onClick={() => setIsOpenManageModal(false)}>
            Close
          </Button>
          <Button
            disabled={isDisabledManageModalActions}
            onClick={() => setIsOpenManageModal(false)}>
            Save
          </Button>
        </Box>
      </Modal>
    </>
  );
};

VoyageGroupsPopup.defaultProps = {
  mode: 'search'
};

VoyageGroupsPopup.propTypes = {
  mode: PropTypes.string
};

export default VoyageGroupsPopup;
