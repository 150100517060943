import React from 'react';
import { Typography } from '@esgian/esgianui';

//-------------------------- OPERATOR PROFILE --------------------------
export const OPERATOR_PROFILE_FLEET_CII_RATING_TEXT = `Number of vessels of the fleet with each CII rating based on estimated CO₂ emission. 
Completed years have ratings based on the emission for the corresponding year. 
Rating for the current year and upcoming years are ratings based on the rating criteria for the 
corresponding year assuming the fleet operation is same as the latest completed year.`;

//-------------------------- DRAUGHT ON ARRIVAL/DEPARTURE  --------------------------
export const DRAUGHT_ON_ARRIVAL_DEPARTURE_TEXT = `Draught Reported / Max Draught (%)`;
export const DRAUGHT_ON_ARRIVAL_DEPARTURE_POD_TEXT = `Draught Reported / Max Draught (%) at POD`;

//-------------------------- VESSEL PROFILE --------------------------

//-------------------------- REGION PROFILE --------------------------

//-------------------------- COUNTRY PROFILE --------------------------

//-------------------------- PORT PROFILE --------------------------

//-------------------------- OWNER PROFILE --------------------------

//-------------------------- GENERAL --------------------------

//-------------------------- CATEGORY FILTERS --------------------------

export const COM_CAT_TYPE_ALL_TEXT = (
  <React.Fragment>
    <Typography variant={'body2'}>
      This filter displays all vessels covered in the MPP Segment in Esgian Shipping.
    </Typography>
  </React.Fragment>
);

export const COM_CAT_TYPE_DEEP_SEA_CORE_TEXT = (
  <React.Fragment>
    <Typography variant={'body2'}>
      This filter displays operators with vessels meeting these three criteria:{' '}
    </Typography>
    <Typography variant={'body2'}>a) DWT Capacity: 2.500 DWT and above</Typography>
    <Typography variant={'body2'}>b) Combined Crane Lifting Capacity: 100 MT and above</Typography>
    <Typography variant={'body2'}>c) Built year: After 1984.</Typography>
  </React.Fragment>
);

export const COM_CAT_TYPE_DEEP_SEA_EXT_TEXT = (
  <React.Fragment>
    <Typography variant={'body2'}>
      This filter displays all vessels in “Deep Sea Core”, but in addition, it includes vessels
      belonging to operators who have vessels falling into the “Core Deep Sea” category, but not
      adhering to the strict definition regarding DWT, crane lifting capacity nor built year. The
      logic is to show all vessels under an operator having ships in “Deep Sea Core” by using this
      filter.
    </Typography>
  </React.Fragment>
);
export const COM_CAT_TYPE_SHORT_SEA_EUR_TEXT = (
  <React.Fragment>
    <Typography variant={'body2'}>
      This filter shows the vessels meeting the four criteria listed and operated by top 15 European
      short sea operators (top 15 in terms of fleet size and are considered as the main operators in
      this segment by Toepfer Transport). If a vessel changes commercial operator to another
      operator falling outside this scope, Esgian will continue to track the vessel to the new
      operator, but will not cover this operator’s fleet exhaustively.
    </Typography>
    <Typography variant={'body2'}>a) 1000&lt;DWT&lt;9999</Typography>
    <Typography variant={'body2'}>
      b) No crane or combined lifting capacity &lt;99 tonnes
    </Typography>
    <Typography variant={'body2'}>c) Built after 1980</Typography>
    <Typography variant={'body2'}>d) All ships controlled by European entities</Typography>
  </React.Fragment>
);

export const COM_CAT_TYPE_OTHER_TEXT = (
  <React.Fragment>
    <Typography variant={'body2'}>
      This filter displays vessels that are not considered part of the main MPP market according to
      Toepfer Transport, but can still be viewed as “tier 2” competitors. These vessels are tracked
      by Esgian, but the operator is not updated periodically by Toepfer.
    </Typography>
  </React.Fragment>
);

export const OPR_TYPE_BOTH_TEXT = (
  <React.Fragment>
    <Typography variant={'body2'}>
      Display all commercial RoRo operators covered by Esgian
    </Typography>
  </React.Fragment>
);

export const OPR_TYPE_SHORT_SEA_TEXT = (
  <React.Fragment>
    <Typography variant={'body2'}>
      Displays commercial RORO operators with majority of their services in intra-region short sea
      trades{' '}
    </Typography>
  </React.Fragment>
);
export const OPR_TYPE_DEEP_SEA_TEXT = (
  <React.Fragment>
    <Typography variant={'body2'}>
      Displays commercial RORO operators with majority of their services in Deep sea trades between
      continents
    </Typography>
  </React.Fragment>
);

//-------------------------- VOYAGE ANALYTICS --------------------------

export const ESTIMATED_AVG_EU_ETS_COST_TEXT = (
  <React.Fragment>
    <Typography variant={'body2'}>
      Estimated EU ETS cost based on Avg. cargo weight of the entire voyage
    </Typography>
  </React.Fragment>
);
