import React, { useEffect, useState } from 'react';
import { Grid, NotFound, Paper, Typography, Stack } from '@esgian/esgianui';
import OperatorFleetCiiRatingFilters from '@components/Sections/EmissionsAnalytics/OperatorComparisonSection/OperatorFleetCiiRatingSection/OperatorFleetCiiRatingFilters';
import { useSearchParams } from 'react-router-dom';
import { OPERATOR_SELECT_TYPES, OPERATOR_TYPE } from '@constants';
import moment from 'moment';
import { getOperatorsCiiRating } from '@api/Emissions';
import { FleetCiiRatingTable } from '@components/Sections';
import OperatorCiiRatingChart from './OperatorCiiRatingChart';
import { EmissionsDisclaimer } from '@components';
import AccreditationStatementModal from '../../../../Modals/AccreditationStatementModal';
import { useDispatch, useSelector } from 'react-redux';
import { useSegment } from '@hooks';
import { getLookupOperators, getOperatorType } from '@store/features';
import {
  FLEET_CII_SLICE_KEYS,
  getOprFleetCiiFilters
} from '@store/features/filters/EmissionAnalyticsPage/FleetCiiRatingSlice/FleetCiiRatingSlice';
import { updateOprFleetCiiFilters } from '@store/features/filters/EmissionAnalyticsPage/OperatorComparisonReducer/OperatorComparisonReducer';
import {
  getSelectedOperatorsFromUri,
  getStandardValuesFromUri,
  setSelectedOperatorsToUrl,
  setSliceToUri
} from '@helpers/uriHelpers';

const initUrlParams = (filters, lookupOperators, searchParams) => {
  let newFilters = { ...filters };

  newFilters = getStandardValuesFromUri(newFilters, FLEET_CII_SLICE_KEYS, 6, searchParams);
  const operators = getSelectedOperatorsFromUri(
    'oprCiiSelectedAssets',
    newFilters.selectType,
    lookupOperators,
    searchParams
  );
  if (operators) {
    newFilters.selectedAssets = operators;
  }
  if (isNaN(newFilters.startDate)) {
    newFilters.startDate = moment(newFilters.startDate).year();
  }
  return newFilters;
};

const setUrlParams = (filters, searchParams, setSearchParams) => {
  searchParams = setSliceToUri(filters, FLEET_CII_SLICE_KEYS, 'oprCii', searchParams);
  searchParams = setSelectedOperatorsToUrl(
    filters.selectedAssets,
    filters.selectType,
    'oprCiiSelectedAssets',
    searchParams
  );

  setSearchParams(searchParams);
};

function OperatorFleetCiiRatingSection() {
  const [ciiRatingData, setCiiRatingData] = useState(null);
  const [showStatement, setShowStatement] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noData, setNoData] = useState(true);
  const dispatch = useDispatch();
  const { segment } = useSegment();
  const lookupOperators = useSelector(getLookupOperators);
  const fleetCiiRatingFilters = useSelector(getOprFleetCiiFilters);
  const operatorType = useSelector(getOperatorType);

  useEffect(() => {
    if (lookupOperators?.length) {
      let newFilters = initUrlParams(fleetCiiRatingFilters, lookupOperators, searchParams);
      dispatch(updateOprFleetCiiFilters(newFilters));
      setInit(true);
    }
  }, [lookupOperators]);

  useEffect(() => {
    if (init) {
      setUrlParams(fleetCiiRatingFilters, searchParams, setSearchParams);
    }
  }, [fleetCiiRatingFilters, init]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getCiiRatingData = async () => {
      const { selectType, selectedAssets, startDate: year } = fleetCiiRatingFilters;
      try {
        if (!init || !moment(year).isValid()) {
          return;
        }
        let operatorIds = null;

        if (selectType === OPERATOR_SELECT_TYPES.MANUAL && !selectedAssets.length) {
          setNoData(true);
          setCiiRatingData(null);
          return;
        }
        if (selectType === OPERATOR_SELECT_TYPES.MANUAL && selectedAssets.length) {
          operatorIds = selectedAssets.map(({ id }) => id);
        }

        setLoading(true);
        setNoData(false);
        await getOperatorsCiiRating(
          year,
          operatorIds,
          fleetCiiRatingFilters.minFleetSize,
          fleetCiiRatingFilters.maxFleetSize,
          fleetCiiRatingFilters.minCapacity,
          fleetCiiRatingFilters.maxCapacity,
          fleetCiiRatingFilters.minBuildYear,
          fleetCiiRatingFilters.maxBuildYear,
          segment.id,
          operatorType,
          signal
        )
          .catch(() => {
            setNoData(true);
            setCiiRatingData(null);
            setLoading(false);
          })
          .then((result) => {
            setCiiRatingData(result);
            setNoData(false);
            setLoading(false);
          });
      } catch (error) {
        setNoData(true);
        setLoading(false);
        setCiiRatingData(null);
      }
    };
    getCiiRatingData();
    return () => {
      controller.abort();
    };
  }, [fleetCiiRatingFilters, init, segment, operatorType]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <Stack spacing={2}>
            <Stack spacing={2} sx={{ p: 2 }}>
              <Typography variant={'h6'}>Operator fleet CII rating comparison</Typography>
              <OperatorFleetCiiRatingFilters />
              {!noData && (
                <Stack spacing={2}>
                  <EmissionsDisclaimer setShowStatement={setShowStatement} />
                  <OperatorCiiRatingChart
                    fileName={'operator-fleet-cii'}
                    loading={loading}
                    ciiRatingData={ciiRatingData}
                  />
                </Stack>
              )}

              {!loading && noData && (
                <Grid item xs={12}>
                  <NotFound
                    header={'No Matching Result'}
                    text={'Please change your search parameters'}
                  />
                </Grid>
              )}
            </Stack>
            {!noData && <FleetCiiRatingTable ciiRatingData={ciiRatingData} loading={loading} />}
          </Stack>
        </Paper>
      </Grid>
      <AccreditationStatementModal
        handleClose={() => setShowStatement(false)}
        open={showStatement}
      />
    </Grid>
  );
}

OperatorFleetCiiRatingSection.propTypes = {};

OperatorFleetCiiRatingSection.defaultProps = {};

export default OperatorFleetCiiRatingSection;
