import React from 'react';
import PropTypes from 'prop-types';
import { ArrowSwapButton, Box, Stack, Typography } from '@esgian/esgianui';
import { PortSelect } from '@components/Inputs';
import { useSelector } from 'react-redux';
import { getLookupPorts } from '@store/features';

function SelectPortLoadAndDischarge({
  selectedPortDischarge,
  selectedPortLoad,
  setSelectedPortLoad,
  setSelectedPortDischarge,
  handleSwap,
  insideLabel,
  dischargeLabel,
  loadLabel
}) {
  const lookupPorts = useSelector(getLookupPorts);
  const handlePortSwap = () => {
    let tempLoad = selectedPortLoad ? { ...selectedPortLoad } : null;
    let tempDist = selectedPortDischarge ? { ...selectedPortDischarge } : null;
    if (handleSwap) {
      handleSwap(tempDist, tempLoad);
    } else {
      setSelectedPortLoad(tempDist);
      setSelectedPortDischarge(tempLoad);
    }
  };
  return (
    <Stack direction={'row'}>
      <Stack className={'section-filter'}>
        {!insideLabel && (
          <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
            {loadLabel}
          </Typography>
        )}
        <PortSelect
          label={insideLabel ? loadLabel : null}
          id={'port-select-load'}
          getOptionDisabled={(option) => option.id === selectedPortDischarge?.id}
          placeholder={'Select load port...'}
          selectedPort={selectedPortLoad}
          handleChange={setSelectedPortLoad}
          portList={lookupPorts}
        />
      </Stack>
      <Box alignSelf={'flex-end'} sx={{ pb: 1 }}>
        <ArrowSwapButton handleClick={handlePortSwap} color={'inherit'} />
      </Box>
      <Stack className={'section-filter'}>
        {!insideLabel && (
          <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
            {dischargeLabel}
          </Typography>
        )}
        <PortSelect
          label={insideLabel ? dischargeLabel : null}
          id={'port-select-discharge'}
          getOptionDisabled={(option) => option.id === selectedPortLoad?.id}
          placeholder={'Select discharge port...'}
          selectedPort={selectedPortDischarge}
          handleChange={setSelectedPortDischarge}
          portList={lookupPorts}
        />
      </Stack>
    </Stack>
  );
}

SelectPortLoadAndDischarge.propTypes = {
  selectedPortDischarge: PropTypes.object,
  dischargeLabel: PropTypes.string,
  loadLabel: PropTypes.string,
  selectedPortLoad: PropTypes.object,
  insideLabel: PropTypes.bool,
  setSelectedPortLoad: PropTypes.func.isRequired,
  setSelectedPortDischarge: PropTypes.func.isRequired,
  handleSwap: PropTypes.func
};

SelectPortLoadAndDischarge.defaultProps = {
  selectedPortDischarge: null,
  dischargeLabel: 'Port of Discharge*',
  loadLabel: 'Port of Load*',
  insideLabel: false,
  handleSwap: null
};

export default SelectPortLoadAndDischarge;
