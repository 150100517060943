import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ArrowDropDownIcon,
  ArrowDropUpIcon,
  Box,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography
} from '@esgian/esgianui';
import { TradeRouteMap } from '@components/Maps';
import { getOperatorTradeRoute, getTradeRoutePorts, getVesselOperatorHistory } from '@api';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { formatNumberReadable } from '@helpers';
import PropTypes from 'prop-types';
import { SEGMENT } from '@constants';
import { useSegment } from '@hooks';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getOperatorType } from '@store/features';

function TradeRouteSection() {
  const [tradeRouteData, setTradeRouteData] = useState(null);
  const [portData, setPortData] = useState({});
  const [loading, setLoading] = useState(false);
  const [selectedQuarter, setSelectedQuarter] = useState(
    moment().subtract(1, 'quarter').format('[Q]Q YYYY')
  );
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const operatorType = useSelector(getOperatorType);
  const params = useParams();
  const { segment } = useSegment();

  useEffect(() => {
    window.scrollTo(0, 0);
    const controller = new AbortController();
    const { signal } = controller;

    const getTradeRouteData = async (operatorId) => {
      try {
        setLoading(true);
        setTradeRouteData(null);
        let endDate = moment(selectedQuarter, '[Q]Q YYYY').endOf('quarter').format('YYYY-MM-DD');
        let startDate = moment(selectedQuarter, '[Q]Q YYYY')
          .startOf('quarter')
          .format('YYYY-MM-DD');
        let oprHistoryData = [];
        await getVesselOperatorHistory(signal, {
          start: startDate,
          end: endDate,
          operatorIds: [operatorId],
          segmentTypeId: segment.id
        })
          .then((resp) => {
            oprHistoryData = resp;
          })
          .catch((e) => {});

        const imos = oprHistoryData?.map(({ imo }) => imo) ?? [];

        await Promise.all([
          getTradeRoutePorts(
            {
              operatorId: operatorId,
              date: startDate,
              SegmentTypeId: segment.id,
              OperatorTypeId: operatorType,
              CommercialCategoryId: commercialCategoryType
            },
            signal
          )
            .then((result) => setPortData(result))
            .catch(() => {
              setPortData({});
            }),
          getOperatorTradeRoute(operatorId, signal, {
            startDate: startDate,
            endDate: endDate,
            imos: imos
          })
            .then((result) => {
              setTradeRouteData(result);
            })
            .catch(() => {
              setTradeRouteData(null);
            })
        ]);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };
    getTradeRouteData(params.id);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [selectedQuarter]);

  const {
    fleetSizeFirstPeriod,
    fleetSizeSecondPeriod,
    portCallsFirstPeriod,
    portCallsSecondPeriod,
    firstPeriod,
    secondPeriod
  } = portData || {};

  const getPercent = (val1, val2) => {
    return ((val1 - val2) / val2) * 100;
  };

  const quarterMenuItems = useMemo(() => {
    let quarters = [];
    let startDate = segment.id === SEGMENT.RoRo.id ? '01/01/2021' : '01/01/2022';
    let firstQuarter = moment(startDate, 'DD/MM/YYYY');
    while (firstQuarter.isBefore(moment().subtract(1, 'quarter'))) {
      quarters.push(firstQuarter.format('[Q]Q YYYY'));
      firstQuarter.add(1, 'quarter');
    }
    return quarters;
  }, [segment]);

  const getPercentChange = useCallback(
    (last, second) => {
      if (!last || !second) return '';
      if (selectedQuarter === quarterMenuItems[0]) return '';
      if (last > second) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end'
            }}>
            <ArrowDropUpIcon color={'success'} />
            <Typography
              variant={'caption'}
              sx={{ color: (theme) => theme.palette.success.main }}
              bold>
              {parseFloat(getPercent(last, second).toFixed(2))}%
            </Typography>
          </div>
        );
      } else {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end'
            }}>
            <ArrowDropDownIcon fontSize={'small'} color={'error'} />
            <Typography variant={'caption'} color={'error'} bold>
              {parseFloat(getPercent(last, second).toFixed(2))}%
            </Typography>
          </div>
        );
      }
    },
    [quarterMenuItems, selectedQuarter]
  );
  return (
    <Grid item xs={12}>
      <Paper>
        <Stack>
          <Stack sx={{ p: 2 }}>
            <Typography variant={'h6'}>Global Commercial Footprint</Typography>
            <FormControl size={'small'} variant="standard" sx={{ m: 1, width: 100 }}>
              <Select
                value={selectedQuarter}
                onChange={({ target }) => setSelectedQuarter(target.value)}
                variant={'standard'}>
                {quarterMenuItems?.map((item, i) => (
                  <MenuItem key={`quarter-select-${i}`} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Box sx={{ pr: 2, pt: 1, pb: 1, pl: 2 }}>
            <Divider />
          </Box>
          <Stack
            direction={'row'}
            justifyContent={'space-evenly'}
            sx={{ p: 2 }}
            divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack direction={'row'}>
                <Typography
                  variant={'h6'}
                  sx={{ color: ({ palette }) => palette.charts.twoColorsContrast[1] }}
                  bold>
                  {portCallsFirstPeriod !== undefined
                    ? formatNumberReadable(portCallsFirstPeriod?.toFixed(0))
                    : 0}
                </Typography>
                {getPercentChange(portCallsFirstPeriod, portCallsSecondPeriod)}
              </Stack>
              <Typography variant={'caption'} color={'text.secondary'}>
                {`Number of port calls ${selectedQuarter}`}
              </Typography>
            </Stack>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack direction={'row'}>
                <Typography
                  sx={{ color: ({ palette }) => palette.charts.twoColorsContrast[1] }}
                  variant={'h6'}
                  bold>
                  {firstPeriod?.length !== undefined
                    ? (portCallsFirstPeriod / fleetSizeFirstPeriod).toFixed(2)
                    : 0}
                </Typography>
                {getPercentChange(
                  parseFloat((portCallsFirstPeriod / fleetSizeFirstPeriod).toFixed(2)),
                  parseFloat((portCallsSecondPeriod / fleetSizeSecondPeriod).toFixed(2))
                )}
              </Stack>
              <Typography variant={'caption'} color={'text.secondary'}>
                Average number of port calls per vessel {selectedQuarter}
              </Typography>
            </Stack>
            <Stack sx={{ alignItems: 'center' }}>
              <Stack direction={'row'}>
                <Typography
                  variant={'h6'}
                  bold
                  sx={{ color: ({ palette }) => palette.charts.twoColorsContrast[1] }}>
                  {firstPeriod?.length || 0}
                </Typography>
                {getPercentChange(firstPeriod?.length, secondPeriod?.length)}
              </Stack>
              <Typography variant={'caption'} color={'text.secondary'}>
                Number of unique ports {selectedQuarter}
              </Typography>
            </Stack>
          </Stack>
          <TradeRouteMap
            loading={loading}
            portData={portData}
            tradeRouteData={tradeRouteData}
            selectedQuarter={selectedQuarter}
          />
        </Stack>
      </Paper>
    </Grid>
  );
}

TradeRouteSection.propTypes = {};

TradeRouteSection.defaultProps = {};

export default TradeRouteSection;
