import React, { useState } from 'react';
import {
  Badge,
  Button,
  DatePickerMobi,
  FilterIcon,
  Grid,
  Stack,
  Typography
} from '@esgian/esgianui';
import moment from 'moment';
import { FleetCiiRatingAdvancedFilters } from '@components/Sections/EmissionsAnalytics/OperatorComparisonSection/OperatorFleetCiiRatingSection/OperatorFleetCiiRatingFilters/index';
import OperatorAdvancedMultiSelect from '@components/Inputs/OperatorAdvancedSelect';
import { useSegment } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getLookupOperators } from '@store/features';
import {
  getOprFleetCiiAdvFilterCount,
  getOprFleetCiiFilters
} from '@store/features/filters/EmissionAnalyticsPage/FleetCiiRatingSlice/FleetCiiRatingSlice';
import { updateOprFleetCiiFilters } from '@store/features/filters/EmissionAnalyticsPage/OperatorComparisonReducer/OperatorComparisonReducer';

function OperatorFleetCiiRatingFilters() {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const { segmentMinDate } = useSegment();
  const dispatch = useDispatch();
  const lookupOperators = useSelector(getLookupOperators);
  const fleetCiiRatingFilters = useSelector(getOprFleetCiiFilters);
  const filterCount = useSelector(getOprFleetCiiAdvFilterCount);
  const { startDate: year, selectType, selectedAssets } = fleetCiiRatingFilters;

  const updateFilterValue = (payload) => {
    dispatch(updateOprFleetCiiFilters(payload));
  };

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item lg={2.5} md={6} sm={10} className={'section-filter'}>
          <Stack spacing={1}>
            <Typography variant={'subtitle2'} bold gutterBottom color={'text.primary'}>
              Year*
            </Typography>
            <DatePickerMobi
              sx={{
                inputProps: {
                  InputProps: {},
                  defaultValue: undefined,
                  value: year || '-',
                  placeholder: 'Start date'
                }
              }}
              selectedDate={year ? moment(`${year}`) : null}
              onClose={({ value }) => {
                if (moment(value).year() !== year) {
                  updateFilterValue({ startDate: moment(value).year() });
                }
              }}
              dateFormat={'YYYY'}
              placeholder={'Year...'}
              minStartDate={segmentMinDate}
              disableFuture
              dateWheels={'YYYY'}
            />
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={1}>
            <Typography variant={'subtitle2'} bold>
              Operators*
            </Typography>
            <OperatorAdvancedMultiSelect
              selected={selectedAssets}
              autoEnabledText={'Displaying the top 10 operators given the selected year'}
              handleClose={(values) => {
                updateFilterValue({
                  selectType: values.operatorType,
                  selectedAssets: values.selectedOperators
                });
              }}
              loading={!lookupOperators}
              operators={lookupOperators}
              selectType={selectType}
            />
          </Stack>
        </Grid>
        <Grid item alignSelf={'end'} sx={{ pb: '0.7em' }}>
          <Badge badgeContent={filterCount} color="error">
            <Button
              startIcon={<FilterIcon />}
              variant={'text'}
              onClick={() => setAdvancedOpen(true)}>
              Advanced Filter
            </Button>
          </Badge>
        </Grid>
        <FleetCiiRatingAdvancedFilters
          updateFilterValue={updateFilterValue}
          advancedOpen={advancedOpen}
          setAdvancedOpen={setAdvancedOpen}
        />
      </Grid>
    </Grid>
  );
}

OperatorFleetCiiRatingFilters.propTypes = {};

OperatorFleetCiiRatingFilters.defaultProps = {};

export default OperatorFleetCiiRatingFilters;
