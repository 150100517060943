import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRightIcon, Stack, Typography } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';

function AveragePortTimeDashboardDetails({ details }) {
  return (
    <Stack>
      <Typography variant={'body1'} align="left" sx={{ pb: 2 }}>
        Monthly Comparison: Latest completed month vs previous month
      </Typography>
      <ul>
        <li style={{ marginBottom: '4px' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body1'}> Average turn around time:</Typography>
            <Typography color={'primary'} variant={'body1'} align={'right'}>
              {typeof details.avgTurnAroundDays === 'number'
                ? details.avgTurnAroundDays?.toFixed(2)
                : '-'}{' '}
              days/vessel ({`${details.avgTurnAroundPercent?.toFixed(2)}%`})
            </Typography>
          </Stack>
        </li>
        <li style={{ marginBottom: '4px' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body1'}> Total time at terminal:</Typography>
            <Typography color={'primary'} variant={'body1'} align={'right'}>
              {typeof details.totalTerminalDays === 'number'
                ? details.totalTerminalDays?.toFixed(2)
                : '-'}{' '}
              days ({`${details.totalTerminalPercent?.toFixed(2)}%`})
            </Typography>
          </Stack>
        </li>
        <li style={{ marginBottom: '4px' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body1'}> Port Calls:</Typography>
            <Typography color={'primary'} variant={'body1'} align={'right'}>
              {typeof details?.portCalls === 'number' ? details?.portCalls : '-'} calls (
              {`${details?.portCallsPercent?.toFixed(2)}%`})
            </Typography>
          </Stack>
        </li>
        <li style={{ marginBottom: '4px' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body1'}> Supply/Demand impact:</Typography>
            <Typography color={'primary'} variant={'body1'} align={'right'}>
              {typeof details.supplyDemandImpact === 'number'
                ? details.supplyDemandImpact?.toFixed(2)
                : '-'}{' '}
              ship equivalent
            </Typography>
          </Stack>
        </li>
        <li style={{ marginBottom: '4px' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body1'}>Highest rate of increase (Avg Port Time)</Typography>
            <ProfileLink
              uriParams={'section=2'}
              profile={'port'}
              id={details.highestIncreasePortId}
              name={
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Typography>{details.highestIncreasePortName}</Typography>
                  <ChevronRightIcon
                    fontSize={'small'}
                    color={'primary'}
                    style={{ verticalAlign: 'middle', marginLeft: '-4px' }}
                  />
                </Stack>
              }
            />
          </Stack>
        </li>
        <li style={{ marginBottom: '4px' }}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant={'body1'}>Highest rate of decrease (Avg Port Time)</Typography>
            <ProfileLink
              uriParams={'section=2'}
              profile={'port'}
              id={details.highestDecreasePortId}
              name={
                <Stack direction={'row'} alignItems={'center'} spacing={1}>
                  <Typography>{details.highestDecreasePortName}</Typography>
                  <ChevronRightIcon
                    fontSize={'small'}
                    color={'primary'}
                    style={{ verticalAlign: 'middle', marginLeft: '-4px' }}
                  />
                </Stack>
              }
            />
          </Stack>
        </li>
      </ul>
    </Stack>
  );
}

AveragePortTimeDashboardDetails.propTypes = {
  details: PropTypes.object
};

AveragePortTimeDashboardDetails.defaultProps = {
  details: {}
};

export default AveragePortTimeDashboardDetails;
