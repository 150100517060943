import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getCommercialCategoryType, setCommercialCategoryType } from '@store/features';
import { Button, Divider, Paper, Stack, Typography } from '@esgian/esgianui';
import { COMMERCIAL_CATEGORY_TYPE } from '@constants';
import {
  COM_CAT_TYPE_ALL_TEXT,
  COM_CAT_TYPE_DEEP_SEA_CORE_TEXT,
  COM_CAT_TYPE_DEEP_SEA_EXT_TEXT,
  COM_CAT_TYPE_OTHER_TEXT,
  COM_CAT_TYPE_SHORT_SEA_EUR_TEXT
} from '@constants/tooltips';
import { TextWithTooltipIcon } from '@components';

function CommercialCategoryTypeSelect({ typeCategoryVesselsCount }) {
  const dispatch = useDispatch();
  const comCatType = useSelector(getCommercialCategoryType);
  const currentUrl = window.location.href;
  const isOperator = currentUrl.includes('/operator/');

  return (
    <Paper>
      <Stack
        direction={'row'}
        divider={<Divider sx={{ height: 'inherit' }} orientation={'vertical'} />}>
        <Button
          disabled={isOperator ? !typeCategoryVesselsCount?.hasOwnProperty(0) : false}
          fullWidth
          onClick={() => dispatch(setCommercialCategoryType(COMMERCIAL_CATEGORY_TYPE.ALL))}
          sx={{ borderRadius: '4px 0px 0px 4px' }}
          variant={comCatType === COMMERCIAL_CATEGORY_TYPE.ALL ? 'contained' : 'text'}
          id={'btn-commercial-category-type-select-all'}>
          All
          <TextWithTooltipIcon
            label={
              <Typography variant={'contained'} style={{ visibility: 'hidden' }}>
                .
              </Typography>
            }
            tooltipText={COM_CAT_TYPE_ALL_TEXT}
          />
        </Button>
        <Button
          disabled={isOperator ? !typeCategoryVesselsCount?.hasOwnProperty(1) : false}
          fullWidth
          onClick={() =>
            dispatch(setCommercialCategoryType(COMMERCIAL_CATEGORY_TYPE.DEEP_SEA_CORE))
          }
          sx={{ borderRadius: '0px 0px 0px 0px' }}
          variant={comCatType === COMMERCIAL_CATEGORY_TYPE.DEEP_SEA_CORE ? 'contained' : 'text'}
          id={'btn-commercial-category-type-select-deep-sea-core'}>
          Deep Sea - Core
          <TextWithTooltipIcon
            label={
              <Typography variant={'contained'} style={{ visibility: 'hidden' }}>
                .
              </Typography>
            }
            tooltipText={COM_CAT_TYPE_DEEP_SEA_CORE_TEXT}
          />
        </Button>
        <Button
          disabled={isOperator ? !typeCategoryVesselsCount?.hasOwnProperty(2) : false}
          fullWidth
          onClick={() => dispatch(setCommercialCategoryType(COMMERCIAL_CATEGORY_TYPE.DEEP_SEA_EXT))}
          sx={{ borderRadius: '0px 4px 4px 0px' }}
          variant={comCatType === COMMERCIAL_CATEGORY_TYPE.DEEP_SEA_EXT ? 'contained' : 'text'}
          id={'btn-commercial-category-type-select-deep-sea-ext'}>
          Deep Sea - Extended
          <TextWithTooltipIcon
            label={
              <Typography variant={'contained'} style={{ visibility: 'hidden' }}>
                .
              </Typography>
            }
            tooltipText={COM_CAT_TYPE_DEEP_SEA_EXT_TEXT}
          />
        </Button>
        <Button
          disabled={isOperator ? !typeCategoryVesselsCount?.hasOwnProperty(3) : false}
          fullWidth
          onClick={() =>
            dispatch(setCommercialCategoryType(COMMERCIAL_CATEGORY_TYPE.SHORT_SEA_EUR))
          }
          sx={{ borderRadius: '0px 4px 4px 0px' }}
          variant={comCatType === COMMERCIAL_CATEGORY_TYPE.SHORT_SEA_EUR ? 'contained' : 'text'}
          id={'btn-commercial-category-type-select-short-sea-eur'}>
          Short Sea - Europe
          <TextWithTooltipIcon
            label={
              <Typography variant={'contained'} style={{ visibility: 'hidden' }}>
                .
              </Typography>
            }
            tooltipText={COM_CAT_TYPE_SHORT_SEA_EUR_TEXT}
          />
        </Button>
        <Button
          disabled={isOperator ? !typeCategoryVesselsCount?.hasOwnProperty(4) : false}
          fullWidth
          onClick={() => dispatch(setCommercialCategoryType(COMMERCIAL_CATEGORY_TYPE.OTHER))}
          sx={{ borderRadius: '0px 4px 4px 0px' }}
          variant={comCatType === COMMERCIAL_CATEGORY_TYPE.OTHER ? 'contained' : 'text'}
          id={'btn-commercial-category-type-select-other'}>
          Other
          <TextWithTooltipIcon
            label={
              <Typography variant={'contained'} style={{ visibility: 'hidden' }}>
                .
              </Typography>
            }
            tooltipText={COM_CAT_TYPE_OTHER_TEXT}
          />
        </Button>
      </Stack>
    </Paper>
  );
}

CommercialCategoryTypeSelect.propTypes = {};

CommercialCategoryTypeSelect.defaultProps = {};

export default CommercialCategoryTypeSelect;
