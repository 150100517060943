import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart } from '@esgian/esgianui';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

const getOptions = (categories, theme, user, fileName) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, sixColorsStacked }
    }
  } = theme;
  return {
    chart: {
      type: 'bar',
      height: 350,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      background: background,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },
        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      },
      stacked: true
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val?.toFixed(0);
      }
    },
    theme: {
      mode: mode
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        horizontal: false
      }
    },
    xaxis: {
      categories: categories
    },
    fill: {
      opacity: 1
    },
    colors: sixColorsStacked,
    yaxis: {
      decimalsInFloat: 0,
      max: 100,
      title: {
        text: '% of time'
      }
    },
    labels: {
      formatter: function (val) {
        return `${val?.toFixed(0)}%`;
      }
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    tooltip: {
      shared: true,
      intersect: false,
      followCursor: true,
      y: {
        formatter: function (value) {
          if (value !== null && value !== undefined) {
            return value.toFixed(0) + '%';
          }
          return ' - ';
        }
      }
    },
    legend: {
      position: 'top',
      offsetX: -35,
      horizontalAlign: 'left',
      itemMargin: {
        horizontal: 10,
        vertical: 5
      }
    },
    noData: {
      text: 'There is no data to display'
    }
  };
};
function OperatorActivityProfileChart({ loading, activityStatusData, fileName }) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const user = useSelector(getUser);
  const { theme } = useTheme();

  useEffect(() => {
    if (!activityStatusData) return;
    setSeries([
      {
        name: `Transit`,
        group: 'operator',
        data:
          activityStatusData?.operatorMonthlyPercentActivityStatus?.map(
            ({ percentMonthlyTransit }) => parseFloat(percentMonthlyTransit) || 0
          ) || []
      },
      {
        name: `Standby`,
        group: 'operator',
        data:
          activityStatusData?.operatorMonthlyPercentActivityStatus?.map(
            ({ percentMonthlyStandBy }) => percentMonthlyStandBy || 0
          ) || []
      },
      {
        name: `At Port`,
        group: 'operator',
        data:
          activityStatusData?.operatorMonthlyPercentActivityStatus?.map(
            ({ percentMonthlyAtPort }) => percentMonthlyAtPort || 0
          ) || []
      },
      {
        name: `Segment Transit`,
        group: 'segment',
        data:
          activityStatusData?.segmentTypeIdMonthlyPercentActivityStatus?.map(
            ({ percentMonthlyTransit }) => parseFloat(percentMonthlyTransit) || 0
          ) || []
      },
      {
        name: `Segment Standby`,
        group: 'segment',
        data:
          activityStatusData?.segmentTypeIdMonthlyPercentActivityStatus?.map(
            ({ percentMonthlyStandBy }) => percentMonthlyStandBy || 0
          ) || []
      },
      {
        name: `Segment At port`,
        group: 'segment',
        data:
          activityStatusData?.segmentTypeIdMonthlyPercentActivityStatus?.map(
            ({ percentMonthlyAtPort }) => percentMonthlyAtPort || 0
          ) || []
      }
    ]);
  }, [activityStatusData]);

  useEffect(() => {
    let categories =
      activityStatusData?.operatorMonthlyPercentActivityStatus?.map(({ monthStr }) => monthStr) ||
      [];
    setOptions(getOptions(categories, theme, user, fileName));
  }, [theme, series]);

  return (
    <ApexChart
      loading={loading || !options || !series?.length}
      height={480}
      options={options}
      type={'bar'}
      data={series}
    />
  );
}
OperatorActivityProfileChart.propTypes = {
  loading: PropTypes.bool,
  fileName: PropTypes.string,
  activityStatusData: PropTypes.object,
  loadingActivityStatus: PropTypes.bool
};
OperatorActivityProfileChart.defaultProps = {
  activityStatusData: null,
  fileName: '',
  loading: false,
  loadingActivityStatus: false
};
export default OperatorActivityProfileChart;
