import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Paper, Typography } from '@esgian/esgianui';
import ValueDisplay from '@components/ValueDisplay/ValueDisplay';
import { ProfileLink } from '@components/Links';
import { formatNumberReadable } from '@helpers';

function VesselOverview({ showTitle, vesselsData, loading, isRoRo, title, width }) {
  const {
    imo,
    buildYear,
    deadweight,
    grossTonnage,
    operatorShortName,
    operatorLongName,
    ownerShortName,
    ownerLongName,
    class: vesselClass,
    flagState,
    capacity,
    operatorId,
    ownerId,
    maxLiftingCapacity,
    numCranes,
    gears
  } = vesselsData || {};
  return (
    <Paper sx={{ p: 2, height: '100%', width }}>
      <Grid container spacing={2}>
        {showTitle && (
          <>
            <Grid item container spacing={2} xs={12}>
              <Grid item alignSelf={'center'}>
                <Typography variant={'h6'} id={'vessel-overview-title'}>
                  {title ?? 'Overview'}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <Grid container direction={'column'} spacing={1}>
            <ValueDisplay
              title={'IMO'}
              value={imo}
              className={'vessel-details'}
              loading={loading}
            />
            <ValueDisplay
              title={'Build Year'}
              value={buildYear}
              className={'vessel-details'}
              loading={loading}
            />
            <ValueDisplay
              title={'DWT'}
              value={`${formatNumberReadable(deadweight)} mt`}
              className={'vessel-details'}
              loading={loading}
            />
            <ValueDisplay
              title={'GT'}
              value={grossTonnage !== null ? `${formatNumberReadable(grossTonnage)}` : '-'}
              className={'vessel-details'}
              loading={loading}
            />
            <ValueDisplay
              title={isRoRo ? 'Capacity' : 'Max Lifting Capacity'}
              value={
                isRoRo
                  ? capacity !== null
                    ? `${formatNumberReadable(capacity)} CEU`
                    : '-'
                  : maxLiftingCapacity !== null
                  ? formatNumberReadable(maxLiftingCapacity)
                  : '-'
              }
              className={'vessel-details'}
              loading={loading}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container direction={'column'} spacing={1}>
            <ValueDisplay loading={loading} title={'Operator'} className={'vessel-details'}>
              <ProfileLink
                profile={'operator'}
                name={`${operatorLongName} (${operatorShortName})`}
                id={operatorId}
              />
            </ValueDisplay>
            {isRoRo && (
              <ValueDisplay title={'Owner'} loading={loading} className={'vessel-owner'}>
                <ProfileLink
                  profile={'owner'}
                  name={`${ownerLongName} (${ownerShortName})`}
                  id={ownerId}
                />
              </ValueDisplay>
            )}
            <ValueDisplay
              title={'Flag'}
              value={flagState}
              loading={loading}
              className={'vessel-details'}
            />
            <ValueDisplay
              title={'Class'}
              value={vesselClass !== null ? vesselClass : '-'}
              loading={loading}
              className={'vessel-details'}
            />
            {!isRoRo && (
              <>
                <ValueDisplay
                  title={'No. Cranes'}
                  value={numCranes !== null ? numCranes : '-'}
                  className={'vessel-details'}
                  loading={loading}
                />
                <ValueDisplay
                  title={'Gears in Overview Box'}
                  value={gears !== null ? gears : '-'}
                  loading={loading}
                  className={'vessel-class'}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

VesselOverview.propTypes = {
  vesselsData: PropTypes.object,
  loading: PropTypes.bool,
  isRoRo: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showTitle: PropTypes.bool
};

VesselOverview.defaultProps = {
  vesselsData: null,
  loading: false,
  isRoRo: false,
  showTitle: true
};

export default VesselOverview;
