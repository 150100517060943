import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table, Typography } from '@esgian/esgianui';
import { checkUserHasAccess, getTableCellRender } from '@helpers';
import { ProfileLink } from '@components/Links';
import { useTheme } from '@hooks';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';

function ServiceFrequencyTable({ performanceData, dateRangeList }) {
  const [data, setData] = useState([]);
  const [lowScores, setLowScores] = useState([0, 0, 0, 0, 0]);
  const [highScores, setHighScores] = useState([0, 0, 0, 0, 0]);
  //total,avg,min,max,consScore
  const [columns, setColumns] = useState([]);
  const { theme } = useTheme();
  const user = useSelector(getUser);

  useEffect(() => {
    if (performanceData && dateRangeList.length) {
      let tempData = [];
      // Structure operator data
      let { operatorPerformance, segmentPerformance } = performanceData;
      let { operatorFrequencyResult } = operatorPerformance;
      operatorFrequencyResult = operatorFrequencyResult[0];
      const {
        frequencyMax,
        frequencyMin,
        frequencyStats,
        frequencyTotal,
        operatorId,
        operatorLongName,
        operatorMonthlyFrequencyResults
      } = operatorFrequencyResult;
      let { consistencyScore: oprScore, average } = frequencyStats;
      average = parseFloat(average?.toFixed(2));

      let operatorObj = {
        consistencyScore: oprScore,
        average: average,
        total: frequencyTotal,
        min: frequencyMin,
        max: frequencyMax,
        operatorName: operatorLongName,
        operatorId: operatorId
      };
      dateRangeList?.forEach((monthDate, i) => {
        let monthObj = {};
        monthObj[monthDate.split(' ').join('_')] = operatorMonthlyFrequencyResults[i]?.portCalls;
        operatorObj = { ...operatorObj, ...monthObj };
      });
      tempData.push(operatorObj);
      const { segmentFrequencyResult, numberOfOperatorsInSegment } = segmentPerformance;

      if (numberOfOperatorsInSegment > 1) {
        const {
          frequencyMax: segMax,
          frequencyMin: segMin,
          frequencyStats: segStats,
          frequencyTotal: segTot,
          segmentOperatorMonthlyPerformance: segResultsMonth
        } = segmentFrequencyResult;
        let { consistencyScore: segScore, average: segAvg } = segStats;
        segAvg = parseFloat(segAvg?.toFixed(2));
        let segObj = {
          consistencyScore: segScore,
          average: segAvg,
          total: parseFloat(segTot?.toFixed(1)),
          min: parseFloat(segMin?.toFixed(1)),
          max: parseFloat(segMax?.toFixed(1)),
          operatorName: 'Segment Average',
          operatorId: null
        };
        dateRangeList?.forEach((monthDate, i) => {
          let monthObj = {};
          monthObj[monthDate.split(' ').join('_')] =
            segResultsMonth[i]?.segmentFrequencyAverage?.toFixed(1);
          segObj = { ...segObj, ...monthObj };
        });
        let tempMin = [0, 0, 0, 0, 0];
        let tempMax = [0, 0, 0, 0, 0];
        if (segTot >= frequencyTotal) {
          tempMin[0] = frequencyTotal;
          tempMax[0] = segTot;
        } else {
          tempMax[0] = frequencyTotal;
          tempMin[0] = segTot;
        }

        if (segAvg >= average) {
          tempMin[1] = average;
          tempMax[1] = segAvg;
        } else {
          tempMax[1] = average;
          tempMin[1] = segAvg;
        }
        if (segMin >= frequencyMin) {
          tempMin[2] = frequencyMin;
          tempMax[2] = segMin;
        } else {
          tempMax[2] = frequencyMin;
          tempMin[2] = segMin;
        }
        if (segMax >= frequencyMax) {
          tempMin[3] = frequencyMax;
          tempMax[3] = segMax;
        } else {
          tempMax[3] = frequencyMax;
          tempMin[3] = segMax;
        }
        if (segScore >= oprScore) {
          tempMin[4] = oprScore;
          tempMax[4] = segScore;
        } else {
          tempMax[4] = oprScore;
          tempMin[4] = segScore;
        }

        setHighScores(tempMax);
        setLowScores(tempMin);
        tempData.push(segObj);
      }
      setData(tempData);
    }
  }, [performanceData, dateRangeList]);

  useEffect(() => {
    if (!performanceData) {
      return;
    }
    const {
      palette: {
        tables: {
          boxShadow: { insetRight }
        }
      }
    } = theme;
    let columnDef = [
      {
        name: 'operatorName',
        label: 'Operator',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                boxShadow: insetRight
              }
            };
          },
          customBodyRender: (dataIndex, rowIndex) => {
            const { rowData } = rowIndex;
            return dataIndex !== null && rowData[1] !== null ? (
              <ProfileLink profile={'operator'} name={dataIndex} id={rowData[1]} />
            ) : (
              <Typography variant={'body2'}>{dataIndex}</Typography>
            );
          }
        }
      },
      {
        name: 'operatorId',
        options: { display: false, viewColumns: false, filter: false }
      }
    ];

    const tableEnd = [
      {
        name: 'total',
        label: 'Total',
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellProps: () => {
            return {
              style: {
                boxShadow: '-3px 0px 2px -1px rgb(0 0 0 / 20%)'
              }
            };
          },
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[0], lowScores[0], dataIndex);
          }
        }
      },
      {
        name: 'average',
        label: 'Avg',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[1], lowScores[1], dataIndex);
          }
        }
      },
      {
        name: 'min',
        label: 'Min',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[2], lowScores[2], dataIndex);
          }
        }
      },
      {
        name: 'max',
        label: 'Max',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[3], lowScores[3], dataIndex);
          }
        }
      },
      {
        name: 'consistencyScore',
        label: 'Consistency Score',
        options: {
          sort: true,
          sortThirdClickReset: true,
          customBodyRender: (dataIndex) => {
            return getTableCellRender(highScores[4], lowScores[4], dataIndex);
          }
        }
      }
    ];
    dateRangeList?.map((value, i) => {
      columnDef.push({
        name: value.split(' ').join('_'),
        label: value,
        options: {
          sort: true,
          sortThirdClickReset: true,
          setCellHeaderProps: () => {
            let shadow = {};
            if (i === 0) {
              shadow = 'inset 3px 0px 2px -1px rgb(0 0 0 / 20%)';
            } else if (i === dateRangeList.length - 1) {
              shadow = 'inset -3px 0px 2px -1px rgb(0 0 0 / 20%)';
            }
            return {
              style: {
                boxShadow: `${shadow}`
              }
            };
          },
          customBodyRender: (dataIndex) => {
            return <Typography sx={{ p: 1, m: '4px' }}>{dataIndex}</Typography>;
          }
        }
      });
    });
    setColumns([...columnDef, ...tableEnd]);
  }, [dateRangeList, data]);

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: 'frequency-analysis'
    },
    print: false,
    elevation: 0,
    viewColumns: false,
    pagination: false,
    toolbar: checkUserHasAccess(user, true),
    rowsPerPageOptions: [10, 25, 50, 100]
  };

  return (
    <Table
      customStyle={{
        MUIDataTableFilterList: { display: 'none' },
        MUIDataTableToolbar: {
          styleOverrides: {
            root: {
              minHeight: 0
            }
          }
        }
      }}
      loading={!data.length}
      mode={theme.mode}
      title={''}
      data={data.length ? data : []}
      columns={columns}
      options={options}
    />
  );
}

ServiceFrequencyTable.propTypes = {
  loading: PropTypes.bool,
  performanceData: PropTypes.object,
  dateRangeList: PropTypes.arrayOf(PropTypes.string)
};

ServiceFrequencyTable.defaultProps = {
  loading: false,
  performanceData: null,
  startDate: null,
  endDate: null,
  dateRangeList: []
};

export default ServiceFrequencyTable;
