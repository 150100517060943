import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  SearchIcon,
  Skeleton,
  Typography
} from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { useTheme } from '@hooks/useTheme';
import { useSelector } from 'react-redux';
import { getLookupRegions } from '@store/features';

function RegionListDisplay({ loading, map, setHoverRegion }) {
  const [query, setQuery] = useState('');
  const [regionsList, setRegionsList] = useState([]);
  const lookupRegions = useSelector(getLookupRegions);
  const { customScrollbar } = useTheme();

  useEffect(() => {
    if (query !== '') {
      setRegionsList(
        lookupRegions.filter(({ name }) => {
          return name.toLowerCase().includes(query.toLowerCase());
        })
      );
    } else {
      setRegionsList(lookupRegions);
    }
  }, [lookupRegions, query]);

  const handleRegionHover = (id, name) => {
    map.current.getCanvas().style.cursor = 'pointer';
    map.current?.setFilter('cfh', ['==', 'id', id]);
    map.current?.setFilter('cb', ['==', 'id', id]);
    setHoverRegion(name);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: '100%' }}>
      <div
        style={{
          display: 'flex',
          padding: '0px'
        }}>
        {(loading || lookupRegions.length) && (
          <Grid container>
            <Grid
              item
              xs={12}
              sx={{
                padding: '16px 16px 16px 16px',
                zIndex: 10
              }}>
              <OutlinedInput
                fullWidth
                value={query}
                onChange={({ target }) => setQuery(target.value)}
                inputProps={{ style: { padding: '8px' } }}
                endAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
                placeholder={'Search region...'}
              />
            </Grid>

            {(!!regionsList?.length || loading) && (
              <Grid item xs={12} sx={{ pt: '0px !important' }}>
                <List
                  sx={{
                    p: 0,
                    width: '100%',
                    bgcolor: 'background.paper',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: `calc(80vh - 72px)`,
                    '& ul': { padding: 0 },
                    ...customScrollbar
                  }}>
                  {loading &&
                    Array.apply(null, { length: 12 }).map((e, i) => (
                      <React.Fragment key={`skeleton-${i}`}>
                        <ListItem
                          sx={{
                            backgroundColor: ({ palette }) =>
                              i % 2 === 0 ? palette.background.paper : palette.background.default
                          }}>
                          <ListItemText>
                            <Skeleton width={'100%'} />
                          </ListItemText>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    ))}
                  {!loading &&
                    regionsList?.map(({ name, regionId }, i) => (
                      <React.Fragment key={`${regionId}-${i}`}>
                        <ListItem
                          onMouseEnter={() => handleRegionHover(regionId, name)}
                          onMouseLeave={() => handleRegionHover('', null)}
                          sx={{
                            backgroundColor: ({ palette }) =>
                              i % 2 === 0 ? palette.background.paper : palette.background.default
                          }}>
                          <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                            <ProfileLink profile={'region'} name={name} id={regionId} />
                          </ListItemText>
                        </ListItem>
                      </React.Fragment>
                    ))}
                </List>
              </Grid>
            )}
            {!regionsList?.length && !loading && (
              <Grid item xs={12} sx={{ p: 2 }}>
                <Typography>No matching search results</Typography>
              </Grid>
            )}
          </Grid>
        )}
      </div>
    </div>
  );
}

RegionListDisplay.propTypes = {
  loading: PropTypes.bool,
  map: PropTypes.object,
  setHoverRegion: PropTypes.func.isRequired
};

RegionListDisplay.defaultProps = {
  loading: false,
  map: null
};

export default RegionListDisplay;
