import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DateRangeMobi,
  FilterIcon,
  Grid,
  OperatorTreeSelect,
  TextField,
  Stack,
  Typography
} from '@esgian/esgianui';
import moment from 'moment';
import { getLocationTypeId } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { getDateTimeFormat, getLookupVesselHierarchy, getLookupOperators } from '@store/features';
import { getMonthDateRangePickerSx, getStartEndMonth } from '@helpers/DatePickerHelpers';
import { useSegment } from '@hooks';
import AdvancedVoyageAnalyticsFilters from '@components/Sections/ShipAnalytics/VoyageAnalyticsSection/VoyageAnalyticsFilters/AdvancedVoyageAnalyticsFilters';
import {
  getCompletedVoyagesFilters,
  updateCompletedVoyagesFilters
} from '@store/features/filters/VoyageAnalyticsPage/CompletedVoyage/CompletedVoyagesSlice';
import LocationLoadAndDischargeSelect from '@components/Inputs/LocationLoadAndDischargeSelect/LocationLoadAndDischargeSelect';

function VoyageAnalyticsFilters({ handleApplyFilters }) {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const lookupVesselHierarchy = useSelector(getLookupVesselHierarchy);
  const lookupOperators = useSelector(getLookupOperators);
  const dispatch = useDispatch();
  const { segmentMinDate } = useSegment();
  const completedVoyagesFilters = useSelector(getCompletedVoyagesFilters);
  const dateFormat = useSelector(getDateTimeFormat);

  const { fromId, toId, startDate, endDate, maxTransitTime, operatorIds, imos, fromType, toType } =
    completedVoyagesFilters ?? {};

  const updateFilterValue = (payload) => {
    setIsLoading(true);
    dispatch(updateCompletedVoyagesFilters(payload));
    setIsLoading(false);
  };

  const getSelectedOperators = () => {
    return lookupOperators.filter((operator) => operatorIds?.includes(operator.id));
  };

  const getSelectedVessels = () => {
    return lookupVesselHierarchy?.vessels?.filter((vessel) => imos?.includes(vessel.id)) || [];
  };

  return (
    <Grid item xs={12}>
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <LocationLoadAndDischargeSelect
            selectedLocationOfDischarge={toId}
            setSelectedLocationOfDischarge={(val) =>
              updateFilterValue({
                toId: val,
                toType: getLocationTypeId(val.type)
              })
            }
            selectedLocationOfLoad={fromId}
            setSelectedLocationOfLoad={(val) =>
              updateFilterValue({ fromId: val, fromType: getLocationTypeId(val.type) })
            }
            handleSwap={() => {
              updateFilterValue({ fromId: toId, toId: fromId });
            }}
          />
          <Stack className={'section-filter'}>
            <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
              Period*
            </Typography>
            <DateRangeMobi
              sx={getMonthDateRangePickerSx(startDate, endDate)}
              onClose={({ value }) => {
                const { start, end } = getStartEndMonth(value, dateFormat);
                updateFilterValue({
                  startDate: start,
                  endDate: end
                });
              }}
              dateFormat={dateFormat}
              minStartDate={segmentMinDate}
              disableFuture
              startDate={moment(startDate, dateFormat)}
              endDate={moment(endDate, dateFormat)}
              placeholder={'Period'}
              dateWheels={'MMM YYYY'}
            />
          </Stack>
          <Stack className={'section-filter'}>
            <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
              Select Operators/Vessels
            </Typography>
            <OperatorTreeSelect
              handleClose={(value) => {
                const selectedImos = value.selected.map((operator) => operator.id);
                updateFilterValue({ imos: selectedImos?.length > 0 ? selectedImos : null });
              }}
              handleSelectTypeChange={() => {}}
              lookupVesselHierarchy={lookupVesselHierarchy}
              loading={!lookupVesselHierarchy}
            />
          </Stack>
          <Stack className={'section-filter'}>
            <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
              Max Transit Time
            </Typography>
            <TextField
              sx={{ width: '170px' }}
              placeholder={'Max transit time...'}
              type={'number'}
              value={maxTransitTime || ''}
              onChange={({ target }) => {
                const value = Number(target.value);
                if (value <= 100) {
                  updateFilterValue({ maxTransitTime: value });
                }
              }}
              InputProps={{
                inputProps: {
                  max: 100
                }
              }}
            />
          </Stack>
          <Stack className={'section-filter'}>
            <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
              &nbsp;
            </Typography>
            <Button
              onClick={handleApplyFilters}
              variant={'contained'}
              color={'primary'}
              sx={{ height: 'min-content' }}>
              Apply Filters
            </Button>
          </Stack>
        </Stack>
        <Stack>
          <Typography variant={'subtitle2'} bold gutterBottom color={'text.secondary'}>
            &nbsp;
          </Typography>
          <Button
            sx={{ height: 'min-content' }}
            startIcon={<FilterIcon />}
            variant={'text'}
            onClick={() => setAdvancedOpen(true)}>
            Advanced Filter
          </Button>
        </Stack>
      </Stack>
      <AdvancedVoyageAnalyticsFilters
        updateFilterValue={updateFilterValue}
        advancedOpen={advancedOpen}
        setAdvancedOpen={setAdvancedOpen}
        handleApplyFilters={handleApplyFilters}
      />
    </Grid>
  );
}

VoyageAnalyticsFilters.propTypes = {
  handleApplyFilters: PropTypes.func.isRequired
};

VoyageAnalyticsFilters.defaultProps = {};

export default VoyageAnalyticsFilters;
