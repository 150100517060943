import React from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Switch,
  Typography
} from '@esgian/esgianui';

import PropTypes from 'prop-types';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getGeneralSlice } from '@store/features';

const VoyageGroupFilter = ({ multiSelectOptions, selectedVesselsId, onSelectVessels }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { themeMode } = useSelector(getGeneralSlice);

  const isColorCodeChecked = searchParams.get('showColorCode') === 'true';
  const isShowPortChecked = searchParams.get('showPorts') === 'true';

  const updateSearchParams = (key, value) => {
    setSearchParams((p) => {
      p.set(key, value);
      return p;
    });
  };

  const exitFullScreenMode = () => {
    setSearchParams((p) => {
      p.set('view', 'list');
      p.delete('showColorCode');
      p.delete('showPorts');
      return p;
    });
  };

  return (
    <Paper
      style={{
        position: 'absolute',
        zIndex: 1,
        left: '16px',
        top: '16px',
        right: '16px',
        backgroundColor: themeMode ? '#191919E5' : '#FFFFFFE5',
        width: '100%'
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          p: 2
        }}>
        <Button
          variant="contained"
          onClick={exitFullScreenMode}
          startIcon={<img src="/assets/images/full-screen-exit-icon.png" alt="Fullscreen Exit" />}>
          <Typography color="black">Exit</Typography>
        </Button>

        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="vessels">Vessels</InputLabel>
          <Select
            labelId="vessels"
            id="select-multiple-vessels"
            multiple
            value={selectedVesselsId}
            onChange={(e) => {
              const value = e.target.value;
              const selectedVesselsImo = typeof value === 'string' ? value.split(',') : value;
              onSelectVessels(selectedVesselsImo);
            }}
            input={<OutlinedInput label="Vessels" />}
            renderValue={(selected) =>
              selected.length === multiSelectOptions.length
                ? 'Vessels: All'
                : selected
                    .map((id) => multiSelectOptions.find((v) => v.imo === id)?.name ?? '')
                    .join(', ')
            }>
            {multiSelectOptions.map((v) => {
              const isChecked = selectedVesselsId.includes(v.imo);
              return (
                <MenuItem key={v.imo} value={v.imo}>
                  <Checkbox checked={isChecked} />
                  <ListItemText primary={v.name} />
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControlLabel
          control={
            <Switch
              defaultChecked
              checked={isColorCodeChecked}
              onChange={() => updateSearchParams('showColorCode', !isColorCodeChecked)}
            />
          }
          label="Show color code"
        />
        <FormControlLabel
          control={
            <Switch
              defaultChecked
              checked={isShowPortChecked}
              onChange={() => updateSearchParams('showPorts', !isShowPortChecked)}
            />
          }
          label="Show Ports"
        />
      </Box>
    </Paper>
  );
};

VoyageGroupFilter.propTypes = {
  multiSelectOptions: PropTypes.array,
  selectedVesselsId: PropTypes.array,
  onSelectVessels: PropTypes.func
};

export default VoyageGroupFilter;
