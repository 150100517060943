import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CircularProgress, Paper, Stack, Typography } from '@esgian/esgianui';
import { useNavigate } from 'react-router-dom';
import { Map } from 'mapbox-gl';
import { MAP_STYLE_DARK_MODE, MAP_STYLE_LIGHT_MODE } from '@constants';
import RegionListDisplay from '@components/Maps/RegionMap/RegionListDisplay';
import { useSelector } from 'react-redux';
import { useSegment, useTheme } from '@hooks';
import { getLookupRegions } from '@store/features';
import { convertPolygonToCoordinates } from '@helpers/mapHelpers';

const drawRegionPolygon = (map, lookupRegions, setHoverRegion, handleRegionClick) => {
  let polygons = lookupRegions.map(({ polygon }) => convertPolygonToCoordinates(polygon));
  let myPoly = polygons.map((val, i) => {
    return {
      type: 'Feature',
      properties: {
        name: lookupRegions[i].name,
        id: lookupRegions[i].regionId,
        shortName: lookupRegions[i].shortName
      },
      geometry: {
        type: 'Polygon',
        coordinates: [val]
      }
    };
  });

  const source = { type: 'FeatureCollection', features: myPoly };

  if (!map.current.getSource('rs')) {
    map.current.addSource('rs', {
      type: 'geojson',
      data: source
    });
  }
  if (!map.current.getLayer('cf')) {
    map.current.addLayer({
      id: 'cf', // country-fills
      type: 'fill',
      source: 'rs',
      layout: {},
      paint: {
        'fill-color': '#fff',
        'fill-opacity': 0
      }
    });
  }
  if (!map.current.getLayer('cb')) {
    map.current.addLayer({
      id: 'cb', // country borders
      type: 'line',
      source: 'rs',
      layout: {},
      paint: {
        'line-color': '#4DD1E3',
        'line-width': 1
      },
      filter: ['==', 'id', '']
    });
  }
  if (!map.current.getLayer('cfh')) {
    map.current.addLayer({
      id: 'cfh', // country-fills-hover",
      type: 'fill',
      source: 'rs',
      layout: {},
      paint: {
        'fill-color': 'rgba(128,223,235,0.43)',
        'fill-opacity': 0.5
      },
      filter: ['==', 'id', '']
    });
  }
  map.current.on('mousemove', function (e) {
    const features = map.current.queryRenderedFeatures(e.point, { layers: ['cf'] });
    if (features.length) {
      map.current.getCanvas().style.cursor = 'pointer';
      map.current.setFilter('cfh', ['==', 'id', features[0].properties.id]);
      map.current.setFilter('cb', ['==', 'id', features[0].properties.id]);
      setHoverRegion(features[0].properties.name);
    } else {
      map.current.setFilter('cfh', ['==', 'id', '']);
      map.current.setFilter('cb', ['==', 'id', '']);
      map.current.getCanvas().style.cursor = '';
      setHoverRegion(null);
    }
  });
  map?.current?.on('click', function (e) {
    const features = map.current.queryRenderedFeatures(e.point, { layers: ['cf'] });
    if (features.length) {
      handleRegionClick(features[0].properties.id);
    }
  });
};

function RegionOverviewMap() {
  const mapContainer = useRef(null);
  const [styleReady, setStyleReady] = useState(false);
  const [hoverRegion, setHoverRegion] = useState(null);
  const [loadReady, setLoadReady] = useState(false);
  const [layersAdded, setLayersAdded] = useState(false);
  const navigate = useNavigate();
  const map = useRef(null);
  const { themeMode } = useTheme();
  const lookupRegions = useSelector(getLookupRegions);
  const { uriExt } = useSegment();

  const handleRegionClick = useCallback(
    (regionId) => {
      navigate(`${uriExt}/commercial-analytics/region/${regionId}`);
    },
    [uriExt]
  );

  useEffect(() => {
    setStyleReady(false);
    setLoadReady(false);
    if (!map.current) {
      map.current = new Map({
        container: mapContainer.current,
        renderWorldCopies: false,
        style: themeMode ? MAP_STYLE_DARK_MODE : MAP_STYLE_LIGHT_MODE,
        projection: { name: 'mercator' },
        center: [50, 50],
        zoom: 0
      });
    }
    map.current.flyTo({ center: [0, 20] });
    map.current?.on('style.load', () => {
      setStyleReady(true);
    });

    map.current?.on('load', () => {
      setLoadReady(true);
    });
    return () => {
      map.current = null;
    };
  }, [themeMode]);

  useEffect(() => {
    if (!lookupRegions?.length || !styleReady || !loadReady) return;
    drawRegionPolygon(map, lookupRegions, setHoverRegion, handleRegionClick);
    setLayersAdded(true);
  }, [styleReady, loadReady, lookupRegions]);

  return (
    <Paper>
      <Stack direction={'row'}>
        <div
          style={{
            boxShadow: 'rgba(0, 0, 0, 0.25) 2px 4px 4px 0px',
            clipPath: 'inset(0px -15px 0px 0px)',
            width: '15vw',
            borderTopLeftRadius: '4px',
            height: `80vh`,
            display: 'flex',
            maxHeight: `80vh`
          }}>
          <RegionListDisplay
            setHoverRegion={setHoverRegion}
            map={map}
            loading={false}
            lookupRegions={lookupRegions}
          />
        </div>
        <div
          ref={mapContainer}
          style={{
            width: '82%',
            height: '80vh'
          }}
          className="dashboard-map">
          {hoverRegion && (
            <div style={{ position: 'absolute', right: 10, top: 10, zIndex: 1 }}>
              <Paper variant={'outlined'} sx={{ p: 2 }}>
                <Typography>{hoverRegion}</Typography>
              </Paper>
            </div>
          )}
          {(!styleReady || !loadReady || !layersAdded) && (
            <div
              style={{
                height: '100%',
                width: '100%',
                background: 'rgb(255 255 255 / 50%)',
                zIndex: 2,
                position: 'absolute'
              }}>
              <CircularProgress
                sx={{
                  position: 'absolute',
                  top: 'calc(50% - 50px)',
                  left: 'calc(50% - 50px)'
                }}
                size={100}
              />
            </div>
          )}
        </div>
      </Stack>
    </Paper>
  );
}

RegionOverviewMap.propTypes = {};

RegionOverviewMap.defaultProps = {};

export default RegionOverviewMap;
