import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Paper,
  Typography,
  FormControl,
  Select,
  MenuItem,
  NotFound,
  Stack
} from '@esgian/esgianui';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import VesselCiiRatingChart from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselCiiRatingSection/VesselCiiRatingChart';
import { getVesselCiiRatingData } from '@api';
import VesselCiiRatingTable from '@components/Sections/ShipAnalytics/VesselProfile/SubSections/VesselCiiRatingSection/VesselCiiRatingTable';
import { AccreditationStatementModal } from '@components/Modals';
import EmissionsDisclaimer from '../../../../../Display/EmissionsDisclaimer';

function VesselCiiRatingSection({ vesselName }) {
  const [vesselCiiRatingData, setVesselCiiRatingData] = useState(null);
  const [showStatement, setShowStatement] = useState(false);
  const [noData, setNoData] = useState(true);
  const [periodSelect, setPeriodSelect] = useState(1);

  const [loading, setLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    let periodValue = 'monthly';
    if (periodSelect === 3) {
      periodValue = 'yearly';
    } else if (periodSelect === 2) {
      periodValue = 'quarterly';
    }

    const getVslCiiRatingData = async (imo) => {
      setLoading(true);

      try {
        await getVesselCiiRatingData(imo, periodValue, signal).then((result) => {
          setVesselCiiRatingData(result);
          setNoData(false);
        });
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
      setLoading(false);
    };
    getVslCiiRatingData(params.imo);
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [periodSelect]);

  const handlePeriodSelect = useCallback(({ target }) => {
    setPeriodSelect(target.value);
  }, []);

  return (
    <Grid item xs={12}>
      <Paper variant={'outlined'}>
        <Stack>
          <Stack spacing={2}>
            <Stack sx={{ p: 2 }} direction={'row'} justifyContent={'space-between'}>
              <Stack>
                <Stack>
                  <Typography variant={'h6'}>Vessel CII Rating</Typography>
                  <FormControl variant={'standard'} sx={{ mt: 1, minWidth: 10 }}>
                    <Select
                      variant={'standard'}
                      value={periodSelect}
                      labelId="vessel-emission-history-select-label"
                      onChange={handlePeriodSelect}
                      id="vessel-emission-history-select"
                      inputProps={{ 'aria-label': 'Without label' }}>
                      <MenuItem value={1}>Monthly</MenuItem>
                      <MenuItem value={2}>Quarterly</MenuItem>
                      <MenuItem value={3}>Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Stack>
            </Stack>
            {!loading && noData && (
              <NotFound
                header={'No Matching Result'}
                text={'Please change your search parameters'}
              />
            )}
            {!noData && (
              <Stack spacing={2} sx={{ pr: 2, pl: 2 }}>
                <EmissionsDisclaimer setShowStatement={setShowStatement} />
                <VesselCiiRatingChart
                  loading={loading}
                  periodSelect={periodSelect}
                  ciiRatingData={vesselCiiRatingData}
                  fileName={`${vesselName?.toLowerCase().replace(/\s+/g, '-')}-cii-rating`}
                />
              </Stack>
            )}
          </Stack>
          {!noData && (
            <VesselCiiRatingTable
              loading={loading}
              periodSelect={periodSelect}
              ciiRatingData={vesselCiiRatingData}
              fileName={`${vesselName?.toLowerCase().replace(/\s+/g, '-')}-cii-rating`}
            />
          )}
        </Stack>
      </Paper>
      <AccreditationStatementModal
        handleClose={() => setShowStatement(false)}
        open={showStatement}
      />
    </Grid>
  );
}

VesselCiiRatingSection.propTypes = {
  vesselName: PropTypes.string
};

VesselCiiRatingSection.defaultProps = {
  vesselName: ''
};

export default VesselCiiRatingSection;
