import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Grid, Skeleton, Typography } from '@esgian/esgianui';
import moment from 'moment';

function VesselPositionDetails({ loading, vesselLatestPositionData }) {
  const { course, draught, mmsi, timestamp, speed } = vesselLatestPositionData || {};
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Grid item container sx={{ p: 2 }} spacing={2}>
          <Grid item xs={12}>
            <Typography variant={'h6'} id={'vessel-latest-position-info-title'}>
              Latest Position Info
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <Skeleton />
        ) : (
          <Grid
            item
            container
            className={'vessel-position-details'}
            justifyContent={'space-between'}
            sx={{ p: 2 }}>
            <Grid item>
              <Typography bold variant={'body2'}>
                Position Received
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body2'}>
                {timestamp && moment(timestamp).isValid()
                  ? moment(timestamp).format('MMMM Do YYYY, hh:mm:ss a')
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <Skeleton />
        ) : (
          <Grid
            item
            container
            className={'vessel-position-details'}
            justifyContent={'space-between'}
            sx={{ p: 2 }}>
            <Grid item>
              <Typography bold variant={'body2'}>
                MMSI
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body2'}>{mmsi || '-'}</Typography>
            </Grid>
          </Grid>
        )}
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <Skeleton />
        ) : (
          <Grid
            item
            container
            className={'vessel-position-details'}
            justifyContent={'space-between'}
            sx={{ p: 2 }}>
            <Grid item>
              <Typography bold variant={'body2'}>
                Speed
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body2'}>
                {speed !== undefined && speed !== '' ? `${speed} kn` : '-'}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <Skeleton />
        ) : (
          <Grid
            item
            container
            className={'vessel-position-details'}
            justifyContent={'space-between'}
            sx={{ p: 2 }}>
            <Grid item>
              <Typography bold variant={'body2'}>
                Course
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body2'}>
                {course !== undefined && course !== '' ? `${course}°` : '-'}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {loading ? (
          <Skeleton />
        ) : (
          <Grid
            item
            container
            className={'vessel-position-details'}
            justifyContent={'space-between'}
            sx={{ p: 2 }}>
            <Grid item>
              <Typography bold variant={'body2'}>
                Draught
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant={'body2'}>
                {draught !== undefined && draught !== '' ? `${draught} m` : '-'}
              </Typography>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

VesselPositionDetails.propTypes = {
  loading: PropTypes.bool,
  vesselLatestPositionData: PropTypes.object
};

VesselPositionDetails.defaultProps = {
  loading: false,
  vesselLatestPositionData: {}
};

export default VesselPositionDetails;
