import React from 'react';
import PropTypes from 'prop-types';
import TableWithSummary from './TableWithSummary/TableWithSummary';
import {
  getValueUptoTwoDecimalPlaces,
  getValueUptoFourDecimalPlaces
} from '../../Sections/VoyageAnalytics/VoyageSummarySection/VoyageSummaryRouteMap/utils';
import { formatNumberReadable } from '@helpers';

const OverallVoyage = ({ overallVoyage, isLoading }) => {
  const summary = [];

  const columns = [
    {
      name: 'voyageLeg',
      label: 'Voyage Leg'
    },
    {
      name: 'origin',
      label: 'Origin'
    },
    {
      name: 'destination',
      label: 'Destination'
    },
    {
      name: 'energyProduction',
      label: 'Energy Production'
    },
    {
      name: 'operational',
      label: 'Operational'
    },
    {
      name: 'overall',
      label: 'Overall'
    }
  ];

  const data = [];

  const totalRow = {
    voyageLeg: { value: 'Total' },
    origin: { value: '-' },
    destination: { value: '-' },
    energyProduction: { value: '-' },
    operational: { value: '-' },
    overall: { value: '-' }
  };

  const detailsTableColumns = [
    {
      name: 'voyageLeg',
      label: 'Voyage Leg'
    },
    {
      name: 'origin',
      label: 'Origin'
    },
    {
      name: 'destination',
      label: 'Destination'
    },
    {
      name: 'estimatedCargoWeightT',
      label: 'Estimated Cargo weight (t)'
    },
    {
      name: 'shortestDistanceKm',
      label: 'Shortest distance (km)',
      customCellValue: getValueUptoTwoDecimalPlaces
    },
    {
      name: 'transportActivityTKm',
      label: 'Transport Activity (t-km) '
    },
    {
      name: 'energyProduction',
      label: 'Energy Production',
      customCellValue: getValueUptoFourDecimalPlaces
    },
    {
      name: 'operational',
      label: 'Operational',
      customCellValue: getValueUptoFourDecimalPlaces
    },
    {
      name: 'overall',
      label: 'Overall',
      customCellValue: getValueUptoFourDecimalPlaces
    }
  ];

  const detailsTableData = [];

  const detailsTableTotalRow = {
    voyageLeg: { value: 'Total' },
    origin: { value: '-' },
    destination: { value: '-' },
    estimatedCargoWeightT: { value: '-' },
    shortestDistanceKm: { value: '-' },
    transportActivityTKm: { value: '-' },
    energyProduction: { value: null },
    operational: { value: null },
    overall: { value: null }
  };

  if (overallVoyage) {
    summary.push({
      label: ' Total CO₂e (kg)',
      value: formatNumberReadable(overallVoyage.totalGhgEmissionOverallKg.toFixed(0))
    });

    data.push(
      ...overallVoyage.voyageLegEmissionIntensities.map((d) => ({
        voyageLeg: { value: `#${d.voyageLeg}` },
        origin: { value: d.fromPortName },
        destination: { value: d.toPortName },
        energyProduction: {
          value: formatNumberReadable(d.ghgEmissionEneryProductionKg?.toFixed(0))
        },
        operational: { value: formatNumberReadable(d.ghgEmissionOperationalKg?.toFixed(0)) },
        overall: { value: formatNumberReadable(d.ghgEmissionOverallKg?.toFixed(0)) }
      }))
    );

    totalRow.energyProduction.value = formatNumberReadable(
      overallVoyage.totalGhgEmissionEneryProductionKg?.toFixed(0)
    );
    totalRow.operational.value = formatNumberReadable(
      overallVoyage.totalGhgEmissionOperationalKg?.toFixed(0)
    );
    totalRow.overall.value = formatNumberReadable(
      overallVoyage.totalGhgEmissionOverallKg?.toFixed(0)
    );

    detailsTableData.push(
      ...overallVoyage.voyageLegEmissionIntensities.map((d) => ({
        voyageLeg: { value: `#${d.voyageLeg}` },
        origin: { value: d.fromPortName },
        destination: { value: d.toPortName },
        estimatedCargoWeightT: { value: d.cargoWeightTn },
        shortestDistanceKm: { value: formatNumberReadable(d.distanceKm) },
        transportActivityTKm: { value: formatNumberReadable(d.transportActivity) },
        energyProduction: { value: d.emissionIntensityEneryProduction },
        operational: { value: d.emissionIntensityOperational },
        overall: { value: d.emissionIntensityOverall }
      }))
    );

    detailsTableTotalRow.shortestDistanceKm.value = overallVoyage.totalDistanceKm;
    // detailsTableTotalRow.energyProduction.value = overallVoyage.totalGhgEmissionEneryProductionKg;
  }
  return (
    <TableWithSummary
      title="Overall voyage"
      columns={columns}
      data={[...data, totalRow]}
      summary={summary}
      detailsTableTitle="Overall Voyage Emission intensity"
      detailsTableColumns={detailsTableColumns}
      detailsTableData={[...detailsTableData, detailsTableTotalRow]}
      isLoading={isLoading}
    />
  );
};

OverallVoyage.propTypes = {
  overallVoyage: PropTypes.object,
  isLoading: PropTypes.bool
};

export default OverallVoyage;
