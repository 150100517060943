import { createSlice } from '@reduxjs/toolkit';
import { COMMERCIAL_CATEGORY_TYPE, OPERATOR_TYPE } from '@constants';

const initSideMenuItemsOpen = { emissionsOpen: false, saOpen: false, vaOpen: false };

const initialState = {
  themeMode: !!JSON.parse(localStorage.getItem('esgian-theme-mode')),
  sideMenuOpen: !!JSON.parse(localStorage.getItem('esgian-menu-open')),
  dropDownMenuOpen: initSideMenuItemsOpen,
  segment: null,
  init: true,
  dateFormat: 'YYYY-MM-DD',
  dateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  operatorType: OPERATOR_TYPE.ALL,
  commercialCategoryType: COMMERCIAL_CATEGORY_TYPE.ALL
};

const GeneralSlice = createSlice({
  name: 'General',
  initialState: initialState,
  reducers: {
    setThemeMode: (state, action) => {
      state.themeMode = action.payload;
      localStorage.setItem('esgian-theme-mode', `${action.payload}`);
    },
    setInit: (state, action) => {
      state.init = action.payload;
    },
    setSideMenuOpen: (state, action) => {
      if (!action.payload) {
        GeneralSlice.caseReducers.closeAllSideMenuItems(state);
      }
      state.sideMenuOpen = action.payload;
      localStorage.setItem('esgian-menu-open', `${action.payload}`);
    },
    setSideMenuItemOpen: (state, action) => {
      state.dropDownMenuOpen = action.payload;
    },
    setSegment: (state, action) => {
      state.segment = action.payload;
    },
    setOperatorType: (state, action) => {
      state.operatorType = action.payload;
    },
    setCommercialCategoryType: (state, action) => {
      state.commercialCategoryType = action.payload;
    },
    updateGeneralSlice: (state, action) => {
      const { payload } = action;
      Object.keys(payload).forEach((key) => {
        state[key] = payload[key];
      });
    },
    closeAllSideMenuItems: (state) => (state.dropDownMenuOpen = initSideMenuItemsOpen)
  }
});
export const getGeneralSlice = (state) => state.general;
export const getSideMenuOpen = (state) => state.general.sideMenuOpen;
export const getDateFormat = (state) => state.general.dateFormat;
export const getDateTimeFormat = (state) => state.general.dateTimeFormat;
export const getDropDownMenuOpen = (state) => state.general.dropDownMenuOpen;
export const getThemeMode = (state) => state.general.themeMode;
export const getSegment = (state) => state.general.segment;
export const getOperatorType = (state) => state.general.operatorType;
export const getCommercialCategoryType = (state) => state.general.commercialCategoryType;

export const {
  setThemeMode,
  setSegment,
  setCommercialCategoryType,
  setSideMenuOpen,
  setSideMenuItemOpen,
  setInit,
  updateGeneralSlice,
  setOperatorType,
  closeAllSideMenuItems
} = GeneralSlice.actions;

export default GeneralSlice.reducer;
