import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Paper, Stack, Table, Typography } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import { checkUserHasAccess } from '@helpers';
import { useSelector } from 'react-redux';
import { getUser } from '@store/features';
import { useTheme } from '@hooks';

function PortsInRegionTable({
  regionPortsData = [],
  loading,
  hideFilters,
  hideColumns,
  regionName
}) {
  const user = useSelector(getUser);
  const { theme } = useTheme();

  const columns = [
    {
      name: 'portName',
      label: 'Port Name',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'textField',
        setCellProps: () => {
          return {
            style: {
              width: '15vw',
              boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
            }
          };
        },
        customBodyRender: (value, data) => {
          const { rowData } = data;
          return value !== null ? (
            <ProfileLink profile={'port'} name={value} id={rowData[1]} />
          ) : (
            '--'
          );
        }
      }
    },
    {
      name: 'id',
      options: { display: false, viewColumns: false, filter: false, download: false }
    },
    {
      name: 'unlocode',
      label: 'Port Code',

      options: {
        display: !hideColumns.includes('portCode'),
        sort: true,
        sortThirdClickReset: true,
        filter: !hideFilters.includes('portFilterCode'),
        filterType: 'multiselect'
      }
    },
    {
      name: 'id',
      options: { display: false, viewColumns: false, filter: false, download: false }
    },
    {
      name: 'country.id',
      options: { display: false, viewColumns: false, filter: false, download: false }
    },
    {
      name: 'country.isoAlpha3',
      options: { display: false, viewColumns: false, filter: false, download: false }
    },
    {
      name: 'country.countryName',
      label: 'Country Name',
      options: {
        sort: true,
        sortThirdClickReset: true,
        filterType: 'textField',
        setCellProps: () => {
          return {
            style: {
              width: '15vw',
              boxShadow: '3px 0px 2px -1px rgb(0 0 0 / 20%)'
            }
          };
        },
        customBodyRender: (value, data) => {
          const { rowData } = data;
          return value !== null ? (
            <ProfileLink
              profile={'country'}
              id={rowData[4]}
              name={value}
              countryCode={rowData[5]}
            />
          ) : (
            '--'
          );
        }
      }
    }
  ];

  const options = {
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
    enableNestedDataAccess: '.',
    download: checkUserHasAccess(user, true),
    downloadOptions: {
      filename: `ports-in-${regionName}.csv`.toLowerCase().split(' ').join('-')
    },
    setTableProps: () => {
      return {
        id: 'ports-in-regions-table'
      };
    },
    print: false,
    viewColumns: false,
    pagination: false,
    tableBodyMaxHeight: '30vh',
    toolbar: checkUserHasAccess(user, true),
    rowsPerPageOptions: [10, 25, 50, 100],
    elevation: 0,
    sortOrder: {
      name: 'portName',
      direction: 'asc'
    }
  };
  return (
    <Paper sx={{ overflow: 'auto', minHeight: '100%' }}>
      <Table
        customStyle={{
          MUIDataTableFilterList: { display: 'none' }
        }}
        title={
          <Stack>
            <Typography id={'regions-title'} variant={'h6'}>
              Ports
            </Typography>
            <Typography variant={'body2'} color={'text.secondary'}>
              {regionName}
            </Typography>
          </Stack>
        }
        skeletonRows={6}
        loading={loading}
        mode={theme.mode}
        data={regionPortsData || []}
        columns={columns}
        options={options}
      />
    </Paper>
  );
}

PortsInRegionTable.propTypes = {
  regionPortsData: PropTypes.arrayOf(PropTypes.object),
  hideFilters: PropTypes.arrayOf(PropTypes.string),
  hideColumns: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  regionName: PropTypes.string
};

PortsInRegionTable.defaultProps = {
  regionPortsData: [],
  hideFilters: [],
  hideColumns: [],
  loading: false,
  regionName: ''
};

export default PortsInRegionTable;
