import React from 'react';
import { Box, Button, Paper, Typography } from '@esgian/esgianui';
import VoyageSummaryMapOptions from './VoyageSummaryMapOptions';
import PropTypes from 'prop-types';
import VoyageSummaryDateSlider from './VoyageSummaryDateSlider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getGeneralSlice } from '@store/features';
import { updateVoyageProfileFilters } from '@store/features/filters/VoyageAnalyticsPage/VoyageProfile/VoyageProfileSlice';

const VoyageSummaryFilter = ({ voyageTransitDetails, voyagePortCalls }) => {
  const [searchParams] = useSearchParams();
  const { themeMode } = useSelector(getGeneralSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const exitFullScreenMode = () => {
    dispatch(updateVoyageProfileFilters({ mapOptions: { fullscreen: false } }));
    searchParams.set('fullscreen', false);
    navigate({ search: searchParams.toString() }, { replace: true });
  };

  return (
    <Paper
      style={{
        position: 'absolute',
        zIndex: 1,
        left: '16px',
        top: '16px',
        right: '16px',
        backgroundColor: themeMode ? '#191919E5' : '#FFFFFFE5'
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          px: 2,
          py: 1
        }}>
        <Button
          variant="contained"
          onClick={exitFullScreenMode}
          startIcon={<img src="/assets/images/full-screen-exit-icon.png" alt="Fullscreen Exit" />}>
          <Typography color="black">Exit</Typography>
        </Button>
        <VoyageSummaryDateSlider
          startDate={voyageTransitDetails.startDate}
          endDate={voyageTransitDetails.endDate}
          voyagePortCalls={voyagePortCalls}
        />
        <VoyageSummaryMapOptions />
      </Box>
    </Paper>
  );
};

VoyageSummaryFilter.propTypes = {
  voyageTransitDetails: PropTypes.object.isRequired,
  voyagePortCalls: PropTypes.object.isRequired
};

export default VoyageSummaryFilter;
