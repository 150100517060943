import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography
} from '@esgian/esgianui';
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { scrollbarStyling } from '../../../../utils/constants';

const VoyageGroupListPopup = ({
  savedGroups,
  setModalOpen,
  isSavingVoyageToGroup,
  isLoadingVoyageGroups,
  setIsOpenManageModal
}) => {
  const hasAnyGroup = savedGroups.length > 0;

  const navigate = useNavigate();

  return (
    <Box py={1} sx={{}}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" p={1}>
        <Typography variant={'body2'}>COMPLETED VOYAGE GROUPS</Typography>
        <Button variant="text" onClick={() => setModalOpen(true)}>
          CREATE
        </Button>
        <Button
          disabled={savedGroups.length === 0}
          variant="text"
          onClick={() => setIsOpenManageModal(true)}>
          Manage
        </Button>
      </Stack>

      {!hasAnyGroup && !isLoadingVoyageGroups && (
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '2',
            WebkitBoxOrient: 'vertical'
          }}
          variant="body1"
          color="gray"
          px={1}>
          Hi, You have no group yet. Create one to start
        </Typography>
      )}
      {isLoadingVoyageGroups && (
        <Typography variant="body1" p={1}>
          Loading...
        </Typography>
      )}
      {!isLoadingVoyageGroups && hasAnyGroup && (
        <List sx={{ maxHeight: '200px', overflow: 'auto', ...scrollbarStyling }}>
          {savedGroups.map((group) => {
            return (
              <ListItem key={group.id} disablePadding>
                <ListItemButton
                  sx={{ py: 0.5, px: 1 }}
                  onClick={() => navigate(`/roro/voyage-group/${group.id}`)}
                  disabled={isSavingVoyageToGroup}>
                  <ListItemText primary={group.name} />
                  <Typography variant="body2" color="gray">
                    ({group.voyages.length} voyages)
                  </Typography>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      )}
    </Box>
  );
};

VoyageGroupListPopup.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
  savedGroups: PropTypes.object.isRequired,
  isSavingVoyageToGroup: PropTypes.bool,
  isLoadingVoyageGroups: PropTypes.bool,
  setIsOpenManageModal: PropTypes.bool
};

export default VoyageGroupListPopup;
