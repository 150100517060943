import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Breadcrumbs,
  Grid,
  Link,
  PageHeader,
  Paper,
  Slide,
  Tab,
  Tabs,
  Typography
} from '@esgian/esgianui';
import PortsOverviewSection from '@components/Sections/ShipAnalytics/PortsOverviewSection';
import { getPortCongestion, getTopPorts } from '@api';
import moment from 'moment';
import OperatorTypeSelect from '@components/Inputs/OperatorTypeSelect/OperatorTypeSelect';
import { DATE_FORMAT, GEOGRAPHICAL_TYPES } from '@constants';
import PortWaitingTimeSection from '@components/Sections/ShipAnalytics/PortProfileSection/SubSections/PortWaitingTimeSection';
import { useSegment } from '@hooks';
import { useSelector } from 'react-redux';
import { getCommercialCategoryType, getLookupPorts, getOperatorType } from '@store/features';
import { CommercialCategoryTypeSelect } from '@components/Inputs';

function PortsOverviewPage() {
  const [loading, setLoading] = useState(false);
  const [topPortsData, setTopPortsData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [portCongestionData, setPortCongestionData] = useState(null);
  const commercialCategoryType = useSelector(getCommercialCategoryType);
  const { isRoRo, segment, segmentMinDate } = useSegment();
  const operatorType = useSelector(getOperatorType);
  const lookupPorts = useSelector(getLookupPorts);

  const [section, setSection] = useState(1);
  const handleSectionChange = useCallback(
    (_, val) => {
      if (val === 2 && !portCongestionData) {
        return;
      }
      setSection(val);
    },
    [portCongestionData]
  );

  const navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getPortsData = async () => {
      try {
        setLoading(true);
        await getPortCongestion(
          {
            StartDate: moment(segmentMinDate).format(DATE_FORMAT),
            EndDate: moment().format(DATE_FORMAT),
            GeoType: GEOGRAPHICAL_TYPES.PORT,
            OperatorTypeId: operatorType,
            SegmentTypeId: segment.id
          },
          signal
        ).then((result) => {
          setPortCongestionData(result);
        });
        setLoading(false);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    if (isRoRo) {
      getPortsData();
    }
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [segment, operatorType]);

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    const getTopPortsData = async () => {
      try {
        await Promise.all([
          getTopPorts(
            {
              NumberOfPorts: 100,
              NumberOfOperators: 3,
              Year: selectedYear,
              OperatorTypeId: operatorType,
              SegmentTypeId: segment.id,
              CommercialCategoryId: commercialCategoryType
            },
            signal
          ).then((result) => {
            const { topPortsYTD } = result;
            setTopPortsData(topPortsYTD);
          })
        ]);
      } catch (error) {
        if (error.name === 'AbortError') {
          console.log('Found abortError');
        }
      }
    };
    getTopPortsData();
    return () => {
      console.log('Unmount cancel request');
      controller.abort();
    };
  }, [selectedYear, operatorType, segment, commercialCategoryType]);

  const handleSelectYear = ({ target }) => {
    setSelectedYear(target.value);
  };

  return (
    <Grid item container spacing={2} justifyContent={'stretch'}>
      <Grid item xs={12}>
        <PageHeader
          loading={loading}
          title={'Ports Overview'}
          id={'lbl-port-overview'}
          breadcrumbs={
            <Breadcrumbs aria-label="breadcrumb">
              <Link
                underline="hover"
                color="inherit"
                id="home-breadcrumb"
                sx={{ cursor: 'pointer' }}
                onClick={() => routeChange('/roro/commercial-analytics')}>
                Home
              </Link>
              <Link
                sx={{ cursor: 'pointer' }}
                underline="hover"
                color="inherit"
                id="ship-analysis-breadcrumb"
                onClick={() => routeChange('/roro/commercial-analytics')}>
                Commercial Analytics
              </Link>
              <Typography color="primary" id="ports-overview-breadcrumb">
                Ports Overview
              </Typography>
            </Breadcrumbs>
          }
        />
      </Grid>

      <Grid item xs={12}>
        {isRoRo ? <OperatorTypeSelect /> : <CommercialCategoryTypeSelect />}
      </Grid>

      <Grid item xs={12} id={'ports-overview'}>
        <Paper>
          <Tabs value={section} onChange={handleSectionChange} variant="scrollable">
            <Tab value={1} label="Ports Overview" />
            {isRoRo && <Tab value={2} label="Port waiting time" />}
          </Tabs>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Slide direction="right" in={section === 1} mountOnEnter unmountOnExit>
          <div>
            <PortsOverviewSection
              topPortsData={topPortsData}
              ports={lookupPorts}
              loading={loading}
              selectedYear={selectedYear}
              handleSelectYear={handleSelectYear}
            />
          </div>
        </Slide>
        <Slide direction="right" in={section === 2} mountOnEnter unmountOnExit>
          <div>
            <PortWaitingTimeSection portCongestionData={portCongestionData} loading={loading} />
          </div>
        </Slide>
      </Grid>
    </Grid>
  );
}

PortsOverviewPage.propTypes = {};

PortsOverviewPage.defaultProps = {};

export default PortsOverviewPage;
