import { BaseApiRequest } from '@api/BaseApi';

import { objectToQuery } from '@api/helpers';
import { SEGMENT } from '@constants';

export const getShipsInRegionByOperators = (
  { date, minLiftingCapacity, vesselSizeMin, vesselSizeMax, maxLiftingCapacity },
  regionId,
  segmentTypeId,
  operatorTypeId,
  imos,
  signal
) => {
  const isRoRo = segmentTypeId === SEGMENT.RoRo.id;
  const body = {
    date: date,
    carCapacitySizeMin: isRoRo ? vesselSizeMin : null,
    carCapacitySizeMax: isRoRo ? vesselSizeMax : null,
    deadweightSizeMin: isRoRo ? null : vesselSizeMin,
    deadweightSizeMax: isRoRo ? null : vesselSizeMax,
    minLiftingCapacity: isRoRo ? null : minLiftingCapacity,
    maxLiftingCapacity: isRoRo ? null : maxLiftingCapacity,
    regionId: regionId,
    imos: imos
  };
  return BaseApiRequest(signal, `/ShipsInRegionByFilters?api-version=1.0`, 'ais', 'POST', body);
};

export const fetchRegionDetails = (
  {
    regionId,
    startDate,
    endDate,
    selectedOperators,
    segmentTypeId,
    operatorType,
    commercialCategoryId,
    topXOperators
  },
  signal
) => {
  let body = {
    startDate: startDate,
    endDate: endDate,
    regionId: regionId,
    operatorIds: selectedOperators.map(({ id }) => id),
    segmentTypeId: segmentTypeId,
    operatorTypeId: operatorType,
    commercialCategoryId: commercialCategoryId,
    topXOperators: topXOperators
  };

  return BaseApiRequest(
    signal,
    `/Ais/RegionDetailsByFilters?api-version=1.0`,
    'ships',
    'POST',
    body
  );
};

export const getRegionActivityByOperators = (
  startDate,
  endDate,
  operatorIds,
  sizeMin,
  sizeMax,
  minLiftingCapacity,
  maxLiftingCapacity,
  segmentId,
  regionId,
  signal
) => {
  const isRoRo = segmentId === SEGMENT.RoRo.id;
  let body = {
    startDate: startDate,
    endDate: endDate,
    carCapacitySizeMin: isRoRo ? sizeMin : null,
    carCapacitySizeMax: isRoRo ? sizeMax : null,
    deadweightSizeMin: isRoRo ? null : sizeMin,
    deadweightSizeMax: isRoRo ? null : sizeMax,
    minLiftingCapacity: isRoRo ? null : minLiftingCapacity,
    maxLiftingCapacity: isRoRo ? null : maxLiftingCapacity,
    regionId: null,
    operatorIds: operatorIds,
    segmentTypeId: segmentId,
    operatorTypeId: 0,
    comercialCategoryId: 0,
    topXOperators: null
  };
  return BaseApiRequest(signal, `/Ais/RegionStatsByFilters?api-version=1.0`, 'ships', 'POST', body);
};

export const getRegionUpcomingPortCalls = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/IncomingVessels/Region?${query}`);
};

export const getRegionPorts = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Region/Ports?${query}`);
};

export const getRegionPortStats = (data, signal) => {
  const query = objectToQuery(data);
  return BaseApiRequest(signal, `/Region/PortStats?${query}`);
};
