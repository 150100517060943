import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApexChart, Divider, Grid, Paper, Stack, Typography } from '@esgian/esgianui';
import moment from 'moment';
import { checkUserHasAccess, getDateList } from '@helpers';
import { useTheme } from '@hooks';
import { getUser } from '@store/features';
import { useSelector } from 'react-redux';

const getAnnotations = (theme, meanPctg, meanTime) => {
  const {
    palette: {
      charts: { twoColorsContrast },
      text: { primary }
    }
  } = theme;
  let annotations = [
    {
      y: meanTime,
      yAxisIndex: 0,
      opacity: 1,
      strokeDashArray: 5,
      borderColor: twoColorsContrast[1],
      label: {
        position: 'left',
        textAnchor: 'start',
        borderColor: twoColorsContrast[1],
        style: {
          color: primary,
          background: twoColorsContrast[1]
        },
        text: 'Avg [Hours].'
      }
    }
  ];
  annotations.push({
    y: meanPctg,
    yAxisIndex: 1,
    borderColor: twoColorsContrast[0],
    opacity: 1,
    strokeDashArray: 5,
    label: {
      borderColor: twoColorsContrast[0],
      textAnchor: 'end',
      style: {
        color: primary,
        background: twoColorsContrast[0]
      },
      text: 'Avg [%].'
    }
  });
  return {
    position: 'front',
    yaxis: annotations
  };
};

const getOptions = (theme, categories, timeMax, changeMax, meanPctg, meanTime, user, fileName) => {
  const {
    palette: {
      mode,
      charts: { background, rowColors, twoColorsContrast }
    }
  } = theme;
  return {
    chart: {
      type: 'bar',
      background: background,
      fontFamily: ['Noto Sans', 'Roboto', 'helvetica', 'Arial', 'sans-serif'].join(','),
      height: 350,
      toolbar: {
        tools: {
          download: checkUserHasAccess(user, true)
        },

        export: {
          csv: {
            filename: fileName
          },
          svg: {
            filename: fileName
          },
          png: {
            filename: fileName
          }
        }
      }
    },
    annotations: getAnnotations(theme, meanPctg, meanTime),
    colors: [twoColorsContrast[1], twoColorsContrast[0]],
    theme: {
      mode: mode
    },
    grid: {
      row: {
        colors: rowColors,
        opacity: 0.5
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        columnWidth: '80%'
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      markers: { radius: [3, 12] }
    },
    yaxis: [
      {
        seriesName: 'Time[Hours]',
        min: timeMax * -1,
        max: timeMax,
        labels: { style: { colors: twoColorsContrast[1] } },
        title: {
          text: 'Hours'
        },
        decimalsInFloat: 0
      },
      {
        opposite: true,
        seriesName: 'Change [%]',
        decimalsInFloat: 0,
        min: changeMax * -1,
        max: changeMax,
        labels: { style: { colors: twoColorsContrast[0] } },
        title: {
          text: '%'
        }
      }
    ],
    xaxis: {
      categories: categories,
      tickPlacement: 'between'
    },
    stroke: {
      width: [0, 0],
      curve: 'straight'
    },
    markers: {
      size: 5,
      strokeWidth: 0,
      hover: {
        size: 5
      }
    }
  };
};

function PortTimeYtdComparisonChart({ loading, portName, portStats, fileName }) {
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState(null);
  const [meanPctg, setMeanPctg] = useState(null);
  const [meanTime, setMeanTime] = useState(null);
  const { theme } = useTheme();
  const user = useSelector(getUser);

  useEffect(() => {
    if (!portStats) return;
    const { monthlyPortCallDto } = portStats;
    const { monthlyTotals, meanPctg, meanTime } = monthlyPortCallDto;
    const startDate = moment(monthlyTotals[0]?.currentYear?.date);
    const endDate = moment(monthlyTotals[monthlyTotals.length - 1]?.currentYear?.date);
    let dates = getDateList(startDate, endDate);
    setMeanPctg(meanPctg);
    setMeanTime(meanTime);

    // Find the largest number for both y-axis.
    // This number is used to ensure that 0 is centered on the chart, and that the grid lines up correctly with each other
    let timeMax = 0;
    let changeMax = 0;
    const timeSeries = monthlyTotals?.map(({ timeDifference }) => {
      let val = timeDifference;
      if (Math.abs(val) > timeMax) {
        timeMax = Math.abs(val) || 0;
      }
      return parseFloat(val?.toFixed(2) || 0);
    });

    const changeSeries = monthlyTotals?.map(({ pctgDifference }) => {
      let val = pctgDifference?.toFixed(2) || 0;
      if (Math.abs(val) > changeMax) {
        changeMax = Math.abs(val) || 0;
      }
      return parseFloat(pctgDifference?.toFixed(2) || 0);
    });
    timeMax = timeMax * 1.2;
    changeMax = changeMax * 1.2;
    setOptions(getOptions(theme, dates, timeMax, changeMax, meanPctg, meanTime, user, fileName));
    setSeries([
      {
        name: 'Time [Hours]',
        data: timeSeries,
        type: 'column'
      },
      {
        name: 'Change [%]',
        data: changeSeries,
        type: 'line'
      }
    ]);
  }, [portStats, theme]);

  const {
    palette: {
      charts: { twoColorsContrast }
    }
  } = theme;
  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack divider={<Divider />} spacing={2}>
        <Stack>
          <Typography variant={'h6'}>Change in port time from the previous year</Typography>
          <Typography variant={'body2'} color={'text.secondary'}>
            {portName}
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <Grid container sx={{ textAlign: 'center' }}>
            <Grid item xs>
              <Typography sx={{ color: twoColorsContrast[1] }} variant={'body2'} bold>
                {meanTime?.toFixed(1)} hours
              </Typography>
              <Typography variant={'caption'} color={'text.secondary'}>
                average time
              </Typography>
            </Grid>
            <div>
              <Divider orientation={'vertical'} />
            </div>
            <Grid item xs>
              <Typography sx={{ color: twoColorsContrast[0] }} variant={'body2'} bold>
                {meanPctg?.toFixed(1)}%
              </Typography>
              <Typography variant={'caption'} color={'text.secondary'}>
                average %
              </Typography>
            </Grid>
          </Grid>
          <ApexChart
            loading={loading || !options}
            data={series}
            height={480}
            options={options || {}}
            type={'line'}
          />
        </Stack>
      </Stack>
    </Paper>
  );
}

PortTimeYtdComparisonChart.propTypes = {
  loading: PropTypes.bool,
  portName: PropTypes.string,
  portStats: PropTypes.object,
  fileName: PropTypes.string.isRequired
};

PortTimeYtdComparisonChart.defaultProps = {
  loading: false,
  portName: null,
  portStats: null
};

export default PortTimeYtdComparisonChart;
