import { Box, Paper, Popover, Skeleton, Stack, Typography } from '@esgian/esgianui';
import { ProfileLink } from '@components/Links';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
import { getVesselProfile } from '@api';
import { useSegment } from '@hooks';
import VesselOverview from '../../../ShipAnalytics/VesselProfile/SubSections/VesselOverviewSection/VesselOverview/VesselOverview';
import { formatNumberReadable } from '@helpers';

const VoyageSummaryOverview = ({ isLoading, summaryOverview, voyageTransitDetails }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoadingVesselsData, setIsLoadingVesselsData] = useState(false);
  const [vesselsData, setVesselsData] = useState(null);
  const { isRoRo } = useSegment();
  const [currentImo, setCurrentImo] = useState(null);

  const handlePopoverOpen = (event, imo) => {
    setAnchorEl(event.currentTarget);
    setCurrentImo(imo);
    if (!vesselsData || imo !== currentImo) {
      setIsLoadingVesselsData(true);
      const controller = new AbortController();
      const { signal } = controller;
      getVesselProfile({ imo: imo }, signal)
        .then((result) => {
          setVesselsData(result);
        })
        .finally(() => {
          setIsLoadingVesselsData(false);
        });
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setCurrentImo(null);
  };

  const overviewData = [
    { label: 'Vessel Name:', value: summaryOverview?.vesselName, id: summaryOverview?.imo },
    {
      label: 'Departure Port:',
      value: summaryOverview?.departurePort,
      id: voyageTransitDetails?.fromPortId
    },
    {
      label: 'Departure Time:',
      value: moment(summaryOverview?.departureTime).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      label: 'Arrival Port:',
      value: summaryOverview?.arrivalPort,
      id: voyageTransitDetails?.toPortId
    },
    {
      label: 'Arrival Time:',
      value: moment(summaryOverview?.arrivalTime).format('YYYY-MM-DD HH:mm:ss')
    },
    { label: 'No. of Intermediate Port Calls:', value: summaryOverview?.numPortsVisited },
    { label: 'Transit Time:', value: voyageTransitDetails?.transitDays?.toFixed(2) + ' Days' },
    {
      label: 'Total Distance:',
      value: formatNumberReadable(voyageTransitDetails?.totalDistance?.toFixed(0)) + ' NM'
    }
  ];

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Stack>
        <Typography variant={'h6'} align="left" sx={{ p: 2 }}>
          Overview
        </Typography>
        {isLoading ? (
          <Skeleton variant={'rectangular'} height={'25vh'}></Skeleton>
        ) : (
          <ul>
            {overviewData.map(({ label, value, id }) => {
              const isVesselName = label === 'Vessel Name:';
              return (
                <li key={label} style={{ marginBottom: '8px' }}>
                  <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography variant={'body1'}>{label}</Typography>
                    {(isVesselName || !id) && (
                      <Typography
                        mr={2}
                        variant={'body1'}
                        onMouseEnter={isVesselName ? (e) => handlePopoverOpen(e, id) : undefined}
                        onMouseLeave={isVesselName ? (e) => handlePopoverClose(e) : undefined}
                        sx={{ cursor: isVesselName ? 'pointer' : 'default' }}>
                        {value}
                      </Typography>
                    )}
                    {id && !isVesselName && (
                      <Box mr={2}>
                        <ProfileLink
                          uriParams={'section=1'}
                          profile={label === 'Vessel Name:' ? 'vessel' : 'port'}
                          id={id}
                          name={
                            <Stack direction={'row'} alignItems={'center'} spacing={1}>
                              <Typography>{value}</Typography>
                            </Stack>
                          }
                        />
                      </Box>
                    )}
                    {isVesselName && (
                      <Popover
                        id="voyage-info-popover"
                        sx={{
                          pointerEvents: 'none'
                        }}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                        PaperProps={{
                          onMouseEnter: (e) => handlePopoverOpen(e, id),
                          onMouseLeave: handlePopoverClose,
                          style: { pointerEvents: 'auto' }
                        }}>
                        <VesselOverview
                          vesselsData={vesselsData}
                          loading={isLoadingVesselsData}
                          isRoRo={isRoRo}
                          title="Voyage Specification"
                          width="700px"
                          showTitle={false}
                        />
                      </Popover>
                    )}
                  </Stack>
                </li>
              );
            })}
          </ul>
        )}
      </Stack>
    </Paper>
  );
};

export default VoyageSummaryOverview;

VoyageSummaryOverview.propTypes = {
  summaryOverview: PropTypes.object,
  isLoading: PropTypes.bool,
  voyageTransitDetails: PropTypes.object
};

VoyageSummaryOverview.defaultProps = {
  summaryOverview: null,
  isLoading: false,
  voyageTransitDetails: null
};
