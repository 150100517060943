import React from 'react';
import { Divider, Stack, Tab, Tabs, Typography } from '@esgian/esgianui';
import { setOprEmissionsDisplayUnit } from '@store/features/filters/EmissionAnalyticsPage/OperatorComparisonReducer/OperatorComparisonReducer';
import { TextWithTooltipIcon } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { useSegment } from '@hooks';
import { getOprCo2EmissionsFilters } from '@store/features/filters/EmissionAnalyticsPage/Co2EmissionsSlice/Co2EmissionsSlice';

function OprCo2EmissionsTabs() {
  const dispatch = useDispatch();
  const { isRoRo } = useSegment();
  const { displayUnit } = useSelector(getOprCo2EmissionsFilters);

  return (
    <Stack>
      <Tabs
        onChange={(_, val) => {
          dispatch(setOprEmissionsDisplayUnit(val));
        }}
        value={displayUnit}>
        {isRoRo && (
          <Tab
            value={1}
            id="co2-ceu-tab"
            label={
              <TextWithTooltipIcon
                label={
                  <Typography variant={'subtitle2'}>
                    Avg. CO<sub>2</sub>/CEU
                  </Typography>
                }
                tooltipText={
                  'Tonnes of CO₂ emission per CEU(Car equivalent Unit) for the operator based on the fleet operated by the operator in the selected time period.'
                }
              />
            }
          />
        )}
        {isRoRo && (
          <Tab
            value={2}
            id="gt-nm-tab"
            label={
              <TextWithTooltipIcon
                label={
                  <Typography variant={'subtitle2'}>
                    CO<sub>2</sub>/(GT&#8226;NM)
                  </Typography>
                }
                tooltipText={
                  'Grams of CO₂ emission per GT(Gross tonnage) capacity and distance travelled(NM- nautical miles) for the operator based on the fleet operated in the selected time period.'
                }
              />
            }
          />
        )}
        <Tab
          value={3}
          id="dwt-nm-tab"
          label={
            <TextWithTooltipIcon
              label={
                <Typography variant={'subtitle2'}>
                  CO<sub>2</sub>/(DWT&#8226;NM)
                </Typography>
              }
              tooltipText={
                'Grams of CO₂ emission per DWT(Deadweight) capacity and distance travelled(NM- nautical miles) for the operator based on the fleet operated in the selected time period.'
              }
            />
          }
        />
        <Tab
          value={4}
          id="total-co2-tab"
          label={
            <TextWithTooltipIcon
              label={
                <Typography variant={'subtitle2'}>
                  Total CO<sub>2</sub>
                </Typography>
              }
              tooltipText={
                'Tonnes of CO₂ emission of the operator for the fleet operated in the selected time period.'
              }
            />
          }
        />
      </Tabs>
      <Divider />
    </Stack>
  );
}

OprCo2EmissionsTabs.propTypes = {};

OprCo2EmissionsTabs.defaultProps = {};

export default OprCo2EmissionsTabs;
