import { ColorBox, Typography } from '@esgian/esgianui';
import React from 'react';

export const getTableCellRenderCII = (value, theme) => {
  const {
    palette: {
      charts: { fiveColorsIndication }
    }
  } = theme;

  let color;
  switch (value) {
    case 'A':
      color = fiveColorsIndication[0];
      break;
    case 'B':
      color = fiveColorsIndication[1];
      break;
    case 'C':
      color = fiveColorsIndication[2];
      break;
    case 'D':
      color = fiveColorsIndication[3];
      break;
    case 'E':
      color = fiveColorsIndication[4];
      break;
    default:
      color = null;
  }
  return color !== null ? (
    <div
      style={{
        margin: '4px',
        minWidth: '2.5em',
        alignSelf: 'center',
        justifyContent: 'center',
        display: 'flex',
        width: 'fit-content',
        fontSize: '0.875rem',
        padding: '8px',
        borderRadius: '10px',
        backgroundColor: `${color}80`
      }}>
      <Typography variant={'body2'} bold>
        {value}
      </Typography>
    </div>
  ) : (
    <Typography sx={{ p: 1, m: '4px' }}>{'-'}</Typography>
  );
};
export const getTableCellRender = (
  max,
  min,
  value,
  inverted = false,
  allowZero = true,
  coloring = true
) => {
  let isMinOrMax = false;
  let color = 'success';
  if (max === value) {
    isMinOrMax = true;
    color = inverted ? 'error' : 'success';
  }
  if (min === value) {
    isMinOrMax = true;
    color = inverted ? 'success' : 'error';
  }
  if (min !== null && max !== null && min === max) {
    if (min === 0 && max === 0) {
      isMinOrMax = false;
    } else {
      isMinOrMax = true;
      color = 'info';
    }
  }

  if (!allowZero && value === 0) {
    return (
      <Typography variant={'caption'} sx={{ p: 1, m: '4px' }}>
        {'-'}
      </Typography>
    );
  }

  if (!coloring) {
    isMinOrMax = false;
  }

  return value !== null && value !== undefined ? (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {isMinOrMax ? (
        <ColorBox color={color}>
          <Typography variant={'caption'} bold>
            {value}
          </Typography>
        </ColorBox>
      ) : (
        <Typography variant={'caption'} sx={{ p: 1, m: '4px' }}>
          {value}
        </Typography>
      )}
    </div>
  ) : (
    <Typography variant={'caption'} sx={{ p: 1, m: '4px' }}>
      {'-'}
    </Typography>
  );
};

export const tableTitlePaddingLeft = {
  MUIDataTableToolbar: {
    styleOverrides: {
      root: {
        paddingLeft: 18
      }
    }
  }
};

export const tableFilterWidth = {
  MUIDataTableFilter: {
    styleOverrides: {
      root: {
        minWidth: 450
      }
    }
  }
};
