import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AdvancedFilterDrawer,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@esgian/esgianui';
import { OperatorMultiSelect } from '@components/Inputs';
import { OPERATOR_SELECT_TYPES } from '@constants';
import { useDispatch, useSelector } from 'react-redux';
import { getLookupOperators } from '@store/features';
import {
  getPortActivityFilters,
  resetPortActivityAdvFilters
} from '@store/features/filters/CommercialAnalyticsPage/PortActivitySlice/PortActivitySlice';

function AdvancedPortActivityFilters({ advancedOpen, setAdvancedOpen, updateFilterValue }) {
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const lookupOperators = useSelector(getLookupOperators);
  const portActivityFilters = useSelector(getPortActivityFilters);

  const { selectedOperators, selectType } = filters || {};

  const handleClearClick = () => {
    dispatch(resetPortActivityAdvFilters());
    setAdvancedOpen(false);
  };

  const handleApplyClick = () => {
    updateFilterValue({
      ...filters,
      ...{
        selectedOperators: selectType !== OPERATOR_SELECT_TYPES.MANUAL ? [] : selectedOperators
      }
    });
    setAdvancedOpen(false);
  };

  const updateTempFilters = (payload) => {
    setFilters({ ...filters, ...payload });
  };
  useEffect(() => {
    setFilters({
      ...portActivityFilters
    });
  }, [portActivityFilters]);

  return (
    <AdvancedFilterDrawer
      anchor={'right'}
      open={advancedOpen}
      handleReset={handleClearClick}
      onClose={() => setAdvancedOpen(false)}
      handleApply={handleApplyClick}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={'section-filter'}>
          <Stack spacing={2}>
            <Stack>
              <Typography variant={'subtitle1'} gutterBottom color={'text.primary'}>
                Display Operators
              </Typography>
              <RadioGroup
                value={selectType}
                onChange={({ target }) => {
                  updateTempFilters({ selectType: target.value });
                }}>
                <FormControlLabel
                  value={OPERATOR_SELECT_TYPES.AUTO}
                  label={<Typography variant={'subtitle2'}>Top 5</Typography>}
                  control={<Radio />}
                />
                <FormControlLabel
                  value={OPERATOR_SELECT_TYPES.DYNAMIC}
                  label={<Typography variant={'subtitle2'}>All dynamic operators</Typography>}
                  control={<Radio />}
                />
                <FormControlLabel
                  value={OPERATOR_SELECT_TYPES.MANUAL}
                  label={<Typography variant={'subtitle2'}>Manually select operators</Typography>}
                  control={<Radio />}
                />
              </RadioGroup>
              {selectType === OPERATOR_SELECT_TYPES.MANUAL && (
                <OperatorMultiSelect
                  placeholder={'Select Operators...'}
                  selectedOperators={selectedOperators}
                  operatorsList={lookupOperators || []}
                  handleChange={(_, values) => updateTempFilters({ selectedOperators: values })}
                />
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </AdvancedFilterDrawer>
  );
}

AdvancedPortActivityFilters.propTypes = {
  advancedOpen: PropTypes.bool,
  setAdvancedOpen: PropTypes.func.isRequired,
  updateFilterValue: PropTypes.func.isRequired
};

AdvancedPortActivityFilters.defaultProps = {
  advancedOpen: false
};

export default AdvancedPortActivityFilters;
