import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import {
  Badge,
  Box,
  Button,
  DateRangeMobi,
  FilterIcon,
  Grid,
  HelpOutlineIcon,
  Stack,
  ToolTip,
  Typography,
  VesselsTreeSelect
} from '@esgian/esgianui';
import VesselComparisonAdvancedFilters from '@components/Sections/EmissionsAnalytics/VesselComparisonSection/VesselComparisonSectionsFilter/VesselComparisonAdvancedFilters';
import {
  initUrlParams,
  setUrlParams
} from '@components/Sections/EmissionsAnalytics/VesselComparisonSection/helpers';
import { useSearchParams } from 'react-router-dom';
import { useSegment } from '@hooks';
import { useDispatch, useSelector } from 'react-redux';
import { getVesselCompSection } from '@store/features/filters/SectionSlice/SectionSlice';
import {
  updateVslCo2EmissionsFilters,
  updateVslFleetCiiFilters
} from '@store/features/filters/EmissionAnalyticsPage/VesselComparisonReducer/VesselComparisonReducer';
import { getDateFormat, getLookupVesselHierarchy } from '@store/features';
import { getVslCo2EmissionsAdvFilterCount } from '@store/features/filters/EmissionAnalyticsPage/Co2EmissionsSlice/Co2EmissionsSlice';
import { getVslFleetCiiAdvFilterCount } from '@store/features/filters/EmissionAnalyticsPage/FleetCiiRatingSlice/FleetCiiRatingSlice';
import { getStartEndMonth } from '@helpers/DatePickerHelpers';

function VesselComparisonSectionsFilter({ setInit, filters, init }) {
  const [advancedOpen, setAdvancedOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMPP, segmentMinDate } = useSegment();
  const dispatch = useDispatch();
  const lookupVesselHierarchy = useSelector(getLookupVesselHierarchy);
  const { ownerHierarchy } = lookupVesselHierarchy;
  const dateFormat = useSelector(getDateFormat);
  const section = useSelector(getVesselCompSection);
  const filterCount = useSelector(
    section === 1 ? getVslCo2EmissionsAdvFilterCount : getVslFleetCiiAdvFilterCount
  );

  const { startDate, endDate, selectedAssets, selectType } = filters;

  const updateFilterValue = useCallback(
    (payload) => {
      if (section === 1) {
        dispatch(updateVslCo2EmissionsFilters(payload));
      } else {
        dispatch(updateVslFleetCiiFilters(payload));
      }
    },
    [section]
  );

  useEffect(() => {
    if (ownerHierarchy?.length) {
      const newFilters = initUrlParams(filters, section, ownerHierarchy, searchParams);
      updateFilterValue(newFilters);
      setInit(true);
    }
  }, [ownerHierarchy, section]);

  useEffect(() => {
    if (init) {
      setUrlParams(filters, section, searchParams, setSearchParams);
    }
  }, [filters, init]);

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} className={'section-filter'}>
          <Stack direction={'row'} spacing={2}>
            <Stack>
              <Typography variant={'subtitle2'} bold gutterBottom color={'text.primary'}>
                Period*
                {section === 'cii' ? (
                  <ToolTip
                    title={
                      'CII rating is an annual rating, therefore please select a period within a year'
                    }>
                    <HelpOutlineIcon sx={{ fontSize: '1rem' }} />
                  </ToolTip>
                ) : (
                  ''
                )}
              </Typography>
              <DateRangeMobi
                onClose={({ value }) => {
                  const { start, end } = getStartEndMonth(value, dateFormat);
                  updateFilterValue({
                    startDate: start,
                    endDate: end
                  });
                }}
                dateFormat={dateFormat}
                minStartDate={segmentMinDate}
                disableFuture
                startDate={moment(startDate, dateFormat)}
                endDate={moment(endDate, dateFormat)}
                placeholder={'Period'}
                dateWheels={'MMM YYYY'}
              />
            </Stack>
            <Stack>
              <Typography variant={'subtitle2'} bold gutterBottom color={'text.primary'}>
                Vessels*
              </Typography>
              <VesselsTreeSelect
                autoEnabledText={'Displaying the top 15 vessels given the selected period'}
                handleClose={(value) => {
                  let selected = value.selected;
                  if (
                    JSON.stringify(selected) === JSON.stringify(selectedAssets) &&
                    value.type === selectType
                  )
                    return;
                  console.log('after return');
                  if (value.type === 'auto') {
                    selected = [];
                  }
                  updateFilterValue({ selectType: value.type, selectedAssets: selected });
                }}
                hideOwner={isMPP}
                autoButtonTitle={'Top 15'}
                selectType={selectType}
                handleSelectTypeChange={() => {}}
                lookupVesselHierarchy={lookupVesselHierarchy}
                selected={selectedAssets}
                loading={!lookupVesselHierarchy}
                placeholder={'Vessels...'}
              />
            </Stack>
            <Badge sx={{ alignSelf: 'flex-end' }} badgeContent={filterCount} color="error">
              <Button
                sx={{ height: 'min-content', mb: '0.6em' }}
                startIcon={<FilterIcon />}
                variant={'text'}
                onClick={() => setAdvancedOpen(true)}>
                Advanced Filter
              </Button>
            </Badge>
          </Stack>
        </Grid>
        <VesselComparisonAdvancedFilters
          filters={filters}
          updateFilterValue={updateFilterValue}
          advancedOpen={advancedOpen}
          setAdvancedOpen={setAdvancedOpen}
        />
      </Grid>
    </Box>
  );
}

VesselComparisonSectionsFilter.propTypes = {
  init: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  setInit: PropTypes.func.isRequired
};

VesselComparisonSectionsFilter.defaultProps = {};

export default VesselComparisonSectionsFilter;
