import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  DatePickerMobi,
  Grid,
  Skeleton,
  TextField,
  Typography
} from '@esgian/esgianui';
import { OperatorMultiSelect } from '@components/Inputs';
import moment from 'moment/moment';
import { DATE_FORMAT } from '@constants';
import { useSearchParams } from 'react-router-dom';
import { activityMapCheckValidFilters } from '@helpers';
import { getRegionActivityByOperators } from '@api';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACTIVITY_MAP_SLICE_KEYS,
  getActivityMapFilters,
  updateActivityMapFilters
} from '@store/features/filters/CommercialAnalyticsPage/ActivityMapSlice/ActivityMapSlice';
import { useSegment } from '@hooks';
import { getLookupOperators } from '@store/features';
import PropTypes from 'prop-types';
import {
  getSelectedOperatorsFromUri,
  getStandardValuesFromUri,
  setSliceToUri
} from '@helpers/uriHelpers';

const setUrlParams = (newFilters, lookupOperators, searchParams, setSearchParams) => {
  const { selectedOperators } = newFilters;
  searchParams = setSliceToUri(newFilters, ACTIVITY_MAP_SLICE_KEYS, 'am', searchParams);

  if (selectedOperators === 'all') {
    searchParams.set(`amSelectedOperators`, 'all');
  } else if (Array.isArray(selectedOperators)) {
    if (selectedOperators.length === lookupOperators.length) {
      searchParams.set(`amSelectedOperators`, 'all');
    } else {
      searchParams.set(`amSelectedOperators`, selectedOperators?.map(({ id }) => id).join(','));
    }
  }

  setSearchParams(searchParams);
};

const initUrlParams = (setTempSelectedDate, activityMapFilters, lookupOperators, searchParams) => {
  let newFilters = { ...activityMapFilters };

  newFilters = getStandardValuesFromUri(newFilters, ACTIVITY_MAP_SLICE_KEYS, 2, searchParams);
  const oprs = getSelectedOperatorsFromUri(
    'amSelectedOperators',
    null,
    lookupOperators,
    searchParams
  );
  if (oprs) {
    newFilters.selectedOperators = oprs;
  } else {
    if (newFilters.selectedOperators === 'all') {
      newFilters.selectedOperators = lookupOperators;
    }
  }
  setTempSelectedDate(newFilters.selectedDate);
  return newFilters;
};

function SectionFilters({ setLoading, setActivityData }) {
  const [init, setInit] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    date: null,
    vesselSize: null,
    minMaxLifting: null
  });
  const [updateData, setUpdateData] = useState(true);
  const [tempSelectedDate, setTempSelectedDate] = useState(moment().format(DATE_FORMAT));
  const [filters, setFilters] = useState({});
  const dispatch = useDispatch();
  const { segment, isRoRo, segmentMinDate } = useSegment();
  const activityMapFilters = useSelector(getActivityMapFilters);
  const lookupOperators = useSelector(getLookupOperators);
  const [searchParams, setSearchParams] = useSearchParams();

  const setFiltersToState = () => {
    let newFilters = {
      ...filters
    };
    newFilters.selectedDate = tempSelectedDate;
    newFilters.selectedOperators =
      filters.selectedOperators.length === lookupOperators.length
        ? 'all'
        : filters.selectedOperators;
    setUrlParams(newFilters, lookupOperators, searchParams, setSearchParams);

    dispatch(updateActivityMapFilters(newFilters));
  };

  const handleFilterChange = useCallback(
    (val) => {
      setFilters({ ...filters, ...val });
    },
    [filters]
  );
  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchData = async () => {
      try {
        const {
          vesselSizeMin,
          selectedOperators,
          vesselSizeMax,
          minLiftingCapacity,
          maxLiftingCapacity
        } = filters;
        if (!activityMapCheckValidFilters(filters, tempSelectedDate, inputErrors, setInputErrors)) {
          return;
        }
        if (selectedOperators?.length === 0) {
          setActivityData(null);
          return;
        }
        setLoading(true);

        await getRegionActivityByOperators(
          moment(tempSelectedDate, DATE_FORMAT).subtract(1, 'month').format(DATE_FORMAT),
          moment(tempSelectedDate, DATE_FORMAT).format(DATE_FORMAT),
          selectedOperators.map(({ id }) => id),
          vesselSizeMin,
          vesselSizeMax,
          minLiftingCapacity,
          maxLiftingCapacity,
          segment.id,
          signal
        )
          .catch(() => {
            if (!signal.aborted) {
              setActivityData(null);
              setLoading(false);
            }
          })
          .then((result) => {
            if (!signal.aborted) {
              setActivityData(result);
              setLoading(false);
            }
          });
        setFiltersToState();
      } catch (error) {
        if (error.name === 'AbortError') {
          // console.log('Found abortError');
        }
      }
    };
    if (init) {
      fetchData();
    }
    return () => {
      controller.abort();
    };
  }, [updateData, init]);

  useEffect(() => {
    if (lookupOperators?.length) {
      let tempFilters = initUrlParams(
        setTempSelectedDate,
        activityMapFilters,
        lookupOperators,
        searchParams
      );
      setFilters(tempFilters);
      setUrlParams(tempFilters, lookupOperators, searchParams, setSearchParams);

      setInit(true);
      setUpdateData(!updateData);
    }
  }, [lookupOperators]);

  const {
    vesselSizeMin,
    vesselSizeMax,
    minLiftingCapacity,
    maxLiftingCapacity,
    selectedOperators
  } = filters;
  return (
    <Grid
      item
      xs={3}
      sx={{
        pr: 2,
        mt: 2,
        borderRight: ({ palette }) => `3px solid ${palette.primary.main}`,
        zIndex: 2,
        boxShadow: 'rgb(44 121 173 / 46%) 2px 0px 3px 0px'
      }}>
      <Grid container spacing={2} sx={{ pr: 2 }}>
        <Grid item xs={12}>
          <Typography id={'filters-options'} variant={'h6'}>
            Filters / Options
          </Typography>
        </Grid>
        <Grid item xs={12} className={'section-filter'}>
          <OperatorMultiSelect
            helperText={init && !selectedOperators.length ? 'No operators selected' : ''}
            error={init && !selectedOperators.length}
            placeholder={'Select Operators...'}
            selectedOperators={selectedOperators}
            operatorsList={lookupOperators || []}
            handleChange={(_, values) => {
              handleFilterChange({ selectedOperators: values });
            }}
          />
        </Grid>
        <Grid item container xs={12} spacing={2} className={'section-filter'}>
          <Grid item xs={6}>
            <Typography
              id={'lbl-vessel-size'}
              variant={'subtitle2'}
              bold
              gutterBottom
              color={'text.secondary'}>
              Vessel Size {isRoRo ? 'Capacity' : 'DWT'}
            </Typography>
            <TextField
              id={'min-vessel-size'}
              value={init ? vesselSizeMin : ''}
              label={init ? '' : <CircularProgress size={25} />}
              onChange={({ target }) => {
                if (target.value > -1) {
                  handleFilterChange({ vesselSizeMin: target.value });
                }
              }}
              type={'number'}
              fullWidth
              placeholder={'Min size...'}
            />
          </Grid>
          <Grid item xs={6} alignSelf={'flex-end'}>
            <TextField
              id={'max-vessel-size'}
              value={init ? vesselSizeMax : ''}
              label={init ? '' : <CircularProgress size={25} />}
              onChange={({ target }) => {
                if (target.value > -1) {
                  handleFilterChange({ vesselSizeMax: target.value });
                }
              }}
              type={'number'}
              fullWidth
              placeholder={'Max size...'}></TextField>
          </Grid>
          <Typography sx={{ pl: 2 }} color={'error'} variant={'caption'}>
            {inputErrors.vesselSize}
          </Typography>
        </Grid>
        {!isRoRo && (
          <Grid item container xs={12} spacing={2} className={'section-filter'}>
            <Grid item xs={6}>
              <Typography
                id={'lbl-vessel-size'}
                variant={'subtitle2'}
                bold
                gutterBottom
                color={'text.secondary'}>
                Lifting Capacity
              </Typography>
              <TextField
                id={'min-vessel-size'}
                value={init ? minLiftingCapacity : ''}
                label={init ? '' : <CircularProgress size={25} />}
                onChange={({ target }) => {
                  handleFilterChange({ minLiftingCapacity: target.value });
                }}
                type={'number'}
                fullWidth
                placeholder={'Min size...'}
              />
            </Grid>
            <Grid item xs={6} alignSelf={'flex-end'}>
              <TextField
                id={'max-vessel-size'}
                value={init ? maxLiftingCapacity : ''}
                label={init ? '' : <CircularProgress size={25} />}
                onChange={({ target }) => {
                  handleFilterChange({ maxLiftingCapacity: target.value });
                }}
                type={'number'}
                fullWidth
                placeholder={'Max size...'}
              />
            </Grid>
            <Typography sx={{ pl: 2 }} color={'error'} variant={'caption'}>
              {inputErrors.minMaxLifting}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} className={'section-filter'}>
          <Typography
            id={'lbl-date-range'}
            variant={'subtitle2'}
            bold
            gutterBottom
            color={'text.secondary'}>
            Date up to
          </Typography>
          {init ? (
            <DatePickerMobi
              sx={{
                inputProps: {
                  sx: { width: '100%' },
                  InputProps: {},
                  defaultValue: undefined,
                  value: moment(tempSelectedDate, DATE_FORMAT).format(DATE_FORMAT) || '-',
                  placeholder: 'Date...'
                }
              }}
              selectedDate={moment(tempSelectedDate, DATE_FORMAT)}
              onClose={({ value }) => {
                setTempSelectedDate(moment(value, DATE_FORMAT).format(DATE_FORMAT));
              }}
              dateFormat={DATE_FORMAT}
              placeholder={'Date...'}
              minStartDate={segmentMinDate}
              disableFuture
            />
          ) : (
            <Skeleton width={'100%'} height={'5em'} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            id={'apply-filter-btn'}
            size={'large'}
            onClick={() => setUpdateData((prev) => !prev)}
            disableElevation
            fullWidth
            variant={'contained'}>
            Apply filters
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

SectionFilters.propTypes = {
  setLoading: PropTypes.func.isRequired,
  setActivityData: PropTypes.func.isRequired
};

SectionFilters.defaultProps = {};

export default SectionFilters;
